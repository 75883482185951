import api from '../../api';
export default {
    async recurringBillGet(params){
        return await api.get('admin/'+params.url,{params});
    },
    recurringBillPost(params){
        return api.post('admin/'+params.url, params);
    },
    getRecordFiles(params){
        return api.get(`admin/get-record-files/${params.id}`,{params});
    },
    deleteRecordFiles(params){
        return api.get(`admin/delete-record-files/${params.id}`)
    }
}