import repository from '@/api/Main/component-setting'
export default {
    state: {
        component_settings:{},
        edit_from_data_table:{},
        new_data_for_data_table:false
    },
    getters: {
     GET_COMPONENT_SETTINGS:(state)=>state.component_settings,
     GET_EDIT_FROM_DATA_TABLE:(state)=>state.edit_from_data_table,
     GET_NEW_DATA_FOR_DATA_TABLE:(state)=>state.new_data_for_data_table,
    },
    actions: {
        async getEditFromDataTable({commit,state},payload){
            return await new Promise((resolve,reject)=>{
                commit('EDIT_FROM_DATA_TABLE',payload)
                resolve(payload)
            }).catch(error=>{
                reject(error)
            })
        },
        post({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.post(payload).then((response)=>{
                    commit('NEW_DATA_FOR_DATA_TABLE',true)
                    resolve(response)
                }).catch(err=>{
                    reject(err)
                })
            })

        },
        put({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.put(payload).then((response)=>{
                    commit('NEW_DATA_FOR_DATA_TABLE',true)
                    resolve(response)
                }).catch(err=>{
                    reject(err)
                })
            })

        },
        delete({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.delete(payload).then((response)=>{
                    commit('NEW_DATA_FOR_DATA_TABLE',true)
                    resolve(response)
                }).catch(err=>{
                    reject(err)
                })
            })

        },
        getAllData({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getAllData(payload).then((response)=>{
                    resolve(response)
                }).catch(err=>{
                    reject(err)
                })
            })

        },
        show({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.show(payload).then((response)=>{
                    resolve(response)
                }).catch(err=>{
                    reject(err)
                })
            })
        }
    },
    mutations: {
        EDIT_FROM_DATA_TABLE: async (state,data)=>{
            state.edit_from_data_table = await state.component_settings.children.data_table.items.find(e=>e.id == data)
        },
        COMPONENT_SETTINGS:(state,data)=>{
            state.component_settings = data
        },
        NEW_DATA_FOR_DATA_TABLE:(state,data)=>{
            state.new_data_for_data_table = data
        }
    },
  };
  