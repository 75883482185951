import api from '../../api'

export default {
    getalldashboardmodules(params){
        return api.get('dashboard/all-modules', {params});
    },
    getalluserdashboards(params) {
        return api.get('dashboard/all-user-dashboards', {params})
    },
    updateuserdashboardlayout(params) {
        return api.post(`/dashboard/update-layout`, params)
    },
    updateuserdashboardheight(params) {
        return api.post(`/dashboard/update-height`, params)
    }
}
