<template>
    <v-container>
        <ComponentHeader :button="true" :title="'Repair Form Count Per Status'" :type="'Service'"></ComponentHeader>
        <v-card class="bg-bluish-gray m-1 p-3">
            <v-card-text>
                <v-row>
                    <v-col lg="10">
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-autocomplete :items="dropdown_status"
                                    multiple
                                    small-chips
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    v-model="filters.selected_status"
                                    background-color="white"
                                    label="Select RF Status"
                                    auto-select-first
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-autocomplete :items="GET_ITEM_SELECTION_DEFAULT"
                                    multiple
                                    small-chips
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    v-model="filters.item_ids"
                                    item-value="value"
                                    item-text="text"
                                    background-color="white"
                                    label="Select Items Model"
                                    auto-select-first
                                >
                                </v-autocomplete>
                            </v-col>
                            <!-- <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-autocomplete :items="GET_ALL_TECHNICIANS"
                                    multiple
                                    outlined
                                    dense
                                    deletable-chips
                                    v-model="filters.technician_id"
                                    item-value="id"
                                    item-text="name"
                                    background-color="white"
                                    label="Select Technicians"
                                    clearable
                                    small-chips
                                >
                                </v-autocomplete>
                            </v-col> -->
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="2" xl="2" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-center' : 'justify-content-end' )">
                        <v-btn small @click="resetFields()" class="mr-1">Clear </v-btn>
                        <v-btn small @click="list(1)" class="mr-1">Export </v-btn>
                        <v-btn small @click="list()">Filter </v-btn>
                    </v-col>
                </v-row>
                <v-row class="py-5">
                    <v-col lg="10">
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-menu
                                v-model="menu.date_from"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_from"
                                        readonly
                                        append-icon="mdi-calendar-clock"
                                        v-on="on"
                                        dense
                                        hide-details
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_from" @input="menu.date_from = false"></v-date-picker>
                            </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-menu
                                    v-model="menu.date_to"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="filters.date_to"
                                            readonly
                                            append-icon="mdi-calendar-clock"
                                            v-on="on"
                                            dense
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.date_to" @input="menu.date_to = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-checkbox v-model="filters.with_parts" label="With Parts" true-value="1" false-value="0">

                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col lg="12" v-for="(item,index) in filters.status" :key="index" >
                        <v-card v-if="!!dataTable.items[item]" class="my-3">
                            <v-card-title>
                                {{ item }}: {{ !!dataTable.items[item] ? dataTable.totals[item][0].count : 0 }}
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :items="dataTable.items[item]"
                                    :headers="dataTable.headers"
                                    dense
                                >
                                    <template v-slot:[`item.status_date`]="{ item }">
                                        <span class="text-nowrap">
                                            {{ item.status_date | formatDate }}
                                        </span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dataTable.loading" width="100%" >
            <v-progress-circular
                size="100"
                :width="10"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </v-dialog>
    </v-container>
</template>

<script>
import ComponentHeader from "@/views/main/layouts/Reports/ReportsHeaderComponent.vue";
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { mapGetters } from "vuex";
export default {
    mixins:[SharedFunctionsComponent],
    components:{
        ComponentHeader
    },
    data(){
        return {
            filters:{
                status:[],
                selected_status:[],
                item_ids:'',
                with_parts:'0',
                date_from:this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to:this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                with_installed:'0',
                technician_id:[]
            },
            menu:{
                date_from:false,
                date_to:false,
            },
            dataTable:{
                items:[],
                headers:[
                    { text:'RF', value:'rf_number' },
                    { text:'Unit', value:'model' },
                    { text:'Customer', value:'customer' },
                    // { text:'Parts', value:'parts'},
                    // { text:'Qty Requested', value:'requested_qty'},
                    // { text:'Qty Served', value:'serve_qty'},
                    { text:'Repair Status', value:'repair_status' },
                    { text:'Warranty', value:'warranty_type' },
                    { text:'Serial', value:'serial' },
                ],
                totals:[],
                options:{},
                totalItems:0,
                loading:false,
                filtered:false
            },
            dropdown_status: [],
        }
    },

    mounted(){
        this.$store.dispatch('getAllitemSelectionDefault')
        this.$store.dispatch('getAllProcessingTabs')
        this.$store.dispatch('getAllTechnicians')
        this.$store.dispatch('getServiceCustomers')
    },
    computed:{
        ...mapGetters([
            'GET_ITEM_SELECTION_DEFAULT',
            'GET_ALL_PROCESSING_TABS',
            'GET_SERVICE_CUSTOMERS_END_USER',
            'GET_ALL_TECHNICIANS'
        ]),
    },
    methods:{
        async list(exportToExcel = 0){

            this.dataTable.loading = true
            let filters = this.filters

            filters.status = filters.selected_status.length == 0 ? this.GET_ALL_PROCESSING_TABS : this.filters.selected_status
            Object.assign(filters,{
                exportToExcel:exportToExcel
            })
            await this.$store.dispatch('serviceGet',{
                url:'service-repair',
                ...filters
            }).then(async response=>{
                response.data = response.data.map(e=>{
                    e.created_at = this.formatDateandTime(e.created_at)
                    e.customer = this.GET_SERVICE_CUSTOMERS_END_USER.find(el=>el.id == e.rr_id).customer
                    return e
                })

                this.dataTable.items = this.divideArrayByAttribute(response.data,'status','object')
                let exportHeadersAndData = {};
                if(exportToExcel == 1){
                    filters.status.forEach(e=>{
                        if(this.filters.with_parts == 0){
                            exportHeadersAndData[e] = {
                                headers: [
                                    { header:'RF', key:'rf_number' },
                                    { header:'Unit', key:'model' },
                                    { header:'Repair Status', key:'repair_status' },
                                    { header:'Warranty', key:'warranty_type' },
                                    { header:'Serial', key:'serial' },
                                ],
                                data:[]
                            }
                        }else{
                            exportHeadersAndData[e] = {
                                headers : [
                                    { header:'RF', key:'rf_number' },
                                    { header:'Unit', key:'model' },
                                    { header:'Parts', key:'parts', width: 15 },
                                    { header:'Qty Requested', key:'requested_qty', width: 14 },
                                    { header:'Qty Serve', key:'serve_qty'},
                                    { header:'Repair Status', key:'repair_status', width: 14 },
                                    { header:'Warranty', key:'warranty_type', width: 13 },
                                    { header:'Serial', key:'serial' },
                                    { header:'Status Date', key:'status_date', width: 11 },
                                ],
                                data:[]
                            }
                        }
                        if(!!this.dataTable.items[e] ? this.dataTable.items[e].length : 0 > 0){
                            this.dataTable.items[e].forEach(el => {
                                el.status_date = this.formatDate(el.status_date);
                            })
                            exportHeadersAndData[e].data = this.dataTable.items[e];
                        }
                    })
                    await this.exportExcel(exportHeadersAndData, `Rf-count${this.$dayjs().format('YYYY-MM-DD')}`);
                }
                this.rfCounts();
                this.dataTable.loading = false
                this.filters.selected_status = []
            })
        },
        async rfCounts(){
            await this.$store.dispatch('serviceGet',{
                url:'service-repair-count',
                ...this.filters
            }).then(r=>{
                this.dataTable.totals = this.divideArrayByAttribute(r.data,'status','object')
            })
        },
        resetFields(){
            Object.assign(this.$data, this.$options.data.call(this));
        }
    },
    watch:{
        'GET_ALL_PROCESSING_TABS':{
            handler(val){
                const desiredElements = ["PRQ", "PP", "CF", "SPPT", "PIN", "RFD", "DEL", "COL", "PFO"];
                this.dropdown_status = val.filter(e => desiredElements.includes(e))
            }
        },
        'filters.with_parts':{
            handler(val){
                if(val == 0){
                    this.dataTable.headers = [
                        { text:'RF', value:'rf_number' },
                        { text:'Unit', value:'model' },
                        { text:'Customer', value:'customer' },
                        { text:'Repair Status', value:'repair_status' },
                        { text:'Warranty', value:'warranty_type' },
                        { text:'Serial', value:'serial' },
                    ]
                }else{
                    this.dataTable.headers = [
                        { text:'RF', value:'rf_number' },
                        { text:'Unit', value:'model'},
                        { text:'Parts', value:'parts'},
                        { text:'Qty Requested', value:'requested_qty'},
                        { text:'Qty Served', value:'serve_qty'},
                        { text:'Customer', value:'customer' },
                        { text:'Repair Status', value:'repair_status' },
                        { text:'Warranty', value:'warranty_type' },
                        { text:'Serial', value:'serial' },
                        { text:'Status Date', value:'status_date' },
                    ]
                }
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    ::v-deep .v-dialog{
        box-shadow: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
</style>
