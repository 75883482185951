import repository from '@/api/modules/Accounting/CashAdvance/index.js'
export default {
    state:{
        ACTION: {}
    },
    getters:{
        COMPONENT_INFO: (state) => state
    },
    actions:{
        cashAdvanceGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cashAdvanceGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        cashAdvancePost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cashAdvancePost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        cashAdvancePut({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cashAdvancePut(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        cashAdvanceDelete({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cashAdvanceDelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        cashAdvanceUpload({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cashAdvanceUpload(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        cashAdvanceGetFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cashAdvanceGetFiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        cashAdvanceDeleteFiles({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cashAdvanceDeleteFiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
    },
    mutations:{
        COMPONENT_INFO: (state, data) => { (state.ACTION = data) }
    }
}
