import api from '../../../api';

export default {
  addBooklet(params){
    return api.post('accounting/addBooklet', params);
  },
  createChequeVoucher(params){
    return api.post('accounting/createChequeVoucher', params);
  },
  getBooklet(params){
    return api.get(`accounting/getBooklet/${params}`);
  },
  getBooklets(params){
    return api.get('accounting/getBooklets', {params});
  },
  getChequeVoucher(params){
    return api.get(`accounting/getChequeVoucher/${params}`);
  },
  getChequeVouchers(params){
    return api.get('accounting/getChequeVouchers', {params});
  },
  getNextChequeNumber(params){
    return api.get('accounting/getNextChequeNumber', {params});
  },
  pulseBooklet(params){
    return api.get(`accounting/pulseBooklet/${params}`);
  },
  removeChequeVoucher(params){
    return api.delete(`accounting/removeChequeVoucher/${params}`);
  },
  selectBooklet(params){
    return api.get('accounting/selectBooklet', {params});
  },
  updateChequeVoucher(params){
    return api.put(`accounting/updateChequeVoucher`, params);
  },
  replaceChequeVoucher(params){
    return api.post(`accounting/replaceChequeVoucher`, params);
  },
  updateClearedDate(params){
    return api.post(`accounting/updateClearedDate`, params);
  },
  getBookletList(params){
    return api.get(`accounting/getBookletList/${params}`);
  },
  cancelBooklet(params){
    return api.post(`accounting/cancelBooklet/${params.id}`, params);
  },
  cancelBookletParent(params){
    return api.get(`accounting/cancelBookletParent/${params}`);
  },
  cancelBooklets(params){
    return api.post(`accounting/cancelBooklets`, params);
  },
}