import api from '../../../api'
export default {
    async servicePaymentRequestGet(params){
        return await api.get('accounting/service-payment-request/'+params.url,{params})
    },
    servicePaymentRequestPost(params){
        return api.post('accounting/service-payment-request/'+params.url, params)
    },
    async servicePaymentRequestTypeGet(params){
        return await api.get('accounting/service-payment-request-type/'+params.url,{params})
    },
    servicePaymentRequestTypePost(params){
        return api.post('accounting/service-payment-request-type/'+params.url, params)
    },
    getServicePaymentRequestFiles(params){
        return api.get(`accounting/get-service-payment-request-files/${params.id}`,{params});
    },
    deleteServicePaymentRequestFiles(params){
        return api.get(`accounting/delete-service-payment-request-files/${params.id}`)
    }
}
