import repository from '@/api/Main/SystemSettings/GeneralSettings.js';
import store from '@/store';
import Swal from 'sweetalert2';
export default {
  state: {
    new_password_default:false,
    general_settings:{},
    email_settings: [],
    new_bio_metric_api:false,
    bio_metric_api:[],
    new_bio_metric_port:false,
  },
  getters: {
    GET_NEW_PASSWORD_DEFAULT:(state)=>state.new_password_default,
    GET_GENERAL_SETTINGS:(state)=>state.general_settings,
    GET_EMAIL_SETTINGS:(state)=>state.email_settings,
    GET_BIO_API_SETTINGS:(state)=>state.bio_metric_api,
    GET_NEW_BIO_API_SETTINGS:(state)=>state.new_bio_metric_api,
    GET_NEW_BIO_PORT_SETTINGS:(state)=>state.new_bio_metric_port
  },
  actions: {
    passwordDefault({commit},payload){
      repository.passwordDefault(payload).then((response)=>{
        commit('NEW_PASSWORD_DEFAULT',true)
      }).catch((error) =>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      });
    },
    GeneralSettings({commit}){
        return new Promise((resolve, reject) => {
            repository.GeneralSettings().then((response)=>{

              commit('GENERAL_SETTINGS',response.data);

              }).catch((err) => {
                    reject(err);
                    console.log(err)
            });
        });
    },
    getEmailSettings({commit},payload){
        return new Promise((resolve, reject) => {
            repository.getemailsettings(payload).then((response)=>{
              resolve(response.data)
              commit('EMAIL_SETTINGS',response.data);

              }).catch((err) => {
                    reject(err);
                    console.log(err)
            });
        });
    },
    addModuleEmailSettings({commit},payload){
        return new Promise((resolve, reject) => {
            repository.addmoduleemailsettings(payload).then((response)=>{
              resolve(response.data)
              }).catch((err) => {
                    reject(err);
                    console.log(err)
            });
        });
    },
    addNewEmailModule({commit},payload){
        return new Promise((resolve, reject) => {
            repository.addNewEmailModule(payload).then((response)=>{
              resolve(response.data)
              }).catch((err) => {
                    reject(err);
                    console.log(err)
            });
        });
    },
    BioApiSettings({commit},payload){
      repository.BioApiSettings(payload).then((response)=>{
        commit('NEW_BIO_API_SETTINGS',true)
      }).catch((error) =>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      });
    },
    getBioApiSettings({commit}){
      repository.getBioApiSettings().then((response)=>{
        commit('BIO_API_SETTINGS',response.data)
      }).catch((error) =>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      });
    },
    BioPortSettings({commit},payload){
      repository.BioPortSettings(payload).then((response)=>{
        commit('NEW_BIO_PORT_SETTINGS',true)
      }).catch((error) =>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      });
    },
    getPortSettings({commitm},payload){
     return new Promise((resolve,reject)=>{
      repository.getPortSettings(payload).then((response)=>{
        resolve(response.data)
      }).catch((error) =>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      });
     })
    },
    getMigrationPendingList({commit},payload){
      return new Promise((resolve,reject)=>{
        repository.getMigrationPendingList().then((response)=>{
          resolve(response.data)
        }).catch((error) =>{
          Swal.fire({
            title:'ERROR',
            icon:'error'
          })
          reject(error)
        });
      })
    },
    postMigrationPendingList({commit},payload){
      return new Promise((resolve,reject)=>{
        repository.postMigrationPendingList(payload).then((response)=>{
          resolve(response.data)
        }).catch((error) =>{
          Swal.fire({
            title:'ERROR',
            icon:'error'
          })
          reject(error)
        });
      })
    },
    optimizeServer({commit}){
      return new Promise((resolve,reject)=>{
        repository.optimizeServer().then((response)=>{
          resolve(response.data)
        }).catch((error) =>{
          Swal.fire({
            title:'ERROR',
            icon:'error'
          })
          reject(error)
        });
      })
    },
    getChannelLogs({commit}){
      return new Promise((resolve,reject)=>{
        repository.getChannelLogs().then((response)=>{
          resolve(response.data)
        }).catch((error) =>{
          console.error(error);
          reject(error)
        });
      })
    },
    getChannelLogsByFile({commit},payload){
      return new Promise((resolve,reject)=>{
        repository.getChannelLogsByFile(payload).then((response)=>{
          resolve(response.data)
        }).catch((error) =>{
          console.error(error);
          reject(error)
        });
      })
    },
    sendEmailForTesting({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.sendEmailForTesting(payload).then((response)=>{
                resolve(response.data)
            }).catch((error) =>{
                console.error(error);
                reject(error)
            });
        })
    },
    getMailers({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.getMailers(payload).then((response)=>{
                resolve(response.data)
            }).catch((error) =>{
                console.error(error);
                reject(error)
            });
        })
    },
  },
  mutations: {
    NEW_PASSWORD_DEFAULT:(state,data)=>{
        state.new_password_default = data
    },
    GENERAL_SETTINGS:(state,data)=>{
        state.general_settings = data
    },
    EMAIL_SETTINGS:(state,data)=>{
        state.email_settings = data
    },
    NEW_BIO_API_SETTINGS:(state,data)=>{
        state.new_bio_metric_api = data
    },
    BIO_API_SETTINGS:(state,data)=>{
      state.bio_metric_api = data
    },
    NEW_BIO_PORT_SETTINGS:(state,data)=>{
      state.new_bio_metric_port = data
  },
  },
}
