import repository from '@/api/Main/SystemSettings/index';
import store from '@/store';
import Swal from 'sweetalert2';
export default {
  state: {
    navigation_drawer_list:[],
    icons_list:[],
    new_navigation_drawer:false,
    edit_navigation_drawer:[],
    new_status_table:false,
    status_table:[],
    status_table_total:0,

    uploadedByDrawer: undefined,
    reports: [],
  },
  getters: {
    GET_NAVIGATION_DRAWER:(state) => state.navigation_drawer_list,
    GET_ICONS_LIST:(state) => state.icons_list,
    GET_NEW_NAVIGATION_DRAWER:(state) => state.new_navigation_drawer,
    GET_EDIT_NAVIGATION_DRAWER:(state) => state.edit_navigation_drawer,
    GET_NEW_STATUS_TABLE:(state)=>state.new_status_table,
    GET_STATUS_TABLE:(state)=>state.status_table,
    GET_NEW_STATUS_TABLE:(state)=>state.new_status_table,
    GET_UPLOADED_BY_DRAWER:(state)=>state.uploadedByDrawer,
    GET_REPORTS:(state)=>state.reports,
  },
  actions: {
    addNavigationDrawer({commit},payload){
      repository.addnavigationdrawer(payload).then((response)=>{
        store.dispatch('SocketReload');
        commit('NEW_NAVIGATION_DRAWER',true)
      }).catch((error) =>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      });
    },
    NavigationDrawerList({commit}){
      repository.getnavigationdrawer().then((response)=>{
        commit('NAVIGATION_DRAWER_LOAD',response.data)

      }).catch((error) => {
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      })
    },
    IconsList({commit}){
      repository.geticons().then((response)=>{
        commit('ICONS_LOAD',response.data);
      })
    },
    deleteActions({commit},payload){
      repository.deleteActions(payload).then((response)=>{

      })
    },
    addNavlinkaction({commit},payload){
      return new Promise((resolve,reject)=>{
        repository.addnavlinkaction(payload).then(response=>{
          resolve(response)
        }).catch((error)=>{
          reject(error)
        })
      })
    },
    SaveallNavlinkaction({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.savenavlinkaction(payload).then(response=>{
              resolve(response)
            }).catch((error)=>{
              reject(error)
            })
          })
    },
    saveNavigationDrawer({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.saveNavigationDrawer(payload).then(response=>{
              resolve(response)
            }).catch((error)=>{
              reject(error)
            })
          })
    },
    DeleteNavigationDrawer({commit},payload){
      repository.deletenavigation(payload).then((response)=>{
        Swal.fire({
          title:'Success',
          icon:'success',
          timer:1500,
          allowOutsideClick:false,
        })
        store.dispatch('SocketReload');
        commit('NEW_NAVIGATION_DRAWER',true)
      }).catch((error)=>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      })

    },
    updateNavigationDrawer({commit},payload){
      repository.updatenavigationdrawer(payload).then((response)=>{
        Swal.fire({
          title:'Success',
          icon:'success',
          timer:1500,
          allowOutsideClick:false,
        })
        store.dispatch('SocketReload');
        commit('NEW_NAVIGATION_DRAWER',true)
      }).catch((error)=>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      })
    },
    updateNavigationDrawerAction({commit},payload){
      repository.updatenavigationdraweraction(payload).then((response)=>{
        Swal.fire({
          title:'Success',
          icon:'success',
          timer:1500,
          allowOutsideClick:false,
        })
        store.dispatch('SocketReload');
        commit('NEW_NAVIGATION_DRAWER',true)
      }).catch((error)=>{
        Swal.fire({
          title:'ERROR',
          icon:'error'
        })
        console.log(error)
      })
    },
    GetAllStatus({commit,state},payload){
      commit('STATUS_TABLE',[]);
			return new Promise((resolve,reject)=>{
				repository.GetAllStatus(payload).then((response)=>{
					commit('STATUS_TABLE',response.data.data.data);
          state.status_table_total =  response.data.data.total
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		AddStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.AddStatus(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		UpdateStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateStatus(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteStatus(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		accessReports({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.accessReports(payload).then((response)=>{
					commit('REPORTS',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
  },
  mutations: {
    ICONS_LOAD: (state,data) =>{
      state.icons_list = data
    },
    NAVIGATION_DRAWER_LOAD:(state,data) => {
      state.navigation_drawer_list = data;
    },
    NEW_NAVIGATION_DRAWER:(state,data) => {
      (state.new_navigation_drawer = data)
    },
    EDIT_NAVIGATION_DRAWER:(state,data) =>{
      (state.edit_navigation_drawer = data);
    },
    UPLOADED_BY_DRAWER:(state,data) =>{
      state.uploadedByDrawer = data;
    },
    NEW_STATUS_TABLE:(state,data)=>{
      state.new_status_table = data
    },
    STATUS_TABLE:(state,data)=>{
      state.status_table = data
    },
    REPORTS:(state,data)=>{
      state.reports = data
    },
  },
}
