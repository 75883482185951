import repository from '@/api/modules/Dashboard/Accounting'

export default {
    state: {
        paid_soa_amount: [],
        current_vs_overdue: [],
        credit_memo_breakdown: [],
        dated_cheque: [],
        post_dated_cheque: [],
        top_customer_current:[],
        allocated_payments:[],
        soa_per_month:[],
        cash_term_with_balance:[],
    },
    getters: {
        GET_SOA_PAID_AMOUNT: state => state.paid_soa_amount,
        GET_CURRENT_VS_OVERDUE: state => state.current_vs_overdue,
        GET_CREDIT_MEMO_BREAKDOWN: state => state.credit_memo_breakdown,
        GET_DATED_CHEQUE: state => state.dated_cheque,
        GET_POST_DATED_CHEQUE: state => state.post_dated_cheque,
        GET_TOP_CUSTOMER_CURRENT: state => state.top_customer_current,
        GET_ALLOCATED_PAYMENTS: state => state.allocated_payments,
        GET_SOA_PER_MONTH: state => state.soa_per_month,
        GET_CASH_TERM_WITH_BALANCE: state => state.cash_term_with_balance,
    },
    actions: {
        getAccountingDashboard({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getAccountingDashboard(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit(payload.mutation_name, response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        SOA_PAID_AMOUNT: (state, data) => {
            state.paid_soa_amount = data
        },
        CURRENT_VS_OVERDUE: (state, data) => {
            state.current_vs_overdue = data
        },
        CREDIT_MEMO_BREAKDOWN: (state, data) => {
            state.credit_memo_breakdown = data
        },
        DATED_CHEQUE: (state, data) => {
            state.dated_cheque = data
        },
        POST_DATED_CHEQUE: (state, data) => {
            state.post_dated_cheque = data
        },
        TOP_CUSTOMER_CURRENT: (state, data) => {
            state.top_customer_current = data
        },
        ALLOCATED_PAYMENTS: (state, data) => {
            state.allocated_payments = data
        },
        SOA_PER_MONTH: (state, data) => {
            state.soa_per_month = data
        },
        CASH_TERM_WITH_BALANCE: (state, data) => {
            state.cash_term_with_balance = data
        },
    }
}
