import repository from "@/api/modules/Procurement/PurchaseOrder";

export default {
    state: {
        po_purchase_order:[],
        po_item_selection:[],
        po_details_item:[],
        po_details:[],
        po_export:[],
        incoming_stocks:[],
        average_consumption:[],
        pending_orders:[],
        po_item_stocks:[],
        po_export_raw_data:[],
        po_export_item_detail:[],

    },
    getters: {
        GET_PO_PURCHASE_ORDER: (state) => state.po_purchase_order,
        GET_PO_ITEM_SELECTION: (state) => state.po_item_selection,
        GET_PO_DETAILS_ITEM: (state) => state.po_details_item,
        GET_PO_DETAILS: (state) => state.po_details,
        GET_PO_EXPORT: (state) => state.po_export,
        GET_INCOMING_STOCKS: (state) => state.incoming_stocks,
        GET_AVERAGE_CONSUMPTION: (state) => state.average_consumption,
        GET_PENDING_ORDERS: (state) => state.pending_orders,
        GET_PO_ITEM_STOCKS: (state) => state.po_item_stocks,
        GET_PO_EXPORT_RAW_DATA: (state) => state.po_export_raw_data,
        GET_PO_EXPORT_ITEM_DETAILS: (state) => state.po_export_item_detail,

    },
    actions: {
        getPoPurchaseOrder({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getpopurchaseorder(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PO_PURCHASE_ORDER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPoItemSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getpoitemselection(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PO_ITEM_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPoDetailsItem({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getpodetailsitem(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PO_DETAILS_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPoDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getpodetails(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PO_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storePo({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.storepo(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPoExport({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getpoexport(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PO_EXPORT', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getIncomingStocks({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getincomingstocks(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('INCOMING_STOCKS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAverageConsumption({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getaverageconsumption(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('AVERAGE_CONSUMPTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPendingOrders({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getpendingorders(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PENDING_ORDERS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPoItemStocks({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitemstocks(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_STOCKS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        importOrderTemplate({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.importordertemplate(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        poUpdate({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.poupdate(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        submitForChecking({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.submitforchecking(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyPo({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroypo(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        exportRawData({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.exportrawdata(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PO_EXPORT_RAW', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        exportItemDetail({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.exportitemdetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PO_EXPORT_ITEM_DETAIL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        purchaseOrderGet({ commit }, payload){
            return new Promise((resolve, reject) => {
                repository.purchaseOrderGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        purchaseOrderPost({ commit }, payload){
            return new Promise((resolve, reject) => {
                repository.purchaseOrderPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        }
    },
    mutations: {
        PO_PURCHASE_ORDER: (state, data) => { (state.po_purchase_order = data) },
        PO_ITEM_SELECTION: (state, data) => { (state.po_item_selection = data) },
        PO_DETAILS_ITEM: (state, data) => { (state.po_details_item = data) },
        PO_DETAILS: (state, data) => { (state.po_details = data) },
        PO_EXPORT: (state, data) => { (state.po_export = data) },
        INCOMING_STOCKS: (state, data) => { (state.incoming_stocks = data) },
        AVERAGE_CONSUMPTION: (state, data) => { (state.average_consumption = data) },
        PENDING_ORDERS: (state, data) => { (state.pending_orders = data) },
        ITEM_STOCKS: (state, data) => { (state.po_item_stocks = data) },
        PO_EXPORT_RAW: (state, data) => { (state.po_export_raw_data = data) },
        PO_EXPORT_ITEM_DETAIL: (state, data) => { (state.po_export_item_detail = data) },
    }
};
