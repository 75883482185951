import api from '../../../api'
export default{

    wpfget(params){
      return api.get(`/inventory/`+params.url,{params})
    },
    wpfpost(params){
      return api.post(`/inventory/`+params.url,params)
    },
    wpfput(params){
      return api.put(`/inventory/`+params.url,params)
    },
    wpfdelete(params){
      return api.delete(`/inventory/`+params.url,params)
    },
    storewarehousepreparationlistfile(params){
      return api.post('/inventory/upload-wp-list-files',params)
    }

}
