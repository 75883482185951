import repository from '@/api/credentials'

const routes = [{
    path: '/marketing/onlinetracking',
    name: 'webscraping',
    component: () => import('@/views/main/modules/Marketing/OnlineTracking/OnlineTrackingGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Online Tracking',
    },
}]

export default routes;
