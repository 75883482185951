import repository from '@/api/modules/MasterData'

export default {
  state: {
    warehouse: [],
    selected_warehouse: [],
    new_warehouse: false,
    warehouse_loaded: false,
  },
  getters: {
    GET_WAREHOUSE_LIST: state => state.warehouse,
    GET_SELECTED_WAREHOUSE: state => state.selected_warehouse,
    NEW_WAREHOUSE: state => state.new_warehouse,
    GET_WAREHOUSE_LOADED: state => state.warehouse_loaded,
  },
  actions: {
    async getWarehouse({ commit }, payload) {
      return await new Promise((resolve,reject)=>{
        repository.warehouselist(payload).then(response => {
          let res = response.data;
            resolve(res.data)
            commit('WAREHOUSE_LOADED',false)
            commit('WAREHOUSE_LIST', res);

          }).catch(err=>{
            reject(err)
          });
      })
    },
    addNewwarehouse({ commit }, payload) {
      return new Promise((resolve,reject)=>{
        repository.addwarehouse(payload).then((response) => {
          resolve(response)
          commit('NEW_WAREHOUSE', true);
        }).catch((error)=>{
          reject(error)
        });
			})
    },
    addItemsToNewWarehouse({ commit }, payload) {
      repository.addwarehouseitems(payload);
    },
    Updatewarehouse({ commit }, payload) {
      const params = {
        id: payload.id,
        name: payload.payload.name,
        code: payload.payload.code,
        active: payload.payload.active,
        default: payload.payload.default,
        virtual: payload.payload.virtual,
        allow_butal: payload.payload.allow_butal,
        actual: payload.payload.actual,
        service: payload.payload.service,
      };
      return new Promise((resolve,reject)=>{
        repository.updatewarehouse(params).then(response => {
          commit('NEW_WAREHOUSE', true);
          resolve(response)
        }).catch( error => {
          reject(error)
        })
      })
    },
    deletewarehouse({ commit }, payload) {
      repository.deletewarehouse(payload).then(response => {
        commit('DELETED_WAREHOUSE', payload);
      })
    },
  },
  mutations: {
    WAREHOUSE_LIST: (state, data) => {
        state.warehouse = data;
    },
    NEW_WAREHOUSE: (state, data) => {
      state.new_warehouse = data;
    },
    SELECTED_WAREHOUSE: (state, data) => {
      state.selected_warehouse = data;
    },
    EDITED_WAREHOUSE: (state, payload) => {
      const index = state.warehouse.findIndex(t => t.id == payload.id);
      state.warehouse.splice(index, 1, payload);
    },
    DELETED_WAREHOUSE: (state, id) => {
      state.warehouse = state.warehouse.filter(warehouse => warehouse.id !== id);
    },
    WAREHOUSE_LOADED: (state, data) => {
      state.warehouse_loaded = data;
    },
  },
};
