import repository from '@/api/modules/Admin/internationalPayment'
import store from '@/store';
export default {
	state: {

	},
	getters: {
        INTERNATIONAL_PAYMENT:(state)=>state,
	},
	actions:{
        internationalPaymentPost({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.internationalPaymentPost(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
        internationalPaymentGet({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.internationalPaymentGet(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
	},
	mutations:{

	},
}
