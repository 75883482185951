import store from '../../../store';
import api from '../../api'

export default {
   CreateRRF(params) {
      return api.post('/admin/repairrequestform', params)
   },
   UpdateRRF(params) {
      return api.put(`/admin/repairrequestform/${params.id}`, params)
   },
   DeleteRRF(params) {
      return api.delete(`/admin/repairrequestform/${params}`)
   },
   getAllRRF(params) {
      return api.get('/admin/getAllRRF', { params })
   },
   getAllRRFItems(params) {
      return api.get('/admin/getAllRRFItems', { params })
   },
   getSelectedRrfsInformation(params) {
      return api.get('/admin/getSelectedRrfsInformation', { params })
   },
   getRRFItems(params) {
      return api.get('/admin/getRRFItems', { params })
   },
   rrfPrintData(params) {
      return api.get('/admin/rrfPrintData', { params })
   },
   getAllRRFNum(params){
      return api.get('/dropdown/getAllRRFNum',{ params });
   },
   getAllRepairType(params){
      return api.get('/admin/getAllRepairType',{ params });
   },
   EnterRepairTypePost(params){
      return api.post(`/admin/${params.url}`,params);
   },
   EnterRepairTypeGet(params){
      return api.get(`/admin/${params.url}`,{params});
   },


}

