import repository from '@/api/modules/Accounting/Jobs/jobs.js'
export default {
    state:{
        ACTION: ''
    },
    getters:{
        GET_ACCOUNTING_JOBS: (state) => state
    },
    actions:{
        jobsGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.jobsGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        jobsPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.jobsPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
    },
    mutations:{
        ACCOUNTING_JOBS: (state, data) => { (state.ACTION = data) }
    }
}
