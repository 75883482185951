import api from '../../../api'
export default {
    creditBillIndex(params){
        return api.get(`/accounting/${params.url}`,{params})
    },
    creditBillStore(params){
        return api.post(`/accounting/${params.url}`,params)
    },
    creditBillUpdate(params){
        return api.put(`/accounting/${params.url}/${params.id}`,params)
    },
    creditBillDestroy(params){
        return api.delete(`/accounting/${params.url}/${params.id}`,params)
    },
    creditBillApproveAll(params){
        return api.post(`/accounting/${params.url}`,params)
    },
}