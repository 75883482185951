import repository from '@/api/modules/PartsTransfer/index'
export default {
	state: {
        DIALOG:{
            VALUE:false,
            ACTION:''
        },
        DATA_TABLE:{
            ITEMS:[],
            SELECTED:{
                data:{
                    series:''
                }
            }
        },
        TAB:0,
        ACCESS:[]
	},
	getters: {
        PARTS_REPLACEMENT: (state) => state,
	},
	actions:{
        // async partsTransferGet({commit},payload) {
        //     return await new Promise((resolve, reject) => {
        //         repository.partsTransferGet(payload).then((response) => {
        //             resolve(response)
        //             if(!!payload.mutations){
        //                 this.commit(payload.mutations,response)
        //             }
        //         }).catch((error) => {
        //             reject(error)
        //         });
        //     })
        // },
        // partsTransferPost({commit},payload) {
        //     return new Promise((resolve, reject) => {
        //         repository.partsTransferGet(payload).then((response) => {
        //             resolve(response)
        //             if(!!payload.mutations){
        //                 this.commit(payload.mutations)
        //             }
        //         }).catch((error) => {
        //             reject(error)
        //         });
        //     })
        // }
	},
	mutations:{
        TOGGLE_ADD_DIALOG: (state, data) => { (state.DIALOG.VALUE = data) },
        DATA_TABLE: (state, data) => { (state.DATA_TABLE.ITEMS = data) },
        DIALOG_ACTION: (state, data) => { (state.DIALOG.ACTION = data) },
        DATA_TABLE_SELECTED: (state, data) => { (state.DATA_TABLE.SELECTED = data) },
        CHECK_ACCESS: (state, data) => { (state.ACCESS = data) },
	},
}
