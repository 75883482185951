import store from '../../../store';
import api from '../../api'

export default {
   CreateEnterBill(params){
      return api.post('/admin/enterbill',params)
   },
   UpdateEnterBill(params){
      return api.put(`/admin/enterbill/edit`,params)
   },
   DeleteEnterBill(params){
      return api.delete(`/admin/enterbill/${params}`)
   },
   ShowEnterBill(params){
      return api.get(`/admin/enterbill/${params}`)
   },
   EnterBillGet(params){
      return api.get(`/admin/enterbill/${params.url}`,{params})
   },
   EnterBillPost(params){
      return api.post(`/admin/enterbill/${params.url}`,params)
   },
   EnterBillExportDataTable(params){
      return api.get(`/admin/enterbill/export-data-table`, {params})
   },
}

