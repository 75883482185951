import repository from '@/api/modules/Admin/purchaseOrder'
import store from '@/store';
export default {
	state: {
        STATUS:'',
        DISPATCH:'',
		REFRESH: false,
		PRINT: false,
		EXPORT: false,
		DATA: [],
        PAYMENT_METHOD:[
            { text:'Payment First', value:1,class:'badge-success'},
            { text:'Receive first', value: 2,class:'badge-primary'},
            { text:'Partial payment', value: 3,class:'badge-secondary'},
        ],
		PO_FILES: [],
		VAT_AMOUNT: '',
		VIEW: null,
		LAST_PO_NUM: '',
		COMPANY_IMAGE: null,
		EXCEL_LOADING: false,
	},
	getters: {
        PURCHASE_ORDER:(state)=>state,
	},
	actions:{
        adminPurchaseOrderGet({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.adminPurchaseOrderGet(payload).then((response)=>{
					commit('PURCHASE_ORDER_DISPATCH_REFRESH', false)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        adminPurchaseOrderPost({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.adminPurchaseOrderPost(payload).then((response)=>{
					commit('PURCHASE_ORDER_DISPATCH_REFRESH', true)
                    resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
		getAdminPOFiles({ commit }, payload) {
            return new Promise((resolve,reject)=>{
                repository.getAdminPOFiles(payload).then((response)=>{
                    if(response.status == 200){
						commit('FILES', response.data)
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        deleteAdminPOFiles({ commit }, payload) {
            return new Promise((resolve,reject)=>{
                repository.deleteAdminPOFiles(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        }
	},

	mutations:{
        EDIT_PURCHASE_ORDER_TABLE:(state,data)=>{
            state.edit_purchase_order_details = data;
        },
		PURCHASE_ORDER_DISPATCH:(state,data)=>{
			state.DISPATCH = data
		},
        // NEW_PURCHASE_ORDER_TABLE:(state,data)=>{
        //     (state.new_purchase_order_details = data);
        // },
		// PRINT_PURCHASE_ORDER_DETAIL:(state,data)=>{
		// 	state.print_purchase_order_detail = data
		// },
		// PRINT_PURCHASE_ORDER:(state,data)=>{
		// 	state.print_purchase_order = data;
		// },
		PRINT_PURCHASE_ORDER:(state,data)=>{
			state.PRINT = data;
		},
		EXPORT_PURCHASE_ORDER:(state,data)=>{
			state.EXPORT = data;
		},
		PURCHASE_ORDER_DISPATCH_STATUS:(state,data)=>{
			state.STATUS = data;
		},
		PURCHASE_ORDER_DISPATCH_REFRESH:(state,data) =>{
			state.REFRESH = data
		},
		PURCHASE_ORDER_DATA:(state,data)=>{
			state.DATA = data;
		},
		FILES:(state,data)=>{
			state.PO_FILES = data;
		},
		PO_VAT:(state,data)=>{
			state.VAT_AMOUNT = data
		},
		VIEW:(state,data)=>{
			state.VIEW = data
		},
		LAST_PO_NUM:(state,data)=>{
			state.LAST_PO_NUM = data
		},
		COMPANY_IMAGE:(state,data)=>{
			state.COMPANY_IMAGE = data
		},
		EXCEL_LOADING:(state,data)=>{
			state.EXCEL_LOADING = data
		},
	},
}
