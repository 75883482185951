import repository from '@/api/modules/HR/Settings/hrSettings'
import store from '@/store';
export default {
	state: {
        hr_settings_dialog_title:'',
		hr_settings_tab_is_active:'',
		hr_settings_dispatch:{},
		hr_settings_getters:null,
		hr_settings_new_data: false,
		hr_settings_view:[],
		hr_settings:[],
		form_settings:[
            {
                settings: {},
                type: "Leave"
            },
            {
                settings: {},
                type: "timekeeping"
            },
            {
                settings: {},
                type: "Memo"
            },
            {
                settings: {},
                type: "Processing"
            },

        ]
	},
	getters: {
		GET_HR_SETTINGS_DIALOG_TITLE:(state) => state.hr_settings_dialog_title,
		GET_HR_SETTINGS_TAB_IS_ACTIVE:(state) => state.hr_settings_tab_is_active,
		GET_HR_SETTINGS_DISPATCH:(state) => state.hr_settings_dispatch,
		GET_HR_SETTINGS_GETTERS:(state)=>state.hr_settings_getters,
		GET_HR_SETTINGS_NEW_DATA_TRIGGER:(state)=>state.hr_settings_new_data,
		GET_HR_SETTINGS_VIEW:(state)=>state.hr_settings_view,
		GET_HR_SETTINGS:(state)=>state.hr_settings,
		GET_FORM_SETTINGS:(state)=>state.form_settings,
	},
	actions:{
		AddHrSettings({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addhrSettings(payload).then((response)=>{
					commit('HR_SETTINGS_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		UpdateHrSettings({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updatehrSettings(payload).then((response)=>{
					commit('HR_SETTINGS_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		DeleteHrSettings({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deletehrSettings(payload).then((response)=>{
					commit('HR_SETTINGS_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		GetHrSettings({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.gethrSettings(payload).then((response)=>{
					commit('HR_SETTINGS',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		SaveHrSettings({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.savehrSettings(payload).then((response)=>{
					commit('HR_SETTINGS_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getSettingsByModule({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getSettingsByModule(payload).then((response)=>{
					commit('HR_SETTINGS_NEW_DATA_TRIGGER',true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
	},
	mutations:{
		HR_SETTINGS_DIALOG_TITLE:(state,data)=>{state.hr_settings_dialog_title = data},
		HR_SETTINGS_TAB_IS_ACTIVE:(state,data)=>{state.hr_settings_tab_is_active = data},
		HR_SETTINGS_DISPATCH:(state,data)=>{state.hr_settings_dispatch = data},
		HR_SETTINGS_GETTERS:(state,data)=>{state.hr_settings_getters = data},
		HR_SETTINGS_NEW_DATA_TRIGGER:(state,data)=>{state.hr_settings_new_data = data},
		HR_SETTINGS_VIEW:(state,data)=>{state.hr_settings_view = data},
		HR_SETTINGS:(state,data)=>{state.hr_settings = data},
		FORM_SETTINGS:(state,data)=>{
            let settings_index = state.form_settings.map(e=> e.type).indexOf(data.type)
            state.form_settings[settings_index].settings = data.settings
        },
	},
}
