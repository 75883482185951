import api from '../../../api'
export default {

    postpackinglists(params){
        return api.post(`packing-lists/packingLists`,params);
    },
    showpackinglists(id){
        return api.get(`packing-lists/packingLists/${id}`);
    },
    updatepackinglists(id,params){
        return api.put(`packing-lists/packingLists/${id}`,params);
    },
    getallpackinglists(params){
        return api.get(`packing-lists/getAll`,{params});
    },
    deletepackinglists(id){
        return api.delete(`packing-lists/packingLists/${id}`);
    },
    cancelpackinglists(id,params){
        return api.post(`packing-lists/cancelPackingList/${id}`,params);
    },
    updatepackingliststatus(id,params){
        return api.post(`packing-lists/updatePackingListStatus/${id}`,params);
    },
    updateplprinted(id,params){
        return api.post(`packing-lists/updatePLPrinted/${id}`,params);
    },
    // confirmoverrideprintpl(params){
    //     return api.post(`packing-lists/confirmOverridePrintPL`,params);
    // },
    converttostockcard(id){
        return api.post(`inventory/convertToStockCard/${id}`);
    },
    viewpackinglist(id){
        return api.get(`packing-lists/viewPackingList/${id}`);
    },
    viewstockcard(id){
        return api.get(`packing-lists/viewStockCard/${id}`);
    },
    
}
