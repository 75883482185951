import repository from '@/api/modules/Accounting/JournalVoucherManager/journalvouchermanager'
import store from '@/store';
export default {
	state: {
        journal_voucher_manager_dialog:false,
        journal_voucher_manager_status:{},
        new_journal_voucher_manager_table:false,
        edit_journal_voucher_manager:{},
        journal_voucher_manager:[],
		jvm_num: "",
		journal_voucher_manager_filters: {
			search: null,
			vendor_id: null,
			company_id: null,
			stores_id: null,
			transact_date:[],
			prepared_by: null,
			company_classification_id: null,
		}
	},
	getters: {
        GET_JOURNAL_VOUCHER_MANAGER_DIALOG:(state)=>state.journal_voucher_manager_dialog,
        GET_JOURNAL_VOUCHER_MANAGER_STATUS:(state)=>state.journal_voucher_manager_status,
        GET_NEW_JOURNAL_VOUCHER_MANAGER_TABLE:(state)=>state.new_journal_voucher_manager_table,
        GET_EDIT_JOURNAL_VOUCHER_MANAGER:(state)=>state.edit_journal_voucher_manager,
        GET_JOURNAL_VOUCHER_MANAGER:(state)=>state.journal_voucher_manager,
		GET_JVM_NUM: (state) => state.jvm_num,
	},
	actions:{
		postJournalVoucherManager({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalManagerPost(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		getJournalVoucherManager({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalManagerGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit(payload.mutation_code, response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getJournalVoucherManagerItemExport({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalManagerGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit(payload.mutation_code, response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		putJournalVoucherManager({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalManagerPut(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteJournalVoucherManager({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalManagerDelete(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getJVMNum({ commit }) {
			return new Promise((resolve, reject) => {
					repository
					.getJVMNum()
					.then((response) => {
							commit("JVM_NUM", response.data);
							resolve(response.data);
					})
					.catch((e) => {
							reject();
					});
			});
		},
		getJVMFiles({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.getJVMFiles(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
		deleteJVMFiles({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.deleteJVMFiles(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
	},
	
	mutations:{
        JOURNAL_VOUCHER_MANAGER:(state, data) => {
			state.journal_voucher_manager = data
		},
        EDIT_JOURNAL_VOUCHER_MANAGER:(state, data) => {
			state.edit_journal_voucher_manager = data
		},
		JOURNAL_VOUCHER_MANAGER_STATUS:(state, data) => {
			state.journal_voucher_manager_status = data
		},
        NEW_JOURNAL_VOUCHER_MANAGER_TABLE:(state,data)=>{
            state.new_journal_voucher_manager_table = data;
        },
		JVM_NUM: (state, data) => {
			state.jvm_num = data
		},
	},
}
