import repository from "@/api/modules/Operations/CycleCount/index";

export default {
    state:{
        cycle_count:[],
        cycle_count_details:[],
        save_detail_cycle_count:[],
        schedule_cycle_count:[],
        export_cycle_count:[],
        items_not_adequate:[],
    },
    getters:{
        GET_CYCLE_COUNT:(state) => state.cycle_count,
        GET_CYCLE_COUNT_DETAILS:(state) => state.cycle_count_details,
        GET_SAVE_DETAIL_CYCLE_COUNT:(state) => state.save_detail_cycle_count,
        GET_SCHEDULE_CYCLE_COUNT:(state) => state.schedule_cycle_count,
        GET_EXPORT_CYCLE_COUNT:(state) => state.export_cycle_count,
        GET_ITEMS_NOT_ADEQUATE:(state) => state.items_not_adequate,
    },
    actions:{
        getCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getcyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('CYCLE_COUNT',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        showCycleCountDetails({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.showcyclecountdetails(payload.id,payload.params).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('CYCLE_COUNT_DETAILS',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        postCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.postcyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        approveCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.approvecyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        saveDetailCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.savedetailcyclecount(payload.id,payload.params).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('SAVE_DETAIL_CYCLE_COUNT',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        submitForCheckingCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.submitforcheckingcyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        returnForRecountCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.returnforrecountcyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        completeCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.completecyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        destroyCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.destroycyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        importCycleCountTemplate({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.importcyclecounttemplate(payload.id,payload.params).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        createCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.createcyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        scheduleCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.schedulecyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        getScheduleCycleCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getschedulecyclecount(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('SCHEDULE_CYCLE_COUNT',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        destroyCycleCountSchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.destroycyclecountschedule(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        exportCyclecount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.exportcyclecount(payload.id,payload.params).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('EXPORT_CYCLE_COUNT',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        itemsNotAdequate({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.itemsnotadequate(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('ITEMS_NOT_ADEQUATE',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        
    },
    mutations:{
        CYCLE_COUNT: (state,data) => {(state.cycle_count = data)},
        CYCLE_COUNT_DETAILS: (state,data) => {(state.cycle_count_details = data)},
        SAVE_DETAIL_CYCLE_COUNT: (state,data) => {(state.save_detail_cycle_count = data)},
        SCHEDULE_CYCLE_COUNT: (state,data) => {(state.schedule_cycle_count = data)},
        EXPORT_CYCLE_COUNT: (state,data) => {(state.export_cycle_count = data)},
        ITEMS_NOT_ADEQUATE: (state,data) => {(state.items_not_adequate = data)},
     
    }

}
