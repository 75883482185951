<template>
    <v-container>
        <ComponentHeader
            :button="true"
            :title="'CF Movement'"
            :type="'Service'"
        ></ComponentHeader>
        <v-container class="bg-bluish-gray" fluid>
            <v-card>
                <v-card-text>
                    <v-row class="p-2">
                        <v-col sm="3" md="2">
                            <v-autocomplete
                                v-model="filters.statuses"
                                :items="dropdowns.statuses"
                                label="Select Status"
                                auto-select-first
                                hide-details
                                clearable
                                dense
                                outlined
                                deletable-chips
                                small-chips
                                multiple
                            ></v-autocomplete>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-autocomplete
                                v-model="filters.item_type"
                                :items="dropdowns.item_type"
                                label="Select Item Type"
                                auto-select-first
                                hide-details
                                clearable
                                dense
                                outlined
                                deletable-chips
                                small-chips
                                multiple
                            ></v-autocomplete>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-checkbox
                                v-model="filters.include_image"
                                :true-value="1"
                                :false-value="0"
                                label="Include Image"
                                dense
                                hide-details
                            ></v-checkbox>
                        </v-col>
                       
                        <v-spacer />
                        <v-col sm="12" md="4" class="text-right">
                            <!-- <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn> -->
                            <v-btn small class="ml-1" :loading="loading.export" @click="exportToExcel()">Excel</v-btn>
                            <!-- <v-btn small class="ml-1" @click="getSalesIncentives()">Filter</v-btn> -->
                        </v-col>
                    </v-row>
                    <v-row class="p-2">
                        <v-col sm="3" md="2">
                            <v-menu
                                v-model="filters.menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.dates[0]"
                                        v-on="on"
                                        label="Date From"
                                        append-icon="mdi-calendar-clock"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.dates[0]" @input="filters.menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-menu
                                v-model="filters.menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.dates[1]"
                                        v-on="on"
                                        label="Date To"
                                        append-icon="mdi-calendar-clock"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.dates[1]" @input="filters.menu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
    },
    data() {
        return {
            dropdowns: {
                statuses: [
                    { value: 0, text: 'Checking' },
                    { value: 1, text: 'Checked' },
                ],
                item_type: [],
            },
            filters: {
                dates: [
                    this.$dayjs().startOf("month").format("YYYY-MM-DD"),
                    this.$dayjs().endOf("month").format("YYYY-MM-DD"),
                ],
                statuses: null,
                item_type: null,
                menu2: false,
                menu3: false,
                include_image: 0,
            },
            loading: {
                export: false,
            },
        }
    },
    methods: {
        async exportToExcel() {
            let exportData = {}
            let fileName = ''
            swal.fire({
                title:                      'Please wait...',
                allowOutsideClick:          false,
            })
            swal.showLoading();
            this.loading.export = true

            await this.$store.dispatch('serviceGet', {
                filters: this.filters,
                url: 'get-cf-movement',
            }).then(async response => {
                fileName = `CF Movement ${this.filters.dates[0]} - ${this.filters.dates[1]}`
                exportData = {
                    ['Summary']: {
                        headers: [
                            { header: 'CF#', key: 'id' },
                            { header: 'Warehouse', key: 'warehouse_code' },
                            { header: 'WPF#', key: 'wp_list_id' },
                            { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                            { header: 'Customer', key: 'customer_name' },
                            { header: 'Status', key: 'status' },
                            { header: 'Date Issued', key: 'issued_date', formatAs: 'datetime' },
                            { header: 'Checker', key: 'checker_name' },
                            { header: 'Movement Date', key: 'created_at', formatAs: 'datetime' },
                            { header: 'Complete Date', key: 'complete_date', formatAs: 'date' },
                            { header: 'Completed By', key: 'completed_by_name' },
                            { header: '# Of Boxes', width: 8, key: 'num_of_box' },
                            { header: 'Correct Picking', key: 'picking_correction' },
                            { header: 'Picking Reason', width: 24, key: 'correct_picking_reason' },
                            { header: 'Start Date', width: 16, key: 'start_date', formatAs: 'datetime' },
                            { header: 'End Date', width: 16, key: 'end_date', formatAs: 'datetime' },
                            { header: 'Duration', width: 16, key: 'durations' },
                            { header: 'Printed', key: 'printed' },
                            { header: 'Converted', key: 'packing_list_status' },
                        ],
                        data: response.data.summary,
                    },
                    ['Detailed']: {
                        headers: [
                            { header: 'IMAGE', key: '', width: 22, style: { alignment: { vertical: 'middle', horizontal: 'center' } }, include: this.filters.include_image },
                            { header: 'CF#', key: 'id' },
                            { header: 'Warehouse', key: 'warehouse_code' },
                            { header: 'WPF#', key: 'wp_list_id' },
                            { header: 'SO#', key: 'order_num', style: { numFmt: '@' } },
                            { header: 'Customer', key: 'customer_name' },
                            { header: 'Date Issued', key: 'issued_date', formatAs: 'datetime' },
                            { header: 'Checker', key: 'checker_name' },
                            { header: 'Complete Date', key: 'complete_date', style: { numFmt: 'mm/dd/yyyy' } },
                            { header: 'Completed By', key: 'completed_by_name' },
                            { header: '# Of Boxes', width: 8, key: 'num_of_box' },
                            { header: 'Correct Picking', key: 'picking_correction' },
                            { header: 'Picking Reason', width: 24, key: 'correct_picking_reason' },
                            { header: 'Start Date', width: 16, key: 'start_date', style: { numFmt: 'mm/dd/yyyy' }, formatAs: 'datetime' },
                            { header: 'End Date', width: 16, key: 'end_date', style: { numFmt: 'mm/dd/yyyy' }, formatAs: 'datetime' },
                            { header: 'Duration', width: 16, key: 'durations' },
                            { header: 'Printed', key: 'printed' },
                            { header: 'Converted', key: 'packing_list_status' },
                            { header: 'Model', width: 16, key: 'model' },
                            { header: 'Name', width: 20, key: 'name' },
                            { header: 'UOM', width: 10, key: 'uom' },
                            { header: 'Type', width: 8, key: 'type' },
                            { header: 'Category', width: 8, key: 'category' },
                            { header: 'Quantity', key: 'quantity', style: { numFmt: '#,##0' }, formatAs: 'int' },
                            { header: 'Quantity To Ship', key: 'quantity_served', style: { numFmt: '#,##0' }, formatAs: 'int' },
                            { header: 'Stocks', key: 'warehouse_stocks', style: { numFmt: '#,##0' }, formatAs: 'int' },
                        ],
                        data: response.data.detailed,
                        hasImages: this.filters.include_image,
                        options: {
                            views: [
                                { state: 'frozen', xSplit: 2, ySplit: 1 }
                            ],
                        },
                    },
                }

                await this.exportExcel(exportData, fileName, 'F69F1A', true)
            }).catch(error => {
                console.log(error)
            })

            swal.close();
            this.loading.export = false
        },
    },
    async mounted() {
        this.$store.dispatch('getItemTypes').then((response)=>{
          this.dropdowns.item_type = response.data
        })
    },
}
</script>