import repository from '@/api/modules/Accounting/ChartOfAccounts/index'
import store from '@/store';
export default {
	state: {
        edit_chart_of_accounts_details:{},
        new_chart_of_accounts_details:false,
		chart_of_accounts_order:false,
		chart_of_accounts_detail:{},
		chart_of_accounts_dispatch:{},
		chart_of_accounts_status:{},
		chart_of_accounts_refresh:1,
		chart_of_accounts_number:'',
		chart_of_accounts_parent_id:0,
		import_dialog:false,
		coa_import: [],
		edit_chart_of_accounts:{},
		chart_of_accounts:[]
	},
	getters: {
        GET_EDIT_CHART_OF_ACCOUNTS_TABLE:(state)=>state.edit_chart_of_accounts_details,
        GET_NEW_CHART_OF_ACCOUNTS_TABLE:(state)=>state.new_chart_of_accounts_details,
		GET_PRINT_CHART_OF_ACCOUNTS:(state)=>state.print_chart_of_accounts,
		GET_PRINT_CHART_OF_ACCOUNTSR_DETAIL:(state)=>state.print_chart_of_accounts_detail,
		GET_CHART_OF_ACCOUNTS_DISPATCH:(state)=>state.chart_of_accounts_dispatch,
		GET_CHART_OF_ACCOUNTS_STATUS:(state)=>state.chart_of_accounts_status,
		GET_CHART_OF_ACCOUNTS_DISPATCH_REFRESH:(state)=>state.chart_of_accounts_refresh,
		GET_CHART_OF_ACCOUNTS_NUMBER:(state)=>state.chart_of_accounts_number,
		GET_CHART_OF_ACCOUNT_PARENT_ID:(state)=>state.chart_of_accounts_parent_id,
		GET_COA_IMPORT_DIALOG:(state)=>state.import_dialog,
		GET_COA_IMPORT:(state)=>state.coa_import,
		GET_EDIT_CHART_OF_ACCOUNTS:(state)=>state.edit_chart_of_accounts,
		GET_CHART_OF_ACCOUNTS:(state)=>state.chart_of_accounts,
	},
	actions:{
        CreateChartOfAccounts({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.CreateChartOfAccounts(payload).then((response)=>{
					if(response.status == 200){
						commit('NEW_CHART_OF_ACCOUNTS_TABLE', response.data) // commit the response data
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		UpdateChartOfAccounts({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateChartOfAccounts(payload).then((response)=>{
					commit('NEW_CHART_OF_ACCOUNTS_TABLE',response.data)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteChartOfAccounts({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteChartOfAccounts(payload).then((response)=>{
					commit('NEW_CHART_OF_ACCOUNTS_TABLE',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
        getAllChartOfAccounts({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllChartOfAccounts(payload).then((response)=>{
					if(response.status == 200){
						commit('CHART_OF_ACCOUNTS', response.data);
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getChartOfAccountsItems({ commit }, payload) {
			return new Promise((resolve, reject) => {
					repository.getChartOfAccountsItems(payload).then((response) => {
							commit('EDIT_CHART_OF_ACCOUNTS', response.data);
					}).catch((error) => {
							console.log(error);
							reject(error);
					})
			})
		},
		coaImport({ commit }, payload, config) {
            return new Promise((resolve, reject) => {
                repository.coaImport(payload, config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('COA_IMPORT', response.data)
						commit('NEW_CHART_OF_ACCOUNTS_TABLE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
	},
	
	mutations:{
        EDIT_CHART_OF_ACCOUNTS_TABLE:(state,data)=>{
            state.edit_chart_of_accounts_details = data;
        },
		CHART_OF_ACCOUNTS_DISPATCH:(state,data)=>{
			state.chart_of_accounts_dispatch = data
		},
        NEW_CHART_OF_ACCOUNTS_TABLE:(state,data)=>{
			state.new_chart_of_accounts_details = data;
		},
		PRINT_CHART_OF_ACCOUNTS_DETAIL:(state,data)=>{
			state.print_chart_of_accounts_detail = data
		},
		PRINT_CHART_OF_ACCOUNTS:(state,data)=>{
			state.print_chart_of_accounts = data;
		},
		CHART_OF_ACCOUNTS_STATUS:(state,data)=>{
			state.chart_of_accounts_status = data;
		},
		CHART_OF_ACCOUNTS_DISPATCH_REFRESH:(state,data) =>{
			(state.chart_of_accounts_refresh = data);
		},
		CHART_OF_ACCOUNTS_NUMBER:(state,data)=>{
			state.chart_of_accounts_number = data;
		},
		CHART_OF_ACCOUNT_PARENT_ID:(state,data)=>{
			state.chart_of_accounts_parent_id = data;
		},
		COA_IMPORT_DIALOG:(state,data)=>{
			state.import_dialog = data;
		},
		COA_IMPORT:(state, data)=>{ 
			(state.coa_import = data) 
		},
		EDIT_CHART_OF_ACCOUNTS:(state, data) => {
			state.edit_chart_of_accounts = data
		},
		CHART_OF_ACCOUNTS:(state, data) => {
			state.chart_of_accounts = data
		},
	},
}
