import repository from '@/api/modules/Admin/AdminOtherTabs'
import store from '@/store';
export default {
	state: {
		selected_admin_classification:{},
		new_admin_other_table:false,
		dialog_title:'',
		admin_tab_is_active:'',
		admin_dispatch:{},
		admin_other_table:'',
		admin_classification_table:[],
		admin_location_table:[],
		admin_brand_table:[],
		admin_category_table:[],
		admin_getters:null,
		brand_table_total:0,
		classification_table_total:0,
		location_table_total:0,
		category_table_total:0,
		admin_item_category_type: 0,
		admin_low_value_assets_selection: [],
		admin_delivery_adresses_action:'',
		selected_delivery_addresses:{},

	},
	getters: {
		SELECTED_ADMIN_CLASSIFICATION:(state) => state.selected_admin_classification,
		GET_NEW_ADMIN_OTHER_TABLE:(state)=>state.new_admin_other_table,
		GET_ADMIN_DIALOG_TITLE:(state) => state.dialog_title,
		GET_ADMIN_TAB_IS_ACTIVE:(state)=>state.admin_tab_is_active,
		GET_ADMIN_DISPATCH:(state)=>state.admin_dispatch,
		GET_ADMIN_OTHER_TABLE:(state)=>state.admin_other_table,
		GET_ADMIN_CLASSIFICATION_TABLE:(state)=>state.admin_classification_table,
		GET_ADMIN_LOCATION_TABLE:(state)=>state.admin_location_table,
		GET_ADMIN_BRAND_TABLE:(state)=>state.admin_brand_table,
		GET_ADMIN_CATEGORY_TABLE:(state)=>state.admin_category_table,
		GET_ADMIN_GETTERS:(state)=>state.admin_getters,
		GET_ITEM_CATEGORY_TYPE:(state)=>state.admin_item_category_type,
		GET_LOW_VALUE_ASSETS_SELECTION:(state)=>state.admin_low_value_assets_selection,
		GET_DELIVERY_ADDRESSES_ACTION:(state)=>state.admin_delivery_adresses_action,
		GET_SELECTED_DELIVERY_ADDRESSES:(state)=>state.selected_delivery_addresses,
	},
	actions:{
		GetAllAdminClassification({commit,state},payload){
			commit('ADMIN_CLASSIFICATION_TABLE',[]);
			return new Promise((resolve,reject)=>{
				repository.GetAllAdminClassification(payload).then((response)=>{
			
					commit('ADMIN_CLASSIFICATION_TABLE',response.data.data.data);
					state.classification_table_total = response.data.data.total
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		AddAdminClassification({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.AddAdminClassification(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		UpdateAdminClassification({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateAdminClassification(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteAdminClassification({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteAdminClassification(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		GetAllAdminLocation({commit,state},payload){
			commit('ADMIN_LOCATION_TABLE',[]);
			return new Promise((resolve,reject)=>{
				repository.GetAllAdminLocation(payload).then((response)=>{
					commit('ADMIN_LOCATION_TABLE',response.data.data.data);
				
					state.location_table_total = response.data.data.total
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		AddAdminLocation({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.AddAdminLocation(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		UpdateAdminLocation({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateAdminLocation(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteAdminLocation({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteAdminLocation(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		GetAllAdminBrand({commit,state},payload){

			commit('ADMIN_BRAND_TABLE',[]);
			return new Promise((resolve,reject)=>{
				repository.GetAllAdminBrand(payload).then((response)=>{
					commit('ADMIN_BRAND_TABLE',response.data.data.data);
					state.brand_table_total = response.data.data.total
					if(response.status == 200){
						resolve(response.data.data.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		AddAdminBrand({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.AddAdminBrand(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		UpdateAdminBrand({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateAdminBrand(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteAdminBrand({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteAdminBrand(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		GetAllAdminCategories({commit,state},payload){

			commit('ADMIN_CATEGORY_TABLE',[]);
			return new Promise((resolve,reject)=>{
				repository.GetAllAdminCategories(payload).then((response)=>{
					commit('ADMIN_CATEGORY_TABLE',response.data.data.data);
					state.category_table_total = response.data.data.total
					if(response.status == 200){
						resolve(response.data.data.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		AddAdminCategories({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.AddAdminCategories(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		UpdateAdminCategories({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateAdminCategories(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteAdminCategories({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteAdminCategories(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_TABLE',true)
					if(response.status == 200){
						
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		adminDropdownGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.adminDropdownGet(payload).then((response) => {
                    resolve(response)
                    if(!!payload.mutations){
                        this.commit(payload.mutations, response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
		getSelectedLowValueAsset({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getSelectedLowValueAsset(payload).then((response) => {
                    resolve(response)
                    if(!!payload.mutations){
                        this.commit(payload.mutations, response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
		adminDeliveryAddressesGet({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.adminDeliveryAddressesGet(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
		adminDeliveryAddressesPost({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.adminDeliveryAddressesPost(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
		adminDeliveryAddressesPut({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.adminDeliveryAddressesPut(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
		adminDeliveryAddressesDelete({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.adminDeliveryAddressesDelete(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        }

	},
	
	mutations:{
		EDIT_ADMIN_CLASSIFICATION:(state,data) => {state.selected_admin_classification = data},
		NEW_ADMIN_OTHER_TABLE:(state,data)=>{state.new_admin_other_table = data},
		ADMIN_DIALOG_TITLE:(state,data)=>{state.dialog_title = data},
		ADMIN_TAB_IS_ACTIVE:(state,data)=>{state.admin_tab_is_active = data},
		ADMIN_DISPATCH:(state,data)=>{state.admin_dispatch = data},
		ADMIN_OTHER_TABLE:(state,data)=>{state.admin_other_table = data},
		ADMIN_CLASSIFICATION_TABLE:(state,data) => {state.admin_classification_table = data},
		ADMIN_LOCATION_TABLE:(state,data) => {state.admin_location_table = data},
		ADMIN_BRAND_TABLE:(state,data) => {state.admin_brand_table = data},
		ADMIN_CATEGORY_TABLE:(state,data)=>{state.admin_category_table = data},
		ADMIN_GETTERS:(state,data)=>{state.admin_getters = data},
		ITEM_CATEGORY_TYPE:(state,data)=>{state.admin_item_category_type = data},
		LOW_VALUE_ASSETS_SELECTION:(state,data)=>{state.admin_low_value_assets_selection = data},
		ADMIN_DELIVERY_ADDRESSES_ACTION:(state,data)=>{state.admin_delivery_adresses_action = data},
		SELECTED_DELIVERY_ADDRESSES:(state,data)=>{state.selected_delivery_addresses = data},
		
	},
}
