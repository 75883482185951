import BaklasReportComponent from '@/views/main/modules/Reports/ServiceReports/BaklasReportComponent'
import SparePartsReportComponent from '@/views/main/modules/Reports/ServiceReports/SparePartsReportComponent'
import RepairFormsReportComponent from '@/views/main/modules/Reports/ServiceReports/RepairFormsReportComponent'
import ChinaDefectiveReportComponent from '@/views/main/modules/Reports/ServiceReports/ChinaDefectiveReportComponent'
import RepairCountReportComponent from '@/views/main/modules/Reports/ServiceReports/RepairCountReportComponent'
import PendingRepairFromsReportsComponent from '@/views/main/modules/Reports/ServiceReports/RepairFormPerItemComponent'
import RepairFormAgeReportsComponent from '@/views/main/modules/Reports/ServiceReports/RepairFormCountPerStatusReportComponent'
import InandOutPerWarrantyReportsComponent from '@/views/main/modules/Reports/ServiceReports/RepairFormAverageAgePerDayReportComponent'
import ItemPerRFReportComponent from '@/views/main/modules/Reports/ServiceReports/ItemPerRFReportComponent'
import TechnicianAccuracyReportComponent from '@/views/main/modules/Reports/ServiceReports/TechnicianAccuracyReportComponent'
import EfficientTechniciansReportComponent from '@/views/main/modules/Reports/ServiceReports/EfficientTechniciansReportComponent'
import TurnAroundTechniciansReportComponent from '@/views/main/modules/Reports/ServiceReports/TurnAroundTimeTechniciansReportComponent'
import PartConsumtionReportComponent from '@/views/main/modules/Reports/ServiceReports/PartConsumtionReportComponent'
import WpfMovementReportComponent from '@/views/main/modules/Reports/ServiceReports/WpfMovementReportComponent'
import CfMovementReportComponent from '@/views/main/modules/Reports/ServiceReports/CfMovementReportComponent'
import PartsSummaryMovementReportComponent from '@/views/main/modules/Reports/ServiceReports/PartsSummaryMovementReportComponent'

const routes = [{
    path: 'service/reports/baklas',
    name: 'service-baklas-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/BaklasReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Baklas Report',
    }
},
{
    path: 'service/reports/rf-master',
    name: 'service-rf-master-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/RepairFormsReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Repair Form Report',
    }
},
{
    path: 'service/reports/sp-master',
    name: 'service-sp-master-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/SparePartsReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Spare Parts Report',
    }
},
{
    path: 'service/reports/china-defective',
    name: 'service-china-defective-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/ChinaDefectiveReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'China Defective Report',
    }
},
{
    path: 'service/reports/repair-count',
    name: 'repiar-count-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/RepairCountReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Repair Form Count Per Status Report',
    }
},
{
    path: 'service/reports/pending-repair',
    name: 'pending-repair-form-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/RepairFormPerItemComponent'),
    meta: {
        requiresAuth: true,
        title: 'Pending Repair Report',
    }
},
{
    path: 'service/reports/repair-form-age',
    name: 'repair-form-age-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/RepairFormCountPerStatusReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Repair Form Count per Customer/Warranty/Tehcnician',
    }
},
{
    path: 'service/reports/in-and-out-warranty',
    name: 'in-and-out-per-warranty',
    component: () => import('@/views/main/modules/Reports/ServiceReports/RepairFormAverageAgePerDayReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Repair Form Average Age Per Customer/Warranty/Tehcnician',
    }
},
{
    path: 'service/reports/item-per-rf',
    name: 'item-per-rf',
    component: () => import('@/views/main/modules/Reports/ServiceReports/ItemPerRFReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Item Per RF Report',
    }
},
{
    path: 'service/reports/technician-accuracy-report',
    name: 'technician-accuracy-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/TechnicianAccuracyReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Technician Accuracy Report',
    }
},
{
    path: 'service/reports/wpf-movement-report',
    name: 'wpf-movement-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/WpfMovementReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'WPF Movement Report',
    }
},
{
    path: 'service/reports/cf-movement-report',
    name: 'cf-movement-report',
    component: () => import('@/views/main/modules/Reports/ServiceReports/CfMovementReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'CF Movement Report',
    }
},
// {
//     path: 'service/reports/efficient-technician-report',
//     name: 'efficient-technician-report',
//     component: () => import('@/views/main/modules/Reports/ServiceReports/EfficientTechniciansReportComponent'),
//     meta: {
//         requiresAuth: true,
//         title: 'Efficient Technician Report',
//     }
// },
// {
//     path: 'service/reports/turn-around-time-technicians-report',
//     name: 'turn-around-time-technicians-report',
//     component: () => import('@/views/main/modules/Reports/ServiceReports/TurnAroundTimeTechniciansReportComponent'),
//     meta: {
//         requiresAuth: true,
//         title: 'Turn Around Time Technicians Report',
//     }
// },
// {
//     path: 'service/reports/part-consumption-report',
//     name: 'part-consumption-report',
//     component: () => import('@/views/main/modules/Reports/ServiceReports/PartConsumtionReportComponent'),
//     meta: {
//         requiresAuth: true,
//         title: 'Part Consumption Report',
//     }
// },
{
    path: 'service/reports/parts-summary-movement-report',
    name: 'parts-summary-movement-report',
    component: PartsSummaryMovementReportComponent,
    meta: {
        requiresAuth: true,
        title: 'Parts Summary (Movement) Report',
    }
},
]

export default routes;
