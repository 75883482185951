import repository from '@/api/modules/Accounting/StoreCashIn/storecashin'
export default {
    state:{
        ACTION: '',
        TAB: '',
    },
    getters:{
        STORE_CASH_IN: (state) => state
    },
    actions:{
        storeCashInGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.storeCashInGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        storeCashInPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.storeCashInPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        }
    },
    mutations:{
        STORE_CASH_IN_ACTION: (state, data) => { (state.ACTION = data) },
        STORE_CASH_IN_TAB:(state, data)=>{ (state.TAB = data) }
    }
}
