import api from '../../api'
export default {
passwordDefault(params){
      return api.post('system-settings/passworddefault',params);
    },
    GeneralSettings(params){
        return api.get('system-settings/settings');
    },
    getemailsettings(params){
        return api.get('/system-settings/email-settings',{params});
    },
    addmoduleemailsettings(params){
        return api.post('/system-settings/email-settings',params);
    },
    addNewEmailModule(params){
        return api.post('/system-settings/addNewEmailModule',params);
    },
    BioApiSettings(params){
    return api.post('system-settings/BioApiSettings',params);
    },
    getBioApiSettings(){
        return api.get('system-settings/BioApiSettings');
    },
    BioPortSettings(params){
    return api.post('system-settings/bioPortSettings',params);
    },
    getPortSettings(params){
    return api.get('system-settings/getPortSettings', {params});
    },
    getMigrationPendingList(){
        return api.get('/system-settings/migration-list-pending');
    },
    optimizeServer(){
        return api.post('/system-settings/optimize');
    },
    postMigrationPendingList(params){
        return api.post('/system-settings/migrate',params);
    },
    getChannelLogs(params){
        return api.get('/system-settings/getChannelLogs');
    },
    getChannelLogsByFile(params){
        return api.get(`/system-settings/getChannelLogsByFile/${params}`);
    },
    sendEmailForTesting(params){
        return api.post(`/system-settings/sendEmailForTesting`, params);
    },
    getMailers(params){
        return api.get(`/system-settings/getMailers`, params);
    },
}
