import repository from '@/api/modules/Admin/adminDeliveryReceipt'
import store from '@/store'
export default {
    state: {
        DATA:[],
        DETAILS: [],
        PRINT: false,
    },
    getters: {
        GET_ADMIN_DELIVERY_RECEIPT:(state)=>state,
    },
    actions: {
        adminDeliveryReceiptPost({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.adminDeliveryReceiptPost(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
        adminDeliveryReceiptGet({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.adminDeliveryReceiptGet(payload).then((response)=>{
                    resolve(response.data)
                    commit('ADMIN_DELIVERY_ADDRESS_DATA', response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
    },
    mutations: {
        ADMIN_DELIVERY_RECEIPT_DATA:(state,data)=>{
            state.DATA = data
        },
        ADMIN_DELIVERY_RECEIPT_PRINT:(state, data)=>{
            state.PRINT = data
        },
        ADMIN_DELIVERY_RECEIPT_PRINT_DETAILS:(state, data)=>{
            state.DETAILS = data
        },
    }
    
}