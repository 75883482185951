<template>
  <v-dialog v-model="CELEBRANTS_DIALOGS" @click:outside="closeDialog()" :max-width="width" :scrollable="scrollable" :retain-focus="retainfocus" hide-overlay elevation="0">
    <component :is="cp"></component>
  </v-dialog>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default{

    props:['cp','scrollable','width','selectedAction','retainfocus'],
    data(){
        return{
          dialog:false
        }
    },
    computed:{
      ...mapGetters([
        'CELEBRANTS_DIALOGS'
      ])
    },
    methods: {
        closeDialog() {
            this.$store.commit("CELEBRANTS_DIALOG_MUTATE", false);
        },
    },
    watch:{
      CELEBRANTS_DIALOG:{
        handler(val){
        }
      }
    }
};
</script>
