import repository from '@/api/modules/Accounting/ServicePaymentRequest/index'
export default {
    state:{
        ACTION: ''
    },
    getters:{
        SERVICE_PAYMENT_REQUEST: (state) => state
    },
    actions:{
        servicePaymentRequestGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicePaymentRequestGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        servicePaymentRequestPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicePaymentRequestPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        servicePaymentRequestTypeGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicePaymentRequestTypeGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        servicePaymentRequestTypePost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.servicePaymentRequestTypePost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        getServicePaymentRequestFiles({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.getServicePaymentRequestFiles(payload).then((response)=>{
                    if(response.status == 200){
						commit('FILES', response.data)
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        deleteServicePaymentRequestFiles({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.deleteServicePaymentRequestFiles(payload).then((response)=>{
                    if(response.status == 200){
						commit('FILES', response.data)
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
    },
    mutations:{
        SERVICE_PAYMENT_REQUEST_ACTION: (state, data) => { (state.ACTION = data) }
    }
}
