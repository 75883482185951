
import repository from '@/api/credentials';

const routes = [{
    path: '/service/packing-list/:id',
    name: 'servicepackinglist',
    component: () => import('@/views/main/modules/Sales/PackingList/PackingListGroupComponent.vue'),
    beforeEnter:(to,from,next)=>{
        repository.checkAccessUser(to.params.id).then((response)=>{
            if(response.data == 'fail'){
                next('/no-access')
                }else{
                next()
            }
        }).catch(()=>{

        })

    },
    meta:{
        requiresAuth:true,
        service:1,
    }
}]

export default routes;
