import repository from '@/api/modules/Admin/repairrequestform'
import store from '@/store';
export default {
        state: {
                rrf_dispatch: {},
                all_rrf_items_list: [],
                rrf_items_list: [],
                edit_rrf: {},
                new_rrf_table: false,
                loaded: false,
                rrf_print: false,
                status: '',
                rrf_num: [],
                allRepairType: [],
        },
        getters: {
                GET_RRF_DISPATCH: (state) => state.rrf_dispatch,
                GET_ALL_RRF_ITEMS_LIST: (state) => state.all_rrf_items_list,
                GET_RRF_ITEMS_LIST: (state) => state.rrf_items_list,
                GET_EDIT_RRF: (state) => state.edit_rrf,
                GET_NEW_RRF_TABLE: (state) => state.new_rrf_table,
                GET_RRF_ITEMS_LOADED: (state) => state.loaded,
                GET_RRF_PRINT: (state) => state.rrf_print,
                GET_RRF_DISPATCH_STATUS: (state) => state.status,
                GET_RRF_NUM: (state) => state.rrf_num,
                GET_ALL_REPAIR_TYPE: (state) => state.allRepairType,
        },
        actions: {
                CreateRRF({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.CreateRRF(payload).then((response) => {
                                        resolve(response);
                                        commit('NEW_RRF_TABLE', true)
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                UpdateRRF({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.UpdateRRF(payload).then((response) => {
                                        resolve(response);
                                        commit('NEW_RRF_TABLE', true)
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getAllRRF({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.getAllRRF(payload).then((response) => {
                                        resolve(response.data);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getAllRRFItems({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                commit('RRF_ITEMS_LOADED', true);
                                repository.getAllRRFItems(payload).then((response) => {
                                        commit('ALL_RRF_ITEMS_LIST', response.data);
                                        commit('RRF_ITEMS_LOADED', false);
                                        resolve(response);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getSelectedRrfsInformation({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                commit('RRF_ITEMS_LOADED', true);
                                repository.getSelectedRrfsInformation(payload).then((response) => {
                                        resolve(response);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getRRFItems({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                commit('RRF_ITEMS_LOADED', true);
                                repository.getRRFItems(payload).then((response) => {
                                        commit('RRF_ITEMS_LIST', response.data);
                                        commit('RRF_ITEMS_LOADED', false);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                rrfPrintData({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.rrfPrintData(payload).then((response) => {
                                        resolve(response);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                DeleteRRF({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.DeleteRRF(payload).then((response) => {
                                        commit('NEW_RRF_TABLE', true)
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getAllRRFNum({ commit }) {
                        return new Promise((resolve, reject) => {
                                repository
                                .getAllRRFNum()
                                .then((response) => {
                                        commit("RRF_NUM", response.data.data);
                                })
                                .catch((e) => {
                                        reject();
                                });
                        });
                },
                getAllRepairType({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.getAllRepairType(payload).then((response) => {
                                resolve(response) 
                                commit("SET_ALL_REPAIR_TYPE", response.data);
                                })
                                .catch((e) => {
                                reject();
                                });
                        });
                },
                EnterRepairTypePost({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.EnterRepairTypePost(payload).then((response) => {
                                if(response.status == 200 || response.status == 201){
                                        resolve(response)
                                }
                                })
                                .catch((e) => {
                                        reject();
                                });
                        });
                },
                EnterRepairTypeGet({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.EnterRepairTypeGet(payload).then((response) => {
                                if(response.status == 200 || response.status == 202){
                                        resolve(response)
                                }
                                })
                                .catch((e) => {
                                        reject();
                                });
                        });
                },
                
        },
        mutations: {
                RRF_DISPATCH: (state, data) => {
                        state.rrf_dispatch = data;
                },
                RRF_ITEMS_LIST: (state, data) => {
                        state.rrf_items_list = data
                },
                ALL_RRF_ITEMS_LIST: (state, data) => {
                        state.all_rrf_items_list = data
                },
                EDIT_RRF: (state, data) => {
                        state.edit_rrf = data
                },
                NEW_RRF_TABLE: (state, data) => {
                        state.new_rrf_table = data
                },
                RRF_NUM:(state,data)=>{
                        state.rrf_num = data
                },
                SET_ALL_REPAIR_TYPE(state, data) {
                        state.allRepairType = data
                },
                RRF_ITEMS_LOADED: (state, data) => state.loaded = data,
                RRF_PRINT: (state, data) => state.rrf_print = data,
                RRF_DISPATCH_STATUS: (state, data) => state.status = data,
        },
}
