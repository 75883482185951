import repository from '@/api/modules/Admin/purchaseOrderItemsTemplate'
import store from '@/store';
export default {
	state: {
		REFRESH: false,
		PRINT: false,
		EXPORT: false,
		DATA: [],
		TEMPLATE_SELECTION: [],
       
	},
	getters: {
        PO_ITEMS_TEMPLATE:(state)=>state,
	},
	actions:{
        purchaseOrderItemsTemplateGet({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.purchaseOrderItemsTemplateGet(payload).then((response)=>{
					commit('PO_ITEMS_TEMPLATE_REFRESH', false)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        purchaseOrderItemsTemplatePost({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.purchaseOrderItemsTemplatePost(payload).then((response)=>{
					commit('PO_ITEMS_TEMPLATE_REFRESH', true)
                    resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
		getPurchaseOrderItemsTemplateSelection({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.getPurchaseOrderItemsTemplateSelection(payload).then((response)=>{
					resolve(response.data)
					commit('TEMPLATE_SELECTION', response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
		},
	},

	mutations:{
        PO_ITEMS_TEMPLATE_REFRESH:(state,data) =>{
			state.REFRESH = data
		},
        TEMPLATE_SELECTION:(state,data) =>{
			state.TEMPLATE_SELECTION = data
		},
	},
}
