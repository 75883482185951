import { render, staticRenderFns } from "./sidenav.vue?vue&type=template&id=5099e120&scoped=true"
import script from "./sidenav.vue?vue&type=script&lang=js"
export * from "./sidenav.vue?vue&type=script&lang=js"
import style0 from "./sidenav.vue?vue&type=style&index=0&id=5099e120&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5099e120",
  null
  
)

export default component.exports