import api from '../../../api'
export default {
  getallcontainers (params) {
    return api.get('containers/getAll', { params: params });
  },
  viewcontainers(params) {
    return api.get(`/containers/containers/${params}`);
  },
  addnewcontainers(params) {
    return api.post('/containers/containers', params);
  },
  editcontainers(params) {
    return api.put('/containers/edit-container', params);
  },
  receivecontainers(params) {
    return api.post(`/containers/receiveContainer/${params}`);
  },
  approvecontainers(params) {
    return api.post(`/containers/approveContainer/${params}`);
  },
  rejectcontainers(params) {
    return api.post(`/containers/rejectContainer/${params}`);
  },
  deletecontainers(params) {
    return api.delete(`/containers/delete-container/${params}`);
  },
  exportcontainers(params) {
    return api.post('containers/exportContainers', params);
  },
  sendContainerEmail(params) {
    return api.post('containers/sendContainerEmail', params);
  },
  uploadContainerFiles(params, config) {
    return api.post('containers/uploadContainerFiles', params, config);
  },
  getcontainerfiles(params) {
    return api.get('containers/getcontainerfiles', {params});
  },
  containerGet(params){
    return api.get('containers/' + params.url, {params});
  },
  containerPost(params){
    return api.post('containers/' + params.url, params);
  }
}
