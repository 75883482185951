import api from '../../../api'

export default {
    CreateChartOfAccountsTypes(params){
        return api.post('/accounting/chartofaccountstypes',params)
    },
    UpdateChartOfAccountsTypes(params){
        return api.put(`/accounting/chartofaccountstypes/${params.id}`,params)
    },
    DeleteChartOfAccountsTypes(params){
        return api.delete(`/accounting/chartofaccountstypes/${params}`)
    },
    getAllChartOfAccountsTypes(params){
        return api.get('/accounting/getAllchartofaccountstypes',{params})
    },
    getChartOfAccountsTypesItem(params) {
        return api.get('/accounting/getchartofaccountstypesitems',{params})
    },
}

