<template>
  <v-app>
    <navbar v-if=isFullScreen()></navbar>
    <!-- <ChangePasswordView></ChangePasswordView> -->
    <router-view></router-view>
    <HomeDashboard v-if="$route.name == 'home'"></HomeDashboard>
    <Celebrant_DialogComponent :cp="components" ></Celebrant_DialogComponent>
    <!-- <target v-if="$route.name == 'home'"></target> -->
    <!-- <v-menu v-model="menu" top offset-y z-index="101">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" fab dark small color="primary"
                    style="position: fixed; right: 10px; bottom: 10px">
                    <v-badge v-if="unreadall_text != 0" color="red" :content="unreadall_text" overlap>
                        <v-icon transition="slide-x-transition">mdi-comment-processing</v-icon>
                    </v-badge>
                    <v-icon v-else-if="unreadall_text == 0" transition="slide-x-transition">mdi-comment-processing
                    </v-icon>
                </v-btn>
            </template>
            <v-card max-width="350" max-height="400">
                <v-card-title>Contacts</v-card-title>
                <v-card-title>
                    <v-text-field type="text" placeholder="Search..." dense outlined />
                </v-card-title>
                <v-list dense>
                    <v-list-item-group active-class="dark--text">
                        <template v-for="(item, index) in GET_ALL_USERS">
                            <v-list-item :key="item.id" v-if="item.id != USERACCOUNT_LOAD.id"
                                class="border-bottom pa-0 ma-0" @click="add_chat_box(item)" dense>
                                <v-col cols="3" class="d-flex justify-content-center">
                                    <v-avatar color="orange" size="40">
                                        <span class="white--text headline">{{ item.name }}</span>
                                    </v-avatar>
                                </v-col>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                    <span v-for="imessage in allmessage" :key="imessage.them_id">
                                        <v-list-item-subtitle v-if="item.id == imessage.them_id" class="d-flex">
                                            <span style="display: inline-block; max-width: 130px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="text-caption">{{ imessage.content }}</span>
                                            &nbsp; - &nbsp;
                                            <span class="text-caption mt-0">{{ imessage.time }}</span>
                                        </v-list-item-subtitle>
                                    </span>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-menu> -->
    <!-- <v-container>
            <v-row class="d-flex no-block justify-content-end pr-16">
                <div class="col-md-2" v-for="(chat_boxes, index) in chat_box" :key="index">
                    <v-card height="455px" width="328px" max-height="455px" max-width="328px" class="chat_box bg-ingco"
                        elevation="10">
                        <v-card-subtitle class="bg-white text-black pr-0 pt-2 pl-3 pb-3 border-bottom">
                            <span>{{ chat_boxes.name }}
                                <v-btn text icon small color="black" class="float-right"
                                    @click="removeChatBoxTab(chat_boxes.id, index)">
                                    <v-icon small style="font-size: 15px">mdi-close-circle</v-icon>
                                </v-btn>
                            </span>
                        </v-card-subtitle>
                        <v-card height="360px" flat width="328px"
                            :class="`chat_message bg-ingco chat-container-${index}`"
                            v-on:scroll="onScroll(chat_boxes, index)" ref="chatContainer">
                            <v-container fluid class="pa-0 ma-0">
                                <v-row class="no-gutters pa-0 ma-0">
                                    <v-col cols="12">
                                        <v-container
                                            style="position: relative; max-width:328px; height: auto; max-height:360px;"
                                            class="overflow-auto">
                                            <span v-for="(messages_arr, i) in message_arr[index].messages" :key="i"
                                                class="imessage">
                                                <span :class="
                                            messages_arr.user_id == USERACCOUNT_LOAD.id
                                                ? 'from-me'
                                                : 'from-them'">
                                                    <div v-html="messages_arr.content"></div>
                                                    <div class="image_grid" v-if="messages_arr.imgURL.length > 0">
                                                        <div v-for="(fb_img, fb_key) in messages_arr.imgURL"
                                                            :key="fb_key" class="img_item">
                                                            <div v-if="fb_img && fb_img != 'NULL'">
                                                                <img :src="fb_img" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </span>
                                        </v-container>
                                    </v-col>
                                    <v-col>
                                        <div flat style="overflow-y: auto; width:100%; height:50px;">
                                            <div class="img-bg" v-for="(images, keys) in chat_boxes.imageURL"
                                                :key="keys">
                                                <img :src="images.imagename" alt="Cinque Terre" width="80"
                                                    height="40" />
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <v-card>
                            <v-col md="12" class="d-flex no-block align-items-center">
                                <v-file-input v-model="chat_box[index].uploadImage" :id="`file_upload${index}`"
                                    class="d-none cursor-pointer" @change="getImage(chat_boxes)" multiple />
                                <label :for="`file_upload${index}`" class="cursor-pointer"
                                    style="position:absolute;left: 0; top:20px;z-index: 1;">
                                    <v-icon>mdi-file</v-icon>
                                </label>
                                <v-textarea filled auto-grow rows="1" cols="5" class="v-text-message" dense
                                    :placeholder="`Send ${chat_boxes.name} a message...`" background-color="white"
                                    v-model="chat_boxes.messages" loader-height="2" @keyup="keypress(chat_boxes)">
                                </v-textarea>
                                <v-icon class="cursor-pointer"
                                    style="position:absolute;right: 0;top:20px;z-index: 1; padding-right:5px;"
                                    @click="createMessage(chat_boxes, index)" :disabled="chat_boxes.disable_btn">
                                    mdi-send
                                </v-icon>
                            </v-col>
                        </v-card>
                    </v-card>
                </div>
            </v-row>
        </v-container> -->
  </v-app>
</template>
<script>
import navbar from "./layouts/nav.vue";
// import target from "./layouts/Target.vue";
import { mapGetters } from "vuex";
// import firebase from "@/firebase_db/init";
// import Swal from "sweetalert2";
import HomeDashboard from './modules/Dashboard/HomeDashboard.vue'
import BirthdayCelebrantsViewVue from '@/views/main/layouts/dialogs/HR/EmployeeData/BirthdayCelebrantsView.vue';
import Celebrant_DialogComponent from '@/views/main/layouts/dialogs/Celebrant_Dialog.vue';
import ChangePasswordView from '@/views/main/layouts/dialogs/Others/ChangePasswordView.vue'

export default {
    components: {
        navbar,
        // target,
        HomeDashboard,
        BirthdayCelebrantsViewVue,
        Celebrant_DialogComponent,
        ChangePasswordView,
    },
  data() {
    return {
      text: "",
      fav: true,
      menu: false,
      hints: true,
      chat_box: [],
      message: null,
      from_arr: [],
      chatMessages: [],
      message_arr: [],
      totalChatHeight: null,
      loading: false,
      tmp: [],
      read_count: 0,
      array_id: [],
      unreadall_text: 0,
      array_unreadall: [],
      unread_array: [],
      allmessage: [],
      scroll_count: 5,
      current_index: 0,
      uploadImage: null,
      img_url: [],
      parser: new DOMParser(),
      isView : false,
      components:'',
    };
  },
  async mounted() {
    this.$store.dispatch("credentials");
    await this.$store.dispatch('getEmployeeBirthdayCelebrants').then(response=>{
      this.getBirthdayCelebrants()
    })
    // await this.$store.dispatch("getAllUsers").then(() => {
    //   this.allunreadmessage();
    //   this._allmessage();
    // });
  },
  computed: {
    ...mapGetters([
        "USERACCOUNT_LOAD",
        "GET_ALL_USERS",
        "GET_EMPLOYEE_BIRTHDAY_CELEBRANTS"
    ]),
    // unreadmessages() {
    //   return this.GET_ALL_USERS;
    // },
  },
  methods: {
    keypress(value) {
      if (value.messages) {
        value.disable_btn = false;
      } else {
        value.disable_btn = true;
      }
    },
    // add_chat_box(item) {
    //   let if_exist = this.chat_box.map((e) => e.id);
    //   if (!if_exist.includes(item.id)) {
    //     if (this.chat_box.length < 3) {
    //       let count = 0;
    //       this.chat_box.push({
    //         id: item.id,
    //         name: item.name,
    //         show: true,
    //         disable_btn: true,
    //         messages: "",
    //         imageURL: [],
    //       });
    //       this.message_arr.push({
    //         messages: [],
    //       });
    //       this.img_url.push({
    //         imageURL: [],
    //       });
    //       this.chat_box.forEach((e, index) => {
    //         count = index;
    //       });
    //       this.scrollToEnd(count);
    //       this.scroll_count = 5;
    //       this.getMessage(item, count);
    //     }
    //   }
    // },
    // removeChatBoxTab(user_id, key) {
    //   this.current_index = 0;
    //   this.chat_box.splice(key, 1);
    //   this.message_arr.splice(key, 1);
    // },
    // _allmessage() {
    //   let that = this;
    //   let all_message = [];
    //   let count = 0;
    //   this.GET_ALL_USERS.forEach((e) => {
    //     let to = firebase.database().ref(e.id);
    //     to.child(that.USERACCOUNT_LOAD.id)
    //       .orderByKey()
    //       .limitToLast(1)
    //       .on("child_added", function (snapshot) {
    //         const data = snapshot.val();
    //         if (data) {
    //           all_message.push({
    //             user_id: data.user_id,
    //             name: data.name,
    //             time: data.time != "" ? this.$dayjs(data.time).fromNow() : "",
    //             content: data.content
    //               ? "You: " + data.content
    //               : data.imgURL.length > 1
    //               ? "You sent " + data.imgURL.length + " photos."
    //               : "You sent a photo.",
    //             them_id: e.id,
    //             id: data.id,
    //             imgURL: data.imgURL.length > 0 ? "You sent a photo." : "",
    //             sort: "to",
    //             read: data.read,
    //             count: 0,
    //           });
    //         }
    //       });
    //     let from = firebase.database().ref(that.USERACCOUNT_LOAD.id);
    //     from
    //       .child(e.id)
    //       .orderByKey()
    //       .limitToLast(1)
    //       .on("child_added", function (snapshot) {
    //         const data = snapshot.val();
    //         if (data) {
    //           all_message.push({
    //             user_id: data.user_id,
    //             name: data.name,
    //             time: data.time != "" ? this.$dayjs(data.time).fromNow() : "",
    //             content: data.content
    //               ? data.content
    //               : data.imgURL.length > 1
    //               ? data.senderName + " sent " + data.imgURL.length + " photos."
    //               : data.senderName + " sent a photo.",
    //             imgURL:
    //               data.imgURL.length > 0
    //                 ? data.senderName + " sent a photo."
    //                 : "",
    //             them_id: e.id,
    //             id: data.id,
    //             sort: "to",
    //             read: data.read,
    //             count: 0,
    //           });
    //         }
    //       });
    //   });
    //   let callback = all_message.sort((a, b) => {
    //     return a.id - b.id;
    //   });
    //   this.allmessage = Object.values(
    //     callback.reduce(
    //       (acc, cur) => Object.assign(acc, { [cur.them_id]: cur }),
    //       {}
    //     )
    //   );
    // },
    // allunreadmessage() {
    //   let that = this;
    //   let database = firebase.database().ref(this.USERACCOUNT_LOAD.id);
    //   let count = 1;
    //   let array_tmp = [];

    //   this.GET_ALL_USERS.forEach((e, index) => {
    //     database.child(e.id).on("child_added", (snapshot) => {
    //       let data = snapshot.val();

    //       that._allmessage();
    //       if (data.read) {
    //         that.array_unreadall.push(e.id);
    //         that.unreadall_text = that.array_unreadall.length;
    //       }
    //     });
    //   });
    // },
    // unreadmessage(id) {
    //   let that = this;
    //   firebase
    //     .database()
    //     .ref(this.USERACCOUNT_LOAD.id)
    //     .child(id)
    //     .once("value", function (snapshot) {
    //       const data = snapshot.val();
    //       if (data) {
    //         Object.keys(data).forEach((key) => {
    //           if (data[key].read) {
    //             that.array_id.push({
    //               user_id: data[key].user_id,
    //               read: data[key].read,
    //             });
    //           }
    //         });
    //       }
    //     });
    // },
    // async getMessage(item, index) {
    //   document.title = "INGCOPH";
    //   let that = this;
    //   let all_message = [];
    //   this.loading = false;
    //   this.chatMessages = [];
    //   that.unread_array = [];

    //   await firebase
    //     .database()
    //     .ref(this.USERACCOUNT_LOAD.id)
    //     .child(item.id)
    //     .once("value")
    //     .then((snapshot) => {
    //       let data = snapshot.val();
    //       if (data) {
    //         Object.keys(data).forEach((key) => {
    //           if (data[key].read) {
    //             that.unread_array.push(0);
    //           }
    //           firebase
    //             .database()
    //             .ref(this.USERACCOUNT_LOAD.id)
    //             .child(item.id)
    //             .child(key)
    //             .set({
    //               id: data[key].id,
    //               name: data[key].name,
    //               time: data[key].time,
    //               them_id: data[key].them_id,
    //               user_id: data[key].user_id,
    //               content: data[key].content,
    //               imgURL: data[key].imgURL ? data[key].imgURL : [],
    //               senderName: data[key].senderName,
    //               read: 0,
    //             });
    //           if (that.array_unreadall.length > 0) {
    //             that.unreadall_text =
    //               that.array_unreadall.length - that.unread_array.length;
    //           }
    //         });
    //       }
    //     });
    //   let to = firebase.database().ref(item.id);
    //   to.child(this.USERACCOUNT_LOAD.id).on("child_added", function (snapshot) {
    //     const data1 = snapshot.val();
    //     all_message.push({
    //       key: snapshot.key,
    //       user_id: data1.user_id,
    //       content: that.isValidURL(data1.content)
    //         ? "<a href='" + data1.content + "'>" + data1.content + "</a>"
    //         : "<span>" + data1.content + "</span>",
    //       imgURL: data1.imgURL,
    //       id: data1.id,
    //       sort: "to",
    //       read: data1.read,
    //     });
    //   });
    //   let from = firebase.database().ref(this.USERACCOUNT_LOAD.id);
    //   from.child(item.id).on("child_added", function (snapshot) {
    //     const data = snapshot.val();
    //     all_message.push({
    //       key: snapshot.key,
    //       user_id: data.user_id,
    //       content: that.isValidURL(data.content)
    //         ? "<a href='" + data.content + "'>" + data.content + "</a>"
    //         : "<span>" + data.content + "</span>",
    //       imgURL: data.imgURL,
    //       id: data.id,
    //       sort: "from",
    //       read: data.read,
    //     });
    //   });
    //   let callback = all_message.sort((a, b) => {
    //     return a.id - b.id;
    //   });
    //   this.scrollToEnd(index);
    //   this.message_arr[index].messages = callback;
    // },
    // createMessage(item, index) {
    //   if (item.messages || item.imageURL.length > 0) {
    //     let that = this;
    //     let id = this.$dayjs().valueOf().toString();
    //     let time = this.$dayjs().format();
    //     let message = {
    //       id: id,
    //       time: time,
    //       user_id: this.USERACCOUNT_LOAD.id,
    //       them_id: item.id,
    //       imgURL: ["NULL"],
    //       name: item.name,
    //       senderName: this.USERACCOUNT_LOAD.name,
    //       content: item.messages,
    //       read: 1,
    //     };
    //     let key;
    //     let imageURL = [];
    //     let to = firebase.database().ref(item.id);
    //     to.child(this.USERACCOUNT_LOAD.id)
    //       .push(message)
    //       .then((data) => {
    //         if (item.imageURL.length > 0) {
    //           for (let x of item.imageURL) {
    //             let filename = x.imagePath;

    //             let storageRef = firebase
    //               .storage()
    //               .ref("images/" + data.key + "-" + filename.name);
    //             let uploadImage = storageRef.put(filename);
    //             uploadImage.on(
    //               "state_changed",
    //               (snapshot) => {},
    //               (error) => {},
    //               () => {
    //                 uploadImage.snapshot.ref
    //                   .getDownloadURL()
    //                   .then((downloadURL) => {
    //                     imageURL.push(downloadURL);
    //                     firebase
    //                       .database()
    //                       .ref(item.id)
    //                       .child(that.USERACCOUNT_LOAD.id)
    //                       .child(data.key)
    //                       .update({ imgURL: imageURL });
    //                   });
    //               }
    //             );
    //           }
    //         }
    //       })
    //       .then(() => {
    //         item.messages = "";
    //         item.imageURL = "";
    //         item.disable_btn = true;
    //         that.scrollToEnd(index);
    //         that._allmessage();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // },
    isValidURL(string) {
      var res = string.match(
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      );
      return res !== null;
    },
    // onScroll(id, index) {},
    // scrollToEnd(index) {
    //   this.$nextTick(() => {
    //     let container = this.$el.querySelector(`.chat-container-${index}`);
    //     container.scrollTop = container.scrollHeight;
    //   });
    // },
    // scrollTo(index) {
    //   this.$nextTick(() => {
    //     let currentHeight = this.$refs.chatContainer.scrollHeight;
    //     let difference = currentHeight - this.totalChatHeight;
    //     let container = this.$el.querySelector(`.chat-container-${index}`);
    //     container.scrollTop = difference;
    //   });
    // },
    getImage(item) {
      let that = this;
      let uploadFile = item.uploadImage;
      uploadFile.forEach((e, i) => {
        item.imageURL.push({
          imagename: URL.createObjectURL(e),
          imagePath: uploadFile[i],
          fileType: e.type,
        });
      });
      item.disable_btn = false;
    },
    getBirthdayCelebrants(){
      let logged_times = JSON.parse(localStorage.getItem("logged_times"));
      let celebrants = Object.values(this.GET_EMPLOYEE_BIRTHDAY_CELEBRANTS).map(obj => obj);
            let filtered = celebrants.filter(e => {
            const birthDate = this.$dayjs(e.birth_date, 'YYYY-MM-DD');
                return birthDate.format('MM-DD') === this.$dayjs().format('MM-DD');
            });
      let seen_count = JSON.parse(localStorage.getItem("seen_today"))
      celebrants = filtered
    if(celebrants.length == 0 || celebrants == []){
        return false
    }
    else{
        localStorage.setItem("seen_today", seen_count ? seen_count + 1 : 1);
        if(logged_times == 1 && JSON.parse(localStorage.getItem("seen_today")) == 1){
          this.$store.commit('CELEBRANTS_DIALOG_MUTATE',true);
          this.components = BirthdayCelebrantsViewVue
          var self = this;
          setTimeout(function () {
            self.$store.commit('CELEBRANTS_DIALOG_MUTATE', false);
          }, 8000);
        }
      }
    },
    isFullScreen(){
      let listOfLinks = this.$store.state.Queueing.warehouse_queueing.map(e=>{ return e.route_name});

      if(listOfLinks.includes(this.$route.name)){
        return false
      }
      return true
    }
  },
  watch: {
    menu: {
      handler(val) {
        if (val) {
          this._allmessage();
        }
      },
    },
    USERACCOUNT_LOAD: {
      handler(val) {},
      deep: true,
    },
  },
};
</script>
<style scoped>
#app {
  background-color: #1e1e1c !important;
}

/* .chat_box {
  position: fixed;
  bottom: 0;
  width: 250px;
  background: white;
  border-radius: 50px 50px 0px 0px;
  z-index: 100;
  scroll-behavior: smooth;
}

.imessage {
  display: flex;
  bottom: 0;
  flex-direction: column;
}

.imessage span {
  font-size: 12px;
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage span::before,
.imessage span::after {
  bottom: 0;
  content: "";
  height: 1rem;
  position: absolute;
}

span.from-me {
  align-self: flex-end;
  background-color: #333;
  color: #fff;
  bottom: 0;
  border-radius: 18px 18px 0 18px;
}

span.from-me-avatar {
  align-self: flex-end;
}

span[class^="from-"] {
  margin: 0.2rem 0;
  width: fit-content;
}

span.from-them {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
  border-radius: 18px 18px 18px 0;
}

span.from-them-avatar {
  align-items: flex-start;
} */

.image_grid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 4px;
}

.img_item {
  -ms-flex: 25%;
  flex: 25%;
  max-width: 100%;
  padding: 0 4px;
}

.img_item img {
  margin-top: 8px;
  vertical-align: middle;
  width: 90%;
}

.img-bg {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 80px;
}

div.img-bg img {
  width: 90%;

  border-radius: 50px;
}

.v-text-message {
  resize: none;
  font-size: 0.8rem;
  letter-spacing: 1px;
  width: 100%;
  left: 0px;
  position: absolute;
  bottom: -50px;
}
</style>
