export default {
  data() {
      return {
        registration: null,
        updateExists: false,
        refreshing:false,
        contentText:'',
        isUpdate:false,
      }
    },
  created(){
      document.addEventListener('swUpdated',this.updateAvailable,{once:true})
      document.addEventListener('swDownload',this.downloadingData,{once:true})
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
  },
  methods: {
      updateAvailable(event){
          this.registration = event.detail
          this.updateExists = true
          this.contentText = 'New System update available.'
          this.isUpdate = true
      },
      downloadingData(event){
          this.registration = event.detail
          this.updateExists = true
          this.contentText = 'Downloading the latest system update... Please do not turn off your device'
      },
      refreshApp(){
          
          // Make sure we only send a 'skip waiting' message if the SW is waiting
          
          if (!this.registration || !this.registration.waiting) return
          // Send message to SW to skip the waiting and activate the new SW
          this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
          this.updateExists = false
          // window.location.reload()
           
        }
  },
}