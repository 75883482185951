

import api from '../../../api'
export default {

    getitemjournal(params){
        return api.get(`inventory/item-journal`,{params})
    },
    postitemjournal(params){
        return api.post(`inventory/item-journal`,params)
    },
    approveitemjournal(id){
        return api.post(`inventory/item-journal/${id}/approve`)
    },
    completeitemjournal(id){
        return api.post(`inventory/item-journal/${id}/complete`)
    },
    destroyitemjournal(id){
        return api.post(`inventory/item-journal/destroy/${id}`)
    },
    detailsitemjournal(id,params){
        return api.get(`inventory/item-journal/${id}/details`,{params})
    },
    canedititemjournal(params){
        return api.post(`inventory/item-journal/can-edit`,params)
    },
    cleardetailsitemjournal(id,params){
        return api.post(`inventory/item-journal/${id}/clear-details`,params)
    },
    savedetailsitemjournal(id,params){
        return api.post(`inventory/item-journal/${id}/save-detail`,params)
    },
    updateitemjournal(id,params){
        return api.post(`inventory/item-journal/${id}/update`,params)
    },

}
