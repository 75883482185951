
import repository from '@/api/credentials';

const routes = [{
    path: '/services/techmanager/:id',
    name: 'servicetechmanager',
    component: () => import('@/views/main/modules/Services/Views/TechnicianManagerView.vue'),
    beforeEnter:(to,from,next)=>{
        repository.checkAccessUser(to.params.id).then((response)=>{
            if(response.data == 'fail'){
                next('/no-access')
                }else{
                next()
            }
        }).catch(()=>{

        })

    },
    meta:{
        requiresAuth:true
    }
}]

export default routes;
