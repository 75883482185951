import api from '../../../api'

export default {
   CreateChartOfAccounts(params){
      return api.post('/accounting/chartofaccounts',params)
   },
   UpdateChartOfAccounts(params){
      return api.put(`/accounting/chartofaccounts/${params.id}`,params)
   },
   DeleteChartOfAccounts(params){
      return api.delete(`/accounting/chartofaccounts/${params}`)
   },
   getAllChartOfAccounts(params){
      return api.get('/accounting/getAllchartofaccounts',{params})
   },
   getChartOfAccountsItems(params) {
      return api.get('/accounting/getChartOfAccountsItems',{params})
   },
   coaImport(params, config) {
      return api.post('accounting/coaImport', params, config);
   },
}

