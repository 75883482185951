import repository from "@/api/modules/Admin/adminAssetCondition";

export default {
    state: {
        adminAssetConditionDataTableReload: false,
        adminAssetConditionViewId: undefined,
        adminAssetConditions: [],
    },
    getters: {
        GET_ADMIN_ASSET_CONDITION_DATA_TABLE_RELOAD: (state) =>
            state.adminAssetConditionDataTableReload,
        GET_ADMIN_ASSET_CONDITION_VIEW_ID: (state) =>
            state.adminAssetConditionViewId,
        GET_ADMIN_ASSET_CONDITIONS: (state) =>
            state.adminAssetConditions,
    },
    actions: {
        adminAssetConditionGet({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .adminAssetConditionGet(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminAssetConditionPost({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .adminAssetConditionPost(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminAssetConditionPut({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .adminAssetConditionPut(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminAssetConditionDelete({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .adminAssetConditionDelete(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        ADMIN_ASSET_CONDITION_DATA_TABLE_RELOAD: (state, data) => {
            state.adminAssetConditionDataTableReload = data;
        },
        ADMIN_ASSET_CONDITION_VIEW_ID: (state, data) => {
            state.adminAssetConditionViewId = data;
        },
        ADMIN_ASSET_CONDITIONS: (state, data) => {
            state.adminAssetConditions = data;
        },
    },
};
