import store from '../../../../store';
import api from '../../../api'

export default {
    // Working Schedule API
    addhrscheduletemplate(params){
        return api.post('/hr/hrscheduletemplate',params);
    },
    gethrscheduletemplate(params){
        return api.get('/hr/hrscheduletemplate',{params});
    },
    showWorkScheduleDetails(params){
        return api.get(`/hr/hrscheduletemplate/${params.id}`,{params});
    },
    updatehrscheduletemplate(params){
        return api.put(`/hr/hrscheduletemplate/${params.id}`,params.payload);
    },
    deletehrscheduletemplate(params){
        return api.delete(`/hr/hrscheduletemplate/${params}`);
    },

    // Employee Schedule API
    addhremployeeschedule(params){
        return api.post('/hr/hremployeeschedule',params);
    },
    gethremployeeschedule(params){
        return api.get('/hr/hremployeeschedule',{params});
    },
    updateEmployeeSchedule(params){
        return api.post(`/hr/updateEmployeeSchedule`,params);
    },
    showEmployeeScheduleDetails(params){
        return api.get(`/hr/hremployeeschedule/${params.id}`,{params});
    },
    showEmployeeScheduleTemp(params){
        return api.post(`/hr/showEmployeeScheduleTemp`, params);
    },
    approveEmployeeScheduleTemp(params){
        return api.post(`/hr/approveEmployeeScheduleTemp`, params);
    },
    rejectEmployeeScheduleTemp(params){
        return api.post(`/hr/rejectEmployeeScheduleTemp`, params);
    },
    deleteForApprovalTemp(params){
        return api.delete(`/hr/deleteForApprovalTemp/${params}`, );
    },
    deletehremployeeschedule(params){
        return api.delete(`/hr/hremployeeschedule/${params}`);
    },
    showEmployeeSchedule(params){
        return api.post('/hr/showEmployeeSchedule', params);
    },

    // Temporary Schedule API
    addhrtemporaryschedule(params){
        return api.post('/hr/hrtemporaryschedule',params);
    },
    gethrtemporaryschedule(params){
        return api.get('/hr/hrtemporaryschedule',{params});
    },
    updatetemporarySchedule(params){
        return api.post(`/hr/hrtemporaryschedule`,params);
    },
    deletehrtemporaryschedule(params){
        return api.delete(`/hr/hrtemporaryschedule/${params}`);
    },
    getBiometricsData(params){
        return api.post(`/hr/getBiometricsData`, params);
    },
    getEmployeeIDSBio(params){
        return api.post(`/hr/getEmployeeIDSBio`, params);
    },
    viewEmployeeBioTime(params){
        return api.post(`/hr/viewEmployeeBioTime`, params);
    },

    // Timekeeping Records API
    gettimekeepingrecords(params){
        return api.get('/hr/hrtimekeeping', {params});
    },
    savetimekeepingrecord(params){
        return api.post('/hr/hrtimekeeping', params, params);
    },
    getTimekeepingRecordEmployeeID(params){
        return api.post('/hr/getTimekeepingRecordEmployeeID', params);
    },
    showTimekeepingRecordDetails(params){
        return api.post(`/hr/showTimekeepingRecordDetails`, params);
    },
    updateTimekeepingRecords(params){
        return api.put(`/hr/hrtimekeeping/${params.id}`, params)
    },
    deleteTimekeepingRecords(params){
        return api.delete(`/hr/hrtimekeeping/${params}`)
    },
    getTimekeepingScheduleDay(params){
        return api.post('/hr/getTimekeepingScheduleDay', params);
    },
    approveTimekeepingRecordManual(params){
        return api.post('/hr/approveTimekeepingRecordManual', params);
    },
    checkTimekeepingRecordDay(params){
        return api.post('/hr/checkTimekeepingRecordDay', params);
    },
    changeTimekeepingRecordStatus(params){
        return api.post('/hr/changeTimekeepingRecordStatus', params);
    },
    getTimekeepingRecordFiles(params){
        return api.post('/hr/getTimekeepingRecordFiles', params);
    },
    checkExistingTimekeepingRecord(params){
        return api.post('/hr/checkExistingTimekeepingRecord', params);
    },
    generateFilemakerData(params){
        return api.post('/hr/generateFilemakerData', params);
    },
    fetchHolidays(params){
        return api.post('/hr/fetchHolidays', params);
    },
    getConfirmedOT(params){
        return api.post('/hr/getConfirmedOT', params);
    },

    // Department Schedule
    getDepartmentSchedule(params){
        return api.get('/hr/getDepartmentSchedule', {params});
    },
    showDepartmentSchedule(params){
        return api.get(`/hr/showDepartmentSchedule/${params.id}`, {params});
    },
    updateDepartmentSchedule(params){
        return api.post(`/hr/updateDepartmentSchedule`, params);
    },

    // For Approval Temporary Schedule
    getForApprovalSchedules(params){
        return api.get('/hr/getForApprovalSchedules', {params});
    },
    approveTemporarySchedules(params){
        return api.post('/hr/approveTemporarySchedules', params);
    },

    hrUrlGet(params){
        return api.get(`/hr/${params.url}`,{params});
    },
    hrUrlPost(params){
        return api.post(`/hr/${params.url}`,params);
    },
    hrUrlPut(params){
        return api.put(`/hr/${params.url}/${params.id}`,params);
    },
    hrUrlDelete(params){
        return api.delete(`/hr/${params.url}/${params.id }`, {params});
    },
}
