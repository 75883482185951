import api from '../../../api'
export default {
    dailyManagementSystemGet(params) {
        return api.get('dms/'+params.url,{params});
    },
    dailyManagementSystemPost(params) {
        if(!!params.config){
            return api.post('dms/'+params.url,params.data,params.config);
        }else{
            return api.post('dms/'+params.url,params);
        }
    },
    getCurrentCity(params){
        return api.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${params.latitude}&longitude=${params.longitude}&localityLanguage=en`)
    },
    getsalesmandtr(params){
        return api.get(`dms/daily-time-in-records`,{params})
    },
    getsalesmandetails(params){
        return api.get(`dms/get-salesman-details`,{params})
    },
    getallsalesmancount(params){
        return api.get(`dms/get-salesman-count`,{params})
    },
    getallsalesmandropdown(params){
        return api.get(`dms/get-all-salesman-dropdown`)
    },
    getSalesmanLocation(params){
        return api.get(`dms/get-salesman-locations`,{params})
    },
    getSalesmanLocationMap(params){
        return api.get(`dms/get-salesman-locations-maps`,{params})
    },
    getsalesmantotal(params){
        return api.get(`dms/get-salesman-total`,{params})
    },
    getGeojson(params){
        return api.get(`masterdata/getGeojson`,{params})
    },
}
