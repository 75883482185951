import api from '../../../api'
export default {
    salesOrdersPost(params){
        return api.post('sales-orders/' + params.url,params)
    },
    salesOrdersGet(params){
        return api.get('sales-orders/' + params.url,{params:params})
    },
    salesOrdersDelete(params){
        return api.delete('sales-orders/' + params.url,{params:params})
    }
}