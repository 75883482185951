<template>
    <v-container>
        <ComponentHeader
            :button="true"
            :title="'Parts Summary (Movement)'"
            :type="'Service'"
        ></ComponentHeader>
        <v-container class="bg-bluish-gray" fluid>
            <v-card>
                <v-card-text>
                    <v-row class="p-2">
                        <v-col sm="3" md="1">
                            <v-select
                                v-model="filters.type"
                                :items="dropdowns.type"
                                label="Type"
                                background-color="white"
                                dense
                                outlined
                                hide-details
                            ></v-select>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-menu
                                v-model="filters.menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.dates[0]"
                                        v-on="on"
                                        label="Date From"
                                        append-icon="mdi-calendar-clock"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.dates[0]" @input="filters.menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col sm="3" md="2">
                            <v-menu
                                v-model="filters.menu3"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.dates[1]"
                                        v-on="on"
                                        label="Date To"
                                        append-icon="mdi-calendar-clock"
                                        dense
                                        outlined
                                        hide-details
                                        readonly
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.dates[1]" @input="filters.menu3 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                       
                        <v-spacer />
                        <v-col sm="12" md="4" class="text-right">
                            <!-- <v-btn small class="ml-1" @click="resetFields()">Clear</v-btn> -->
                            <v-btn small class="ml-1" :loading="loading.export" @click="exportToExcel()">Excel</v-btn>
                            <!-- <v-btn small class="ml-1" @click="getSalesIncentives()">Filter</v-btn> -->
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </v-container>
</template>

<script>
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import swal from 'sweetalert2';

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
    },
    data() {
        return {
            dropdowns: {
                type: [
                    { value: 1, text: 'Clean' },
                    { value: 2, text: 'Recycled' },
                ],
            },
            filters: {
                dates: [
                    this.$dayjs().startOf("month").format("YYYY-MM-DD"),
                    this.$dayjs().endOf("month").format("YYYY-MM-DD"),
                ],
                menu2: false,
                menu3: false,
                type: 1,
            },
            loading: {
                export: false,
            },
        }
    },
    methods: {
        async exportToExcel() {
            let data = null
            let requested_parts = []
            let pending_parts = []
            let completed_parts = []
            let exportColumns = [
                { header: 'RF#', width: 15, key: 'rf_number' },
                { header: 'Parts Model', width: 20, key: 'parts_model' },
                { header: 'Parts Name', width: 35, key: 'parts_name' },
            ];
            swal.fire({
                title:                      'Please wait...',
                allowOutsideClick:          false,
            })
            swal.showLoading();
            this.loading.export = true

            await this.$store.dispatch('serviceGet', {
                filters: this.filters,
                url: 'get-parts-summary-movement',
            }).then(async response => {
                let workbook = new this.$exceljs.Workbook()
                let worksheet = workbook.addWorksheet('Summary')

                const docFont = {
                        name: 'Arial',
                        color: { rgb: '000000' },
                        family: 2,
                        size: 10,
                        bold: true
                    }
                const textAlignment = {
                    horizontal: 'left'
                }

                for (let letter of ['B', 'C']){
                    for(let num of [2,3,4,5,6,7,8,9]){
                        if(letter == 'B'){
                            worksheet.mergeCells(`A${num}:${letter + num}`)
                        } else {
                            worksheet.getCell(`${letter + num}`).alignment = textAlignment
                        }
                        worksheet.getCell(`${letter + num}`).font = docFont
                    }
                }

                worksheet.mergeCells('A1:F1')
                worksheet.getColumn('A').width = 30
                worksheet.getCell('A1').value = `Summary Report From ${this.filters.dates[0]} to ${this.filters.dates[1]}`
                worksheet.getCell('A1').font = {size:14, bold:true}
                worksheet.getCell('A1').alignment = { horizontal:'center'}
                worksheet.getCell('A1').fill = {type: 'pattern', pattern:'solid', fgColor:{argb:'F69F1A'}}

                worksheet.getCell('B2').value = "Total Number of RFs moved in PRQ:"
                worksheet.getCell('B3').value = "Total Number of RFs moved in PP:"
                worksheet.getCell('B4').value = "Total Number of RFs moved in PFO:"
                worksheet.getCell('B5').value = "Total Number of RFs moved in CF:"

                let worksheet2 = workbook.addWorksheet('Requested Parts')
                let worksheet3 = workbook.addWorksheet('Pending Parts')
                let worksheet4 = workbook.addWorksheet('Completed Parts')

                if(this.filters.type == 1) {
                    data = response.data
                    response.data.requested_parts.forEach(e => {
                        requested_parts = [...requested_parts, ...e.sc_parts_order_item]
                    });
                    response.data.pending_pp_parts.forEach(e => {
                        pending_parts = [...pending_parts, ...e.sc_parts_order_item]
                    });
                    response.data.pending_pfo_parts.forEach(e => {
                        pending_parts = [...pending_parts, ...e.sc_parts_order_item]
                    });
                    response.data.completed_parts.forEach(e => {
                        completed_parts = [...completed_parts, e]
                    });

                    worksheet.getCell('B6').value = "Total Requested Spare Parts:"
                    worksheet.getCell('B7').value = "Total Pending Spare Parts:"
                    worksheet.getCell('B8').value = "Total Completed Spare Parts:"

                    worksheet.getCell('C2').value = response.data.requested_count
                    worksheet.getCell('C3').value = response.data.pending_pp_count
                    worksheet.getCell('C4').value = response.data.pending_pfo_count
                    worksheet.getCell('C5').value = response.data.completed_count
                    worksheet.getCell('C6').value = requested_parts.reduce((sum, e) => { return sum +(e.parts_served_qty + e.parts_requested_qty)}, 0)
                    worksheet.getCell('C7').value = pending_parts.reduce((sum, e) => { return sum +  e.parts_requested_qty}, 0)
                    worksheet.getCell('C8').value = completed_parts.reduce((sum, e) => { return sum + e.quantity }, 0)

                } else {
                    data = response.data
                    Object.entries(response.data.requested_parts).forEach(([key, value]) => {
                        requested_parts = [...requested_parts, ...value.sc_parts_order_items];
                    });

                    Object.entries(response.data.pending_pp_parts).forEach(([key, value]) => {
                        pending_parts = [...pending_parts, ...value.sc_parts_order_items];
                    });

                    Object.entries(response.data.pending_pfo_parts).forEach(([key, value]) => {
                        pending_parts = [...pending_parts, ...value.sc_parts_order_items];
                    });

                    Object.entries(response.data.completed_parts).forEach(([key, value]) => {
                        completed_parts = [...completed_parts, ...value.sc_parts_order_items];
                    });

                    worksheet.getCell('B6').value = "Total Number of Recycled RFs:"
                    worksheet.getCell('B7').value = "Total Requested Spare Parts:"
                    worksheet.getCell('B8').value = "Total Pending Spare Parts:"
                    worksheet.getCell('B9').value = "Total Completed Spare Parts:"

                    worksheet.getCell('C2').value = response.data.requested_count
                    worksheet.getCell('C3').value = response.data.pending_pp_count
                    worksheet.getCell('C4').value = response.data.pending_pfo_count
                    worksheet.getCell('C5').value = response.data.completed_count
                    worksheet.getCell('C6').value = response.data.recycled_rf_count
                    worksheet.getCell('C7').value = requested_parts.reduce((sum, e) => { return sum +(e.parts_served_qty + e.parts_requested_qty)}, 0)
                    worksheet.getCell('C8').value = pending_parts.reduce((sum, e) => { return sum +  e.parts_requested_qty}, 0)
                    worksheet.getCell('C9').value = completed_parts.reduce((sum, e) => { return sum + e.parts_served_qty}, 0)
                }

                
                if(data != null) {
                    let worksheets = [worksheet2, worksheet3, worksheet4]
                    let parts = [requested_parts,pending_parts,completed_parts]
                    const worksheetLength = worksheets.length
                    for (let ws=0; ws < worksheetLength; ws++){
                        if (ws == 2) {
                            worksheets[ws].columns = [...exportColumns, ...[
                                { header: 'Parts Completed Qty', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } }, key: 'parts_served_qty' },
                                { header: 'Crate Number', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' } }, key: 'crate_number' },
                                { header: 'Picker Name', width: 20, key: 'picker_name' },
                                { header: 'Checker Name', width: 20, key: 'checker_name' },
                                { header: 'Processed By', width: 20, key: 'processed_by' },
                            ]]
                        } else {
                            worksheets[ws].columns = [...exportColumns, ...[
                                { header: 'Parts Requested Qty', width: 20, key: 'parts_requested_qty' },
                                { header: 'Parts Serve Qty', width: 20, key: 'parts_served_qty' },
                            ]]
                        }
                        parts[ws].forEach(e=>{
                            worksheets[ws].addRow(e)
                        })
                        let headerLength = worksheets[ws].columns.length;
                        let headerRow = worksheets[ws].getRow(1);
                        headerRow.font = { bold:true }
                        for (let c=1;c<=headerLength;c++) {
                            let currentCell = headerRow.getCell(c);
                            currentCell.fill = {
                                type: 'pattern',
                                pattern:'solid',
                                fgColor:{argb:'F69F1A'},
                            }
                        }
                    }
                    await workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        this.$filesaver.saveAs(blob, `Parts Summary Report.xlsx`);
                        this.loading.export = false
                    }).catch(e=>{
                        console.log(e)
                        this.loading.export = false
                    });
                } else {
                    this.loading.export = false;
                }
            }).catch(error => {
                console.log(error)
            })

            swal.close();
            this.loading.export = false
        },
    }
}
</script>