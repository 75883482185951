import store from '../../../../store';
import api from '../../../api'

export default {
    //  Manpower Request
    addhrManpowerRequest(params){
        return api.post('/hr/hrmanpowerRequest',params);
    },
    gethrManpowerRequest(params){
        return api.get('/hr/hrmanpowerRequest',{params});
    },
    updatehrManpowerRequest(params){
        return api.put(`/hr/hrmanpowerRequest/${params.id}`,params.payload);
    },
    deletehrManpowerRequest(params){
        return api.delete(`/hr/hrmanpowerRequest/${params}`);
    },
    showhrManpowerRequest(params){
        return api.post('/hr/showhrManpowerRequest',params);
    },
    approveRejectManpowerRequest(params){
        return api.post('/hr/approveRejectManpowerRequest',params);
    },
    uploadManpowerRequestfiles(params,config){
        return api.post(`/hr/uploadManpowerRequestfiles`,params,config);
    },
    getManpowerRequestfiles(params){
        return api.get(`/hr/getManpowerRequestfiles`,{params});
    },
    deleteManpowerRequestFile(params){
        return api.post(`hr/deleteManpowerRequestFile`,params);
    },

    // Applicant List
    addhrApplicants(params){
        return api.post('/hr/hrApplicantList',params);
    },
    gethrApplicants(params){
        return api.get('/hr/hrApplicantList',{params});
    },
    updatehrApplicants(params){
        return api.put(`/hr/hrApplicantList/${params.id}`,params.payload);
    },
    deletehrApplicants(params){
        return api.delete(`/hr/hrApplicantList/${params}`);
    },
    showhrApplicants(params){
        return api.get(`/hr/hrApplicantList/${params}`);
    },
    approveRejectApplicants(params){
        return api.post('/hr/approveRejectApplicant',params);
    },
    getApplicantFiles(params){
        return api.get('/hr/getApplicantFiles',{params})
    },
    getApplicantRequirementFiles(params){
        return api.get('/hr/getApplicantRequirementFiles',{params})
    },
    updateApplicationStatus(params){
        return api.post('/hr/updateApplicationStatus',params)
    },
    // File Upload API
    getFileFromDomain(params){
        return api.get(`/hr/getFileFromDomain?file_name=${params.file_name}`,{params})
    },
    getRequirementsFromDomain(params){
        return api.get(`/hr/getRequirementsFromDomain?file_name=${params.file_name}&requirement_type=${params.requirement_type}`,{params})
    },
    // Approving Applicant Requirements
    approveApplicationRequirements(params){
        return api.post(`/hr/approveApplicationRequirements`,params)
    },

    rejectApplicantRequirements(params){
        return api.post(`/hr/rejectApplicantRequirements`,params)
    },

    // Upload New Requirements of Applicant
    uploadApplicantNewRequirements(params,config){
        return api.post(`/hr/uploadApplicantNewRequirements`,params,config)
    },

    // Delete Applicant Requirements
    deleteApplicationRequirements(params){
        return api.post(`/hr/deleteApplicationRequirements`,params)
    },

    deleteEmployeeRequirements(params){
        return api.post(`/hr/deleteEmployeeRequirements`,params)
    },


    // Privacy Settings
    addhrPrivacySettings(params){
        return api.post('/hr/hrprivacysettings',params);
    },
    gethrPrivacySettings(params){
        return api.get('/hr/hrprivacysettings',{params});
    },
    updatehrPrivacySettings(params){
        return api.put(`/hr/hrprivacysettings/${params.id}`,params.payload);
    },

    // Privacy Settings
    getApplicantStatusMessage(params){
        return api.get('/hr/getApplicantStatusMessage',{params});
    },
    updateApplicantStatusMessage(params){
        return api.post('/hr/updateApplicantStatusMessage',params);
    },

    //  Applicant Requirements
    addApplicantRequirements(params){
        return api.post('/hr/hrapplicantrequirements',params);
    },
    getApplicantRequirements(params){
        return api.get('/hr/hrapplicantrequirements',{params});
    },
    updateApplicantRequirements(params){
        return api.put(`/hr/hrapplicantrequirements/${params.id}`,params.payload);
    },
    deleteApplicantRequirements(params){
        return api.delete(`/hr/hrapplicantrequirements/${params}`);
    },

    //  PRF Selections
    addPRFSelectionTable(params){
        return api.post('/hr/addPRFSelectionTable',params);
    },
    getPRFSelectionTable(params){
        return api.get('/hr/getPRFSelectionTable',{params});
    },
    updatePRFSelectionTable(params){
        return api.post(`/hr/updatePRFSelectionTable`,params);
    },
    deletePRFSelectionTable(params){
        return api.post(`/hr/deletePRFSelectionTable`,params);
    },


}
