import repository from '@/api/credentials';

const routes = [
    {
        path: '/hr/hrrecruitment/:id',
        name: 'hrrecruitment',
        component: () => import('@/views/main/modules/HR/RecruitmentSystem/RecruitmentGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'HR Recruitment',
        }
    },
];
export default routes;