import repository from '@/api/Main/Company/index';

export default {
    state: {
        company_list: [],
        company: null,
        user_companies: {},
        new_company_data: false
    },
    getters: {
        GET_COMPANY_LIST: (state) => state.company_list,
        GET_COMPANY: (state) => state.company,
        GET_USER_COMPANY: (state) => state.user_companies,
        GET_NEW_COMPANY_DATA: (state) => state.new_company_data,
    },
    actions: {
        getCompany({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getcompany(payload).then(response => {
                    commit('COMPANY_LIST_LOAD', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        showCompany({ commit }, payload) {
            repository.showcompany().then(response => {
                commit('COMPANY_LOAD', response.data)
            })
        },
        addCompany({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.addcompany(payload).then(response => {
                    commit('NEW_COMPANY_DATA', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateCompany({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.updatecompany(payload).then(response => {
                    commit('NEW_COMPANY_DATA', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        deleteCompany({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.deletecompany(payload).then(response => {
                    commit('NEW_COMPANY_DATA', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        COMPANY_LIST_LOAD: (state, data) => {
            state.company_list = data
        },
        COMPANY_LOAD: (state, data) => {
            state.company = data
        },
        USER_COMPANY: (state, data) => {
            state.user_companies = data
        },
        NEW_COMPANY_DATA: (state, data) => {
            state.new_company_data = data
        },
    },
}
