import repository from "@/api/modules/Accounting/UnconfirmedPackingList";

export default {
    state: {
        show_packing_lists:[],
        all_packing_lists:[],
        view_packing_list:[],
        view_stock_card:[],
        
    },
    getters: {
        GET_SHOW_PACKING_LISTS:(state) => state.show_packing_lists,
        GET_ALL_PACKING_LISTS:(state) => state.all_packing_lists,
        GET_VIEW_PACKING_LIST:(state) => state.view_packing_list,
        GET_VIEW_STOCK_CARD:(state) => state.view_stock_card,
        
    },
    actions: {
        postPackingLists({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.postpackinglists(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showPackingLists({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.showpackinglists(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_PACKING_LISTS',response.data);
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updatePackingLists({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatepackinglists(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllPackingLists({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getallpackinglists(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_PACKING_LISTS',response.data);
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deletePackingLists({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletepackinglists(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        cancelPackingLists({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cancelpackinglists(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updatePackingListStatus({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatepackingliststatus(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updatePLPrinted({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateplprinted(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        // confirmOverridePrintPL({commit},payload) {
        //     return new Promise((resolve, reject) => {
        //         repository.confirmoverrideprintpl(payload).then((response) => {
        //             if (response.status == 200) {
        //                 resolve(response)
        //             }
        //         }).catch((error) => {
        //             reject(error)
        //         });
        //     })
        // },
        convertToStockCard({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.converttostockcard(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        viewPackingList({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.viewpackinglist(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('V_PACKING_LISTS',response.data);
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        viewStockCard({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.viewstockcard(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('V_STOCK_CARD',response.data);
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        // getGenTradeCustomers({commit},payload) {
        //     return new Promise((resolve, reject) => {
        //         repository.getgentradecustomers(payload).then((response) => {
        //             if (response.status == 200) {
        //                 resolve(response)
        //                 commit('GENTRADE_CUSTOMERS',response.data);
        //             }
        //         }).catch((error) => {
        //             reject(error)
        //         });
        //     })
        // },
        
    },
    mutations: {
        SHOW_PACKING_LISTS: (state, data) => { (state.show_packing_lists = data) },
        ALL_PACKING_LISTS: (state, data) => { (state.all_packing_lists = data) },
        V_PACKING_LISTS: (state, data) => { (state.view_packing_list = data) },
        V_STOCK_CARD: (state, data) => { (state.view_stock_card = data) },
        
    }

}
