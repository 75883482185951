import repository from "@/api/modules/Marketing/index";

export default {
    state: {
        getOnlineMonitoringImages: [],
    },
    getters: {
        GET_ONLINE_MONITORING_IMAGES: (state) => state.getOnlineMonitoringImages,
    },
    actions: {
        getWebScraping({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getWebScraping(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postWebScraping({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.postWebScraping(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postConfigWebScraping({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.postConfigWebScraping(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        ONLINE_MONITORING_IMAGES: (state, data) => { state.getOnlineMonitoringImages = data },
    }

}
