import api from '../../api'

export default {
    getlowlevelstock(params){
        return api.get('dashboard/warehouse/low-level-stock', {params});
    },
    getoutofstock(params){
        return api.get('dashboard/warehouse/out-of-stock', {params});
    },
    getcheckerform(params){
        return api.get('dashboard/warehouse/checker-form', {params});
    },
    getcheckerformusers(params){
        return api.get('dashboard/warehouse/checker-form-users', {params});
    },
    getitemcount(){
        return api.get('dashboard/warehouse/item-count');
    },
    getwpfsconfirmed(){
        return api.get('dashboard/warehouse/get-wpfs-confirmed');
    },
    getwpfschecked(){
        return api.get('dashboard/warehouse/get-wpfs-checked');
    },
    getwpfsnotconverted(){
        return api.get('dashboard/warehouse/get-wpfs-not-converted');
    },
    getsotoprepare(){
        return api.get('dashboard/warehouse/get-so-to-prepare');
    },
    getstockcards(){
        return api.get('dashboard/warehouse/get-stock-cards');
    },
    getpendingcontainers(){
        return api.get('dashboard/warehouse/get-containers');
    }

}
