import repository from "@/api/modules/Operations/ItemJournal";

export default {
    state: {
        item_journal: [],
        details_item_journal: [],
    },
    getters: {
        GET_ITEM_JOURNAL: (state) => state.item_journal,
        GET_DETAILS_ITEM_JOURNAL: (state) => state.details_item_journal,
       
    },
    actions: {
        getItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getitemjournal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_JOURNAL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.postitemjournal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        approveItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.approveitemjournal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        completeItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.completeitemjournal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitemjournal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        detailsItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.detailsitemjournal(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DETAILS_ITEM_JOURNAL', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        canEditItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.canedititemjournal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        clearDetailsItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cleardetailsitemjournal(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        saveDetailsItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.savedetailsitemjournal(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateItemJournal({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updateitemjournal(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        ITEM_JOURNAL: (state, data) => { (state.item_journal = data) },
        DETAILS_ITEM_JOURNAL: (state, data) => { (state.details_item_journal = data) },
    }

}
