import api from '../../../api'

export default {
    journalPost(params){
        return api.post(`/accounting/journalvoucher/`+params.url,params)
    },
    journalGet(params){
        return api.get(`/accounting/journalvoucher/`+params.url,{params})
    },
    journalGetItemExport(params){
        return api.get(`/accounting/journalvoucher/`+params.url,{params})
    },
    journalPut(params){
        return api.put(`/accounting/journalvoucher/journalvoucher/`+params.url,params)
    },
    journalDelete(params){
        return api.delete(`/accounting/journalvoucher/journalvoucher/`+params.url)
    },
    getJVNum(params){
        return api.get('/accounting/journalvoucher/getJVNum',{ params });
    },
    getJVFiles(params){
        return api.get(`accounting/journalvoucher/get-journal_voucher-files/${params.id}`,{params});
    },
    deleteJVFiles(params){
        return api.get(`accounting/journalvoucher/delete-journal_voucher-files/${params.id}`)
    }
}

