import repository from '@/api/modules/Dashboard/Warehouse'

export default {
    state: {
        low_level_stock: [],
        out_of_stock: [],
        checker_form: [],
        item_count: [],
        wpfs_confirmed: [],
        wpfs_checked: [],
        wpfs_not_converted: [],
        so_to_prepare: [],
        stock_cards: [],
        pending_containers: []
    },
    getters: {
        GET_LOW_LEVEL_STOCK: state => state.low_level_stock,
        GET_OUT_OF_STOCK: state => state.out_of_stock,
        GET_PREPARED_VS_TOTAL_CONVERTED: state => state.checker_form,
        GET_ITEM_COUNT_PER_CATEGORY: state => state.item_count,
        GET_WPFS_CONFIRMED: state => state.wpfs_confirmed,
        GET_WPFS_CHECKED: state => state.wpfs_checked,
        GET_WPFS_NOT_CONVERTED: state => state.wpfs_not_converted,
        GET_SO_TO_PREPARE: state => state.so_to_prepare,
        GET_STOCK_CARDS: state => state.stock_cards,
        GET_PENDING_CONTAINERS: state => state.pending_containers
    },
    actions: {
        getLowLevelStock({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getlowlevelstock(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('LOW_LEVEL_STOCK_DEFAULT', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getOutofStock({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getoutofstock(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('OUT_OF_STOCK', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getCheckerForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getcheckerform(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PREPARED_VS_TOTAL_CONVERTED', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getItemCount({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getitemcount().then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_COUNT_PER_CATEGORY', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getWpfsConfirmed({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getwpfsconfirmed().then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('WPFS_CONFIRMED', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getWpfsChecked({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getwpfschecked().then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('WPFS_CHECKED', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getWpfsNotConverted({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getwpfsnotconverted().then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('WPFS_NOT_CONVERTED', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getSotoPrepare({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getsotoprepare().then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SO_TO_PREPARE', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getStockCards({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getstockcards().then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STOCK_CARDS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getPendingContainers({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getpendingcontainers().then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PENDING_CONTAINERS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        
    },
    mutations: {
        LOW_LEVEL_STOCK_DEFAULT: (state, data) => {
            state.low_level_stock = data
        },
        OUT_OF_STOCK: (state, data) => {
            state.out_of_stock = data
        },
        PREPARED_VS_TOTAL_CONVERTED: (state, data) => {
            state.checker_form = data
        },
        ITEM_COUNT_PER_CATEGORY: (state, data) => {
            state.item_count = data
        },
        WPFS_CONFIRMED: (state, data) => {
            state.wpfs_confirmed = data
        },
        WPFS_CHECKED: (state, data) =>{
            state.wpfs_checked = data
        },
        WPFS_NOT_CONVERTED: (state, data) => {
            state.wpfs_not_converted = data
        },
        SO_TO_PREPARE: (state, data) => {
            state.so_to_prepare = data
        },
        STOCK_CARDS: (state, data) => {
            state.stock_cards = data
        },
        PENDING_CONTAINERS:(state, data) =>{
            state.pending_containers = data
        }
    }
}
