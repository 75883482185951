export default {
  state: {
    url: '',
    payload: {},
    dialog: false,
    sub_dialog: false,
    items_dialog: false,
    action: '',
    load:false,
    celebrant_dialog:false,
    approver_dialog: false,
    reset:false,
    selected:{},
    module_name: '',
    module_name_dialog: false,
    selected_module_name: {},
    extra_dialog_on_module_name: false,
    waiting_for_receive_dialog: false,
    shipping_via_dialog: false,
    po_receiving_dialog: false,
    po_received_dialog: false,
    accounting_job_dialog: false,
    credit_card_dialog: false,
    pending_enterbill_dialog: false,
    purchase_order_items_template_dialog: false,
    inbound_outbound_button_loading: false,
    tab: [],
    dataTable:{
        headers:[],
        items:[],
        option:{},
        payload:{},
    },
    current_user:{},
  },
  getters: {
    URL: state => state.url,
    PAYLOAD: state => state.payload,
    DIALOGS: state => state.dialog,
    ITEMS_DIALOG: state => state.items_dialog,
    CURRENT_USER: state => state.current_user,
    CELEBRANTS_DIALOGS: state => state.celebrant_dialog,
    SUB_DIALOG: state => state.sub_dialog,
    ACTION: state => state.action,
    LOAD:state => state.load,
    SELECTED:state => state.selected,
    RESET:state => state.reset,
    TAB: state => state.tab,
    APPROVER_DIALOG: state => state.approver_dialog,
    GET_ACCOUNTING_JOB_DIALOG: state => state.accounting_job_dialog,
    MODULE_NAME: state => state.module_name,
    MODULE_NAME_DIALOG: state => state.module_name_dialog,
    PENDING_ENTERBILL_DIALOG: state => state.pending_enterbill_dialog,
    SELECTED_MODULE_NAME_DIALOG: state => state.selected_module_name,
    EXTRA_DIALOG_ON_MODULE_NAME: state => state.extra_dialog_on_module_name,
    WAITING_FOR_RECEIVE_DIALOG:  state => state.waiting_for_receive_dialog,
    SHIPPING_VIA_DIALOG:  state => state.shipping_via_dialog,
    PO_RECEIVING_DIALOG: state => state.po_receiving_dialog,
    PO_RECEIVED_DIALOG: state => state.po_received_dialog,
    CREDIT_CARD_DIALOG: state => state.credit_card_dialog,
    DATA_TABLE:  state => state.dataTable,
    PURCHASE_ORDER_ITEMS_TEMPLATE_DIALOG:  state => state.purchase_order_items_template_dialog,
    INBOUND_OUTBOUND_BUTTON_LOADING: state => state.inbound_outbound_button_loading,
  },
  mutations: {
    URL: (state, data) => {
      state.url = data;
    },
    PAYLOAD: (state, data) => {
      state.payload = data;
    },
    APPROVER_DIALOG: (state, data) => {
      state.approver_dialog = data;
    },
    DIALOG: (state, data) => {
      state.dialog = data;
    },
    ADD_DIALOG: (state, data) => {
      state.dialog = data;
    },
    ACTION: (state, data) => {
        state.action = data;
    },
    SUB_DIALOG: (state, data) => {
      state.sub_dialog = data;
    },
    LOAD:(state,data) =>{
        state.action = data
    },
    CELEBRANTS_DIALOG_MUTATE: (state, data) => {
      state.celebrant_dialog = data;
    },
    SELECTED: (state, data) => {
      state.selected = data;
    },
    RESET: (state, data) => {
      state.reset = data;
    },
    GET_MODULE_NAME: (state, data) => {
      state.module_name = data;
    },
    ACCOUNTING_JOB_DIALOG: (state, data) => {
      state.accounting_job_dialog = data;
    },
    GET_MODULE_NAME_DIALOG: (state, data) => {
      state.module_name_dialog = data;
    },
    GET_SELECTED_MODULE_NAME_DIALOG: (state, data) => {
      state.selected_module_name = data;
    },
    GET_EXTRA_DIALOG_ON_MODULE_NAME: (state, data) => {
      state.extra_dialog_on_module_name = data;
    },
    WAITING_FOR_RECEIVE_DIALOG: (state, data) => {
      state.waiting_for_receive_dialog = data;
    },
    SHIPPING_VIA_DIALOG: (state, data) => {
      state.shipping_via_dialog = data;
    },
    DATA_TABLE_ITEMS: (state, data) => {
      state.dataTable.items = data;
    },
    DATA_TABLE_OPTIONS: (state, data) => {
      state.dataTable.option = data;
    },
    DATA_TABLE: (state, data) => {
      state.dataTable = data;
    },
    DATA_TABLE_PAYLOAD: (state, data) => {
      state.dataTable.payload = data;
    },
    PO_RECEIVING_DIALOG: (state, data) => {
      state.po_receiving_dialog = data;
    },
    PO_RECEIVED_DIALOG: (state, data) => {
      state.po_received_dialog = data;
    },
    CREDIT_CARD_DIALOG: (state, data) => {
      state.credit_card_dialog = data;
    },
    PENDING_ENTERBILL_DIALOG: (state, data) => {
      state.pending_enterbill_dialog = data;
    },
    PURCHASE_ORDER_ITEMS_TEMPLATE_DIALOG: (state, data) => {
      state.purchase_order_items_template_dialog = data;
    },
    INBOUND_OUTBOUND_BUTTON_LOADING: (state, data) => {
      state.inbound_outbound_button_loading = data;
    },
    CURRENT_USER: (state, data) => {
      state.current_user = data;
    },
    ITEMS_DIALOG: (state, data) => {
      state.items_dialog = data;
    },
    TAB: (state, data) => {
      state.tab = data;
    },
  },
  actions: {},
};
