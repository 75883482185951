import store from '../../../store';
import api from '../../api'

export default {
    async adminStockAdjustmentGet(params){
        return api.get(`admin/${params.url}`,{params})
    },
    adminStockAdjustmentPost(params){
        return api.post(`admin/${params.url}`,params)
    },
}

