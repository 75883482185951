import repository from '@/api/modules/Accounting/AccountingDepartment/accountingdepartment'
import store from '@/store';
export default {
	state: {
		accounting_departments: [],
		edit_accounting_department: {},
		new_accounting_department: false,
	},
	getters: {
		GET_EDIT_ACCOUNTING_DEPARTMENT:(state)=>state.edit_accounting_department,
        GET_NEW_ACCOUNTING_DEPARTMENT:(state)=>state.new_accounting_department,
	},
	actions:{
        getAllAccountingDepartment({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.index(payload).then((response)=>{
					if(response.status == 200){
						commit('ACCOUNTING_DEPARTMENTS', response.data);
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		createAccountingDepartment({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.store(payload).then((response)=>{
					if(response.status == 200){
                        commit('NEW_ACCOUNTING_DEPARTMENT', true)
						resolve(response)
					}
				}).catch((error)=>{
                    console.log(error);
					reject(error)
				});
			})
		},
		updateAccountingDepartment({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.update(payload).then((response)=>{
					if(response.status == 200){
                        commit('NEW_ACCOUNTING_DEPARTMENT', true)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteAccountingDepartment({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.delete(payload).then((response)=>{
					if(response.status == 200){
						commit('NEW_ACCOUNTING_DEPARTMENT', true)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
	},
	mutations:{
		ACCOUNTING_DEPARTMENTS:(state, data) => {
			state.accounting_departments = data
		},
		EDIT_ACCOUNTING_DEPARTMENT:(state, data) => {
			state.edit_accounting_department = data
		},
		NEW_ACCOUNTING_DEPARTMENT:(state,data)=>{
			state.new_accounting_department = data;
		},
	},
}
