import repository from '@/api/credentials';

const routes =
        [{
              path: '/stores/store-flow/:id',
              name: 'storeflow',
              component: () => import('@/views/main/modules/Stores_/StoreFlow/StoreFlowGroupComponent.vue'),
              beforeEnter:(to,from,next)=>{
                repository.checkAccessUser(to.params.id).then((response)=>{
                    if(response.data == 'fail'){
                        next('/no-access')
                        }else{
                        next()
                    }
                }).catch(()=>{

                })

          },
              meta:{
                   requiresAuth:true
                 }
          }]


export default routes;