import repository from "@/api/modules/Operations/CF";

export default {
    state: {
        // export_pending_so_list_report: []
    },
    getters: {
        // GET_PENDING_SO_LIST_REPORT: (state) => state.export_pending_so_list_report
    },
    actions: {
        postCF({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cfpost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        putCF({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cfput(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteCF({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cfdelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCF({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.cfget(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        uploadCheckerFormFile({commit},payload) {
            return new Promise((resolve, reject) => {
              repository.uploadcheckerformfile(payload.params,payload.config).then(response => {
                  if (response.status == 200) {
                    resolve(response);
                  }
                })
                .catch(error => {
                  reject(error);
                })
            });
        },

    },
    mutations: {
        // PENDING_SO_LIST_REPORT: (state, data) => { (state.export_pending_so_list_report = data) }
    }

}
