import repository from '@/api/modules/Accounting/GeneralLedgers/generalledger'
import store from '@/store';
export default {
	state: {},
	getters: {},
	actions:{
        exportFS({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.exportFS(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
    },
	mutations:{},
}
