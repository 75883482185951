import repository from '@/api/modules/HR/Timekeeping/timekeeping'

export default {
    state: {
        employee_schedule: [],
        schedule_template: [],
        timekeeping_new_data: false,
        selected_date_range: {},
        employee_bio_time: null,
        employee_biometrics_dialog: false,
        biometrics_summary_dialog: false,
        biometrics_data: [],
        timekeeping_records: [],
        timekeeping_record_view: {},
        timekeeping_record_employee_id: [],
        show_employee_schedules: {},
        biometrics_data_temp: [],
        department_schedule: [],
        for_approval_schedule: [],
        for_approval_count: 0,
    },
    getters: {
        GET_EMPLOYEE_SCHEDULE:(state) => state.employee_schedule,
        GET_SCHEDULE_TEMPLATE:(state) => state.schedule_template,
        GET_TIMEKEEPING_NEW_DATA:(state) => state.timekeeping_new_data,
        GET_SELECTED_DATE_RANGE:(state) => state.selected_date_range,
        GET_EMPLOYEE_BIO_TIME:(state) => state.employee_bio_time,
        GET_EMPLOYEE_BIOMETRICS_DIALOG:(state) => state.employee_biometrics_dialog,
        GET_BIOMETRICS_SUMMARY_DIALOG:(state) => state.biometrics_summary_dialog,
        GET_BIOMETRICS_DATA:(state) => state.biometrics_data,
        GET_BIOMETRICS_DATA_TEMP:(state) => state.biometrics_data_temp,
        GET_TIMEKEEPING_RECORDS:(state)=> state.timekeeping_records,
        GET_TIMEKEEPING_RECORD_VIEW:(state)=> state.timekeeping_record_view,
        GET_SHOW_EMPLOYEE_SCHEDULES:(state)=> state.show_employee_schedules,
        GET_TIMEKEEPING_RECORD_EMPLOYEE_ID:(state)=> state.timekeeping_record_employee_id,
        GET_DEPARTMENT_SCHEDULE:(state)=> state.department_schedule,
        GET_FOR_APPROVAL_SCHEDULE:(state)=> state.for_approval_schedule,
        GET_FOR_APPROVAL_COUNT:(state)=> state.for_approval_count,
    },
    actions:{
        // Schedule Template Actions
        getScheduleTemplate({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.gethrscheduletemplate(payload).then((response)=>{
                    commit('SCHEDULE_TEMPLATE',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        showWorkScheduleDetails({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.showWorkScheduleDetails(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        addScheduletemplate({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.addhrscheduletemplate(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        updateScheduleTemplate({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.updatehrscheduletemplate(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        deleteScheduleTemplate({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.deletehrscheduletemplate(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        // Employee Schedule Actions
        showEmployeeScheduleDetails({commit},payload){
            return  new Promise((resolve,reject)=>{
                repository.showEmployeeScheduleDetails(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        showEmployeeScheduleTemp({commit},payload){
            return  new Promise((resolve,reject)=>{
                repository.showEmployeeScheduleTemp(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        approveEmployeeScheduleTemp({commit},payload){
            return  new Promise((resolve,reject)=>{
                repository.approveEmployeeScheduleTemp(payload).then((response)=>{
                    resolve(response.data)
                    commit('TIMEKEEPING_NEW_DATA', true)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        rejectEmployeeScheduleTemp({commit},payload){
            return  new Promise((resolve,reject)=>{
                repository.rejectEmployeeScheduleTemp(payload).then((response)=>{
                    resolve(response.data)
                    commit('TIMEKEEPING_NEW_DATA', true)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        deleteForApprovalTemp({commit},payload){
            return  new Promise((resolve,reject)=>{
                repository.deleteForApprovalTemp(payload).then((response)=>{
                    resolve(response.data)
                    commit('TIMEKEEPING_NEW_DATA', true)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getEmployeeSchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.gethremployeeschedule(payload).then((response)=>{
                    commit('EMPLOYEE_SCHEDULE',response.data)
                    commit('HR_USER_DEPARTMENTS', response.data.hr_user_departments)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        addEmployeeSchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.addhremployeeschedule(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        updateEmployeeSchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.updateEmployeeSchedule(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        deleteEmployeeSchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.deletehremployeeschedule(payload).then(response=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        showEmployeeSchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.showEmployeeSchedule(payload).then(response=>{
                    commit('SHOW_EMPLOYEE_SCHEDULES', response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        // Temporary Schedule Actions
        getTemporarySchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.gethrtemporaryschedule(payload).then((response)=>{
                    commit('EMPLOYEE_SCHEDULE',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        addTemporarySchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.addhrtemporaryschedule(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        updateTemporarySchedule({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.updatetemporarySchedule(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        deleteTemporarySchedule({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.deletehrtemporaryschedule(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getBiometricsData({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getBiometricsData(payload).then((response)=>{
                    // commit('BIOMETRICS_DATA', response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getEmployeeIDSBio({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getEmployeeIDSBio(payload).then((response)=>{
                    commit('BIOMETRICS_DATA', response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        viewEmployeeBioTime({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.viewEmployeeBioTime(payload).then((response)=>{
                    commit('EMPLOYEE_BIO_TIME', response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        // Timekeeping Records Actions
        getTimekeepingRecords({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.gettimekeepingrecords(payload).then((response)=>{
                    commit('TIMEKEEPING_RECORDS', response.data)
                    commit('HR_USER_DEPARTMENTS', response.data.hr_user_departments)
                    commit('HR_USER_LOCATION_ITEMS', response.data.hr_users_location_items)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        saveTimekeepingRecord({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.savetimekeepingrecord(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getTimekeepingRecordEmployeeID({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getTimekeepingRecordEmployeeID(payload).then((response)=>{
                    commit('TIMEKEEPING_RECORD_EMPLOYEE_ID', response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        showTimekeepingRecordDetails({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.showTimekeepingRecordDetails(payload).then((response)=>{

                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        deleteTimekeepingRecords({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.deleteTimekeepingRecords(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        updateTimekeepingRecords({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.updateTimekeepingRecords(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getTimekeepingScheduleDay({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getTimekeepingScheduleDay(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        approveTimekeepingRecordManual({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.approveTimekeepingRecordManual(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        checkTimekeepingRecordDay({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.checkTimekeepingRecordDay(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        changeTimekeepingRecordStatus({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.changeTimekeepingRecordStatus(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getTimekeepingRecordFiles({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getTimekeepingRecordFiles(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        checkExistingTimekeepingRecord({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.checkExistingTimekeepingRecord(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        generateFilemakerData({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.generateFilemakerData(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        fetchHolidays({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.fetchHolidays(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getConfirmedOT({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getConfirmedOT(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getDepartmentSchedule({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getDepartmentSchedule(payload).then((response)=>{
                    commit('DEPARTMENT_SCHEDULE', response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        showDepartmentSchedule({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.showDepartmentSchedule(payload).then((response)=>{
                    // commit('DEPARTMENT_SCHEDULE', response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        updateDepartmentSchedule({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.updateDepartmentSchedule(payload).then((response)=>{
                    resolve(response.data)
                    commit('TIMEKEEPING_NEW_DATA', true)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        // For Approval Schedule
        getForApprovalSchedules({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getForApprovalSchedules(payload).then((response)=>{
                    commit('FOR_APPROVAL_SCHEDULE', response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        approveTemporarySchedules({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.approveTemporarySchedules(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        hrUrlGet({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.hrUrlGet(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        hrUrlPost({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.hrUrlPost(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        hrUrlPut({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.hrUrlPut(payload).then((response)=>{
                    // commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        hrUrlDelete({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.hrUrlDelete(payload).then((response)=>{
                    commit('TIMEKEEPING_NEW_DATA', true)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
    },
    mutations:{
        EMPLOYEE_SCHEDULE:(state,data) => {state.employee_schedule = data},
        SCHEDULE_TEMPLATE:(state,data) => {state.schedule_template = data},
		TIMEKEEPING_NEW_DATA:(state, data) => {state.timekeeping_new_data = data},
		SELECTED_DATE_RANGE:(state, data) => {state.selected_date_range = data},
		EMPLOYEE_BIO_TIME:(state, data) => {state.employee_bio_time = data},
		EMPLOYEE_BIOMETRICS_DIALOG:(state, data) => {state.employee_biometrics_dialog = data},
		BIOMETRICS_SUMMARY_DIALOG:(state, data) => {state.biometrics_summary_dialog = data},
		BIOMETRICS_DATA:(state, data) => {state.biometrics_data = data},
        TIMEKEEPING_RECORDS:(state, data) => {state.timekeeping_records = data},
        TIMEKEEPING_RECORD_VIEW:(state, data)=> {state.timekeeping_record_view = data},
        TIMEKEEPING_RECORD_EMPLOYEE_ID:(state, data)=> {state.timekeeping_record_employee_id = data},
        SHOW_EMPLOYEE_SCHEDULES:(state, data)=> {state.show_employee_schedules = data},
        BIOMETRICS_DATA_TEMP:(state, data)=> {state.biometrics_data_temp = data},
        DEPARTMENT_SCHEDULE:(state, data)=> {state.department_schedule = data},
        FOR_APPROVAL_SCHEDULE:(state, data)=> {state.for_approval_schedule = data},
        FOR_APPROVAL_COUNT:(state, data)=> {state.for_approval_count = data},
    }
}
