import repository from '@/api/modules/Accounting/JournalVoucher/journalvoucher'
import store from '@/store';
export default {
	state: {
        journal_voucher_dialog:false,
        journal_voucher_status:{},
        new_journal_voucher_table:false,
        edit_journal_voucher:{},
        journal_voucher:[],
		jv_num: "",
		journal_voucher_filters: {
			search: null,
			vendor_id: null,
			company_id: null,
			stores_id: null,
			transact_date:[],
			prepared_by: null,
			company_classification_id: null,
		}
	},
	getters: {
        GET_JOURNAL_VOUCHER_DIALOG:(state)=>state.journal_voucher_dialog,
        GET_JOURNAL_VOUCHER_STATUS:(state)=>state.journal_voucher_status,
        GET_NEW_JOURNAL_VOUCHER_TABLE:(state)=>state.new_journal_voucher_table,
        GET_EDIT_JOURNAL_VOUCHER:(state)=>state.edit_journal_voucher,
        GET_JOURNAL_VOUCHER:(state)=>state.journal_voucher,
		GET_JV_NUM: (state) => state.jv_num,
	},
	actions:{
		postJournalVoucher({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalPost(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		getJournalVoucher({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit(payload.mutation_code, response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getJournalVoucherItemExport({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalGetItemExport(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit(payload.mutation_code, response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		putJournalVoucher({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalPut(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteJournalVoucher({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.journalDelete(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getJVNum({ commit }) {
			return new Promise((resolve, reject) => {
					repository
					.getJVNum()
					.then((response) => {
							commit("JV_NUM", response.data);
							resolve(response.data);
					})
					.catch((e) => {
							reject();
					});
			});
		},
		getJVFiles({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.getJVFiles(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
		deleteJVFiles({commit},payload) {
            return new Promise((resolve,reject)=>{
                repository.deleteJVFiles(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
	},
	
	mutations:{
        JOURNAL_VOUCHER:(state, data) => {
			state.journal_voucher = data
		},
        EDIT_JOURNAL_VOUCHER:(state, data) => {
			state.edit_journal_voucher = data
		},
		JOURNAL_VOUCHER_STATUS:(state, data) => {
			state.journal_voucher_status = data
		},
        NEW_JOURNAL_VOUCHER_TABLE:(state,data)=>{
            state.new_journal_voucher_table = data;
        },
		JV_NUM: (state, data) => {
			state.jv_num = data
		},
	},
}
