import repository from '@/api/modules/Accounting/Financials/financials'
export default {
    state:{
        ACTION: '',
        TAB: ''
    },
    getters:{
        FINANCIALS: (state) => state
    },
    actions:{
        financialsGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.financialsGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        financialsPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.financialsPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        }
    },
    mutations:{
        FINANCIALS_ACTION: (state, data) => { (state.ACTION = data) },
        FINANCIALS_TAB:(state, data)=>{ (state.TAB = data) }
    }
}
