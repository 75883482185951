import api from '../../api'

export default {
    getLastJONum(params){
        return api.get('/admin/getLastJONum',{ params });
    },
    getJOVendorDetails(params){
        return api.get('/dropdown/getJOVendorDetails',{ params });
    },
    getJobOrder(params) {
        return api.get('/admin/getJobOrder', {params})
    },
    getVendorRrfs(params) {
        return api.get('/admin/getVendorRrfs', {params})
    },
    EnterAdministrativeApproverGet(params){
        return api.get(`/admin/${params.url}`,{params})
    },
    EnterAdministrativeApproverPost(params){
        return api.post(`/admin/${params.url}`,params)
    },
    getAllUsersList(params){
        return api.get('dropdown/getUserApproverCapacity',{params});
    },
    getAllJobTitle(params){
        return api.get('dropdown/getJobTitles',{params});
    },
    getAllRange(params){
        return api.get('dropdown/getRange',{params});
    },
    jobOrderGet(params) {
        return api.get('/admin/' + params.url, {params})
    },
    jobOrderPost(params) {
        return api.post('/admin/' + params.url, params)
    },
    joPrintData(params) {
        return api.get('/admin/joPrintData', { params })
    },
    getDepartmentForAdministrative(params) {
        return api.get('/admin/getDepartmentForAdministrative', { params })
    },
    getAdminJOFiles(params){
        return api.get(`admin/get-admin-jo-files/${params.id}`,{params});
    },
    deleteAdminJOFiles(params){
        return api.get(`admin/delete-admin-jo-files/${params.id}`)
    },
    exportJobOrders(params){
        return api.post(`admin/export-job-orders`,  params )
    },
    getServiceNameSuggestions(params){
        return api.get(`admin/get-service-name-suggestions/`, { params })
    },
}

