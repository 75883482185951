import api from '../../../api'
export default{

    queueget(params){
      return api.get(`/queueing/`+params.url,{params})
    },
    queuepost(params){
      return api.post(`/queueing/`+params.url,params)
    },
    queueput(params){
      return api.put(`/queueing/`+params.url,params)
    },
    queuedelete(params){
      return api.delete(`/queueing/`+params.url,params)
    },

}
