<template>
    <div>
        <div class="page-wrapper ma-1">
            <div class="row p-2">
                <div class="col-8 d-flex no-block align-items-center">
                    <h4 class="page-title">Baklas Report</h4>
                </div>
            </div>
            <div class="container">
                <v-card class="pa-2" style="border-radius:0px">
                    <div>
                        <v-layout row class="mx-0 pb-4">
                            <v-flex lg2 class="mt-3">
                                <v-autocomplete
                                    dense
                                    outlined
                                    :items="items"
                                    :loading="loading.item_selection"
                                    :disabled="loading.item_selection"
                                    v-model="selectedItem"
                                    item-value="value"
                                    item-text="text"
                                    label="Select Item"
                                />
                            </v-flex>
                            <v-spacer />
                            <v-flex lg3 shrink class="mt-3">
                                <div style="text-align:end">
                                    <v-btn class="ml-1" @click="clearFilter()">Clear</v-btn>
                                    <v-btn class="ml-1" @click="showExportDialog()">EXPORT</v-btn>
                                    <v-btn class="ml-1" @click="filterList()">Filter</v-btn>
                                </div>
                            </v-flex>
                        </v-layout>

                        <v-layout>
                            <v-flex shrink>
                                <v-layout row class="mx-0">
                                    <v-flex lg4 class="mr-2 mt-3">
                                        <v-dialog
                                            ref="dateStartDialog"
                                            v-model="startDateModal"
                                            :return-value.sync="startDate"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="startDate"
                                                label="Date Start"
                                                readonly
                                                dense outlined
                                                class="hide-text-field-details"
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="startDate"
                                                scrollable
                                                :max="minDate"
                                            >
                                            <v-spacer />
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="startDateModal = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.dateStartDialog.save(startDate)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-flex>
                                    <v-flex lg4 class="mr-2 mt-3">
                                        <v-dialog
                                            ref="endDateDialog"
                                            v-model="endDateModal"
                                            :return-value.sync="endDate"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="endDate"
                                                label="Date End"
                                                readonly
                                                dense
                                                outlined
                                                class="hide-text-field-details"
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled="!startDate"
                                            />
                                            </template>
                                            <v-date-picker
                                                v-model="endDate"
                                                scrollable
                                                :min="startDate"
                                                :max="minDate"
                                            >
                                            <v-spacer />
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="endDateModal = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.endDateDialog.save(endDate)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-spacer />
                            <v-flex shrink>
                                <div style="text-align:end">
                                    <span class="badge bg-light ml-1" style="cursor:pointer" @click="getBaklasWithStatus('all')">all</span>
                                    <span class="badge bg-success ml-1" style="cursor:pointer" @click="getBaklasWithStatus('available')">available</span>
                                    <span class="badge bg-danger ml-1" style="cursor:pointer" @click="getBaklasWithStatus('not-available')">not-available</span>
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div>
                        <v-layout row class="mx-0">
                            <v-spacer />
                            <v-flex lg3 class="mr-2">
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Baklas#, Serial#, Status, Dateqwe"
                                    single-line
                                    hide-details
                                    clearable
                                    @keydown.enter="searchSp()"
                                    @click:append="searchSp()"
                                />
                            </v-flex>
                            <v-flex lg3>
                                <v-text-field
                                    v-model="searchSparePart"
                                    append-icon="mdi-magnify"
                                    label="Spare Part, Part Num"
                                    single-line
                                    hide-details
                                    clearable
                                    @keydown.enter="searchSp()"
                                    @click:clear="clearSearchSparePart()"
                                    @click:append="searchSp()"
                                />
                            </v-flex>
                        </v-layout>
                    </div>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="baklas"
                            :search="search"
                            :loading="loading"
                        >

                        <template v-slot:item.status="{ item }">
                            <span v-if="item.status == 'available'" class="badge badge-success">{{ item.status }}</span>
                            <span v-else-if="item.status == 'not-available'" class="badge badge-danger">{{ item.status }}</span>
                        </template>

                        <template v-slot:item.action="{ item }">
                            <td class="px-0">
                                <span class="text-nonwrap">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="showBaklasDetailsDialog(item)">mdi-eye</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <v-dialog v-model="baklasDetailsDialog" persistent max-width="98%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Cannibalize Details</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeBaklasDetailsDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-layout row class="mx-4">
                    <v-flex lg4>
                        <div>Baklas#: <span>{{ baklasNumber }}</span></div>
                        <div>Item Model: <span>{{ itemModel }}</span></div>
                        <div>Item Name: <span>{{ itemName }}</span></div>
                        <div>Item Category: <span>{{ itemCategory }}</span></div>
                        <div>Serial Number: <span>{{ itemSerialNumber }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <div>Status: <span>{{ status }}</span></div>
                        <div>Created At: <span>{{ baklasCreatedAt }}</span></div>
                        <div>Created By: <span>{{ createdBy }}</span></div>
                    </v-flex>
                    <v-flex lg4>
                        <div>Item Status: <span>{{ itemStatus }}</span></div>
                        <div>Source Warehouse: <span>{{ warehouseName }}</span></div>
                    </v-flex>
                </v-layout>

                <v-divider />

                <v-card-text>
                    <v-card class="pa-4">
                        <div class="pb-4">Spare Parts</div>
                        <v-data-table
                            :headers="baklasItemsHeaders"
                            :items="baklasItems"
                        >
                        </v-data-table>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="exportDialog" persistent max-width="30%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Export Options</span>
                        </v-card-title>
                    </v-col>

                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeExportDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider />

                <v-layout row class="mx-0">
                    <v-flex lg6>
                        <div style="text-align:center">
                            <v-btn @click="exportExcel(exportBaklasHeadersAndData, exportBaklasName)">Export Baklas</v-btn>
                        </div>
                    </v-flex>
                    <v-flex lg6>
                        <div style="text-align:center">
                            <v-btn @click="exportExcel(exportBaklasItemsHeadersAndData, exportBaklasItemsName)">Export Baklas Items</v-btn>
                        </div>
                    </v-flex>
                </v-layout>

                <v-divider />

                <div class="pb-4"></div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            headers: [
                { text: 'Baklas#', value: 'baklas_number' },
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Category', value: 'category' },
                { text: 'Serial Number', value: 'serial_number' },
                { text: 'Status', value: 'status' },
                { text: 'Item Status', value: 'item_status' },
                { text: 'Created At', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            items: [],
            baklas: [],

            selectedItem: '',
            baklasDetails: null,
            loading: true,
            itemId: '',
            search: '',
            searchSparePart: '',
            exportDialog: false,
            dateToday: this.$dayjs().format('YYYY-MM-DD'),
            baklasItems: [],
            exportBaklasHeadersAndData: {
                Sheet1: {
                    headers:[
                        {header:'Baklas#', key:'baklas_number', width:15},
                        {header:'Model', key:'model', width:15},
                        {header:'Name', key:'name', width:15},
                        {header:'Category', key:'category', width:15},
                        {header:'Serial Number', key:'serial_number', width:15},
                        {header:'Status', key:'status', width:15},
                        {header:'Item Status', key:'item_status', width:15},
                        {header:'Created At', key:'created_at', width:15},
                    ],
                    data:[]
                }
            },
            exportBaklasItemsHeadersAndData: {
                Sheet1: {
                    headers:[
                        {header:'Baklas#', key:'baklas_number', width: 15},
                        {header:'Item Parent', key:'parent_model', width: 15},
                        {header:'Spare Part Model', key:'sp_model', width: 20},
                        {header:'Spare Part Name', key:'sp_name', width: 28},
                        {header:'Original Qty', key:'original_qty', width: 15},
                        {header:'Remaining Qty', key:'remaining_qty', width: 15},
                        {header:'Transferred Qty', key:'transfered_qty', width: 15},
                        {header:'Defective Qty', key:'defective_qty', width: 15},
                        {header:'Created At', key:'created_at', width: 15},
                        {header:'Updated At', key:'updated_at', width: 15},
                        {header:'Transferred By', key:'transferred_by', width: 22},
                    ],
                    data:[]
                }
            },
            exportBaklasLoading: false,
            exportBaklasItemsLoading: false,
            exportBaklasName: `Baklas-${this.$dayjs().format('YYYY-MM-DD')}`,
            exportBaklasItemsName: `Baklas-Items-${this.$dayjs().format('YYYY-MM-DD')}`,
            exportBaklasItems: [],

            baklasItems: [],
            baklasItemsHeaders: [
                { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'Part Num', value: 'part_num' },
                { text: 'Original Qty', value: 'original_quantity' },
                { text: 'Remaining Qty', value: 'remaining_quantity' },
                { text: 'Transferred Qty', value: 'transfered_quantity' },
                { text: 'Defective Qty', value: 'defective_qty' },
                { text: 'Transferred By', value: 'transferred_by' },
                { text: 'Updated At', value: 'updated_at' },
            ],

            baklasId: '',
            baklasNumber: '',
            itemId: '',
            itemModel: '',
            itemName: '',
            itemCategory: '',
            itemSerialNumber: '',
            itemStatus: '',
            warehouseName: '',
            createdBy: '',
            status: '',
            baklasCreatedAt: '',
            baklasDetailsDialog: false,
            baklasStatus: '',
            startDateModal: false,
            startDate: '',
            endDateModal: false,
            endDate: '',
            minDate: this.$dayjs().format('YYYY-MM-DD'),

            loadview:{
                        item_selection : true
            }
        }
    },
    computed:{
        ...mapGetters([
            'GET_ITEMS',
            'GET_ALL_BAKLAS',
            'GET_ALL_BAKLAS_ITEMS',
            'GET_BAKLAS_ITEM_SPARE_PARTS',
        ])
    },
    components: {
        //
    },
    async mounted() {
        this.getAllBaklas();
        this.getAllPtItems();
    },
    watch: {
        baklas() {
            if(this.baklas.length > 0) {
                this.loading = false;
            }
        },
        baklasStatus() {
            if(!!this.baklasStatus) {
                this.selectedItem = '';
                this.baklas = [];
                this.getAllBaklas();
            }
        },
        selectedItem() {
            if(!!this.selectedItem) {
                this.itemId = this.selectedItem;
                this.getAllBaklas();
            }
        },
        startDate() {
            if(!!this.startDate) {
                this.endDate = this.minDate;
            } else {
                this.endDate = '';
            }
        },
        'GET_ITEMS':{
            handler(val){
                this.loadview.item_selection = false
            },
            }
    },
    methods: {
        clearFields() {
            this.search = '';
            this.baklasId = '';
            this.itemSerialNumber = '';
            this.baklasDetails = null;
            this.editBaklasDialog = false;
        },
        getAllPtItems() {
            let payload = {
                type_ids: [1]
            }
            this.$store.dispatch('getItems',payload).then(response => {
                this.items = this.GET_ITEMS
            });
        },
        getAllBaklas() {
            let payload = {
                itemId: this.selectedItem,
                searchItem: this.selectedItemModel,
                searchSp: this.searchSparePart,
                baklasStatus: this.baklasStatus,
                startDate: this.startDate,
                endDate: this.endDate,
            }
            this.$store.dispatch('getAllBaklas',payload).then(response=>{
                this.baklas = this.GET_ALL_BAKLAS;
                this.baklas.forEach(e => e.created_at = this.formatDate(e.created_at))
                this.exportBaklasHeadersAndData.Sheet1.data = this.baklas
            });
        },
        getAllExportBaklasItems() {
            this.$store.dispatch('getAllBaklasItems').then(response=>{
                this.exportBaklasItems = this.GET_ALL_BAKLAS_ITEMS;
                this.exportBaklasItems.forEach(e => {
                    e.created_at = this.formatDate(e.created_at)
                    e.updated_at = this.formatDate(e.updated_at)
                });
                this.exportBaklasItemsHeadersAndData.Sheet1.data = this.exportBaklasItems;
            });
        },
        getBaklasWithStatus(status) {
            this.baklasStatus = status;
        },
        clearFilter() {
            this.selectedItem = '';
            this.selectedItemModel = '';
            this.searchSparePart = '';
            this.baklas = [];
            this.baklasStatus = '';
            this.itemId = '';
            this.selectedItem = '';
            this.startDate = '';
            this.endDate = '';
            this.getAllBaklas();
        },
        searchSp() {
            this.baklas = [];
            this.getAllBaklas();
        },
        showBaklasDetailsDialog(item) {
            this.baklasId = item.id;
            this.baklasNumber = item.baklas_number;
            this.itemModel = item.model;
            this.itemName = item.name;
            this.itemCategory = item.category;
            this.itemSerialNumber = item.serial_number;
            this.status = item.status;
            this.createdAt = item.created_at;
            this.baklasCreatedAt = item.created_by;
            this.itemStatus = item.item_status;
            this.warehouseName = item.warehouse_name;

            this.baklasDetailsDialog = true;
            this.getAllBaklasItems();
        },
        getAllBaklasItems() {
            this.$store.dispatch('getBaklasItemSpareParts',this.baklasId).then(response=>{
                this.baklasItems = this.GET_BAKLAS_ITEM_SPARE_PARTS;
            });
        },
        closeBaklasDetailsDialog(item) {
            this.baklasDetailsDialog = false;
        },
        startDownload() {
            this.exportLoading = true;
        },
        finishDownload() {
            this.exportLoading = false;
        },
        showExportDialog() {
            this.exportDialog = true;
            this.getAllExportBaklasItems();
        },
        closeExportDialog() {
            this.exportDialog = false;
        },
        startBaklasDownload() {
            this.exportBaklasLoading = true;
        },
        finishBaklasDownload() {
            this.exportBaklasLoading = false;
        },
        startBaklasItemsDownload() {
            this.exportBaklasItemsLoading = true;
        },
        finishBaklasItemsDownload() {
            this.exportBaklasItemsLoading = false;
        },
        clearSearchSparePart() {
            this.searchSparePart = '';
            this.baklas = [];
            this.getAllBaklas();
        },
        filterList() {
            this.getAllBaklas();
        }
    }
}
</script>

<style>

</style>
