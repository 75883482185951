import repository from '@/api/modules/Accounting/TransactionJournal/transactionjournal'
export default {
    state:{
        ACTION: '',
        TAB: ''
    },
    getters:{
        TRANSACTION_JOURNAL: (state) => state
    },
    actions:{
        transactionJournalGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transactionJournalGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        transactionJournalPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.transactionJournalPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        }
    },
    mutations:{
        TRANSACTION_JOURNAL_ACTION: (state, data) => { (state.ACTION = data) },
        TRANSACTION_JOURNAL_TAB:(state, data)=>{ (state.TAB = data) }
    }
}
