import api from '../../../api'
export default {
    cashAdvanceGet(params){
        return api.get(`/accounting/${params.url}`,{params})
    },
    cashAdvancePost(params){
        return api.post(`/accounting/${params.url}`,params)
    },
    cashAdvancePut(params){
        return api.put(`/accounting/${params.url}/${params.id}`,params)
    },
    cashAdvanceDelete(params){
        return api.delete(`/accounting/${params.url}/${params.id}`,params)
    },
    cashAdvanceUpload(params){
        return api.post(`/accounting/${params.url}`,params)
    },
    cashAdvanceGetFiles(params){
        return api.get(`/accounting/${params.url}/${params.id}`,{params});
    },
    cashAdvanceDeleteFiles(params){
        return api.get(`/accounting/deleteFiles/${params.id}`,{params});
    },
}