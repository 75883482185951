import api from '../../api'

export default {
    getemployeecount(params){
        return api.get('dashboard/hr/employee-count', {params});
    },

    getemployeeforapprovalcount(params){
        return api.get('dashboard/hr/employee-for-approval-count', {params});
    },

    getemployeecountperdept(params){
        return api.get('dashboard/hr/employee-count-per-dept', {params});
    },

    getemployeecountbyempstatus(params){
        return api.get('dashboard/hr/employee-count-by-employment-status', {params});
    },
    getemployeecountbycity(params){
        return api.get('dashboard/hr/employee-count-by-city', {params});
    },
    getemployeecountbycontract(params){
        return api.get('dashboard/hr/employee-count-by-contract', {params});
    },
    getemployeecountbyage(params){
        return api.get('dashboard/hr/employee-count-by-age', {params});
    },
    getemployeecountbycategory(params){
        return api.get('dashboard/hr/employee-count-by-category', {params});
    },
    getemployeecountbygender(params){
        return api.get('dashboard/hr/employee-count-by-gender', {params});
    },
    getemployeehiredvsleft(params){
        return api.get('dashboard/hr/employee-hired-vs-left', {params});
    },
    getemployeebirthdaycelebrants(params){
        return api.get('dashboard/hr/employee-birthday-celebrants', {params});
    },

    getGeojson(params){
        return api.get(`masterdata/getGeojson`,{params})
    },
    getRegionCityGeojson(params){
        return api.get(`masterdata/getRegionCityGeojson`,{params})
    },
}
