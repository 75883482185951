import repository from '@/api/modules/MasterData'
import dropdown from '@/api/Main/DropDowns/index'
import swal from 'sweetalert2'
import store from '@/store'
export default {
  state: {
    vendor: [],
    vendor_type: [],
    selected_vendor: [],
    vendor_type_table: [],
    newvendor: false,
    newvendortype: false,
    totalcustomer: 0,
    counter: [],
    selected_vendorType: [],
    vendor_type_loader: false,
    vendor_files: [],
    broker_dialog: false,
    broker_items: [],
    vendor_response: [],
  },
  getters: {
    GET_ALL_VENDOR: state => state.vendor,
    GET_ALL_VENDOR_TYPE: state => state.vendor_type,
    GET_ALL_VENDOR_TYPE_TABLE: state => state.vendor_type_table,
    NEW_VENDOR: state => state.newvendor,
    NEW_vendortype: state => state.newvendortype,
    GET_EDIT_VENDORS: state => state.selected_vendor,
    GET_COUNTER_VENDOR: state => state.counter,
    GET_VENDOR_TYPE_LOADER: state => state.vendor_type_loader,
    SELECTED_VENDOR_TYPE: state => state.selected_vendorType,
    GET_VENDOR_FILES: state => state.vendor_files,
    GET_BROKER_DIALOG: state => state.broker_dialog,
    GET_BROKER_ITEMS: state => state.broker_items,
    GET_VENDOR_RESPONSE: state => state.vendor_response,
  },
  actions: {
    addVendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .addvendor(payload)
          .then(response => {
            resolve(response.data);

          }).catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    addVendorAddress({ commit }, payload) {
      repository
        .addvendoraddress(payload)
        .then(response => { })
        .catch(error => { });
    },
    editVendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .editvendor(payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    DeleteVendor({ commit }, id) {
      return new Promise((resolve, reject) => {
        repository
          .deletevendor(id)
          .then(({ data, status }) => {
            if (status == 200) {

              resolve(data);
            }
          })
          .catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    getallvendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .vendor(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('ALL_VENDOR_LOAD', response.data)
            }
          }).catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    getVendortype({ commit }) {
      dropdown.vendortype().then(response => {
        commit('VENDORTYPE_LOAD', response.data);
      }).catch(error => {

        swal.fire({
          title: 'ERROR',
          icon: 'error'
        })
      });
    },
    getVendorTypeTable({ commit }) {
      repository.vendortype().then(response => {
        commit('VENDOR_TYPE_TABLE_LOAD', response.data);
        commit('VENDOR_TYPE_LOADED', true);
      }).catch(error => {

        swal.fire({
          title: 'ERROR',
          icon: 'error'
        })
      });
    },
    getVendorFiles({ commit }, payload) {
        return new Promise((resolve,reject)=>{
        repository.getVendorFiles(payload).then((response)=>{
          if(response.status == 200){
            commit('VENDOR_FILES', response.data);
            resolve(response)
          }
        }).catch((error)=>{
          reject(error)
        });
      })
    },
    deleteVendorFiles({ commit }, payload) {
        return new Promise((resolve,reject)=>{
        repository.deleteVendorFiles(payload).then((response)=>{
          if(response.status == 200){
            commit('VENDOR_FILES', response.data);
            resolve(response)
          }
        }).catch((error)=>{
          reject(error)
        });
      })
    },
    addVendorType({ commit }, payload) {
      return new Promise((resolve, reject)=>{
        repository.addvendortype(payload).then(response => {
          if([200, 201].includes(response.status)){
            commit('NEW_vendortype', true);
            resolve(response)
          }
        }).catch(error => {
          reject(error)
          swal.fire({
            title: 'ERROR',
            icon: 'error'
          })
        });
      });
    },
    editVendorType({ commit }, payload) {
      const params = {
        id: payload.id,
        code: payload.payload.code,
        vendorType: payload.payload.vendorType,
        description: payload.payload.description,
      }
      return new Promise((resolve, reject) => {
        repository.editvendortype(params).then((response) => {
          if (response.status == 200){
            commit('NEW_vendortype', true);
            resolve(response)
          }
        }).catch(error => {
          reject(error)
          swal.fire({
            title: 'ERROR',
            icon: 'error'
          })
        });
      });
    },
    deleteVendorType({ commit }, payload) {
      repository.deletevendortype(payload).then(() => {
        commit('NEW_vendortype', true);
      }).catch(error => {

        swal.fire({
          title: 'ERROR',
          icon: 'error'
        })
      });
    },
    getAddressByVendor({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository.getAddressByVendor(payload).then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          }).catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
    showvendors({commit},payload){
      return new Promise((resolve,reject)=>{
          repository.showvendors(payload).then((res)=>{
            resolve(res.data);
          }).catch(err=>{
            reject(err);
          })
      })
    },
    getVendorCOADetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository.getVendorCOADetails(payload).then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          }).catch(error => {

            swal.fire({
              title: 'ERROR',
              icon: 'error'
            })
          });
      });
    },
  },
  mutations: {
    ALL_VENDOR_LOAD: (state, data) => {
      state.vendor = data;
    },
    VENDORTYPE_LOAD: (state, data) => {
      state.vendor_type = data;
    },
    VENDOR_TYPE_TABLE_LOAD: (state, data) => {
      state.vendor_type_table = data;
    },
    NEW_VENDOR: (state, data) => {
      state.newvendor = data;
    },
    NEW_vendortype: (state, data) => {
      state.newvendortype = data;
    },
    VENDOR_TYPE_LOADED: (state, data) => {
      state.vendor_type_loader = data
    },
    vendor_LOAD: (state, data) => {
      state.vendor = data;
    },
    EDIT_VENDORS: (state, data) => {
      state.selected_vendor = data;
    },
    EDIT_VENDOR_TYPE: (state, data) => {
      state.selected_vendorType = data;
    },
    EDITED_vendorS: (state, payload) => {
      const index = state.allvendor.findIndex(t => t.id == payload.id)
      state.allvendor.splice(index, 1, payload);
    },
    TOTAL_vendor: (state, data) => {
      state.totalvendor = data;
    },
    REMOVE_vendor: (state, id) => (state.allvendor = state.allvendor.filter(vendor => vendor.id !== id)),
    COUNTER: (state, data) => {
      state.counter = data;
    },
    VENDOR_FILES: (state, data) => {
      state.vendor_files = data;
    },
    BROKER_DIALOG: (state, data) => {
      state.broker_dialog = data;
    },
    BROKER_ITEMS: (state, data) => {
      state.broker_items = data;
    },
    VENDOR_RESPONSE: (state, data) => {
      state.vendor_response = data;
    },
  },
};
