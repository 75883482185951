import repository from '@/api/modules/it'

export default {
  state: {
    stock_adjustment: [],
    warehouse_list: [],
    user_list: [],
    get_username_on_select: [],
    get_items_by_models: [],
    add_new_stock_adjustment: [],
    get_stock_adjustment: [],
    update_stock_adjustment: [],
    delete_stock_adjustment: [],
    update_adjustment: [],
    current_user:[],
    reset_table: false,

    stock: {
      menu: '',
    },
    stock_adjustment_files: [],
    warehouse_defaults: []
  },
  getters: {
    GET_ALL_STOCK: state => state.stock_adjustment,
    GET_ALL_WAREHOUSES: state => state.warehouse_list,
    GET_ALL_USER: state => state.user_list,
    GET_USERNAME_ON_SELECT: state => state.get_username_on_select,
    GET_ITEMS_BY_MODEL: state => state.get_items_by_models,
    ADD_NEW_STOCK_ADJUSTMENT: state => state.add_new_stock_adjustment,
    GET_STOCK_ADJUSMENT: state => state.get_stock_adjustment,
    UPDATE_STOCK_ADJUSTMENT: state => state.update_stock_adjustment,
    DELETE_STOCK_ADJUSTMENT: state => state.delete_stock_adjustment,
    UPDATE_ADJUSTMENT: state => state.update_adjustment,
    GET_CURRENT_USER: state => state.current_user,
    GET_RESET_TABLE: state => state.reset_table,
    GET_STOCK_MENU: state => state.stock.menu,
    GET_STOCK_ADJUSTMENT_FILES: state => state.stock_adjustment_files,
    GET_WAREHOUSE_DEFAULTS: state => state.warehouse_defaults,
  },
  actions:{
      getAllStockAdjustment({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.getallstockadjustment(payload).then((response)=>{
                if(response.status == 200){
                resolve(response)
                commit('STOCK_LOAD',response.data)
                }
            }).catch((error)=>{
              reject(error)
            });
        })
      },
        getAllWarehouses({commit},payload){
          return new Promise((resolve,reject)=>{
              repository.getallwarehouses(payload).then((response)=>{

                  if(response.status == 200){
                  resolve(response)
                  commit('WAREHOUSE_LIST',response.data)
                  }
              }).catch((error)=>{
                reject(error)
              });
          })
        },
        async getAllUsersSelect({commit},payload){
          return new Promise((resolve,reject)=>{
              repository.userslistActive(payload).then((response)=>{
                  commit('USERS_LOAD',response.data)
                  resolve(response.data)
              }).catch((error)=>{
                reject(error)
              });
          })
        },
        getUsernameOnSelect({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getusernamenonselect(payload).then((response)=>{
                   if(response.status == 200){
                    resolve(response)
                    commit('USERNAME_GET_ON_SELECT',response.data)
                   }
                }).catch((error)=>{
                  reject(error)
                });
           })
        },
        async getItemsByModel({commit},payload){
            return await new Promise((resolve,reject)=>{
                repository.getitemsbymodel(payload).then((response)=>{
                   if(response.status == 200){
                    resolve(response)
                    commit('ITEMS_BY_MODEL',response.data)
                   }
                }).catch((error)=>{
                  reject(error)
                });
           })
        },
        addNewStockAdjustment({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.addnewstockadjustment(payload).then((response)=>{
                   if(response.status == 200){
                    resolve(response)
                    commit('NEW_STOCK_ADJUSTMENT',response.data)
                   }
                }).catch((error)=>{
                  reject(error)
                });
           })
        },
        getStockAdjustment({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getstockadjustment(payload).then((response)=>{
                   if(response.status == 200){
                    resolve(response)
                    commit('STOCK_ADJUSTMENT',response.data)
                   }
                }).catch((error)=>{
                  reject(error)
                });
           })
        },
        getStockAdjustmentFiles({ commit }, payload) {
          return new Promise((resolve, reject) => {
            repository
              .getStockAdjustmentFiles(payload)
              .then(response => {
                if (response.status == 200) {
                  resolve(response);
                  commit('STOCK_ADJUSTMENT_FILES', response.data);
                }
              })
              .catch(error => {
                reject(error);
              })
          });
        },
        updateStockAdjustment({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.updatestockadjustment(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                        commit('UP_STOCK_ADJUSTMENT',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        deleteStockAdjustment({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.deletestockadjustment(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                        commit('DL_STOCK_ADJUSTMENT',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        updateAdjustment({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.updateadjustment(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                        commit('UP_ADJUSTMENT',response.data)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        deleteStockAdjustmentFile({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.deleteStockAdjustmentFile(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        exportStockAdjustment({commit},payload){
          return new Promise((resolve,reject)=>{
              repository.exportstockadjusment(payload).then((response)=>{
                  if(response.status == 200){
                  resolve(response)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },
        async getCurrentUser({commit},payload){
          return new Promise((resolve,reject)=>{
              repository.getcurrentuser(payload).then((response)=>{
                  commit('CURRENT_USER',response.data)
                  resolve(response.data)
              }).catch((error)=>{
                reject(error)
              });
          })
        },
        getWarehouseDefault({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getWarehouseDefault(payload).then((response)=>{
                    if(response.status == 200){
                    resolve(response)
                    commit('WAREHOUSE_DEFAULTS',response.data)
                    }
                }).catch((error)=>{
                  reject(error)
                });
            })
        },
        checkItemSubstitution({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.checkItemSubstitution(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                  reject(error)
                });
            })
        },
    },
    //   getAllUsers ({ commit }) {
    //       repository.userslist().then((response) => {
    //           commit('USERS_LOAD', response.data)
    // });
    //   },
    getUsernameOnSelect({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .getusernamenonselect(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('USERNAME_GET_ON_SELECT', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    getItemsByModel({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .getitemsbymodel(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('ITEMS_BY_MODEL', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    addNewStockAdjustment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .addnewstockadjustment(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('NEW_STOCK_ADJUSTMENT', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    getStockAdjustment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .getstockadjustment(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('STOCK_ADJUSTMENT', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    getStockAdjustmentFiles({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .getStockAdjustmentFiles(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('STOCK_ADJUSTMENT_FILES', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    updateStockAdjustment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .updatestockadjustment(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('UP_STOCK_ADJUSTMENT', response.data)
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    deleteStockAdjustment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .deletestockadjustment(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('DL_STOCK_ADJUSTMENT', response.data)
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    updateAdjustment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .updateadjustment(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('UP_ADJUSTMENT', response.data)
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
  mutations: {
    STOCK_LOAD: (state, data) => {
      state.stock_adjustment = data;
    },
    WAREHOUSE_LIST: (state, data) => {
      state.warehouse_list = data;
    },
    USERS_LOAD: (state, data) => {
      state.user_list = data;
    },
    USERNAME_GET_ON_SELECT: (state, data) => {
      state.get_username_on_select = data;
    },
    ITEMS_BY_MODEL: (state, data) => {
      state.get_items_by_models = data;
    },
    NEW_STOCK_ADJUSTMENT: (state, data) => {
      state.add_new_stock_adjustment = data;
    },
    STOCK_ADJUSTMENT: (state, data) => {
      state.get_stock_adjustment = data;
    },
    UP_STOCK_ADJUSTMENT: (state, data) => {
      state.update_stock_adjustment = data;
    },
    DL_STOCK_ADJUSTMENT: (state, data) => {
      state.delete_stock_adjustment = data;
    },
    UP_ADJUSTMENT: (state, data) => {
      state.update_adjustment = data;
    },
    CURRENT_USER: (state, data) => {
      state.current_user = data;
    },
    RESET_TABLE: (state, data) => {
      state.reset_table = data;
    },
    SET_STOCK_MENU : (state, data) => {
      state.stock.menu = data;
    },
    STOCK_ADJUSTMENT_FILES : (state, data) => {
      state.stock_adjustment_files = data;
    },
    WAREHOUSE_DEFAULTS : (state, data) => {
      state.warehouse_defaults = data;
    }
  },
}
