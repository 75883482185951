import api from '../../api'

export default {
    CreateCEF(params) {
        return api.post('/admin/cef', params)
    },
    getAllCEF(params) {
        return api.get('/admin/getAllCEF', { params })
    },
    getCEFItems(params) {
        return api.get('/admin/getCEFItems', { params })
    },
    UpdateCEF(params) {
        return api.put(`admin/cef/${params.id}`,params);
    },
    DeleteCEF(params) {
        return api.delete(`/admin/cef/${params}`)
    },
    cefPrintData(params) {
        return api.get('/admin/cefPrintData', { params })
    },
}

