import repository from '@/api/modules/Sales/Promos/index'
import dropdown from '@/api/Main/DropDowns/index';
import store from '@/store';

export default {
  state: {

  },
  getters: {

  },
  actions: {
    getPromoShortlists({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.getpromoshortlists(payload).then((response)=>{
                resolve(response)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    getCustomerPromoInfo({commit},payload){
        let params = {
            promo_id: payload.promo_id,
            customer_id:payload.customer_id
        }
        return new Promise((resolve,reject)=>{
            repository.getcustomerpromoinfo(params).then((response)=>{
                resolve(response)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    promosDropdown(){
        return new Promise((resolve,reject)=>{
            dropdown.promos().then((response)=>{
                resolve(response)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    getItemsNotInPromo({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.itemsnotinpromo(payload).then((response)=>{
                resolve(response)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    getCustomersNotInPromo({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.customersnotinpromo(payload).then((response)=>{
                resolve(response)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    postPromos({commit},payload) {
        return new Promise((resolve, reject) => {
            repository.promospost(payload).then((response) => {
                if (response.status == 200) {
                    resolve(response)
                }
            }).catch((error) => {
                reject(error)
            });
        })
    },
    putPromos({commit},payload) {
        return new Promise((resolve, reject) => {
            repository.promosput(payload).then((response) => {
                if (response.status == 200) {
                    resolve(response)
                }
            }).catch((error) => {
                reject(error)
            });
        })
    },
    deletePromos({commit},payload) {
        return new Promise((resolve, reject) => {
            repository.promosdelete(payload).then((response) => {
                if (response.status == 200) {
                    resolve(response)
                }
            }).catch((error) => {
                reject(error)
            });
        })
    },
    getPromos({commit},payload) {
        return new Promise((resolve, reject) => {
            repository.promosget(payload).then((response) => {
                if (response.status == 200) {
                    resolve(response)
                }
            }).catch((error) => {
                reject(error)
            });
        })
    }

  },
  mutations: {

  },
};
