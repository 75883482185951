import api from '../../api'
export default {

    mapsget(params){
        return api.get(`/maps/`+params.url,{params})
    },
    mapspost(params){
        return api.post(`/maps/`+params.url,params)
    },
    markerpost(params){
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        let url = params.get('url')
        return api.post(`/maps/`+url,params,config)
    },
}
