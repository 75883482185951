import repository from '@/api/modules/MasterData'
import store from '@/store'
export default {
  state: {
    alldepartment: [],
    department: [],
    selected_department: {},
    newdepartment: false,
    department_loaded: false,
    department_for_accounting:[]
  },
  getters: {
    GET_ALL_DEPARTMENT: state => state.alldepartment,
    GET_LIST_DEPARTMENT: state => state.department,
    SELECTED_DEPARTMENT: state => state.selected_department,
    NEW_DEPARTMENT: state => state.newdepartment,
    GET_DEPARMENT_LOADED: state => state.department_loaded,
    GET_DEPARTMENT_ACCOUNTING_LOAD:state=>state.department_for_accounting
  },
  actions: {
    addNewDepartment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .adddepartment(payload)
          .then(({ data, status }) => {
            if (status == 200) {
              resolve(data);
              commit('NEW_DEPARTMENT', true)
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    UpdateDepartment({ commit }, payload) {
      const params = {
        id: payload.deptID,
        name: payload.payload.name,
        code: payload.payload.code,
        managers: payload.payload.manager,
        locations: payload.payload.locations,
        active: payload.payload.active,
        trash: payload.trash
      };
      return new Promise((resolve, reject) => {
        repository
          .updatedepartment(params)
          .then(({ data, status }) => {
            if (status == 200) {
              resolve(data);
            }
            commit('NEW_DEPARTMENT', true);
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    DeleteDepartment({ commit }, id) {
      return new Promise((resolve, reject) => {
        repository
          .deletedepartment(id)
          .then(({ data, status }) => {
            if (status == 200) {
              commit('REMOVE_DEPARTMENT', id);
              resolve(data)
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    getAllDepartment({ commit },payload) {
      return new Promise((resolve, reject) => {
        repository.departmentall(payload).then(response => {
          commit('ALL_DEPARTMENT_LOAD', response.data);
          commit('DEPARTMENT_LOADED', true);
          resolve(response.data)
        }) .catch(error => {
          reject(error);
        })
      });
    },
    getDepartment({ commit }, payload) {
    return new Promise((resolve, reject) => {
        repository
            .departmentlist(payload)
            .then(response => {
                commit('DEPARTMENT_LOAD', response.data);
                resolve(response.data)
              })
            .catch(error => {
            reject(error);
            })
        });
    },
    getDepartmentListforAccounting({ commit }) {
    return new Promise((resolve, reject) => {
        repository
            .departmentlistforaccounting()
            .then(response => {
                resolve(response.data)
                commit('DEPARTMENT_ACCOUNTING_LOAD', response.data);
              })
            .catch(error => {
            reject(error);
            })
        });
    }
  },
  mutations: {
    ALL_DEPARTMENT_LOAD: (state, data) => {
      state.alldepartment = data;
    },
    NEW_DEPARTMENT: (state, data) => {
      state.newdepartment = data;
    },
    DEPARTMENT_LOAD: (state, data) => {
      state.department = data;
    },
    EDIT_DEPARTMENTS: (state, data) => {
      state.selected_department = data;
    },
    EDITED_DEPARTMENTS: (state, payload) => {
      const index = state.alldepartment.findIndex(t => t.id == payload.id)
      state.alldepartment.splice(index, 1, payload);
    },
    REMOVE_DEPARTMENT: (state, id) => {
      state.alldepartment = state.alldepartment.filter(department => department.id !== id)
    },
    DEPARTMENT_LOADED: (state, data) => {
      state.department_loaded = data
    },
    DEPARTMENT_ACCOUNTING_LOAD: (state, data) => {
      state.department_for_accounting = data;
    },
  },
};
