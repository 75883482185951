import repository from "@/api/modules/Operations/Queue";

export default {
    state: {
        // export_pending_so_list_report: []
        queue_dispatch_items: [],
        warehouse_queueing:[
            {
                name:'Dispatch',
                route_name:'warehousequeue-dispatch'
            },
            {
                name:'(PT) WPF-CF',
                route_name:'warehousequeue-wpf-cf-pt'
            },
            {
                name:'(HT) WPF-CF',
                route_name:'warehousequeue-wpf-cf-ht'
            },
            {
                name:'Aftersales',
                route_name:'serviceequeue'
            },
            {
                name:'Aftersales Dashboard',
                route_name:'serviceequeuedashboard'
            },
            {
                name:'Dispatch Dashboard',
                route_name:'dispatchqueuedashboard'
            },
            {
                name:'Show Leave Request',
                route_name:'hrshowleaverequest'
            },
        ],
    },
    getters: {
        // GET_PENDING_SO_LIST_REPORT: (state) => state.export_pending_so_list_report
        GET_QUEUE_DISPATCH_ITEMS: (state) => state.queue_dispatch_items
    },
    actions: {
        postqueue({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.queuepost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        putqueue({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.queueput(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deletequeue({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.queuedelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getqueue({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.queueget(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        }
    },
    mutations: {
        QUEUE_DISPATCH_ITEMS: (state, data) => { (state.queue_dispatch_items = data) }
    }

}
