import store from '../../../../store';
import api from '../../../api'

export default {
    // Plantilla api
    addPlantilla(params){
        return api.post('/hr/hrplantilla',params);
    },
    getPlantilla(params){
        return api.get('/hr/hrplantilla',{params});
    },
    updatePlantilla(params){
        return api.put(`/hr/hrplantilla/${params.id}`,params);
    },
    deletePlantilla(params){
        return api.post(`/hr/deleteplantilla`,params);
    },
    showPlantilla(params){
        return api.post(`/hr/showplantilla`,params);
    },
    getPositionPlantilla(params){
        return api.get(`/hr/getPositionPlantilla`,params);
    },
    getAllExistingPlantillaDept(params){
        return api.get(`/hr/getAllExistingPlantillaDept`,params);
    },
    getCurrentManpower(params){
        return api.get(`/hr/getCurrentManpower`,{params});
    },

}
