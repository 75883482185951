import store from '../../../store';
import api from '../../api'

export default {
    adminPurchaseOrderPost(params){
        return api.post(`/admin/${params.url}`,params)
    },
    adminPurchaseOrderGet(params){
        return api.get(`/admin/${params.url}`,{params})
    },
    getAdminPOFiles(params){
        return api.get(`admin/get-admin-po-files/${params.id}`,{params});
    },
    deleteAdminPOFiles(params){
        return api.get(`admin/delete-admin-po-files/${params.id}`)
    }
}

