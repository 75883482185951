<template>
  <v-dialog v-model="estHistoryDialog" persistent max-width="50%">
        <v-card class="pa-4">
            <v-row class="m-0">
                <v-col>
                    <v-card-title>
                        <span class="headline">Conversations History</span>
                    </v-card-title>
                </v-col>
                <v-col cols="pull-right-10 p-2">
                    <v-btn
                    text
                    icon
                    color="gray"
                    class="float-right"
                    @click="closeEstHistoryDialog()"
                    >
                    <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <div>
                <v-btn @click="refreshConversation()">Refresh</v-btn>
            </div>

            <v-data-table
                :headers="headers"
                :items="conversations"
                :items-per-page="5"
                :loading="loading"
                :no-data-text="noDataText"
            >
                <template v-slot:item.type="{ item }">
                    <v-chip
                        :color="item.type == 'Inbound' ? 'blue' : 'red'"
                        text-color="white"
                        small
                    >
                        {{ item.type }}
                    </v-chip>
                </template>

                <template v-slot:item.created_at="{ item }">
                    {{ formattedDate(item) }}
                </template>
            </v-data-table>
            <v-card class="pa-4">
                <!-- <div>
                    <div style="display:inline-block">
                        <v-checkbox v-model="inbound" dense/>
                    </div>
                    <div style="display:inline-block">
                        <label style="color:gray">Inbound:</label>
                    </div>

                    <div style="display:inline-block">
                        <v-checkbox v-model="outbound" dense/>
                    </div>
                    <div style="display:inline-block">
                        <label style="color:gray">Outbound:</label>
                    </div>
                </div> -->
                <v-layout row class="px-4">
                    <!-- <v-flex lg6>
                        <div style="text-align:start">
                            <div style="display:inline-block">Type:</div>
                            <div style="display:inline-block">
                                <v-autocomplete v-model="selectedType" :items="[
                                    'Inbound', 'Outbound'
                                ]" dense outlined style="width:150px"></v-autocomplete>
                            </div>
                        </div>
                    </v-flex> -->
                    <v-flex lg6>
                        <div style="text-align:end, justify:end">
                            <div style="display:inline-block">Source: <span style="color:red">*</span></div>
                            <div style="display:inline-block">
                                <v-autocomplete v-model="source" :rules="rules.default" :items="[
                                    'Phone', 'Sms', 'Messenger', 'Viber', 'Email'
                                ]" dense outlined style="width:150px" 
                                />
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
                <v-layout row class="px-4 my-3">
                    <v-flex lg6 class="pr-2">
                        <v-textarea v-model="customerMessage" outlined dense label="Customer Message"></v-textarea>
                    </v-flex>
                    <v-flex lg6>
                        <v-textarea v-model="coordinatorMessage" outlined dense label="Coordinator Message"></v-textarea>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex>
                        <div style="text-align:center">
                            <v-btn @click="submit">Submit</v-btn>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    data() {
        return {
            headers: [
                // { text: 'Type', value: 'type' },
                { text: 'Customer Message', value: 'customer_message' },
                { text: 'Coordinator Message', value: 'coordinator_message' },
                { text: 'Source', value: 'source' },
                { text: 'Submitted By', value: 'processed_by' },
                { text: 'Date & Time', value: 'created_at' },
            ],
            conversations: [],
            customerMessage: "",
            coordinatorMessage: "",
            inbound: false,
            outbound: false,
            type: "",
            loading: true,
            noDataText: "",
            source: '',
            selectedType: 'Inbound',
            rules: {
                default: [
                v => !!v || 'This is required',
                ],
            },
        }
    },
    props: ['rfEstId'],
    watch: {
        conversations() {
            if(this.conversations.length > 0) {
                this.loading = false;
            } else {
                setTimeout(() => {
                    this.loading = false;
                    this.noDataText = "No Messages"
                }, 10000);
            }
        },
        rfEstId() {
            if(!!this.rfEstId) {
                this.getAllRfEstConversation();
            }
        }
    },
    computed: {
        ...mapGetters([
            'GET_ALL_RF_EST_CONVERSATION'
        ]),
        estHistoryDialog() {
            return this.$store.state.service.conversationDialog;
        },
    },
    methods: {
        closeEstHistoryDialog() {
            this.customerMessage = "";
            this.coordinatorMessage = "";
            this.conversations = [];
            this.source = "";
            this.selectedType = "";
            this.$store.commit("closeConversationDialog");
            this.$emit('closeDialog', true);
        },
        getAllRfEstConversation() {
            this.$store.dispatch('getAllRfEstConversation',this.rfEstId).then(response=>{
                this.conversations = this.GET_ALL_RF_EST_CONVERSATION;
            });
        },
        submit() {
            if(!this.requiredFields()) {
                if(!this.customerMessage && !this.coordinatorMessage) {
                    swal.fire({
                        title: 'Input Message',
                        icon: 'warning',
                        dangerMode: true
                    })
                } else {
                    swal.fire({
                        title: 'Are you sure you want to submit?',
                        text: 'Make sure that all information are correct',
                        icon: 'question',
                        showConfirmButton:true,
                        showCancelButton  :  true,
                        reverseButtons:true,
                        allowOutsideClick :  false,
                        confirmButtonColor: '#397373',
                        cancelButtonColor : 'grey',
                        confirmButtonText:'Confirm',
                        showCloseButton:true,
                    }).then((result)=> {
                        if(result.isConfirmed) {
                            let payload = {
                                rfEstId: this.rfEstId,
                                type: this.selectedType,
                                customerMessage: this.customerMessage,
                                coordinatorMessage: this.coordinatorMessage,
                                source: this.source,
                            }
                            this.$store.dispatch('submitConversation',payload).then(response=>{
                                if(!!response.data.msg) {
                                    this.customerMessage = "";
                                    this.coordinatorMessage = "";
                                    this.conversations = [];
                                    this.loading = true;
                                    this.getAllRfEstConversation();
                                    swal.fire("", response.data.msg, "success");
                                }
                            });
                        }
                    });
                }
            }
        },
        formattedDate(item) {
            return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
        },
        refreshConversation() {
            this.conversations = [];
            this.loading = true;
            this.getAllRfEstConversation();
        },
        requiredFields() {
            let warning = '';
            if (!this.source) warning += 'Please select a Source.<br>';

            if (warning !== '') return swal.fire({
                title: 'Please Fill out Information:',
                html: warning,
                icon: "warning",
            });
        },
    },
}
</script>

<style>

</style>
