import api from '../../api'
export default {

    addEmail(params){
        return api.post('/webmail/webEmail',params);
    },
    getEmail(params){
        return api.get('/webmail/webEmail',{params});
    },
    updateEmail(params){
        return api.put(`/webmail/webEmail/${params.id}`,params.payload);
    },
    deleteEmail(params){
        return api.delete(`/webmail/webEmail/${params}`);
    },
    showEmail(params){
        return api.get(`/webmail/webEmail/${params}`);
    },
    getEmployeeDataWithEmail(params){
        return api.get('/webmail/getEmployeeDataWithEmail',{params});
    },
    updateEmployeeDataEmails(params){
        return api.post('/webmail/updateEmployeeDataEmails',params);
    },
    getEmployeeEmailsWithLogs(params){
        return api.get('/webmail/getEmployeeEmailsWithLogs',{params});
    },
}
