<template>
    <div class="page-wrapper">

        <v-app id="editItemSubstitutionsModel">
            <v-dialog v-model="dialog" persistent max-width="60%" scrollable>
                <v-card>
                    <v-row class="m-0">
                        <v-col class>
                            <v-card-title>
                                <span class="subtitle-2">{{ title }}</span>
                            </v-card-title>
                        </v-col>
                        <v-col cols="pull-right-10 p-2">
                            <v-btn text icon color="gray" class="float-right"
                                @click="resetFields();">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <!-- Exclusivity Type Section -->
                        <v-card class="pa-4 mt-2" elevation="4">
                            <v-row class="mx-0 mt-1">
                                <v-col class="mt-1" xs="12" sm="12" md="12" lg="4">
                                    <h5 class="subtitle-2 black--text">Exclusivity Type</h5>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col class="" xs="12" sm="12" md="12" lg="4">
                                    <h5 class="subtitle-2 black--text float-right">CUSTOMER COUNT: {{ dataTable.items.length}}</h5>
                                </v-col>
                            </v-row>
                            <v-row class="mx-0 mt-1 border-top">
                                <v-col class="mt-1" xs="12" sm="12" md="12" lg="4">
                                    <v-autocomplete
                                        :items="GET_EXCLUSIVITY_TYPE_SELECTION"
                                        v-model="dropDowns.exclusivity_type_id"
                                        item-value="value"
                                        item-text="text"
                                        dense
                                        outlined
                                        label="Exclusivity Type"
                                        clearable
                                        auto-select-first
                                        @change="clearDropdowns()"
                                        @click:clear="clearFields('add_classification')"
                                    >
                                    <template #append >
                                        <span style="color:red"><v-icon>mdi-menu-down</v-icon> *</span>
                                    </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row class="mx-0 mt-2">
                                <v-col xs="12" sm="12" md="6" lg="4">
                                    <v-autocomplete
                                        :items="GET_CUSTOMER_CATEGORY_SELECTION"
                                        v-model="dropDowns.category_id"
                                        item-value="value"
                                        item-text="text"
                                        dense
                                        outlined
                                        label="Per Category"
                                        clearable
                                        auto-select-first
                                        :disabled="checkExclusivityType(1)"
                                        @change="filterCustomer('category_id')"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col xs="12" sm="12" md="6" lg="4">
                                    <v-autocomplete
                                        :items="GET_CLASSIFICATIONS_DROPDOWN.data"
                                        v-model="dropDowns.classification_id"
                                        item-value="value"
                                        item-text="text"
                                        dense
                                        outlined
                                        label="Per Classifications"
                                        clearable
                                        auto-select-first
                                        :disabled="checkExclusivityType(2)"
                                        @change="filterCustomer('classification_id')"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col xs="12" sm="12" md="6" lg="4">
                                    <v-autocomplete
                                        style="max-height:200px; overflow-y: auto;"
                                        :items="filteredCustomerSelection"
                                        v-model="dropDowns.temporary_selected_customers"
                                        item-value="value"
                                        item-text="text"
                                        dense
                                        outlined
                                        label="Per Customer"
                                        clearable
                                        auto-select-first
                                        return-object
                                        @change="pushCustomer()"
                                        :disabled="checkExclusivityType(3)"
                                        height="40"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card>
                         <!-- Customer's List Section -->
                        <v-card class="pa-4 mt-4" elevation="4">
                            <v-row class="mx-0">
                                    <v-col class="pt-3" xs="12" sm="12" md="12" lg="4">
                                        <h5 class="subtitle-2 black--text">Customer List</h5>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col class="" xs="12" sm="12" md="12" lg="3">
                                        <v-text-field 
                                            v-model="dataTable.search"
                                            dense 
                                            hide-details
                                            label="Search"
                                            prepend-inner-icon="mdi-magnify"
                                        >
                                        </v-text-field>
                                    </v-col>
                            </v-row>
                            <v-row class="mx-0 mt-2 border-top">
                                <v-col class="mt-1" xs="12" sm="12" md="6" lg="12">
                                    <v-data-table
                                        :items="dataTable.items"
                                        :headers="dataTable.headers"
                                        dense
                                        :search="dataTable.search"
                                    >
                                    <template v-slot:[`item.action`]="{ item }">
                                        <v-btn text icon color="red" >
                                            <v-icon class="btn-action" small @click="deleteCustomer(item.value)" :disabled="checkExclusivityType(3) || action == 'View'">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center border" v-if="action != 'View'">
                        <v-row class="mx-0 my-1">
                            <v-col class="text-center" xs="6" sm="6" md="6" lg="6">
                                <v-btn
                                color="secondary"
                                @click="resetFields()"     
                                >
                                Cancel
                                </v-btn>
                            </v-col>
                            <v-col class="text-center" xs="6" sm="6" md="6" lg="6">
                                <v-btn
                                @click="saveItemExclusive()"     
                                >
                                Save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
export default {
    props: [
        'showItemExclusiveModal',
        'action',
        'item_id'
    ],
    data() {
        return {
            dialog:false,
            title:'ADD ITEM EXCLUSIVE',
            dropDowns:{
                exclusivity_type_id:null,
                category_id:null,
                classification_id:null,
                selected_customers:[],
                temporary_selected_customers:null,
            },
            dataTable:{
                items:[],
                headers:[
                    { text:'Customer Name', align:'start',width:'90%',value:'text'},
                    { text:'Action',width:'10%',value:'action'},
                ],
                search:''
            }
        }
    },
    mounted() {
    },
    methods: {
        checkExclusivityType(id){
            if(id == this.dropDowns.exclusivity_type_id){
                return false
            }
            return true
        },
        filterCustomer(type){
            if(type != 'customer'){
                this.dataTable.items = this.GET_CUSTOMERS_SELECTION.filter(e=>{
                    if (e[type] == this.dropDowns[type]){
                        return e
                    }
                })
            }
            else{
                this.dataTable.items = this.GET_CUSTOMERS_SELECTION;
            }  
        },
        clearDropdowns(){
            this.dropDowns.category_id = null
            this.dropDowns.classification_id = null
            this.dataTable.items = []
            this.dropDowns.selected_customers = []
        },
        addCustomer(){
            this.dataTable.items = this.dropDowns.selected_customers
        },
        deleteCustomer(id){
            this.dropDowns.selected_customers = this.dropDowns.selected_customers.filter(e=> e.value != id);
            this.dataTable.items = this.dropDowns.selected_customers
        },
        pushCustomer(){
            if(!!this.dropDowns.temporary_selected_customers){
                this.dropDowns.selected_customers.unshift(this.dropDowns.temporary_selected_customers)
                this.addCustomer();
                setTimeout(() => {
                    this.dropDowns.temporary_selected_customers = null
                }, 100);
            } 
        },
        resetFields(){
            Object.assign(this.$data, this.$options.data.call(this));
            this.$emit('closeItemExclusiveDialog');
        },
        saveItemExclusive(){
            swal.fire({
				icon: 'question',
				text: "Are you sure you want to make this Item Exclusive?",
				cancelButtonText: "Cancel",
				showCancelButton: true,
				allowOutsideClick: false,
				confirmButtonColor: "#397373",
				cancelButtonColor: "#397373",
				reverseButtons: true
			}).then((result) => {
				if (result.isConfirmed) {
                    let data = {
                        exclusivity_type_id: this.dropDowns.exclusivity_type_id,
                        selected_id: !!this.dropDowns.category_id ? this.dropDowns.category_id : this.dropDowns.classification_id,
                        customer_ids: this.dropDowns.selected_customers.map(e=>{return e.value}),
                    }
                    this.$emit('getExclusiveData',data)
                    this.resetFields()
                }
			});
        }
    },
    computed: {
        ...mapGetters([
            'GET_ITEM_SUBSTITUTION',
            'GET_CLASSIFICATIONS_DROPDOWN',
            'GET_CUSTOMER_CATEGORY_SELECTION',
            'GET_CUSTOMERS_SELECTION',
            'GET_EXCLUSIVITY_TYPE_SELECTION',
        ]),
        filteredCustomerSelection(){
            if(this.dropDowns.exclusivity_type_id == 3){
                let existingCustomer = this.dropDowns.selected_customers.map(e=>{
                    return Number(e.value)
                })
                let temp = []
                
                this.GET_CUSTOMERS_SELECTION.forEach(e=>{
                    if (!existingCustomer.includes(e.value)){
                        temp.push(e)
                    }
                })
                return temp;
            }
            return this.GET_CUSTOMERS_SELECTION
        },
    },
    watch: {
        showItemExclusiveModal: function (val) {
            if (val) {
                this.dialog = true
                if(this.action != "Add"){
                    let payload = {
                        item_id: this.item_id,
                        test:'test'
                    }
                    this.$store.dispatch('getItemExclusiveByItem',payload).then(response=>{
                        let data = response.data[0]
                        this.dropDowns.exclusivity_type_id = data.exclusivity_type_id
                        if(data.exclusivity_type_id == 1){
                            this.dropDowns.category_id = data.selected_id
                        }
                        if(data.exclusivity_type_id == 2){
                            this.dropDowns.classification_id = data.selected_id
                        }
                        if(data.exclusivity_type_id == 3){
                            let customer = []
                            data.selected_id.forEach(e=>{
                                let a = _.find(this.GET_CUSTOMERS_SELECTION, ['value', e]);
                                customer.push(a);
                            })
                            this.dropDowns.selected_customers = customer
                            this.addCustomer()
                        }
                    })
                }
            }
        },
        'dropDowns.category_id':{
            handler(val){
                this.filterCustomer('category_id')
            }
        },
        'dropDowns.classification_id':{
            handler(val){
                this.filterCustomer('classification_id')
            }
        },
        // 'dropDowns.selected_customers':{
        //     handler(val){
        //         this.addCustomer()
        //     }
        // },
    },
};
</script>
<style scoped>
</style>
