import repository from '@/api/modules/Services/index'

export default {
    state: {
        get_spare_parts_staging_area: [],
        TAB: 0,
        sp_area_dispatch:{},
        dialog_title:'',
        REFRESH: false,
        sp_area_dialog_view: {},
        switchItem: false,
        filterDate: [],
        search:'',
        sqFile: [],
    },

    getters: {
        GET_SPARE_PARTS_STAGING_AREA:(state)=>state.get_spare_parts_staging_area,
        GET_SPARE_PARTS_AREA_TAB:(state)=>state.TAB,
        GET_SP_AREA_DISPATCH:(state)=>state.sp_area_dispatch,
        GET_SP_AREA_DIALOG_TITLE:(state) => state.dialog_title,
        GET_REFRESH_SP_AREA:(state)=>state.REFRESH, 
        GET_SP_AREA_DIALOG_VIEW:(state)=>state.sp_area_dialog_view,
        GET_SWITCH_ITEM:(state)=>state.switchItem,
        GET_FILTER_DATE:(state)=>state.filterDate,
        GET_SEARCH_DATA:(state) => state.search,
        GET_SQ_FILES:(state) => state.sqFile,
    },

    mutations: {
        SPARE_PARTS_STAGING_AREA:(state,data)=>{state.get_spare_parts_staging_area = data},
		SPARE_PARTS_AREA_TAB:(state,data)=>{state.TAB = data},
        SP_AREA_DISPATCH:(state,data)=>{state.sp_area_dispatch = data},
        SP_AREA_DIALOG_TITLE:(state,data)=>{state.dialog_title = data},
        REFRESH_SP_AREA:(state,data)=>{state.REFRESH = data},
        SP_AREA_DIALOG_VIEW:(state,data)=>{state.sp_area_dialog_view = data},
        SWITCH_ITEM:(state,data)=>{state.switchItem = data},
        FILTER_DATE:(state,data)=>{state.filterDate = data},
        SEARCH_DATA:(state,data)=>{state.search = data},
        SQ_FILES:(state,data)=>{state.sqFile = data},
    },

    actions: {
        getSqFiles({ commit }, payload) {
            return new Promise((resolve, reject) => {
              repository.getSqFiles(payload)
              .then(response => {
                if (response.status == 200) {
                  resolve(response)
                  commit('SQ_FILES', response.data);
                  commit('SP_AREA_DIALOG_VIEW', response.data)
                }
              })
              .catch(error => {
                reject(error);
              })
           })
        },

        sqDeleteFile({commit},payload) {
          return new Promise((resolve, reject) => {
              repository.sqDeleteFile(payload).then((response) => {
                  if (response.status == 200) {
                      resolve(response)
                  }
              }).catch((error) => {
                  reject(error)
              });
          })
        }
    },

}