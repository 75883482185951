<template>
  <div>
    <div class="page-wrapper ma-1">
      <div class="row p-2">
        <div class="col-8 d-flex no-block align-items-center">
          <h4 class="page-title">Report</h4>
        </div>
      </div>
      <div class="container">
        <v-card class="px-4">
          <div>
            <v-layout row class="px-6 pt-6 pb-2">
              <v-flex lg2 class="mr-2">
                <v-autocomplete
                  outlined
                  dense
                  :items="customerList"

                  v-model="selectedCustomer"
                  item-value="id"
                  item-text="company_name"
                  label="Select Customer"
                />
              </v-flex>

              <v-flex lg2 v-if="selectedCustomer == 275">
                <v-autocomplete
                  outlined
                  dense
                  :items="endUserList"
                  v-model="selectedEndUser"
                  item-value="id"
                  item-text="full_name"
                  label="Select Customer"
                />
              </v-flex>
              <v-spacer />
              <v-flex lg2>
                <div style="text-align: end">
                  <v-btn @click="exportExcel(excelHeadersAndData, exportName)">EXPORT</v-btn>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row class="px-6">
              <v-flex lg2 class="mr-2">
                <v-dialog
                    ref="dateStartDialog"
                    v-model="startDateModal"
                    :return-value.sync="startDate"
                    persistent
                    width="290px"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        v-model="startDate"
                        label="Date Start"
                        readonly
                        dense
                        outlined
                        class="hide-text-field-details"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" scrollable :max="minDate">
                        <v-spacer />
                        <v-btn text color="primary" @click="startDateModal = false">
                        Cancel
                        </v-btn>
                        <v-btn
                        text
                        color="primary"
                        @click="$refs.dateStartDialog.save(startDate)"
                        >
                        OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex lg2 class="mr-2">
                <v-dialog
                  ref="endDateDialog"
                  v-model="endDateModal"
                  :return-value.sync="endDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label="Date End"
                      readonly
                      dense
                      outlined
                      class="hide-text-field-details"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!startDate"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    scrollable
                    :min="startDate"
                    :max="minDate"
                  >
                    <v-spacer />
                    <v-btn text color="primary" @click="endDateModal = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endDateDialog.save(endDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex lg2>
                <v-btn @click="filterByDate">Filter</v-btn>
                <v-btn class="ml-1" text @click="clearFilter">Clear</v-btn>
              </v-flex>
            </v-layout>
          </div>
          <v-card-title>
            <v-layout>
              <v-spacer />
              <v-flex lg4>
                <v-text-field
                  v-model="searchRf"
                  append-icon="mdi-magnify"
                  label="RF#, Model, Serial"
                  single-line
                  hide-details
                  clearable
                />
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="rfHeaders"
              :items="repairForms"
              :search="searchRf"
            >
              <template v-slot:item.action="{ item }">
                <v-btn text icon color="orange">
                  <v-icon
                    class="btn-action"
                    small
                    @click="showRfInquiryDialog(item)"
                    >mdi-eye</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <rfInquiryDetailsDialogComponentVue :repairFormId="rfId" />
  </div>
</template>

<script>
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import rfInquiryDetailsDialogComponentVue from "../../Services/Views/InquiryComponent/rfInquiryDetailsDialogComponent.vue";
import { mapGetters } from "vuex";
export default {
  mixins:[SharedFunctionsComponent],
  data() {
    return {
      rfHeaders: [
        { text: "RF#", value: "rf_number" },
        { text: "RD#", value: "rr_number" },
        { text: "Model", value: "model" },
        { text: "Company Name", value: "company_name" },
        { text: "Customer Name", value: "customer_name" },
        { text: "Serial", value: "serial" },
        { text: "Status", value: "status" },
        { text: "Warranty", value: "warranty_type" },
        { text: "Date", value: "created_at" },
        { text: "Action", value: "action" },
      ],
      excelHeadersAndData: {
        Sheet1: {
          headers:[
            {header:'RF#', key:'rf_number', width:15},
            {header:'Model', key:'model', width:22},
            {header:'Name', key:'name', width:22},
            {header:'Category', key:'category', width:22},
            {header:'Serial', key:'serial', width:16},
            {header:'Replacement Serial', key:'replacement_serial_number', width:20},
            {header:'Complaint / Symptom', key:'defect_description', width:20},
            {header:'Defect Remarks', key:'defect_remarks', width:18},
            {header:'Purchase Date', key:'purchase_date', width:16},
            {header:'Warranty', key:'warranty', width:16},
            {header:'Warranty Type', key:'warranty_type', width:18},
            {header:'Accessories', key:'accessories', width:16},
            {header:'Other Accessory', key:'other_accessory', width:21},
            {header:'Unit Condition', key:'unit_condition', width:20},
            {header:'Other Unit Condition', key:'other_unit_condition', width:24},
            {header:'Repair Status', key:'repair_status', width:15},
            {header:'Status', key:'status', width:15},
            {header:'Assigned Technician', key:'assigned_technician', width:22},
            {header:'Created Date', key:'created_at', width:16},
            {header:'Company Name', key:'company_name', width:16},
            {header:'Customer Name', key:'customer_name', width:16},
            {header:'RD#', key:'rr_number', width:8},
            {header:'SQ#', key:'sq_number', width:8},
            {header:'SO#', key:'so_number', width:8},
            {header:'PL#', key:'pl_number', width:8},
            {header:'CM#', key:'cm_number', width:8},
            {header:'Job Done', key:'job_done', width:8},
            {header:'Repair Started At', key:'repair_started_at', width:20},
            {header:'Repair Completed At', key:'repair_completed_at', width:20},
            {header:'Repair Cancelled At', key:'repair_cancelled_at', width:20},
            {header:'Change Warranty Reason', key:'warranty_reject_reason', width:24},
            {header:'QA Approved By', key:'qa_approved_by', width:16},
            {header:'QA Rejected By', key:'qa_rejected_by', width:16},
            {header:'QA Remarks', key:'qa_remarks', width:16},
            {header:'Delivered At', key:'delivered_at', width:16},
            {header:'Collected At', key:'collected_at', width:16},
          ],
          data:[]
        }
      },
      repairForms: [],
      searchRf: "",
      startDate: "",
      endDate: "",
      startDateModal: false,
      endDateModal: false,
      minDate: this.$dayjs().format("YYYY-MM-DD"),
      exportLoading: false,
      exportName: `Repair-Forms-${this.$dayjs().format("YYYY-MM-DD")}`,
      rfId: "",
      customerList: [],
      selectedCustomer: "",
      endUserList: [],
      selectedEndUser: "",
      loading: {
        customer_selection: true,
      },
    };
  },
  components: {
    rfInquiryDetailsDialogComponentVue
  },
  mounted: function () {
    this.getAllRepairForms();
    this.getCustomers();
  },
  watch: {
    startDate() {
      if (!!this.startDate) {
        this.endDate = this.minDate;
      } else {
        this.endDate = "";
      }
    },
    selectedCustomer() {
      if (this.selectedCustomer == 275) {
        this.getEndUsers();
      } else {
        this.selectedEndUser = "";
      }
    },
    GET_SERVICE_ALL_CUSTOMERS: {
      handler(val) {

      },
    },
  },
  computed: {
    ...mapGetters([
      "GET_REPORT_REPAIR_FORMS",
      "GET_SERVICE_ALL_CUSTOMERS",
      "GET_SERVICE_END_USER",
    ]),
  },
  methods: {
    filterByDate() {
      this.getAllRepairForms();
    },
    showExport() {
      if (
        (!!this.minDate && this.endDate == "") ||
        (this.minDate == "" && !!this.endDate)
      ) {
        return false;
      }

      return true;
    },
    async getAllRepairForms() {
      let payload = {
        selectedCustomer: this.selectedCustomer,
        selectedEndUser: this.selectedEndUser,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      await this.$store
        .dispatch("getReportRepairForms", payload)
        .then((response) => {
          this.repairForms = this.GET_REPORT_REPAIR_FORMS;
          this.repairForms.forEach( e => {
            e.purchase_date = this.formatDate(e.purchase_date);
            e.created_at = this.formatDate(e.created_at);
            e.repair_started_at = this.formatDate(e.repair_started_at);
            e.repair_completed_at = this.formatDate(e.repair_completed_at);
            e.repair_cancelled_at = this.formatDate(e.repair_cancelled_at);
            e.delivered_at = this.formatDate(e.delivered_at);
            e.collected_at = this.formatDate(e.collected_at);
          })
          this.excelHeadersAndData.Sheet1.data = this.repairForms;
        });
    },
    startDownload() {
      this.exportLoading = true;
    },
    finishDownload() {
      this.exportLoading = false;
    },
    clearFilter() {
      this.startDate = "";
      this.endDate = "";
      this.selectedCustomer = "";
      this.selectedEndUser = "";
      this.getAllRepairForms();
    },
    showRfInquiryDialog(item) {
      this.rfId = item.id;
      this.$store.commit("showRfInquiryDialog");
    },
    getCustomers() {
      this.$store.dispatch("getServiceAllCustomers").then((response) => {
        this.customerList = this.GET_SERVICE_ALL_CUSTOMERS;
      });
    },
    getEndUsers() {
      this.$store.dispatch("serviceGetEndUsers").then((response) => {
        this.endUserList = this.GET_SERVICE_END_USER;
      });
    },
  },
};
</script>

<style lang="scss">
th,
td {
  padding-left: 8px;
  padding-top: 2px;
}
</style>>
