
import repository from '@/api/credentials';

const routes = [{
    path: '/sales/dailymanagementsystem/:id',
    name: 'dailymanagementsystem',
    component: () => import('@/views/main/modules/Sales/dms/DailyManagementSystemGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'DSMS',
    }
}]

export default routes;
