import repository from '@/api/modules/Admin/joborder'
import { values } from 'lodash';

    export default {
        state: {
                export_job_orders: false,
                jo_dispatch: {},
                PRINT: false,
                jo_num: "",
                service_name_suggestions: [],
                jo_vendor: [],
                jo_items_list: [],
                new_jo_table: false,
                edit_jo: {},
                loaded: false,
                status: '',
                administrative_approver: [],
                all_user_list: [],
                all_job_title: [],
                all_range: [],
                department_for_administrative:[],
                JO_FILES: [],
                JO_PAYMENT_METHOD:[
                    {text:'Service before payment',value:2,class:'badge-primary'},
                    {text:'50/50',value:1,class:'badge-secondary'},
                    {text:'Payment before service',value:3,class:'badge-success'}

                ],
                PRINT_DATA: [],


        },
        getters: {
                GET_EXPORT_JOB_ORDERS: (state) => state.export_job_orders,
                GET_JO_DISPATCH: (state) => state.jo_dispatch,
                GET_JO_PRINT: (state) => state.PRINT,
                GET_JO_NUM: (state) => state.jo_num,
                GET_JO_VENDOR: (state) => state.jo_vendor,
                GET_SERVICE_NAME_SUGGESTIONS: (state) => state.service_name_suggestions,
                GET_JO_ITEMS_LIST: (state) => state.jo_items_list,
                GET_NEW_JO_TABLE: (state) => state.new_jo_table,
                GET_EDIT_JO: (state) => state.edit_jo,
                GET_JO_ITEMS_LOADED: (state) => state.loaded,
                GET_JO_DISPATCH_STATUS: (state) => state.status,
                GET_ADMINISTRATIVE_APPROVER: (state) => state.administrative_approver,
                GET_ALL_USER_LIST: state => state.all_user_list,
                GET_ALL_JOB_TITLE: state => state.all_job_title,
                GET_ALL_RANGE: state => state.all_range,
                GET_DEPARTMENT_FOR_ADMINISTRATIVE: state => state.department_for_administrative,
                GET_JO_FILES: state => state.JO_FILES,
                GET_JOB_ORDERS:state => state
        },
        actions: {
                getLastJONum({ commit }) {
                        return new Promise((resolve, reject) => {
                                repository
                                .getLastJONum()
                                .then((response) => {
                                        commit("JO_NUM", response.data);
                                        resolve(response.data);
                                })
                                .catch((e) => {
                                        reject();
                                });
                        });
                },
                getJOVendorDetails({ commit }) {
                        return new Promise((resolve, reject) => {
                                repository
                                .getJOVendorDetails()
                                .then((response) => {
                                        commit("JO_VENDOR", response.data.data);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getAllAdministrativeApprover({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.getAllAdministrativeApprover(payload).then((response) => {
                                resolve(response)
                                commit("SET_ALL_ADMINISTRATIVE_APPROVER", response.data);
                                })
                                .catch((e) => {
                                reject();
                                });
                        });
                },
                CreateJO({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.CreateJO(payload).then((response) => {
                                        commit('NEW_JO_TABLE', true)
                                        resolve(response);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getAllJO({ commit },payload) {
                        return new Promise((resolve, reject) => {
                                repository.getAllJO(payload).then((response) => {
                                        resolve(response.data);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                UpdateJO({ commit, state }, payload) {

                        return new Promise((resolve, reject) => {
                                repository.UpdateJO(payload).then((response) => {
                                        resolve(response);
                                        commit('NEW_JO_TABLE', true)
                                        commit("JO_NUM", '');
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                DeleteJO({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.DeleteJO(payload).then((response) => {
                                        commit('NEW_JO_TABLE', true)
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getJobOrder({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.getJobOrder(payload).then((response) => {
                                        resolve(response);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getVendorRrfs({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.getVendorRrfs(payload).then((response) => {
                                        resolve(response);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                EnterAdministrativeApproverGet({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.EnterAdministrativeApproverGet(payload).then((response) => {
                                if(response.status == 200 || response.status == 201){
                                        commit("SET_ALL_ADMINISTRATIVE_APPROVER", response.data);
                                        resolve(response)
                                }
                                })
                                .catch((e) => {
                                        reject();
                                });
                        });
                },
                EnterAdministrativeApproverPost({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.EnterAdministrativeApproverPost(payload).then((response) => {
                                if(response.status == 200 || response.status == 202){
                                        resolve(response)
                                }
                                })
                                .catch((e) => {
                                        reject();
                                });
                        });
                },
                async getAllUsersList({commit},payload){
                        return new Promise((resolve,reject)=>{
                            repository.getAllUsersList(payload).then((response)=>{
                                commit('GET_ALL_USER_LIST',response.data)
                                resolve(response.data)
                            }).catch((error)=>{
                              reject(error)
                            });
                        })
                },
                async getAllJobTitle({commit},payload){
                        return new Promise((resolve,reject)=>{
                            repository.getAllJobTitle(payload).then((response)=>{
                                commit('GET_ALL_JOB_TITLE',response.data)
                                // resolve(response.data)
                            }).catch((error)=>{
                              reject(error)
                            });
                        })
                },
                async getAllRange({commit},payload){
                        return new Promise((resolve,reject)=>{
                            repository.getAllRange(payload).then((response)=>{
                                commit('GET_ALL_RANGE',response.data)
                                resolve(response.data)
                            }).catch((error)=>{
                              reject(error)
                            });
                        })
                },
                async jobOrderGet({ commit, state }, payload) {
                        return await new Promise((resolve, reject) => {
                            repository.jobOrderGet(payload).then((response) => {
                                    resolve(response.data);
                                    commit('JO_ITEMS_LIST', response.data);
                                    commit('NEW_JO_TABLE', false)
                            }).catch((error) => {
                                    console.log(error);
                                    reject(error);
                            })
                        })
                },
                jobOrderPost({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                            repository.jobOrderPost(payload).then((response) => {
                                    resolve(response.data);
                            }).catch((error) => {
                                    console.log(error);
                                    reject(error);
                            })
                        })
                },
                joPrintData({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.joPrintData(payload).then((response) => {
                                        resolve(response);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getDepartmentForAdministrative({ commit }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.getDepartmentForAdministrative(payload).then((response) => {
                                        if(response.status == 200 || response.status == 202){
                                                resolve(response)
                                                commit("DEPARTMENT_FOR_ADMINISTRATIVE", response.data);
                                        }
                                })
                                .catch((e) => {
                                        reject();
                                });
                        });
                },
                getAdminJOFiles({ commit }, payload) {
                        return new Promise((resolve,reject)=>{
                            repository.getAdminJOFiles(payload).then((response)=>{
                                if(response.status == 200){
                                                            commit('FILES', response.data)
                                    resolve(response)
                                }
                            }).catch((error)=>{
                                reject(error)
                            });
                        })
                },
                deleteAdminJOFiles({ commit }, payload) {
                        return new Promise((resolve,reject)=>{
                                repository.deleteAdminJOFiles(payload).then((response)=>{
                                if(response.status == 200){
                                        resolve(response)
                                }
                                }).catch((error)=>{
                                reject(error)
                                });
                        })
                },
                exportJobOrders({ commit }, payload) {
                        return new Promise((resolve,reject)=>{
                                repository.exportJobOrders(payload).then((response)=>{
                                if(response.status == 200){
                                        resolve(response)
                                }
                                }).catch((error)=>{
                                reject(error)
                                });
                        })
                },
                getServiceNameSuggestions({ commit }, payload) {
                        return new Promise((resolve,reject)=>{
                                repository.getServiceNameSuggestions(payload).then((response)=>{
                                if(response.status == 200){
                                        commit('SERVICE_NAME_SUGGESTIONS', response.data);
                                        resolve(response)
                                }
                                }).catch((error)=>{
                                reject(error)
                                });
                        })
                },
        },
        mutations: {
                JO_DISPATCH: (state, data) => {
                        state.jo_dispatch = data;
                },
                JO_NUM: (state, data) => {
                        state.jo_num = data
                },
                JO_VENDOR: (state, data) => {
                        state.jo_vendor = data
                },
                SERVICE_NAME_SUGGESTIONS: (state, data) => {
                        state.service_name_suggestions = data
                },
                JO_ITEMS_LIST: (state, data) => {
                        state.jo_items_list = data
                },
                NEW_JO_TABLE: (state, data) => {
                        state.new_jo_table = data
                },
                EDIT_JO: (state, data) => {
                        state.edit_jo = data
                },
                SET_ALL_ADMINISTRATIVE_APPROVER: (state, data) => {
                        state.administrative_approver = data
                },
                JO_PRINT: (state, data) => state.PRINT = data,
                JO_ITEMS_LOADED: (state, data) => state.loaded = data,
                JO_DISPATCH_STATUS: (state, data) => state.status = data,
                GET_ALL_USER_LIST: (state, data) => {
                        state.all_user_list = data;
                },
                GET_ALL_JOB_TITLE: (state, data) => {
                        state.all_job_title = data;
                },
                GET_ALL_RANGE: (state, data) => {
                        state.all_range = data;
                },
                DEPARTMENT_FOR_ADMINISTRATIVE:(state, data) => {
                        state.department_for_administrative = data;
                },
                FILES:(state,data)=>{
                        state.JO_FILES = data;
		},
                EXPORT_JOB_ORDERS: (state, data) => {
                        state.export_job_orders = data;
                },
                JO_PRINT_DATA: (state, data) =>{
                        state.PRINT_DATA = data
                },
        },
}
