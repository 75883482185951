import repository from '@/api/Main/Shared/index';
export default {
  state: {
    related_transaction_id:0,
    related_transaction_model:'',
    files: [],
  },
  getters: {
    GET_RELATED_TRANSACTION_ID:(state)=>state.related_transaction_id,
    GET_FILE_TRANSACTION_MODEL:(state)=>state.related_transaction_model,
    CHECK_IF_EMPTY_FILES:(state)=>state.files,
  },
  actions: {
    saveFiles({},payload){
        return new Promise((resolve,reject)=>{
            repository.saveFiles(payload).then((response)=>{
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    retrieveFiles({},payload){
        return new Promise((resolve,reject)=>{
            repository.retrieveFiles(payload).then((response)=>{
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    destroyFiles({},payload){
        return new Promise((resolve,reject)=>{
            repository.destroyFiles(payload).then((response)=>{
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    geRelatedTransactionId({state,commit},payload){
        commit('RELATED_TRANSACTION_ID',payload)
    },
    getModel({state,commit},payload){
        commit('FILE_TRANSACTION_MODEL',payload)
    },
    downloadFile({},payload){
        return new Promise((resolve,reject)=>{
            repository.downloadFile(payload).then((response)=>{
                resolve(response)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    checkIfEmptyFiles({state,commit},payload){
        commit('CHECK_IF_EMPTY_FILES',payload)
    },
  },
  mutations: {
    RELATED_TRANSACTION_ID:(state,data)=>{
        state.related_transaction_id = data
    },
    FILE_TRANSACTION_MODEL:(state,data)=>{
        state.related_transaction_model = data
    },
    CHECK_IF_EMPTY_FILES:(state,data)=>{
        state.files = data
    }
  },
}
