import api from '../../../api'
export default {

    acctPayableGet(params){
        return api.get(`/accounting/payable/`+params.url,{params})
    },
    acctPayablePost(params){
        return api.post(`/accounting/payable/`+params.url,params)
    },
    acctPayablePut(params){
        return api.put(`/accounting/payable/resource/`+params.url)
    },
    acctPayableDelete(params){
        return api.delete(`/accounting/payable/resource/`+params.url)
    },
    createvendorpaymentdetail(params,config){
        return api.post(`accounting/payable/createVendorPaymentDetail`,params,config)
    },
    getVendorPaymentList(params){
        return api.get(`accounting/payable/getVendorPaymentList`,{params});
    },
    updatePaymentVoucher(id,params){
        return api.put(`accounting/payable/updatePaymentVoucher/${id}`,params);
    },
    getPVPrintData(params){
        return api.get('accounting/getPVPrintData',{params});
    },
    getLastCVDetails(params){
        return api.get('accounting/getLastCVDetails',{params});
    },
    getPaymentAllocations(params){
        return api.post(`accounting/getPaymentAllocations`, params);
    },
    getCheckVoucherByPV(params){
        return api.get(`accounting/getCheckVoucherByPV/${params}`);
    },
    chequeVoucherPrint(params){
        return api.get(`accounting/chequeVoucherPrint/${params.id}`,{params});
    },
    getEnterBillItems(params){
        return api.get(`accounting/getEnterBillItems/${params.name}/${params.vendor_id}`);
    },
    getEnterBillTransactItems(params){
        return api.get(`accounting/getEnterBillTransactItems`,{params});
    },
    getTransactTypeModelName(params){
        return api.get('accounting/getTransactTypeModelName',{params});
    },
    cardBankGet(params){
        return api.get(`/accounting/${params.url}`,{params})
    },
    cardBankPost(params){
        return api.post(`/accounting/${params.url}`,params)
    },
    getViewFiles(params){
        return api.get(`accounting/get-files`,{params});
    },
    getPaymentBillFiles(params){
        return api.get(`/accounting/get-payment-bill-files/${params.id}`,{params});
    },
    deletePaymentBillFiles(params){
        return api.get(`/accounting/delete-payment-bills-files/${params.id}`,{params});
    },
    postPaymentBillUpload(params){
        return api.post(`/accounting/`+params.url, params);
    },
    getVendorCardPayments(params){
        return api.post(`/accounting/`+params.url, params);
    },
}
