import repository from '@/api/modules/PartsTransfer/index'
export default {
	state: {
        DIALOG:{
            ADD:{
                VALUE:false,
                VIEW:false
            },
            ACTION:''
        },
        DATA_TABLE:{
            ITEMS:[],
            SELECTED:{
                data:{
                    series:''
                }
            },
            LOADING: false,
            REFRESH: false
        },
        TAB:0,
        APPROVED_MULTIPLE: false,
	},
	getters: {
        PARTS_TRANSFER: (state) => state,
	},
	actions:{
        async partsTransferGet({commit},payload) {
            return await new Promise((resolve, reject) => {
                repository.partsTransferGet(payload).then((response) => {
                    resolve(response)
                    if(!!payload.mutations){
                        this.commit(payload.mutations,response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        partsTransferPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.partsTransferPost(payload).then((response) => {
                    resolve(response)
                    if(!!payload.mutations){
                        this.commit(payload.mutations)
                        this.commit('DATA_TABLE_REFRESH', true)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        }
	},
	mutations:{
        TOGGLE_ADD_DIALOG: (state, data) => { (state.DIALOG.ADD.VALUE = data) },
        TOGGLE_ADD_DIALOG_VIEW: (state, data) => { (state.DIALOG.ADD.VIEW = data) },
        DATA_TABLE: (state, data) => { (state.DATA_TABLE.ITEMS = data) },
        DIALOG_ACTION: (state, data) => { (state.DIALOG.ACTION = data) },
        DATA_TABLE_SELECTED: (state, data) => { (state.DATA_TABLE.SELECTED = data) },
        IS_APPROVED_MULTIPLE: (state, data) => { (state.APPROVED_MULTIPLE = data)},
        DATA_TABLE_LOADING: (state, data) => { (state.DATA_TABLE.LOADING = data)},
        DATA_TABLE_REFRESH: (state, data) => { (state.DATA_TABLE.REFRESH = data)}
	},
}
