import repository from '@/api/modules/HR/RecruitmentSystem/recruitmentSystem'
import store from '@/store';
export default {
	state: {
		hr_manpower_request:[],
		hr_applicant_list : [],
        hr_recruitment_new_data_trigger: false,
		hr_manpower_request_files : [],
		hr_privacy_settings:[],
        hr_application_message:[],
        hr_file_viewer_new_data_trigger : false,
        hr_applicant_new_data_trigger: false,
        hr_applicant_requirements: [],
        hr_prf_selection:[],
        hr_prf_selection_active_table:''
	},
	getters: {
		GET_HR_MANPOWERREQUEST:(state)=>state,
		GET_HR_APPLICANT_LIST:(state)=>state.hr_applicant_list,
		GET_HR_RECRUITMENT_NEW_DATA:(state)=>state.hr_recruitment_new_data_trigger,
		GET_HR_MANPOWER_REQUEST_FILES:(state)=>state.hr_manpower_request_files,
		GET_HR_PRIVACY_SETTINGS:(state)=>state.hr_privacy_settings,
		GET_HR_APPLICATION_MESSAGE:(state)=>state.hr_application_message,
		GET_HR_FILE_VIEWER_NEW_DATA:(state)=>state.hr_file_viewer_new_data_trigger,
		GET_HR_APPLICANT_NEW_DATA:(state)=>state.hr_applicant_new_data_trigger,
		GET_HR_APPLICANT_REQUIREMENTS:(state)=>state.hr_applicant_requirements,
		GET_HR_PRF_SELECTION:(state)=>state.hr_prf_selection,
		GET_HR_PRF_SELECTION_ACTIVE_TABLE:(state)=>state.hr_prf_selection_active_table,

	},
	actions:{
		// Manpower Request Actions
		AddHrManpowerRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addhrManpowerRequest(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		UpdateHrManpowerRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updatehrManpowerRequest(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		DeleteHrManpowerRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deletehrManpowerRequest(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		GetHrManpowerRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.gethrManpowerRequest(payload).then((response)=>{
					commit('HR_MANPOWERREQUEST',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		ShowHrManpowerRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showhrManpowerRequest(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		approveRejectManpowerRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.approveRejectManpowerRequest(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		// Applicants List Actions
		UpdateHrApplicationList({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updatehrApplicants(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		DeleteHrApplicationList({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deletehrApplicants(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		GetHrApplicationList({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.gethrApplicants(payload).then((response)=>{
					commit('HR_APPLICANT_LIST',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		ShowHrApplicantList({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showhrApplicants(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		approveRejectApplicant({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.approveRejectApplicants(payload).then((response)=>{
                    commit('HR_APPLICANT_NEW_DATA',true)
                    commit('HR_RECRUITMENT_NEW_DATA',true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getApplicantFiles({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getApplicantFiles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getApplicantRequirementFiles({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getApplicantRequirementFiles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		updateApplicationStatus({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateApplicationStatus(payload).then((response)=>{
					commit('HR_APPLICANT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getFileFromDomain({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getFileFromDomain(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getRequirementsFromDomain({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getRequirementsFromDomain(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		// Privacy Settings

		AddHrPrivacySettings({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addhrPrivacySettings(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		UpdateHrPrivacySettings({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updatehrPrivacySettings(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		GetHrPrivacySettings({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.gethrPrivacySettings(payload).then((response)=>{
					commit('HR_PRIVACY_SETTINGS',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		// Email Application Message Settings

		UpdateApplicantStatusMessage({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateApplicantStatusMessage(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		GetApplicantStatusMessage({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getApplicantStatusMessage(payload).then((response)=>{
					commit('HR_APPLICATION_MESSAGE',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		ApproveApplicationRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.approveApplicationRequirements(payload).then((response)=>{
					commit('HR_FILE_VIEWER_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		RejectApplicantRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.rejectApplicantRequirements(payload).then((response)=>{
					commit('HR_FILE_VIEWER_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		uploadApplicantNewRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.uploadApplicantNewRequirements(payload.params,payload.config).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		deleteApplicationRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deleteApplicationRequirements(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		deleteEmployeeRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deleteEmployeeRequirements(payload).then((response)=>{
					commit('HR_RECRUITMENT_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },


        // Application Requirements
		addApplicantRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addApplicantRequirements(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		updateApplicantRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateApplicantRequirements(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		deleteApplicantRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deleteApplicantRequirements(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getApplicantRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getApplicantRequirements(payload).then((response)=>{
					commit('HR_APPLICANT_REQUIREMENTS',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

        // PRF Selections
		addPRFSelectionTable({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addPRFSelectionTable(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		updatePRFSelectionTable({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updatePRFSelectionTable(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		deletePRFSelectionTable({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deletePRFSelectionTable(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getPRFSelectionTable({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getPRFSelectionTable(payload).then((response)=>{
					commit('HR_PRF_SELECTION',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        uploadManpowerRequestfiles({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.uploadManpowerRequestfiles(payload.params,payload.config).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getManpowerRequestfiles({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getManpowerRequestfiles(payload).then((response)=>{
					commit('HR_MANPOWER_REQUEST_FILES',response)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		deleteManpowerRequestFile({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deleteManpowerRequestFile(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
	},
	mutations:{
		HR_MANPOWERREQUEST:(state,data)=>{state.hr_manpower_request = data},
		HR_APPLICANT_LIST:(state,data)=>{state.hr_applicant_list = data},
		HR_RECRUITMENT_NEW_DATA:(state,data)=>{state.hr_recruitment_new_data_trigger = data},
		HR_MANPOWER_REQUEST_FILES:(state,data)=>{state.hr_manpower_request_files = data},
		HR_PRIVACY_SETTINGS:(state,data)=>{state.hr_privacy_settings = data},
		HR_APPLICATION_MESSAGE:(state,data)=>{state.hr_application_message = data},
		HR_FILE_VIEWER_NEW_DATA:(state,data)=>{state.hr_file_viewer_new_data_trigger = data},
		HR_APPLICANT_NEW_DATA:(state,data)=>{state.hr_applicant_new_data_trigger = data},
		HR_APPLICANT_REQUIREMENTS:(state,data)=>{state.hr_applicant_requirements = data},
		HR_PRF_SELECTION:(state,data)=>{state.hr_prf_selection = data},
		HR_PRF_SELECTION_ACTIVE_TABLE:(state,data)=>{state.hr_prf_selection_active_table = data},
	},
}
