import api from '../../api'

export default {
    CreateWarehouseItems(params) {
        return api.post('/admin/adminwarehouse', params)
    },
    getAllWarehouseItems(params) {
        return api.get('/admin/getAllWarehouseItems', { params })
    },
    getAdminWarehouseCode(params){
        return api.get('/admin/getAdminWarehouseCode', {params})
    },
    UpdateWarehouseItems(params) {
        return api.put(`admin/adminwarehouse/${params.id}`,params);
    },
    DeleteWarehouseItems(params) {
        return api.delete(`/admin/adminwarehouse/${params}`)
    },
}

