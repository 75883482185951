import api from '../../api'

export default {
    getcompanytarget(params){
        return api.get('dashboard/sales/company-target', {params});
    },
    getsalesbyitemtype(params){
        return api.get('dashboard/sales/sales-by-item-type', {params});
    },
    getsalesbyitemcategory(params){
        return api.get('dashboard/sales/sales-by-item-category', {params});
    },
    getSalesDashboard(params){
        return api.get(`dashboard/sales/${params.url}`,{params})
    },
    getAverageTimeIn(params){
        return api.get(`dashboard/sales/${params.url}`,{params})
    },
    
}
