import store from '../../../store';
import api from '../../api'

export default {
   AdminCreateAdminItem(params){
      return api.post('/admin/adminitem',params)
   },
   AdminUpdateAdminItem(params){
      return api.put(`/admin/adminitem/${params.id}`,params)
   },
   AdminDeleteAdminItem(params){
      return api.delete(`/admin/adminitem/${params}`)
   },
   getAllAdminItem(params){
      return api.get('/admin/getAllAdminItems',{params})
   },
   exportBatch(params){
      return api.get('/admin/getBatch',{params});
   },
   getLastAssetNum(params){
      return api.get('/admin/getLastAssetNum',{ params });
   },

   adminItemGet: params => { return api.get(`/admin/${params.url ? params.url : ''}`, {params}); },
   adminItemPost: params => { return api.post(`/admin/${params.url ? params.url : ''}`, params); },
   adminItemPut: params => { return api.put(`/admin/${params.url ? params.url : ''}`, params); },
   adminItemDelete: params => { return api.delete(`/admin/${params.url ? params.url : ''}`); },

   adminItemTempGet(params){
      return api.get(`/admin/${params.url}`,{params})
   },
   adminItemTempPost(params){
      return api.post(`/admin/${params.url}`,params)
   },
   adminItemTempPut(params){
      return api.put(`/admin/${params.url}/${params.id}`,params)
   },
   adminItemTempDelete(params){
      return api.delete(`/admin/${params.url}/${params.id}`,params)
   },
}

