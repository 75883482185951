import repository from "@/api/modules/Operations/SampleForm/index";

export default {
    state: {
        sampleFormItems: [],
        selectedItems: [],
        selected_items_sample_form: {},
        refresh_table: false,
        view_data:{},
        sf_dialog_title:'',
        sf_active_tab: null,
        sf_dispatch:{},
        rf_table_refresh: false,
        file_previews : [],
        file_viewer_id: '',
        file_uploads : {}
    },
    getters: {
        GET_SAMPLE_FORM_ITEMS: (state) => state.sampleFormItems,
        GET_SELECTED_ITEM: (state) => state.selectedItems,
        GET_SELECTED_ITEMS_SAMPLE_FORM: (state) => state.selected_items_sample_form,
        REFRESH_TABLE: (state) => state.refresh_table,
        GET_VIEW_DATA: (state) => state.view_data,
        GET_SF_DIALOG_TITLE: (state) => state.sf_dialog_title,
        GET_SF_ACTIVE_TAB: (state) => state.sf_active_tab,
        GET_SF_DISPATCH: (state) => state.sf_dispatch,
        GET_RF_TABLE_REFRESH: (state) => state.rf_table_refresh,
        GET_FILE_PREVIEWS: (state) => state.file_previews,
        GET_FILE_VIEWER_ID: (state) => state.file_viewer_id,
        GET_FILE_UPLOADS: (state) => state.file_uploads,
    },
    actions: {
        // Inventory Tab Actions
        getInventorySampleForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformget(payload).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        // Sample Form Tab Actions
        getInventorySampleFormItems({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformget(payload).then(response => {
                    commit('SAMPLE_FORM_ITEMS', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        getSelectedItems({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformget(payload).then(response => {
                    commit('SELECTED_ITEM', response.data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        addNewSampleForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformpost(payload).then(response => {
                    commit('SET_REFRESH_TABLE', true);
                    if(response.status == 200) {
                        resolve(response);
                    }
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        getAllSampleForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformget(payload).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        viewSampleForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .getsampleform(payload).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        changeStatusSampleForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformpost(payload).then(response => {
                    commit('SET_REFRESH_TABLE', true);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },

        // Return Tab Actions
        getInventoryReturnForms({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformget(payload).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        addUpdateReturnForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformpost(payload).then(response => {
                    commit('RF_TABLE_REFRESH', true);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        viewReturnFormDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .viewreturnform(payload).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        changeStatusReturnForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformpost(payload).then(response => {
                    commit('RF_TABLE_REFRESH', true);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        printSampleForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformpost(payload).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        printReturnForm({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformpost(payload).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        uploadfilesSF({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .uploadfilesSampleFormAPI(payload,payload.config).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        getUploadFiles({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .getUploadFilesAPI(payload,payload.config).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        checkSFItems({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformpost(payload).then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
        deleteSampleFormFiles({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                .sampleformpost(payload).then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                })
            });
        },
    },
    mutations: {
        SAMPLE_FORM_ITEMS: (state, data) => { (state.sampleFormItems = data) },
        SELECTED_ITEM: (state, data) => { (state.selectedItems = data) },
        SET_REFRESH_TABLE: (state, data) => { (state.refresh_table = data) },
        VIEW_DATA: (state, data) => { (state.view_data = data) },
        SF_DIALOG_TITLE: (state, data) => { (state.sf_dialog_title = data) },
        SF_ACTIVE_TAB: (state, data) => { (state.sf_active_tab = data) },
        SF_DISPATCH: (state, data) => { (state.sf_dispatch = data) },
        RF_TABLE_REFRESH: (state, data) => { (state.rf_table_refresh = data) },
        FILE_PREVIEWS: (state, data) => { (state.file_previews = data) },
        FILE_VIEWER_ID: (state, data) => { (state.file_viewer_id = data) },
        FILE_UPLOADS: (state, data) => { (state.file_uploads = data) },
    }
}
