import repository from '@/api/modules/Admin/adminInboundOutbound'
import store from '@/store'
export default {
    state: {
        bound_edit_item:{},
        bound_edit_data_items:[],
        admin_bound_type:'',
        bound_id: '',
    },
    getters: {
        GET_BOUND_EDIT_ITEM:(state)=>state.bound_edit_item,
        GET_BOUND_EDIT_DATA_ITEMS:(state)=>state.bound_edit_data_items,
        GET_ADMIN_BOUND_TYPE:(state)=>state.admin_bound_type,
        GET_ADMIN_BOUND_ID:(state)=>state.bound_id,
    },
    actions: {
        adminInboundOutboundPost({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.adminInboundOutboundPost(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
        adminInboundOutboundGet({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.adminInboundOutboundGet(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        adminInboundOutboundShowItems({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.adminInboundOutboundShowItems(payload).then((response)=>{
                    resolve(response.data)
                    commit('BOUND_EDIT_DATA_ITEMS',response.data);
				}).catch((error)=>{
					reject(error)
				});
			})
        },
    },
    mutations: {
        BOUND_EDIT_ITEM:(state,data)=>{
            state.bound_edit_item = data
        },
        BOUND_EDIT_DATA_ITEMS:(state,data)=>{
            state.bound_edit_data_items = data
        },
        ADMIN_BOUND_TYPE:(state,data)=>{
            state.admin_bound_type = data
        },
        ADMIN_BOUND_ID:(state,data)=>{
            state.bound_id = data
        }
    }
}