import repository from '@/api/modules/Admin/adminwarehousestocks'

        export default {
        state: {
            adm_warehouse_stock_dispatch: {},
            new_adm_warehouse_stock_table: false,
            new_stock_success: false,
            adm_warehouse_items: {},
            edit_adm_warehouse_stock: [],
            search_item: '',
            selected_stocks: [],
            refresh: false,
        },
        getters: {
            GET_ADM_WAREHOUSE_STOCKS_DISPATCH: (state) => state.adm_warehouse_stock_dispatch,
            GET_NEW_ADM_WAREHOUSE_STOCKS_TABLE: (state) => state.new_adm_warehouse_stock_table,
            GET_NEW_STOCK_SUCCESS:(state)=>state.new_stock_success,
            GET_ADM_WAREHOUSE_ITEMS: (state) => state.adm_warehouse_items,
            GET_EDIT_ADM_WAREHOUSE_STOCKS: (state) => state.edit_adm_warehouse_stock,
            GET_SEARCH_ITEM: (state) => state.search_item,
            GET_SELECTED_STOCKS: (state) => state.selected_stocks,
            WAREHOUSE_ITEM_STOCKS_REFRESH: (state) => state.refresh,
        },
        actions: {
            CreateWarehouseStocks({ commit, state }, payload) {
                return new Promise((resolve, reject) => {
                        repository.CreateWarehouseStocks(payload).then((response) => {
                                resolve(response);
                                commit('NEW_ADM_WAREHOUSE_STOCKS_TABLE', true)
                                commit('NEW_STOCK_SUCCESS', true)
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            getAllWarehouseStocks({ commit },payload) {
                return new Promise((resolve, reject) => {
                        repository.getAllWarehouseStocks(payload).then((response) => {
                                resolve(response.data);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            getTaggedWarehouseStocks({ commit },payload) {
                return new Promise((resolve, reject) => {
                        repository.getTaggedWarehouseStocks(payload).then((response) => {
                                resolve(response.data);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            getUntaggedWarehouseStocks({ commit },payload) {
                return new Promise((resolve, reject) => {
                        repository.getUntaggedWarehouseStocks(payload).then((response) => {
                                resolve(response.data);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            getWarehouseStockItems({ commit }, payload) {
                return new Promise((resolve, reject) => {
                        repository.getWarehouseStockItems(payload).then((response) => {
                                commit('ADM_WAREHOUSE_ITEMS', response.data);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            markAsTagged({ commit }, payload) {
                return new Promise((resolve, reject) => {
                        repository.markAsTagged(payload).then((response) => {
                                resolve(response);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            UpdateWarehouseStocks({ commit, state }, payload) {
                return new Promise((resolve, reject) => {
                        repository.UpdateWarehouseStocks(payload).then((response) => {
                                resolve(response);
                                commit('NEW_ADM_WAREHOUSE_STOCKS_TABLE', true)
                                commit('NEW_STOCK_SUCCESS', true)
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            DeleteWarehouseStocks({ commit, state }, payload) {
                return new Promise((resolve, reject) => {
                        repository.DeleteWarehouseStocks(payload).then((response) => {
                                commit('NEW_ADM_WAREHOUSE_STOCKS_TABLE', true)
                                commit('NEW_STOCK_SUCCESS', true)
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            adminWarehouseItemStocksGet({commit},payload){
                return new Promise((resolve,reject)=>{
                    repository.adminWarehouseItemStocksGet(payload).then((response)=>{
                        resolve(response.data)
                    }).catch((error)=>{
                        reject(error)
                    });
                })
            }
        },
        mutations: {
            ADM_WAREHOUSE_STOCKS_DISPATCH: (state, data) => {
                state.adm_warehouse_stock_dispatch = data;
            },
            NEW_ADM_WAREHOUSE_STOCKS_TABLE: (state, data) => {
                state.new_adm_warehouse_stock_table = data
            },
            NEW_STOCK_SUCCESS:(state,data)=>{
                state.new_stock_success = data
            },
            ADM_WAREHOUSE_ITEMS: (state, data) => {
                state.adm_warehouse_items = data
            },
            EDIT_ADM_WAREHOUSE_STOCKS: (state, data) => {
                state.edit_adm_warehouse_stock = data
            },
            SEARCH_ITEM: (state, data) => {
                state.search_item = data
            },
            SELECTED_STOCKS: (state, data) => {
                state.selected_stocks = data
            },
            WAREHOUSE_ITEM_STOCKS_REFRESH: (state, data) => {
                state.refresh = data
            }
        },
}
