import repository from '@/api/modules/Admin/adminwarehouse'

export default {
        state: {
                adm_warehouse_dispatch: {},
                new_adm_warehouse_table: false,
                edit_adm_warehouse: {},
                adm_warehouse_code: [],
        },
        getters: {
                GET_ADM_WAREHOUSE_DISPATCH: (state) => state.adm_warehouse_dispatch,
                GET_NEW_ADM_WAREHOUSE_TABLE: (state) => state.new_adm_warehouse_table,
                GET_EDIT_ADM_WAREHOUSE: (state) => state.edit_adm_warehouse,
                GET_ADM_WAREHOUSE_CODE:(state) => state.adm_warehouse_code,
        },
        actions: {
                CreateWarehouseItems({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.CreateWarehouseItems(payload).then((response) => {
                                        resolve(response);
                                        commit('NEW_ADM_WAREHOUSE_TABLE', true)
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getAllWarehouseItems({ commit },payload) {
                        return new Promise((resolve, reject) => {
                                repository.getAllWarehouseItems(payload).then((response) => {
                                        resolve(response.data);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                getAdminWarehouseCode({ commit },payload) {
                        return new Promise((resolve, reject) => {
                                repository.getAdminWarehouseCode(payload).then((response) => {
                                        commit('ADM_WAREHOUSE_CODE', response.data);
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                UpdateWarehouseItems({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.UpdateWarehouseItems(payload).then((response) => {
                                        resolve(response);
                                        commit('NEW_ADM_WAREHOUSE_TABLE', true)
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
                DeleteWarehouseItems({ commit, state }, payload) {
                        return new Promise((resolve, reject) => {
                                repository.DeleteWarehouseItems(payload).then((response) => {
                                        commit('NEW_ADM_WAREHOUSE_TABLE', true)
                                }).catch((error) => {
                                        console.log(error);
                                        reject(error);
                                })
                        })
                },
        },
        mutations: {
                ADM_WAREHOUSE_DISPATCH: (state, data) => {
                        state.adm_warehouse_dispatch = data;
                },
                NEW_ADM_WAREHOUSE_TABLE: (state, data) => {
                        state.new_adm_warehouse_table = data
                },
                EDIT_ADM_WAREHOUSE: (state, data) => {
                        state.edit_adm_warehouse = data
                },
                ADM_WAREHOUSE_CODE: (state, data) => {
                        state.adm_warehouse_code = data;
                },
        },
}
