import api from '../../../api'

export default {
    getallitems(params) {
        return api.post('items/getAll', params);
    },
    getitemcategories() {
        return api.get('dropdown/item-categories');
    },
    getitems(params) {
        return api.get('dropdown/getItems',{params});
    },
    getitemtypes() {
        return api.get('dropdown/item-types');
    },
    getitemuom() {
        return api.get('dropdown/item-uom');
    },
    getitembrand() {
        return api.get('dropdown/item-brand');
    },
    getitemsize() {
        return api.get('dropdown/item-size');
    },
    getallbundles(params) {
        return api.get('items/itemBundles/getAll', {params});
    },
    getitemeditinfo(params) {
        return api.post(`items/getItemEditInfo/${params}`);
    },
    storeitem(params, config) {
        return api.post('items/storeItem', params, config);
    },
    storeimportitem(params, config) {
        return api.post('items/storeImportItem', params, config);
    },
    storeimportimage(params, config) {
        return api.post('items/storeimportimage', params, config);
    },
    storeimportimageexcel(params, config) {
        return api.post('items/storeimportimageexcel', params, config);
    },
    updateImportItem(params, config) {
        return api.put('items/updateImportItem', params, config);
    },
    showitem(params) {
        return api.get(`/items/showItem/${params}`);
    },
    updateselecteditem(params, config) {
        return api.post('items/updateSelectedItem', params, config);
    },
    destroyitem(params) {
        return api.delete(`/items/destroyItem/${params}`);
    },
    storeitembundle(params) {
        return api.post(`/items/storeItemBundles`, params);
    },
    getitembundle(params) {
        return api.get(`items/itemBundles/${params}`);
    },
    updateitembundle(params) {
        return api.post(`items/updateItemBundles`, params);
    },
    destroyitembundle(params) {
        return api.delete(`/items/destroyItemBundles/${params}`);
    },
    getallitemtypes() {
        return api.get('items/itemTypes/getAll');
    },
    storeitemtypes(params) {
        return api.post('items/itemTypes', params);
    },
    showitemtypes(params) {
        return api.get(`items/itemTypes/${params}`);
    },
    updateitemtypes(params) {
        return api.post(`items/updateItemTypes/`, params);
    },
    destroyitemtype(params) {
        return api.delete(`/items/itemTypes/${params}`);
    },
    getallitemcategories() {
        return api.get(`dropdown/itemCategories/getAllItemCategories`);
    },
    storeitemcategories(params) {
        return api.post(`items/itemCategories`, params);
    },
    showitemcategories(params) {
        return api.get(`items/itemCategories/${params}`);
    },
    updateitemcategories(params) {
        return api.post(`items/updateItemCategories`, params);
    },
    destroyitemcategories(params) {
        return api.delete(`/items/itemCategories/${params}`);
    },
    getallitemuom() {
        return api.get(`items/itemUom/getAll`);
    },
    storeitemuom(params) {
        return api.post(`items/itemUom`, params);
    },
    showitemuom(params) {
        return api.get(`items/itemUom/${params}`);
    },
    updateitemuom(params) {
        return api.post(`items/updateItemUom/`, params);
    },
    destroyitemuom(params) {
        return api.delete(`/items/itemUom/${params}`);
    },
    getallitembrand() {
        return api.get(`items/itemBrands/getAll`);
    },
    storeitembrand(params) {
        return api.post(`items/itemBrands`, params);
    },
    showitembrand(params) {
        return api.get(`items/itemBrands/${params}`);
    },
    updateitembrand(params) {
        return api.post(`items/updateItemBrands/`, params);
    },
    destroyitembrand(params) {
        return api.delete(`/items/itemBrands/${params}`);
    },
    getallitemsizes() {
        return api.get(`items/itemSizes/getAll`);
    },
    getitemsmodelid(params) {
        return api.get(`items/items/${params}`);
    },
    getnewermodel(params){
        return api.get(`items/getNewerModel/${params}`);
    },
    getitemid(params){
        return api.get(`items/getItemId`,{params});
    },
    storeitemsizes(params) {
        return api.post(`items/itemSizes`, params);
    },
    showitemsizes(params) {
        return api.get(`items/itemSizes/${params}`);
    },
    updateitemsizes(params) {
        return api.post(`items/updateItemSizes/`, params);
    },
    destroyitemsizes(params) {
        return api.delete(`/items/itemSizes/${params}`);
    },
    getitemwarehousestock(id,from,params){
        return api.get(`items/getItemWarehouseStocks/${id}/${from}`,{params})
    },
    postwarehouseitemstocks(params){
        return api.post('items/postWarehouseItemStocks',params);
    },
    getitemsubstitutions(params){
        return api.get(`items/getItemSubstitutions/${params}`)
    },
    exportitemmastertoexcel(params){
        return api.post('reports/exportItemMasterToExcel',params);
    },
    getitemtemplate(params){
        return api.get(`items/item-template`,{params})
    },
    itemtemplateselection(params){
        return api.get(`items/item-template/item-selection`,{params})
    },
    itemtemplatecanedit(params){
        return api.get(`items/item-template/can-edit`)
    },
    itemtemplateupdate(id,params){
        return api.post(`items/item-template/${id}/update`,params)
    },
    postitemtemplate(params){
        return api.post(`items/item-template`,params)
    },
    destroyitemtemplate(id){
        return api.post(`items/item-template/destroy/${id}`)
    },
    itemtemplatedetails(id,params){
        return api.get(`items/item-template/${id}/details`,{params})
    },
    itemtemplatecleardetails(id,params){
        return api.post(`items/item-template/${id}/clear-details`,params)
    },
    getbundleditems(params){
        return api.get(`items/getBundledItems/${params}`);
    },
    confirmoverrideamounts(params){
        return api.post(`items/confirmOverrideAmounts`,params);
    },
    getItemEditInfoshared(params){
        return api.post(`items/getItemEditInfo/${params.model_id}`,params);
    },
    totalstocks(params){
        return api.get(`items/total-stocks/${params}`);
    },
    getSubstitutionItemModel(params){
        return api.get(`items/getSubstitutionItemModel`,{params});
    },
    getItemExclusiveByItem(params) {
        return api.get(`/items/getItemExclusiveByItem`,{params});
    },
    itemsGet(params){
        return api.get(`items/${params.url}`,{params});
    },
    itemsPost(params){
        return api.post(`items/${params.url}`,params);
    },
    getpartscompatibility(params){
        return api.get(`items/parts-compatibility`, {params});
    },
    checkitemspareparts(params){
        return api.get(`items/check-item-spare-parts`, {params});
    },
    exportitemmastertopdf(params){
        return api.post('reports/export-item-master-pdf',params);
    },
    getItemFiles(params) {
        return api.post(`items/getItemFiles/${params}`);
    },
}
