import repository from '@/api/modules/WebMail/index'
import store from '@/store';
export default {
	state: {
		dialog_title:'',
        webmail_dispatch:{},
		webmail_new_data: false,
		webmail_tab_is_active:'',
        webmail_view: [],
		employee_emails:{},
		employee_email_dialog:false,
		employee_data_with_emails:[]
	},
	getters: {
		GET_WEBMAIL_DIALOG_TITLE:(state) => state.dialog_title,
        GET_WEBMAIL_DISPATCH:(state)=>state.webmail_dispatch,
		GET_WEBMAIL_NEW_DATA_TRIGGER:(state)=>state.webmail_new_data,
		GET_WEBMAIL_TAB_IS_ACTIVE:(state) => state.webmail_tab_is_active,
		GET_WEBMAIL_VIEW:(state) => state.webmail_view,
		GET_EMPLOYEE_EMAILS:(state) => state.employee_emails,
		GET_EMPLOYEE_EMAIL_DIALOG:(state) => state.employee_email_dialog,
		GET_EMPLOYEE_DATA_WITH_EMAILS:(state) => state.employee_data_with_emails,
	},
	actions:{
		// CPanel Email Actions
		getEmail({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getEmail(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmail({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addEmail(payload).then((response)=>{
					commit('WEBMAIL_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmail({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateEmail(payload).then((response)=>{
					commit('WEBMAIL_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmail({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteEmail(payload).then((response)=>{
					commit('WEBMAIL_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		showEmail({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showEmail(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getEmployeeDataWithEmail({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getEmployeeDataWithEmail(payload).then((response)=>{
					commit('EMPLOYEE_DATA_WITH_EMAILS',response.data)
					resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		updateEmployeeDataEmails({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateEmployeeDataEmails(payload).then((response)=>{
					commit('WEBMAIL_NEW_DATA_TRIGGER',true)
					resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getEmployeeEmailsWithLogs({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getEmployeeEmailsWithLogs(payload).then((response)=>{
					resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
        },

	},
	
	mutations:{
		WEBMAIL_DIALOG_TITLE:(state,data)=>{state.dialog_title = data},
        WEBMAIL_DISPATCH:(state,data)=>{state.webmail_dispatch = data},
		WEBMAIL_NEW_DATA_TRIGGER:(state,data) => {state.webmail_new_data = data},
		WEBMAIL_TAB_IS_ACTIVE:(state,data) =>{state.webmail_tab_is_active =data},
		WEBMAIL_VIEW:(state,data) => {state.webmail_view = data},
		EMPLOYEE_EMAILS:(state,data) => {state.employee_emails = data},
		EMPLOYEE_EMAIL_DIALOG:(state,data) => {state.employee_email_dialog = data},
		EMPLOYEE_DATA_WITH_EMAILS:(state,data) => {state.employee_data_with_emails = data},
	},
}
