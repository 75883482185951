import repository from "@/api/modules/Operations/WarehouseCalendar";

export default {
    state: {
        // export_pending_so_list_report: []
    },
    getters: {
        // GET_PENDING_SO_LIST_REPORT: (state) => state.export_pending_so_list_report
    },
    actions: {
        postCalendar({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.calendarpost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        putCalendar({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.calendarput(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteCalendar({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.calendardelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCalendar({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.calendarget(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        }
    },
    mutations: {
        // PENDING_SO_LIST_REPORT: (state, data) => { (state.export_pending_so_list_report = data) }
    }

}
