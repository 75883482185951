<template>
    <v-container>
        <ComponentHeader :button="true" :title="'Repair Form Count per Customer/Warranty/Technician'" :type="'Service'"></ComponentHeader>
        <v-card class="bg-bluish-gray m-1 p-1">
            <v-card-text>
                <v-row>
                    <v-col lg="10">
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-autocomplete :items="['Dealer-Stock','In-Warranty','Out-Warranty']"
                                    multiple
                                    chips
                                    outlined
                                    dense
                                    deletable-chips
                                    v-model="filters.warranty_type"
                                    background-color="white"
                                    label="Select Warranty"
                                    auto-select-first
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-autocomplete :items="GET_ITEM_SELECTION"
                                    multiple
                                    chips
                                    outlined
                                    dense
                                    deletable-chips
                                    v-model="filters.item_ids"
                                    item-value="value"
                                    item-text="text"
                                    background-color="white"
                                    label="Select Items Model"
                                    auto-select-first
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-autocomplete :items="GET_ALL_TECHNICIANS"
                                    multiple
                                    chips
                                    outlined
                                    dense
                                    deletable-chips
                                    v-model="filters.technician_id"
                                    item-value="id"
                                    item-text="name"
                                    background-color="white"
                                    label="Select Technicians"
                                    auto-select-first
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="2" xl="2" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-center' : 'justify-content-end' )">
                        <v-btn small @click="resetFields()" class="mr-1">Clear </v-btn>
                        <v-btn small @click="list(1)" class="mr-1">Export </v-btn>
                        <v-btn small @click="list()">Filter </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="12">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-radio-group v-model="filters.reportBy.selected" row >
                                    <v-radio v-for="(item,index) in filters.reportBy.selection" :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <!-- <v-row dense>
                            <v-col cols="12" sm="12" md="6" lg="3" xl="3" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-start' : 'justify-content-start' )">
                                <v-checkbox v-model="filters.end_user" label="End user" false-value="0" true-value="1" v-if="filters.reportBy.selected == 0">

                                </v-checkbox>
                            </v-col>
                        </v-row> -->
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="10">
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="2" xl="2" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-center' : 'justify-content-end' )">
                                <v-menu
                                v-model="menu.date_from"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="filters.date_from"
                                        readonly
                                        prepend-icon="mdi-calendar-clock"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filters.date_from" @input="menu.date_from = false"></v-date-picker>
                            </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="2" xl="2" :class="'d-flex ' + (['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'justify-content-center' : 'justify-content-end' )">
                                <v-menu
                                    v-model="menu.date_to"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="filters.date_to"
                                            readonly
                                            prepend-icon="mdi-calendar-clock"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.date_to" @input="menu.date_to = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col lg="2">
                                <v-checkbox v-model="filters.byRepairedDate" false-value="0" true-value="1" label="Filter date by repaired">

                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col lg="12">
                        <v-card class="my-3" v-if="dataTable.filtered">
                            <v-card-text>
                                <v-data-table
                                    :headers="dataTable.headers"
                                    :items="dataTable.items"
                                    :server-items-length="dataTable.totalItems"
                                    :options.sync="dataTable.options"
                                    dense
                                >
                                <template v-slot:item.total="{ item }">
                                    <span>{{thousandSeprator(item.total)}}</span>
                                </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dataTable.loading" width="100%" persistent>
            <v-progress-circular
                size="100"
                :width="10"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </v-dialog>
    </v-container>
</template>

<script>
import ComponentHeader from "@/views/main/layouts/Reports/ReportsHeaderComponent.vue";
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import { mapGetters } from "vuex";
import Swal from 'sweetalert2'
export default {
    mixins:[SharedFunctionsComponent],
    components:{
        ComponentHeader
    },
    data(){
        return {
            filters:{
                status:[],
                selected_status:[],
                item_ids:'',
                with_estimations:'0',
                date_from:this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                date_to:this.$dayjs().endOf('month').format('YYYY-MM-DD'),
                warranty_type:[],
                technician_id:[],
                reportBy:{
                    selected:'1',
                    selection:[
                        { label:'Customer',value:"0"},
                        { label:'Warranty',value:"1"},
                        { label:'Technician',value:"2"},
                    ]
                },
                end_user:'0',
                rawData:'0',
                byRepairedDate:'0'
            },
            menu:{
                date_from:false,
                date_to:false,
            },
            dataTable:{
                items:[],
                headers: [
                    { text:'Percent', value:'percent' },
                    { text:'Total', value:'total' },
                    { text:'Name', value:'name' },
                ],
                totals:[],
                options:{},
                totalItems:0,
                loading:false,
                filtered:false
            },
        }
    },

    mounted(){
        this.$store.dispatch('itemselection')
        this.$store.dispatch('getAllProcessingTabs')
        this.$store.dispatch('getAllTechnicians')
        this.loadDetails();
        this.$store.dispatch('getServiceCustomers')
    },
    computed:{
        ...mapGetters([
            'GET_ITEM_SELECTION',
            'GET_ALL_PROCESSING_TABS',
            'GET_SERVICE_CUSTOMERS_END_USER',
            'GET_ALL_TECHNICIANS'
        ]),
    },
    methods:{
        async list(exportToExcel = 0){

            this.dataTable.loading = true
            this.dataTable.filtered = true

            Object.assign(this.filters,{
                exportToExcel:exportToExcel
            })

            await this.$store.dispatch('serviceGet',{

                url: 'service-report-per-tech',
                ...this.filters,
                ...this.dataTable.options
            }).then(response=>{

                this.dataTable.items = response.data.data.data.map(e=>{

                    let statuses = e.statuses.map(el=>el.status)
                    e.total = 0
                    e.statuses.forEach((s,i)=>{
                        Object.assign(e,{
                            [s.status]:this.thousandSeprator(s.count)
                        })
                        e.total += parseInt(s.count)
                    })

                    e.percent = this.percent(e.total,e.total_rf)
                    this.GET_ALL_PROCESSING_TABS.filter(el=> !statuses.includes(el)).forEach((s,i)=>{
                        Object.assign(e,{
                            [s]:0
                        })
                    })
                    return e
                })

                if(exportToExcel == 1){

                    const sheetName = 'Repair-Form-Per-Item-Report'
                    let exportHeadersAndData = {
                        [sheetName]: {
                            headers: this.dataTable.headers.map( e=>{
                                e.header = e.text
                                e.key = e.value
                                return e;
                            }),
                            data: this.dataTable.items
                        }
                    }
                    this.exportExcel(exportHeadersAndData, `Rf-count${this.$dayjs().format('YYYY-MM-DD')}` );
                    this.dataTable.items = []
                }else{
                    this.dataTable.totalItems = response.data.data.total
                }

                this.dataTable.loading = false
                this.filters.selected_status = []
            }).catch(err=>{
                console.log(err)
                Swal.fire('Error!','-','error')
                this.dataTable.loading = false
            })
        },
        loadDetails(){
            this.$store.dispatch('serviceGet',{
                url:'service-report-monitoring'
            }).then(response=>{

            })
        },
        resetFields(){
            Object.assign(this.$data, this.$options.data.call(this));
        }
    },
    watch:{
        'GET_ALL_PROCESSING_TABS':{
            handler(val){
                val.forEach(e=>{
                    if(e !='TA'){
                        this.dataTable.headers.splice(0,0,{text:e,sortable:false,align:'right',value:e})
                    }
                })
                this.dataTable.headers = this.dataTable.headers.reverse()
            }
        },
        'dataTable.options':{
            handler(val){
                this.list()
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    ::v-deep .v-dialog{
        box-shadow: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
</style>
