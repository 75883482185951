import repository from '@/api/modules/Accounting/CreditBill/creditBill.js'
export default {
    state: {
        credit_bill_dispatch: {},
        credit_bill_status: {},
        new_credit_bill: false,
        edit_credit_bill: {},
        credit_bill_items: [],
    },
    getters: {
        GET_CREDIT_BILL_STATUS: (state) => state.credit_bill_status,
        GET_EDIT_CREDIT_BILL: (state) => state.edit_credit_bill,
        GET_NEW_CREDIT_BILL: (state) => state.new_credit_bill,
        GET_CREDIT_BILL_DISPATCH: (state) => state.credit_bill_dispatch,
    },
    actions: {
        creditBillIndex({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.creditBillIndex(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        creditBillStore({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.creditBillStore(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        creditBillUpdate({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.creditBillUpdate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        creditBillDestroy({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.creditBillDestroy(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        creditBillApproveAll({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.creditBillApproveAll(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        CREDIT_BILL_STATUS: (state,data) => {
            state.credit_bill_status = data
        },
        CREDIT_BILL_DISPATCH: (state,data) => {
            state.credit_bill_dispatch = data
        },
        EDIT_CREDIT_BILL: (state, data) => {
            state.edit_credit_bill = data
        },
        NEW_CREDIT_BILL:(state,data)=>{
            state.new_credit_bill = data
        },
    }
}