import api from '../../api'

export default {
    getcompany(params) {
        return api.get(`company/company`, {params})
    },
    showcompany(params) {
        return api.get(`company/company/${params}`)
    },
    addcompany(params) {
        return api.post(`company/company`, params)
    },
    updatecompany(params) {
        return api.put(`company/company/${params.id}`, params.payload)
    },
    deletecompany(params) {
        return api.delete(`company/company/${params}`)
    },
}
