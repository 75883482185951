import api from '../../api';

export default{
    getrfstatus(){
        return api.get('services/getRFStatus');
    },
    seeallchartrepairitems(params){
        return api.post('services/dashboard/seeAllChartRepairItems',params);
    },
    getalltodaysreport(){
        return api.get('services/getAllTodaysReport');
    },
    seeallrepairitems(){
        return api.post('services/dashboard/seeAllRepairItems');
    },
    getallrepairforms(params){
        return api.post('services/getAllRepairForms',params);
    },
    searchrepairform(id){
        return api.get(`services/searchRepairForm/${id}`);
    },
    getallrfestconversation(id){
        return api.get(`services/getAllRfEstConversation/${id}`);
    },
    submitconversation(params){
        return api.post(`services/submitConversation`,params);
    },
    getallrrf(params){
        return api.get(`services/getAllRrf`,{params});
    },
    getrrdetails(id){
        return api.get(`services/getRrDetails/${id}`);
    },
    successrrprint(id,params){
        return api.post(`services/successRrPrint/${id}`,params)
    },
    getallrfs(params){
        return api.post(`services/getAllRfs`,params)
    },
    async getrfdetails(id){
        return await api.get(`services/getRfDetails/${id}`);
    },
    successrfprint(id,params){
        return api.post(`services/successRfPrint/${id}`,params)
    },
    updaterepairformstatus(params){
        return api.post(`services/updateRepairFormStatus`,params)
    },
    updateprintedqrcode(params){
        return api.post(`services/updatePrintedQrcode`,params)
    },
    servicegetallcustomers(){
        return api.get(`dropdown/serviceGetAllCustomers`)
    },
    servicegetalllocations(){
        return api.get(`dropdown/get-all-locations`)
    },
    createrrandrfs(params){
        return api.post(`services/createRrAndRfs`,params)
    },
    servicegetcurrentuser(params){
        return api.get(`services/getCurrentUser`,{params})
    },
    getreportrepairforms(params){
        return api.post(`services/getReportRepairForms`,params)
    },
    servicegetenduser(){
        return api.get(`services/getEndUsers`)
    },
    servicegetitems(){
        return api.get(`dropdown/serviceGetItems`)
    },
    editrepairform(params,config){
        return api.post(`services/editRepairForm`,params,config)
    },
    servicegetallitems(){
        return api.get(`services/getAllItems`)
    },
    getrepairformfiles(id){
        return api.get(`services/getRepairFormFiles/${id}`)
    },
    getitemwithspfiles(id){
        return api.get(`services/getItemWithSpFiles/${id}`)
    },
    servicedeletefile(params){
        return api.post(`services/deleteFile`,params)
    },
    getallprocessingtabs(){
        return api.get(`services/getAllProcessingTabs`)
    },
    getallprocessrfs(params){
        return api.post(`services/getAllProcessRfs`,params)
    },
    cancelpending(params){
        return api.post(`services/cancel-pending`,params)
    },
    gettechnianrfs(params){
        return api.post(`services/getTechnianRfs`,params)
    },
    getalltechnicians(){
        return api.get(`dropdown/getAllTechnicians`,)
    },
    assigntechnician(params){
        return api.post(`services/assignTechnician`,params)
    },
    getsamepartnumber(params){
        return api.post(`services/getSamePartNumber`,params)
    },
    getrfestimation(id){
        return api.get(`services/getRfEstimation/${id}`)
    },
    searchrequesteditem(payload){
        return api.post(`services/searchRequestedItem`,payload)
    },
    searchrequestedspareparts(payload){
        return api.post(`services/searchRequestedSpareParts`,payload)
    },
    getrfpartsorderforreturn(id){
        return api.get(`services/getRfPartsOrderForReturn/${id}`)
    },
    rfestimatedisapproved(params){
        return api.post(`services/rfEstimateDisapproved`,params)
    },
    transferpartsreturn(params){
        return api.post(`services/transferPartsReturn`,params)
    },
    rfestimateapproved(params){
        return api.post(`services/rfEstimateApproved`,params)
    },
    checkrfconversation(id){
        return api.get(`services/checkRfConversation/${id}`)
    },
    servicegetwarehouses(){
        return api.get(`dropdown/serviceGetWarehouses`)
    },
    getalltechspitems(){
        return api.post(`services/getAllTechSpItems`)
    },
    servicegetwarehouseitemstocks(params){
        return api.get(`services/getWarehouseItemStocks`,{params})
    },
    updaterfestimationitems(params){
        return api.post(`services/updateRfEstimationItems`,params)
    },
    approvedestimation(params){
        return api.post(`services/approvedEstimation`,params)
    },
    estconverttososuccess(id){
        return api.get(`services/estConvertToSoSuccess/${id}`)
    },
    confirmcancelledquotation(params){
        return api.post(`services/confirmCancelledQuotation`,params)
    },
    transferitemforcm(params){
        return api.post(`services/transferItemForCm`,params)
    },
    approvedforcm(params){
        return api.post(`services/approvedForCm`,params)
    },
    disapprovedparts(params){
        return api.post(`services/disapprovedParts`,params)
    },
    approvedforreplacement(params){
        return api.post(`services/approvedForReplacement`,params)
    },
    serviceapprovedfoc(params){
        return api.post(`services/approvedFoc`,params)
    },
    rejectwarrantytype(params){
        return api.post(`services/rejectWarrantyType`,params)
    },
    confirmchangewarranty(params,config){
        return api.post(`services/confirmChangeWarranty`,params,config)
    },
    successprintsq(id){
        return api.get(`services/successPrintSq/${id}`)
    },
    getallspbymodel(params){
        return api.post(`services/getAllSpByModel`,params)
    },
    getservicewarehouses(){
        return api.get(`services/getServiceWarehouses`)
    },
    getapproveorders(id){
        return api.get(`services/getApproveOrders/${id}`)
    },
    repairedrfconfirm(params){
        return api.post(`services/repairedRfConfirm`,params)
    },
    confirmrfpreparation(params){
        return api.post(`services/confirmRfPreparation`,params)
    },
    deliveritem(params){
        return api.post(`services/deliverItem`,params)
    },
    confirmsuccessdelivery(params){
        return api.post(`services/confirmSuccessDelivery`,params)
    },
    servicegenerateso(params){
        return api.post(`services/generateSo`,params)
    },
    servicegeneratepackinglist(params){
        return api.post(`services/generatePackingList`,params)
    },
    markrfreadyfordelivery(params){
        return api.post(`services/markRfReadyForDelivery`,params)
    },
    rfreestimate(params){
        return api.post(`services/rfReEstimate`,params)
    },
    qadisapproved(params){
        return api.post(`services/qaDisapproved`,params)
    },
    servicegetsalesorderstatus(id){
        return api.get(`services/getSalesOrderStatus/${id}`)
    },
    rfdwarrantyreject(params){
        return api.post(`services/rfdWarrantyReject`,params)
    },
    rfdpartsreject(params){
        return api.post(`services/rfdPartsReject`,params)
    },
    updateplprinted(id,params){
        return api.post(`services/updatePLPrinted/${id}`,params)
    },
    getallpartsordertabs(){
        return api.get(`services/getAllPartsOrderTabs`)
    },
    getallwfprfs(id){
        return api.get(`services/getAllWfpRfs/${id}`)
    },
    getallreadyrfs(params){
        return api.post(`services/getAllReadyRfs`,params)
    },
    getrfpartsorder(id){
        return api.get(`services/getRfPartsOrder/${id}`)
    },
    confirmitemreturned(params){
        return api.post(`services/confirmItemReturned`,params)
    },
    successreturneditems(params){
        return api.post(`services/successReturnedItems`,params)
    },
    serveitemreplacement(params){
        return api.post(`services/serveItemReplacement`,params)
    },
    autoservepartsorder(params){
        return api.post(`services/autoServePartsOrder`,params)
    },
    proceedpartsorder(params){
        return api.post(`services/proceedPartsOrder`,params)
    },
    getallreadyfordeliveryrfs(params){
        return api.post(`services/getallReadyForDeliveryRfs`,params)
    },
    getalldefrfs(){
        return api.get(`services/getallDefRfs`)
    },
    getreceivingreports(id){
        return api.get(`services/getReceivingReports/${id}`)
    },
    createdeliveryreceipt(params){
        return api.post(`services/createDeliveryReceipt`,params)
    },
    getsccustomers(){
        return api.get(`services/getScCustomers`)
    },
    getdeliveryreceipt(id){
        return api.get(`services/getDeliveryReceipt/${id}`)
    },
    successdrprint(params){
        return api.post(`services/successDrPrint`,params)
    },
    deliveritems(params){
        return api.post(`services/deliverItems`,params)
    },
    confirmreceiveddelivery(params){
        return api.post(`services/confirmReceivedDelivery`,params)
    },
    deletedrrf(params){
        return api.post(`services/deleteDrRf`,params)
    },
    getcustomerreadyrfs(){
        return api.get(`services/getCustomerReadyRfs`)
    },
    getdeliveryreceipts(id){
        return api.get(`services/getDeliveryReceipts/${id}`)
    },
    deletedeliveryreceipt(params){
        return api.post(`services/deleteDeliveryReceipt`,params)
    },
    getforexportservicedelivery(params){
        return api.post(`services/getForExportServiceDelivery`,params)
    },
    gettechnician(id){
        return api.get(`services/getTechnician/${id}`)
    },
    getserviceusers(){
        return api.get(`services/getServiceUsers`)
    },
    createtechnician(params,config){
        return api.post(`services/createTechnician`,params,config)
    },
    updatetechnician(params,config){
        return api.post(`services/updateTechnician`,params,config)
    },
    gettechnicians(){
        return api.get(`services/getTechnicians`)
    },
    deletetechnician(id){
        return api.get(`services/deleteTechnician/${id}`)
    },
    getselectedtechnicianrfs(id){
        return api.get(`services/getSelectedTechnicianRfs/${id}`)
    },
    reassigntechnician(params){
        return api.post(`services/reAssigntechnician`,params)
    },
    gettechnicianprofiles(id){
        return api.get(`services/getTechnicianProfiles/${id}`)
    },
    getalltechspitemspost(params){
        return api.post(`services/getAllTechSpItems`,params)
    },
    createrfitemestimation(params,config){
        return api.post(`services/createRfItemEstimation`,params,config)
    },
    logtechreceiveditems(params){
        return api.post(`services/logTechReceivedItems`,params)
    },
    confirmitemreceived(params){
        return api.post(`services/confirmItemReceived`,params)
    },
    getfocspareparts(id){
        return api.get(`services/getFocSpareParts/${id}`)
    },
    repairdone(params){
        return api.post(`services/repairDone`,params)
    },
    startrepair(params){
        return api.post(`services/startRepair`,params)
    },
    returnspareparts(params){
        return api.post(`services/returnSpareParts`,params)
    },
    continuerepair(params){
        return api.post(`services/continueRepair`,params)
    },
    returnselectedsp(params){
        return api.post(`services/returnSelectedSp`,params)
    },
    getpartsorderitemfiles(id){
        return api.get(`services/getPartsOrderItemFiles/${id}`)
    },
    uploadsparepartimage(params,config){
        return api.post(`services/uploadSparePartImage`,params,config)
    },
    deletesparepartimage(params){
        return api.post(`services/deleteSparePartImage`,params)
    },
    getalltechrfs(params){
        return api.post(`services/getAllTechRfs`,params)
    },
    getserviceitems(params){
        return api.post(`services/getServiceItems`,params)
    },
    uploadexplodedviews(params,config){
        return api.post(`services/uploadExplodedViews`,params,config)
    },
    servicegetusers(){
        return api.get(`services/getUsers`)
    },
    updateusertech(params){
        return api.post(`services/updateUserTech`,params)
    },
    getcustomerconversations(params){
        return api.post(`services/getCustomerConversations`,params)
    },
    getallconversationsources(){
        return api.get(`services/getAllConversationSources`)
    },
    createcustomerconversation(params){
        return api.post(`services/createCustomerConversation`,params)
    },
    getcustomerconversationid(id){
        return api.get(`services/getCustomerConversation/${id}`)
    },
    getallcustomermessages(id){
        return api.get(`services/getAllCustomerMessages/${id}`)
    },
    escalatecustomerconversation(params){
        return api.post(`services/escalateCustomerConversation`,params)
    },
    finishcustomerconversation(params){
        return api.post(`services/finishCustomerConversation`,params)
    },
    submitcustomermessages(params){
        return api.post(`services/submitCustomerMessages`,params)
    },
    deletelocation(params){
        return api.post(`services/delete-location`,params)
    },
    getlocation(id){
        return api.get(`services/get-location/${id}`)
    },
    createlocation(params){
        return api.post(`services/create-location`,params)
    },
    updatelocation(params){
        return api.post(`services/update-location`,params)
    },
    getallbaklas(params){
        return api.post(`services/getAllBaklas`,params)
    },
    getallbaklasitems(){
        return api.get(`services/getAllBaklasItems`)
    },
    getbaklasitemspareparts(id){
        return api.get(`services/getBaklasItemSpareParts/${id}`)
    },
    getreportspareparts(){
        return api.get(`services/getReportSpareParts`)
    },
    getforchinaclaimingitems(){
        return api.get(`services/getForChinaClaimingItems`)
    },
    deletebaklas(params){
        return api.post(`services/deleteBaklas`,params)
    },
    editbaklas(params){
        return api.post(`services/editBaklas`,params)
    },
    getallitemwarehousestocks(params){
        return api.post(`services/getAllItemWarehouseStocks`,params)
    },
    setusedparts(params){
        return api.post(`services/setUsedParts`,params)
    },
    transferbaklasspareparts(params){
        return api.post(`services/transferBaklasSpareParts`,params)
    },
    createbaklasitems(params){
        return api.post(`services/createBaklasItems`,params)
    },
    getallsparepartsofitem(params){
        return api.post(`services/getAllSparePartsOfItem`,params)
    },
    async serviceGet(params){
        return await api.get('services/'+params.url,{params})
    },
    servicePost(params){
        return api.post('services/'+params.url,params)
    },
    // get all unassigned spare parts
    getServicesItemsNullParent(params){
        return api.get('services/getServicesItemsNullParent',{params})
    },
    getAllItemSpareParts(params){
        return api.get('services/getAllItemSpareParts',{params})
    },
    getAllItemsExcludeSp(params){
        return api.get('services/getAllItemsExcludeSp',{params})
    },
    sparePartsAssign(params){
        return api.post('services/sparePartsAssign',params)
    },
    unassignspareparts(params){
        return api.get('services/unassignspareparts',{params})
    },
    getItemSpareParts(params){
        return api.get('services/getItemSpareParts',{params})
    },
    getItemTypeExcludeSp(){
        return api.get('services/getItemTypeExcludeSp',{ params: { types: [1, 2] } })
    },
    printPdfTechnicalReport(params){
        return api.get('services/printPdfTechnicalReport',{params});
    },
    getBaklasWarehouse(params){
        return api.get('services/getBaklasWarehouse',{params});
    },
    getWarehouseItemStocksBaklas(params){
        return api.get('services/getWarehouseItemStocksBaklas',{params});
    },
    getItemWithSpareParts(params){
        return api.get('services/getItemWithSpareParts',{params});
    },
    getAllSparePartsOfItem(params){
        return api.post('services/getAllSparePartsOfItem',params);
    },
    createBaklas(params){
        return api.post('services/createBaklas',params);
    },
    BaklasAddNewUnit(params){
        return api.post('services/baklas-add-new-unit',params);
    },
    getAllBaklasAddNewUnit(params){
        return api.get('services/baklas-get-all',{params});
    },
    viewAllItemsBaklasNewUnit(params){
        return api.get('services/get-all-new-unit',{params});
    },
    approvedBaklasNewUnit(params){
        return api.get('services/approved-new-unit',{params});
    },
    deleteBaklasNewUnit(params){
        return api.delete(`services/delete-new-unit/${params}`);
    },
    updateBaklasNewUnit(params){
        return api.put('services/update-new-unit', params);
    },
    validateModel(params){
        return api.get('services/baklas-item-details', {params});
    },
    itemsGet(params){
        return api.get(`/items/${params.url}`,{params})
    },
    itemsPost(params){
        return api.post(`/items/${params.url}`,params)
    },
    addchecklist(params){
        return api.post('services/addchecklist',params);
    },
    // addhrManpowerRequest(params){
    //     return api.post('/hr/hrmanpowerRequest',params);
    // },
    // getQaCheckListDelete(params){
    //     console.log(params)
    //     return api.post(`services/qa-check-list-delete`,params)
    // },
    // updateQaCheckList(params){
    //     return api.post(`services/qa-check-list-update`,params)
    // },
    uploadWarranty(params,config){
        return api.post(`services/uploadWarranty`,params,config);
    },

    approvedwa(payload){
        return api.post(`services/approvedwa`,payload)
    },

    cancelChangeWarranty(payload){
        return api.post(`services/cancelChangeWarranty`,payload)
    },

    getAutoFocSp(params){
        return api.get('services/getAutoFocSp',{params});
    },
    updateAutoFoc(payload){
        return api.post(`services/updateAutoFoc`,payload)
    },
    deleteAutoFoc(payload){
        return api.post(`services/deleteAutoFoc`,payload)
    },
    getDefective(params){
        return api.get('services/getDefective',{params});
    },
    approveDTA(payload){
        return api.post(`services/approveDTA`,payload)
    },
    reAssessmentDTA(params){
        return api.post(`services/reAssessmentDTA`,params)
    },
    pullOutDTA(payload){
        return api.post(`services/pullOutDTA`,payload)
    },
    confirmDCA(payload){
        return api.post(`services/confirmDCA`,payload)
    },
    confirmRepair(payload){
        return api.post(`services/confirmRepair`,payload)
    },
    getSparePartParents(params){
        return api.get('services/getSparePartParents', {params});
    },
    getAllSparePartItems(params) {
        return api.get('services/getAllSparePartItems', {params});
    },
    deleteSparePart(params) {
        return api.delete(`services/deleteSparePart`, {params});
    },
    successPrint(params) {
        return api.post(`services/successPrint`,params);
    },
    getScPartsOrder(params)
    {
        return api.get(`services/getScPartsOrder`, {params});
    },
    replaceSelectedSp(params){
        return api.post(`services/replaceSelectedSp`,params)
    },
    // currentUser(){
    //     return api.get('masterdata/current-user');
    // },
    submitReasons(params){
        return api.post(`services/submitReasons`,params)
    },
    
    readyForQaTester(params){
        return api.post(`services/readyForQaTester`,params)
    },
    updateRepairForm(params){
        return api.post(`services/updateRepairForm`, params);
    },
    submitSpLocation(params){
        return api.post(`services/submitSpLocation`,params)
    },
    dataPrintTechnical(params){
        return api.post(`services/dataPrintTechnical`,params)
    },
    submitBatchLocation(params){
        return api.post(`services/submitBatchLocation`,params)
    },
    serviceGetAllLocationWarehouses(){
        return api.get(`dropdown/get-location-warehouses`)
    },
    updateRepairStatus(params){
        return api.post(`services/updateRepairStatus`,params)
    },
    submitFollowUpRemarks(params){
        return api.post(`services/submitFollowUpRemarks`,params)
    },
    getSqFiles(id) {
        return api.get(`services/getSqFiles/${id}`);
    },
    sqDeleteFile(params) {
        return api.post('services/sqDeleteFile', params);
    },
    disposalGet(params){
        return api.get(`/services/${params.url}`,{params})
    },
    disposalPost(params){
        return api.post(`/services/${params.url}`,params)
    },
    getdisposalsp(params) {
        return api.get('services/disposals-sp', {params});
    },
    getcontainerssp() {
        return api.get('services/containers-sp');
    },
    getallactiveitems() {
        return api.get('services/all-items');
    },
    getwarehouseslists() {
        return api.get('services/warehouse-lists');
    },
    uploaddisposalfile(params, config) {
        return api.post('services/disposal-file',params,config);
    },
    disposalDestroy(params) {
        return api.delete(`/services/${params.url}`,{params})
    },
    servicePut(params) {
        return api.put('services/'+params.url,params)
    },
    serviceDelete(params) {
        return api.delete(`/services/${params.url}`,{params})
    },
    uploadscreturnreceiptfile(params, config) {
        return api.post('services/sc-return-receipt-files',params,config);
    },
    getSparePartAssembly(params){
        return api.get('services/getSparePartAssembly', {params});
    },
    deleteSparePartAssembly(params) {
        return api.delete(`services/deleteSparePartAssembly`, {params});
    },
}

