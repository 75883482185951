import repository from '@/api/modules/Dashboard'

export default {
    state: {
        module_list: [],
        user_dashboards: [],
    },
    getters: {
        GET_ALL_DASHBOARD_MODULES: state => state.module_list,
        GET_ALL_USER_DASHBOARDS: state => state.user_dashboards,
    },
    actions: {
        getAllDashboardModules({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getalldashboardmodules(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DASHBOARD_MODULES', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getAllUserDashboards({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getalluserdashboards(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('USER_DASHBOARDS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateUserDashboardLayout({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.updateuserdashboardlayout(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        // commit('USER_DASHBOARDS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        updateUserDashboardHeight({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.updateuserdashboardheight(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        // commit('USER_DASHBOARDS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mutations: {
        DASHBOARD_MODULES: (state, data) => {
            state.module_list = data
        },
        USER_DASHBOARDS: (state, data) => {
            state.user_dashboards = data
        },
    }
}
