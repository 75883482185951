import api from '../../api';
export default{
    getCustomerSalesTarget(){
        return api.get('masterdata/getCustomerSalesTarget');
    },
    getNewItems(params){
        return api.get(`reports/getNewItems`,{params})
    },
    getCustomerClassificationSalesTarget(){
        return api.get('masterdata/getCustomerClassificationSalesTarget');
    },
    getConceptStoreSalesTarget(){
        return api.get('masterdata/getConceptStoreSalesTarget');
    },
    list(params){
        return api.post('dms/salesman/report/list',{params});
    }
}