import repository from '@/api/modules/MasterData/index'

export default {
    state: {
        store_branches_dispatch: {},
        new_store_branches_table: false,
        edit_store_branches: {},
        // store_branches_code: [],
    },
    getters: {
        GET_STORE_BRANCHES_DISPATCH: (state) => state.store_branches_dispatch,
        GET_NEW_STORE_BRANCHES_TABLE: (state) => state.new_store_branches_table,
        GET_EDIT_STORE_BRANCHES: (state) => state.edit_store_branches,
        // GET_ADM_WAREHOUSE_CODE:(state) => state.store_branches_code,
    },
    actions: {
        CreateStoreBranches({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                repository.CreateStoreBranches(payload).then((response) => {
                    resolve(response);
                    commit('NEW_STORE_BRANCHES_TABLE', true)
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                })
            })
        },
        getAllStoreBranches({ commit },payload) {
            return new Promise((resolve, reject) => {
                repository.getAllStoreBranches(payload).then((response) => {
                    resolve(response.data);
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                })
            })
        },
        // getAdminWarehouseCode({ commit },payload) {
        //         return new Promise((resolve, reject) => {
        //                 repository.getAdminWarehouseCode(payload).then((response) => {
        //                         commit('ADM_WAREHOUSE_CODE', response.data);
        //                 }).catch((error) => {
        //                         console.log(error);
        //                         reject(error);
        //                 })
        //         })
        // },
        UpdateStoreBranches({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                repository.UpdateStoreBranches(payload).then((response) => {
                    resolve(response);
                    commit('NEW_ADM_WAREHOUSE_TABLE', true)
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                })
            })
        },
        DeleteStoreBranches({ commit, state }, payload) {
            return new Promise((resolve, reject) => {
                repository.DeleteStoreBranches(payload).then((response) => {
                    commit('NEW_ADM_WAREHOUSE_TABLE', true)
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                })
            })
        },
    },
    mutations: {
        STORE_BRANCHES_DISPATCH: (state, data) => {
            state.store_branches_dispatch = data;
        },
        NEW_STORE_BRANCHES_TABLE: (state, data) => {
            state.new_store_branches_table = data
        },
        EDIT_STORE_BRANCHES: (state, data) => {
            state.edit_store_branches = data
        },
        // ADM_WAREHOUSE_CODE: (state, data) => {
        //     state.adm_warehouse_code = data;
        // },
    },
}
