import repository from '@/api/credentials';

const routes = [
    {
        path: '/hr/hrOvertime/:id',
        name: 'hrovertime',
        component: () => import('@/views/main/modules/HR/Overtime/HROvertimeGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'HR Overtime',
        }
    },
];
export default routes;