import store from '../../../../store';
import api from '../../../api'

export default {
    // Status
    addemployeedatastatus(params){
        return api.post('/system-settings/statuses',params);
    },
    getemployeedatastatus(){
        return api.get('/system-settings/statuses');
    },
    updateemployeedatastatus(params){
        return api.put(`/system-settings/statuses/${params.id}`,params.payload);
    },
    deleteemployeedatastatus(params){
        return api.delete(`/system-settings/statuses/${params}`);
    },
    // Job Title
    addemployeejobtitle(params){
        return api.post('/hr/jobtitles',params);
    },
    getemployeejobtitle(params){
        return api.get('/hr/jobtitles',{params});
    },
    updateemployeejobtitle(params){
        return api.put(`/hr/jobtitles/${params.id}`,params.payload);
    },
    deleteemployeejobtitle(params){
        return api.delete(`/hr/jobtitles/${params}`);
    },
    showemployeejobtitle(params){
        return api.get(`/hr/jobtitles/${params}`);
    },
    // Religion
    addemployeereligion(params){
        return api.post('/hr/hremployeereligion',params);
    },
    getemployeereligion(){
        return api.get('/hr/hremployeereligion');
    },
    updateemployeereligion(params){
        return api.put(`/hr/hremployeereligion/${params.id}`,params.payload);
    },
    deleteemployeereligion(params){
        return api.delete(`/hr/hremployeereligion/${params}`);
    },
    // Marital Status
    addemployeemaritalstatus(params){
        return api.post('/hr/hremployeemaritalstatus',params);
    },
    getemployeemaritalstatus(){
        return api.get('/hr/hremployeemaritalstatus');
    },
    updateemployeemaritalstatus(params){
        return api.put(`/hr/hremployeemaritalstatus/${params.id}`,params.payload);
    },
    deleteemployeemaritalstatus(params){
        return api.delete(`/hr/hremployeemaritalstatus/${params}`);
    },
    // Citizenship
    addemployeecitizenship(params){
        return api.post('/hr/hremployeecitizenship',params);
    },
    getemployeecitizenship(){
        return api.get('/hr/hremployeecitizenship');
    },
    updateemployeecitizenship(params){
        return api.put(`/hr/hremployeecitizenship/${params.id}`,params.payload);
    },
    deleteemployeecitizenship(params){
        return api.delete(`/hr/hremployeecitizenship/${params}`);
    },
    // Contract Type
    addemployeecontractType(params){
        return api.post('/hr/hremployeecontracttype',params);
    },
    getemployeecontractType(){
        return api.get('/hr/hremployeecontracttype');
    },
    updateemployeecontractType(params){
        return api.put(`/hr/hremployeecontracttype/${params.id}`,params.payload);
    },
    deleteemployeecontractType(params){
        return api.delete(`/hr/hremployeecontracttype/${params}`);
    },
    // Job Level
    addjoblevel(params){
        return api.post('/hr/hremployeejoblevel',params);
    },
    getjoblevel(){
        return api.get('/hr/hremployeejoblevel');
    },
    updatejoblevel(params){
        return api.put(`/hr/hremployeejoblevel/${params.id}`,params.payload);
    },
    deletejoblevel(params){
        return api.delete(`/hr/hremployeejoblevel/${params}`);
    },

    // Employee Skills
    getemployeeskills(params){
        return api.get('/hr/getEmployeeSkills',{params});
    },
    addemployeeskills(params){
        return api.post('/hr/addEmployeeSkills',params);
    },
    updateemployeeskills(params){
        return api.post(`/hr/updateEmployeeSkills`,params);
    },
    deleteemployeeskills(params){
        return api.delete(`/hr/deleteEmployeeSkills/${params}`);
    },

    // Employment History
    addEmploymentHistory(params){
        return api.post('/hr/hremploymenthistory',params);
    },
    getEmploymentHistory(params){
        return api.get('/hr/hremploymenthistory',{params});
    },
    updateEmploymentHistory(params){
        return api.put(`/hr/hremploymenthistory/${params.id}`,params.payload);
    },
    deleteEmploymentHistory(params){
        return api.delete(`/hr/hremploymenthistory/${params}`);
    },
    showEmploymentHistory(params){
        return api.get(`/hr/hremploymenthistory/${params}`);
    },

    // Employee Movement
    addEmployeeMovement(params){
        return api.post('/hr/hremployeemovement',params);
    },
    getEmployeeMovement(params){
        return api.get('/hr/hremployeemovement',{params});
    },
    updateEmployeeMovement(params){
        return api.put(`/hr/hremployeemovement/${params.id}`,params);
    },
    deleteEmployeeMovement(params){
        return api.delete(`/hr/hremployeemovement/${params}`);
    },
    showEmployeeMovement(params){
        return api.get(`/hr/hremployeemovement/${params}`);
    },
    approveEmployeeMovement(params){
        return api.post(`/hr/approveEmployeeMovement`,params.payload);
    },
    getMovementTableSelection(params){
        return api.post(`/hr/getMovementTableSelection`,params);
    },
    getCurrentActiveMovement(params){
        return api.get(`/hr/getCurrentActiveMovement`,{params});
    },

    // Employee Store Branch
    addstorebranch(params){
        return api.post('/hr/storebranches',params);
    },
    getstorebranch(params){
        return api.get('/hr/storebranches',{params});
    },
    updatestorebranch(params){
        return api.put(`/hr/storebranches/${params.id}`,params.payload);
    },
    deletestorebranch(params){
        return api.delete(`/hr/storebranches/${params}`);
    },
    // Holidays
    addholidays(params){
        return api.post('/hr/holidays',params);
    },
    getholidays(params){
        return api.get('/hr/holidays',{params});
    },
    showholidays(params){
        return api.get(`/hr/holidays/${params.id}`,{params});
    },
    updateholidays(params){
        return api.put(`/hr/holidays/${params.id}`,params.payload);
    },
    deleteholidays(params){
        return api.delete(`/hr/holidays/${params}`);
    },
}
