import repository from '@/api/credentials'

const routes = [{
    path: '/masterdata/users-list/:id',
    name: 'masterdatausers',
    props: true,
    component: () => import('@/views/main/modules/MasterData/UsersComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Users',
    },
},
{
    path: '/masterdata/department/:id',
    name: 'masterdatadepartments',
    component: () => import('@/views/main/modules/MasterData/DepartmentsComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Departments',
    },
},
{
    path: '/masterdata/department-access/:id',
    name: 'masterdatadepartmentaccess',
    component: () => import('@/views/main/modules/MasterData/DepartmentAccessComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Department Access',
    },
},
{
    path: '/masterdata/roles/:id',
    name: 'masterdataroles',
    component: () => import('@/views/main/modules/MasterData/RolesComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })

    },
    meta: {
        requiresAuth: true,
        title: 'Roles',
    },
},
{
    path: '/masterdata/vehicles/:id',
    name: 'masterdatavehicles',
    component: () => import('@/views/main/modules/MasterData/VehiclesGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Vehicles',
    },
},
{
    path: '/masterdata/warehouse/:id',
    name: 'masterdatawarehouse',
    component: () => import('@/views/main/modules/MasterData/WarehouseComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Warehouses',
    },
},
{
    path: '/masterdata/salesman/:id',
    name: 'masterdatasalesman',
    component: () => import('@/views/main/modules/MasterData/SalesmanComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Salesman',
    },
},
{
    path: '/masterdata/customer/:id',
    name: 'masterdatacustomer',
    component: () => import('@/views/main/modules/MasterData/CustomerComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Customers',
    },
},
{
    path: '/masterdata/vendor/:id',
    name: 'masterdatavendor',
    component: () => import('@/views/main/modules/MasterData/VendorComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Vendors',
    },
},
{
    path: '/masterdata/companies/:id',
    name: 'masterdatacompanies',
    component: () => import('@/views/main/modules/MasterData/CompaniesComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Companies',
    },
},
 {
     path: '/masterdata/cluster/:id',
    name: 'masterdatacluster',
    component: () => import('@/views/main/modules/MasterData/ClusterComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Clusters',
    },
},
{
    path: '/masterdata/store-branches/:id',
    name: 'masterdatastorebranches',
    component: () => import('@/views/main/modules/MasterData/StoreBranches/StoreBranchesGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Store Branches',
    },
},
{
    path: '/masterdata/vat-type/:id',
    name: 'masterdatavattype',
    component: () => import('@/views/main/modules/MasterData/VatTypeComponent.vue'),
    beforeEnter: (to, from, next) => {
       repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
       }).catch(() => {})
    },
    meta: {
        requiresAuth: true,
        title: 'Vat Types',
    },
},
{
    path: '/masterdata/approvers/:id',
    name: 'approvers',
    component: () => import('@/views/main/modules/MasterData/Approvers/ApproversGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
       repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
       }).catch(() => {})
    },
    meta: {
        requiresAuth: true,
        title: 'Approvers',
    },
},
{
    path: '/masterdata/report-module/:id',
    name: 'masterdataReportModule',
    component: () => import('@/views/main/modules/MasterData/ReportModuleComponent.vue'),
    beforeEnter: (to, from, next) => {
    repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Report Module',
    },
},
{
    path: '/masterdata/accounting-ap-settings/:id',
    name: 'apsettings',
    component: () => import('@/views/main/modules/MasterData/Settings/AccountingAP/AccountsPayableGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
       repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'AP Settings',
    },
},
];

export default routes;
