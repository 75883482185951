import repository from "@/api/modules/Sales/Items";

export default {
    state: {
        all_items: [],
        item_categories: [],
        items: [],
        item_types: [],
        item_uom: [],
        item_brand: [],
        item_size: [],
        all_bundles: [],
        item_edit_info: [],
        store_item: [],
        store_import_item: [],
        update_import_item: [],
        store_import_image: [],
        show_item: [],
        update_selected_item: [],
        destroy_item: [],
        store_item_bundle: [],
        item_bundle: [],
        update_item_bundle: [],
        destroy_item_bundle: [],
        all_item_types: [],
        store_item_types: [],
        show_item_types: [],
        update_item_types: [],
        destroy_item_types: [],
        all_item_categories: [],
        store_item_categories: [],
        show_item_categories: [],
        update_item_categories: [],
        destroy_item_categories: [],
        all_item_uom: [],
        store_item_uom: [],
        show_item_uom: [],
        update_item_uom: [],
        destroy_item_uom: [],
        all_item_brand: [],
        store_item_brand: [],
        show_item_brand: [],
        update_item_brand: [],
        destroy_item_brand: [],
        all_item_sizes: [],
        store_item_sizes: [],
        show_item_sizes: [],
        update_item_sizes: [],
        destroy_item_sizes: [],
        warehouse_item_stocks: [],
        post_warehouse_item_stocks: [],
        item_substitution: [],
        exportItemModal: false,
        importItemModal: false,
        export_item_master_to_excel: [],
        item_template: [],
        item_template_selection: [],
        item_template_details: [],
        test: '',
        item_exclusive_by_item: [],
        is_sp:false,
        parts_compatibility: [],
    },
    getters: {
        GET_ALL_ITEMS: (state) => state.all_items,
        GET_ITEM_CATEGORIES: (state) => state.item_categories,
        GET_ITEMS: (state) => state.items,
        GET_ITEM_TYPES: (state) => state.item_types,
        GET_ITEM_UOM: (state) => state.item_uom,
        GET_ITEM_BRAND: (state) => state.item_brand,
        GET_ITEM_SIZE: (state) => state.item_size,
        GET_ALL_BUNDLES: (state) => state.all_bundles,
        GET_ITEM_EDIT_INFO: (state) => state.item_edit_info,
        GET_STORE_ITEM: (state) => state.store_item,
        GET_STORE_IMPORT_ITEM: (state) => state.store_import_item,
        GET_STORE_IMPORT_IMAGE: (state) => state.store_import_image,
        GET_UPDATE_IMPORT_ITEM: (state) => state.update_import_item,
        GET_SHOW_ITEM: (state) => state.show_item,
        GET_UPDATE_SELECTED_ITEM: (state) => state.update_selected_item,
        GET_DESTROY_ITEM: (state) => state.destroy_item,
        GET_STORE_ITEM_BUNDLE: (state) => state.store_item_bundle,
        GET_ITEM_BUNDLE: (state) => state.item_bundle,
        GET_UPDATE_ITEM_BUNDLE: (state) => state.update_item_bundle,
        GET_DESTROY_ITEM_BUNDLE: (state) => state.destroy_item_bundle,
        GET_ALL_ITEM_TYPES: (state) => state.all_item_types,
        GET_STORE_ITEM_TYPES: (state) => state.store_item_types,
        GET_SHOW_ITEM_TYPES: (state) => state.show_item_types,
        GET_UPDATE_ITEM_TYPES: (state) => state.update_item_types,
        GET_DESTROY_ITEM_TYPES: (state) => state.destroy_item_types,
        GET_ALL_ITEM_CATEGORIES: (state) => state.all_item_categories,
        GET_STORE_ITEM_CATEGORIES: (state) => state.store_item_categories,
        GET_SHOW_ITEM_CATEGORIES: (state) => state.show_item_categories,
        GET_UPDATE_ITEM_CATEGORIES: (state) => state.update_item_categories,
        GET_DESTROY_ITEM_CATEGORIES: (state) => state.destroy_item_categories,
        GET_ALL_ITEM_UOM: (state) => state.all_item_uom,
        GET_STORE_ITEM_UOM: (state) => state.store_item_uom,
        GET_SHOW_ITEM_UOM: (state) => state.show_item_uom,
        GET_UPDATE_ITEM_UOM: (state) => state.update_item_uom,
        GET_DESTROY_ITEM_UOM: (state) => state.destroy_item_uom,
        GET_ALL_ITEM_BRAND: (state) => state.all_item_brand,
        GET_STORE_ITEM_BRAND: (state) => state.store_item_brand,
        GET_SHOW_ITEM_BRAND: (state) => state.show_item_brand,
        GET_UPDATE_ITEM_BRAND: (state) => state.update_item_brand,
        GET_DESTROY_ITEM_BRAND: (state) => state.destroy_item_brand,
        GET_ALL_ITEM_SIZES: (state) => state.all_item_sizes,
        GET_STORE_ITEM_SIZES: (state) => state.store_item_sizes,
        GET_SHOW_ITEM_SIZES: (state) => state.show_item_sizes,
        GET_UPDATE_ITEM_SIZES: (state) => state.update_item_sizes,
        GET_DESTROY_ITEM_SIZES: (state) => state.destroy_item_sizes,
        GET_WAREHOUSE_ITEM_STOCK: (state) => state.warehouse_item_stocks,
        GET_POST_WAREHOUSE_ITEM_STOCK: (state) => state.post_warehouse_item_stocks,
        GET_ITEM_SUBSTITUTION: (state) => state.item_substitution,
        GET_EXPORT_ITEM_MASTER_TO_EXCEL: (state) => state.export_item_master_to_excel,
        GET_ITEM_TEMPLATE: (state) => state.item_template,
        GET_ITEM_TEMPLATE_SELECTION: (state) => state.item_template_selection,
        GET_ITEM_TEMPLATE_DETAILS: (state) => state.item_template_details,
        GET_SAMPLE: (state) => state.test,
        GET_ITEM_EXCLUSIVE_BY_ITEM: (state) => state.item_exclusive_by_item,
        GET_PARTS_COMPATIBILITY: (state) => state.parts_compatibility,
    },
    actions: {
        getAllItems({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getallitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_ITEMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemCategories({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getitemcategories().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_CATEGORIES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItems({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitems(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEMS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemTypes({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getitemtypes().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_TYPES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemUom({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getitemuom().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_UOM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemBrand({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getitembrand().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_BRAND', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemSize({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getitemsize().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_SIZE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllBundles({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getallbundles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_BUNDLES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemEditInfo({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitemeditinfo(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_EDIT_INFO', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeItem({ commit }, payload, config) {
            return new Promise((resolve, reject) => {
                repository.storeitem(payload, config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeImportItem({ commit }, payload, config) {
            return new Promise((resolve, reject) => {
                repository.storeimportitem(payload, config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_IMPORT_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeImportImage({ commit }, payload, config) {
            return new Promise((resolve, reject) => {
                repository.storeimportimage(payload, config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_IMPORT_IMAGE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeImportImageExcel({ commit }, payload, config) {
            return new Promise((resolve, reject) => {
                repository.storeimportimageexcel(payload, config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_IMPORT_IMAGE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateImportItem({ commit }, payload, config) {
            return new Promise((resolve, reject) => {
                repository.updateImportItem(payload, config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_IMPORT_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showItem({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.showitem(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateSelectedItem({ commit }, payload, config) {
            return new Promise((resolve, reject) => {
                repository.updateselecteditem(payload, config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_SELECTED_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItem({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitem(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DESTROY_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeItemBundle({ commit }, payload, config) {
            return new Promise((resolve, reject) => {
                repository.storeitembundle(payload, config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_ITEM_BUNDLE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemBundle({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitembundle(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_BUNDLE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateItemBundle({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.updateitembundle(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_ITEM_BUNDLE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItemBundle({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.destroyitembundle(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DESTROY_ITEM_BUNDLE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllItemTypes({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getallitemtypes().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_ITEM_TYPES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeItemTypes({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.storeitemtypes(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_ITEM_TYPES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showItemTypes({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.showitemtypes(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_ITEM_TYPES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateItemTypes({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.updateitemtypes(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_ITEM_TYPES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItemType({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitemtype(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DESTROY_ITEM_TYPE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllItemCategories({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getallitemcategories().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_ITEM_CATEGORIES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeItemCategories({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.storeitemcategories(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_ITEM_CATEGORIES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showItemCategories({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.showitemcategories(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_ITEM_CATEGORIES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateItemCategories({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.updateitemcategories(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_ITEM_CATEGORIES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItemCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitemcategories(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DESTROY_ITEM_CATEGORIES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllItemUom({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getallitemuom().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_ITEM_UOM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeItemUom({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.storeitemuom(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_ITEM_UOM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showItemUom({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.showitemuom(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_ITEM_UOM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateItemUom({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.updateitemuom(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_ITEM_UOM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItemUom({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitemuom(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DESTROY_ITEM_UOM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllItemBrand({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getallitembrand().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_ITEM_BRAND', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeItemBrand({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.storeitembrand(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_ITEM_BRAND', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showItemBrand({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.showitembrand(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_ITEM_BRAND', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateItemBrand({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.updateitembrand(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_ITEM_BRAND', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItemBrand({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitembrand(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DESTROY_ITEM_BRAND', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllItemSizes({ commit }) {
            return new Promise((resolve, reject) => {
                repository.getallitemsizes().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_ITEM_SIZES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeItemSizes({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.storeitemsizes(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_ITEM_SIZES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        showItemSizes({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.showitemsizes(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SHOW_ITEM_SIZES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateItemSizes({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.updateitemsizes(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('UPDATE_ITEM_SIZES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItemSizes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitemsizes(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('DESTROY_ITEM_SIZES', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllWarehouseItemStocks({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitemwarehousestock(payload.id, payload.from,payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_WAREHOUSE_ITEM_STOCKS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postWarehouseItemStocks({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.postwarehouseitemstocks(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('POST_WAREHOUSE_ITEM_STOCK', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemSubstitution({ commit }, params) {
            return new Promise((resolve, reject) => {
                repository.getitemsubstitutions(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_SUBSTITUTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        exportItemMasterToExcel({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.exportitemmastertoexcel(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EXP_ITEM_MASTER', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemTemplate({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitemtemplate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_TEMPLATE', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        itemTemplateSelection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.itemtemplateselection(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_TEMPLATE_SELECTION', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        itemTemplateCanEdit({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.itemtemplatecanedit(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        itemTemplateUpdate({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.itemtemplateupdate(payload.id, payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        postItemTemplate({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.postitemtemplate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        destroyItemTemplate({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.destroyitemtemplate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        itemTemplateDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.itemtemplatedetails(payload.id, payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_TEMPLATE_DETAILS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        itemTemplateClearDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.itemtemplatecleardetails(payload.id, payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getBundledItems({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getbundleditems(payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error);
                })
            })
        },
        confirmOverrideAmounts({ commit }, payload) {
            let params = {
                departments_allowed: payload.departments_allowed,
                override_username: payload.override_username,
                override_user_password: payload.override_user_password,
            }
            return new Promise((resolve, reject) => {
                repository.confirmoverrideamounts(params).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject(e);
                })
            });
        },
        getItemEditInfoshared({ commit }, payload) {
            let params = {
                promo_id: payload.promo_id,
                customer_id: payload.customer_id,
                model_id: payload.model_id
            }
            return new Promise((resolve, reject) => {
                repository.getItemEditInfoshared(params).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject(e);
                })
            })
        },
        getitemsmodelid({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitemsmodelid(payload).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject(e);
                })
            })
        },
        getNewerModel({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getnewermodel(payload).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject(e);
                })
            })
        },
        getItemId({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitemid(payload).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject(e);
                })
            })
        },
        TotalStocks({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.totalstocks(payload).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject(e);
                })
            })
        },
        getSubstitutionItemModel({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getSubstitutionItemModel(payload).then((response) => {
                    resolve(response)
                }).catch((e) => {
                    reject(e);
                })
            })
        },
        getItemExclusiveByItem({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getItemExclusiveByItem(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEM_EXCLUSIVE_BY_ITEM', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        itemsPost({commit},payload){
            return new Promise((resolve, reject) => {
                repository.itemsPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPartsCompatibility({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.getpartscompatibility(payload).then( response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PARTS_COMPATIBILITY', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        checkItemSpareParts({commit}, payload) {
            return new Promise((resolve, reject) => {
                repository.checkitemspareparts(payload).then( response => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        exportItemMasterToPDF({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.exportitemmastertopdf(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getItemFiles({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getItemFiles(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    },
    mutations: {
        ALL_ITEMS: (state, data) => { (state.all_items = data) },
        ITEM_CATEGORIES: (state, data) => { (state.item_categories = data) },
        ITEMS: (state, data) => { (state.items = data) },
        ITEM_TYPES: (state, data) => { (state.item_types = data) },
        ITEM_UOM: (state, data) => { (state.item_uom = data) },
        ITEM_BRAND: (state, data) => { (state.item_brand = data) },
        ITEM_SIZE: (state, data) => { (state.item_size = data) },
        ALL_BUNDLES: (state, data) => { (state.all_bundles = data) },
        ITEM_EDIT_INFO: (state, data) => { (state.item_edit_info = data) },
        STORE_ITEM: (state, data) => { (state.store_item = data) },
        STORE_IMPORT_ITEM: (state, data) => { (state.store_import_item = data) },
        STORE_IMPORT_IMAGE: (state, data) => { (state.store_import_image = data) },
        UPDATE_IMPORT_ITEM: (state, data) => { (state.update_import_item = data) },
        SHOW_ITEM: (state, data) => { (state.show_item = data) },
        UPDATE_SELECTED_ITEM: (state, data) => { (state.update_selected_item = data) },
        DESTROY_ITEM: (state, data) => { (state.destroy_item = data) },
        STORE_ITEM_BUNDLE: (state, data) => { (state.store_item_bundle = data) },
        ITEM_BUNDLE: (state, data) => { (state.item_bundle = data) },
        UPDATE_ITEM_BUNDLE: (state, data) => { (state.update_item_bundle = data) },
        DESTROY_ITEM_BUNDLE: (state, data) => { (state.destroy_item_bundle = data) },
        ALL_ITEM_TYPES: (state, data) => { (state.all_item_types = data) },
        STORE_ITEM_TYPES: (state, data) => { (state.store_item_types = data) },
        SHOW_ITEM_TYPES: (state, data) => { (state.show_item_types = data) },
        UPDATE_ITEM_TYPES: (state, data) => { (state.update_item_types = data) },
        DESTROY_ITEM_TYPE: (state, data) => { (state.destroy_item_types = data) },
        ALL_ITEM_CATEGORIES: (state, data) => { (state.all_item_categories = data) },
        STORE_ITEM_CATEGORIES: (state, data) => { (state.store_item_categories = data) },
        SHOW_ITEM_CATEGORIES: (state, data) => { (state.show_item_categories = data) },
        UPDATE_ITEM_CATEGORIES: (state, data) => { (state.update_item_categories = data) },
        DESTROY_ITEM_CATEGORIES: (state, data) => { (state.destroy_item_categories = data) },
        ALL_ITEM_UOM: (state, data) => { (state.all_item_uom = data) },
        STORE_ITEM_UOM: (state, data) => { (state.store_item_uom = data) },
        SHOW_ITEM_UOM: (state, data) => { (state.show_item_uom = data) },
        UPDATE_ITEM_UOM: (state, data) => { (state.update_item_uom = data) },
        DESTROY_ITEM_UOM: (state, data) => { (state.destroy_item_uom = data) },
        ALL_ITEM_BRAND: (state, data) => { (state.all_item_brand = data) },
        STORE_ITEM_BRAND: (state, data) => { (state.store_item_brand = data) },
        SHOW_ITEM_BRAND: (state, data) => { (state.show_item_brand = data) },
        UPDATE_ITEM_BRAND: (state, data) => { (state.update_item_brand = data) },
        DESTROY_ITEM_BRAND: (state, data) => { (state.destroy_item_brand = data) },
        ALL_ITEM_SIZES: (state, data) => { (state.all_item_sizes = data) },
        STORE_ITEM_SIZES: (state, data) => { (state.store_item_sizes = data) },
        SHOW_ITEM_SIZES: (state, data) => { (state.show_item_sizes = data) },
        UPDATE_ITEM_SIZES: (state, data) => { (state.update_item_sizes = data) },
        DESTROY_ITEM_SIZES: (state, data) => { (state.destroy_item_sizes = data) },
        ALL_WAREHOUSE_ITEM_STOCKS: (state, data) => { (state.warehouse_item_stocks = data) },
        POST_WAREHOUSE_ITEM_STOCK: (state, data) => { (state.post_warehouse_item_stocks = data) },
        ITEM_SUBSTITUTION: (state, data) => { (state.item_substitution = data) },
        showExportItemModal(state) { state.exportItemModal = true; },
        showImportItemModal(state) { state.importItemModal = true; },
        closeExportItemModal(state) { state.exportItemModal = false; },
        closeImportItemModal(state) { state.importItemModal = false; },
        EXP_ITEM_MASTER: (state, data) => { (state.export_item_master_to_excel = data) },
        ITEM_TEMPLATE: (state, data) => { (state.item_template = data) },
        ITEM_TEMPLATE_SELECTION: (state, data) => { (state.item_template_selection = data) },
        ITEM_TEMPLATE_DETAILS: (state, data) => { (state.item_template_details = data) },
        SAMPLE: (state, data) => { state.test = data },
        ITEM_EXCLUSIVE_BY_ITEM: (state, data) => { state.item_exclusive_by_item = data },
        is_sp(state) { state.is_sp = true; },
        PARTS_COMPATIBILITY: (state, data) => { state.parts_compatibility = data},
    }

}
