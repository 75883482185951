import api from '../../../api'

export default {
    pay_bills_dispatch_api(params){
        return api.get('/accounting/getAllAccountingGroups',{params})
    },
    getWaitingForReceiving(params){
        return api.get(`/accounting/getWfr`,{params})
    },
    getPoListItems(params){
        return api.get(`/accounting/getPoListItems/${params.id}`,{params})
    },
    getTransactTypesForWFR(params){
        return api.get('dropdown/getTransactTypesForWFR',{params})
    },
    getWFRListItems(params){
        return api.get(`/accounting/getWFRListItems/${params.id}`,{params})
    },
}

