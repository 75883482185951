import repository from "@/api/modules/Accounting/PayBills";

export default {
    state: {
       pay_bills_dispatch_status:{},
       pay_bills_dispatch:{},
       waiting_for_receiving: [],
       po_list_items: [],
       transact_types_for_wfr: [],
    },
    getters: {
        GET_PAY_BILLS_DISPATCH_STATUS:(state)=>state.pay_bills_dispatch_status,
        GET_PAY_BILLS_DISPATCH:(state)=>state.pay_bills_dispatch,
        GET_PAY_BILLS_DISPATCH:(state)=>state.pay_bills_dispatch,
        GET_WAITING_FOR_RECEIVING: (state)=>state.waiting_for_receiving,
        GET_PO_LIST_ITEMS: (state)=>state.po_list_items,
        GET_TRANSACT_TYPES_FOR_WFR: (state)=>state.transact_types_for_wfr,
    },
    actions: {
        getWaitingForReceiving({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.getWaitingForReceiving(payload).then((response)=>{
					if(response.status == 200){
						commit('WAITING_FOR_RECEIVING',response.data)
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        getPoListItems({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.getPoListItems(payload).then((response)=>{
					if(response.status == 200){
						commit('PO_LIST_ITEMS',response.data)
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        getWFRListItems({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.getWFRListItems(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        getTransactTypesForWFR({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.getTransactTypesForWFR(payload).then((response)=>{
					if(response.status == 200){
						commit('TRANSACT_TYPES_FOR_WFR',response.data)
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        getPoListItems({commit,state},payload){
			return new Promise((resolve,reject)=>{
				repository.getPoListItems(payload).then((response)=>{
					if(response.status == 200){
						commit('PO_LIST_ITEMS',response.data)
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
    },
    mutations: {
        PAY_BILLS_DISPATCH_STATUS:(state,data)=>{
            state.pay_bills_dispatch_status = data
        },
        PAY_BILLS_DISPATCH:(state,data)=>{
            state.pay_bills_dispatch = data
        },
        WAITING_FOR_RECEIVING:(state,data)=>{
            state.waiting_for_receiving = data
        },
        PO_LIST_ITEMS:(state, data)=>{
            state.po_list_items =  data
        },
        TRANSACT_TYPES_FOR_WFR:(state, data)=>{
            state.transact_types_for_wfr =  data
        },
    }

}
