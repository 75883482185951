import repository from '@/api/modules/Admin/adminFasItems.js'
export default {
	state: {
        admin_fastitem_dispatch:{},
        admin_fastitem_status:null,
        new_admin_fasitem:false,
        edit_admin_fasitem:{},
        add_fas_type:'',
        import_fas_price_type:false,
	},
	getters: {
      GET_ADMIN_FASITEM_DISPATCH:(state)=> state.admin_fastitem_dispatch,
      GET_ADMIN_FASITEM_STATUS:(state)=> state.admin_fastitem_status,
      GET_NEW_ADMIN_FASITEM:(state)=> state.new_admin_fasitem,
      GET_EDIT_ADMIN_FASITEMS:(state)=> state.edit_admin_fasitem,
      GET_ADD_FAS_TYPE:(state)=>state.add_fas_type,
      GET_IMPORT_FAS_PRICE:(state)=>state.import_fas_price_type,
	},
	actions:{
         CreateAdminfasItem({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.CreateAdminfasItem(payload).then((response)=>{
                    commit('NEW_ADMIN_FASITEM',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         Updateadminfasitems({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.Updateadminfasitems(payload).then((response)=>{
                    commit('NEW_ADMIN_FASITEM',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
          },
          Deleteadminfasitems({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.Deleteadminfasitems(payload).then((response)=>{
                    commit('NEW_ADMIN_FASITEM',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
          },
          getAlladminfasitems({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getAlladminfasitems(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         saveFromEXCEL({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.saveFromEXCEL(payload).then((response)=>{
                    commit('NEW_ADMIN_FASITEM',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         findFasByCode({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.findFasByCode(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         }
	},
	mutations:{
        ADMIN_FASITEM_DISPATCH:(state,data)=>{
            state.admin_fastitem_dispatch = data
        },
        ADMIN_FASITEM_STATUS:(state,data)=>{
            state.admin_fastitem_status = data
        },
        NEW_ADMIN_FASITEM:(state,data)=>{
            state.new_admin_fasitem = data
        },
        EDIT_ADMIN_FASITEMS:(state,data)=>{
            state.edit_admin_fasitem = data
        },
        ADD_FAS_TYPE:(state,data)=>{
            state.add_fas_type = data;
        },
        IMPORT_FAS_PRICE_TYPE:(state,data)=>{
            state.import_fas_price_type = data
        }
	},
}
