import store from '../../../../store';
import api from '../../../api'

export default {
    //  Employee Data
    addEmployeeData(params){
        return api.post('/hr/hremployeedata',params);
    },
    getEmployeeData(params){
        return api.get('/hr/hremployeedata',{params});
    },
    updateEmployeeData(params){
        return api.put(`/hr/hremployeedata/${params.id}`,params.payload);
    },
    deleteEmployeeData(params){
        return api.post(`/hr/deleteemployeedata/`,params);
    },
    showEmployeeData(params){
        return api.get(`/hr/hremployeedata/${params}`);
    },
    getEmployeeDataTableCount(params){
        return api.get('/hr/getemployeetablecount',{params});
    },
    approveEmployeeData(params){
        return api.get('/hr/approveEmployeeData',{params});
    },
    getfiles(params){
        return api.get(`/hr/getfiles`,{params});
    },
    uploadfiles(params,config){
        return api.post(`/hr/uploadfiles`,params,config);
    },

    // Org Chart
    getOrgChartData(params){
        return api.get('/hr/getorgchartdata',{params});
    },
    // HR Settings
    hrsettings(params){
        return api.post(`/hr/settings`,params);
    },
    // Basic Records for Rehiring Employee
    getBasicRecords(params){
        return api.post(`/hr/getBasicRecords`,params);
    },
    // ID Template
    getIDTemplates(params){
        return api.get(`/hr/getIDTemplates`,params);
    },
    saveIDTemplates(params){
        return api.post(`/hr/saveIDTemplates`,params);
    },
    updateEmployeeSalaryType(params){
        return api.post(`/hr/updateEmployeeSalaryType`,params);
    },
    saveFromExcelHR(params){
        return api.post(`/hr/saveFromExcelHR`,params);
    },

    // Employee Requirements
    getEmployeeRequirementFiles(params){
        return api.get('/hr/getEmployeeRequirementFiles',{params})
    },
    uploadEmployeeNewRequirements(params,config){
        return api.post(`/hr/uploadEmployeeNewRequirements`,params,config)
    },
    uploadEmployeeNewRequirementsMultiple(params,config){
        return api.post(`/hr/uploadEmployeeNewRequirementsMultiple`,params,config)
    },

    // Employee Data Temp
    saveEmployeeDataTemp(params){
        return api.post(`/hr/saveEmployeeDataTemp`, params)
    },
    getEmployeeDataTemp(params){
        return api.get(`/hr/getEmployeeDataTemp`, {params})
    },
    showEmployeeDataChanges(params){
        return api.get(`/hr/showEmployeeDataChanges`, {params})
    },
    showEmployeeDataTemp(params){
        return api.get(`/hr/showEmployeeDataTemp/${params}`)
    },
    deleteEmployeeDataTemp(params){
        return api.delete(`/hr/deleteEmployeeDataTemp/${params}`)
    },
    approveEmployeeTemp(params){
        return api.post(`/hr/approveEmployeeTemp`, params)
    },
}
