import repository from '@/api/modules/Accounting/ChartOfAccountsTypes/coatypes.js'
import store from '@/store';
export default {
	state: {
		edit_chart_of_accounts_types:{},
		chart_of_accounts_types:[],
        new_chart_of_accounts_types:false,
	},
	getters: {
		GET_EDIT_CHART_OF_ACCOUNTS_TYPES:(state)=>state.edit_chart_of_accounts_types,
		GET_CHART_OF_ACCOUNTS_TYPES:(state)=>state.chart_of_accounts_types,
        GET_NEW_CHART_OF_ACCOUNTS_TYPES_TABLE:(state)=>state.new_chart_of_accounts_types,
	},
	actions:{
        CreateChartOfAccountsTypes({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.CreateChartOfAccountsTypes(payload).then((response)=>{
					if(response.status == 200){
                        commit('NEW_CHART_OF_ACCOUNTS_TYPES_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
                    console.log(error);
					reject(error)
				});
			})
		},
		UpdateChartOfAccountsTypes({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateChartOfAccountsTypes(payload).then((response)=>{
					if(response.status == 200){
                        commit('NEW_CHART_OF_ACCOUNTS_TYPES_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteChartOfAccountsTypes({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteChartOfAccountsTypes(payload).then((response)=>{
					if(response.status == 200){
						commit('NEW_CHART_OF_ACCOUNTS_TYPES_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
        getAllChartOfAccountsTypes({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllChartOfAccountsTypes(payload).then((response)=>{
					if(response.status == 200){
						commit('CHART_OF_ACCOUNTS_TYPES', response.data);
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getChartOfAccountsTypesItem({commit},payload) {
			return new Promise((resolve,reject) => {
					repository.getChartOfAccountsTypesItem(payload).then((response) => {
                            if(response.status == 200){
                                commit('EDIT_CHART_OF_ACCOUNTS_TYPES', response.data);
                                resolve(response.data)
                            }
					}).catch((error) => {
							console.log(error);
							reject(error);
					})
			})
		},
	},
	mutations:{
		EDIT_CHART_OF_ACCOUNTS_TYPES:(state, data) => {
			state.edit_chart_of_accounts_types = data
		},
		CHART_OF_ACCOUNTS_TYPES:(state, data) => {
			state.chart_of_accounts_types = data
		},
        NEW_CHART_OF_ACCOUNTS_TYPES_TABLE:(state,data)=>{
			state.new_chart_of_accounts_types = data;
		},
	},
}
