import repository from "@/api/modules/MasterData";
import dropdown from "@/api/Main/DropDowns/index";
import store from "@/store";
import swal from "sweetalert2";
export default {
    state: {
        customer: [],
        customer: [],
        selected_customer: {},
        newcustomer: false,
        totalcustomer: 0,
        affiliatecustomer: [],
        brand_discount: [],
        brand_arr: [],
        discount_type: [],
        customer_id: "",
        salesman_dropdown: [],
        salesman_coor_dropdown: [],
        classifications: [],
        customercategory: [],
        selected_classification: {},
        new_classification: false,
        new_customer_category: false,
        classifications_dropdown: [],
        customer_addresses: [],
        show_customers: [],
        get_customers: [],
        gentrade_customers: [],
        classification_loaded: false,
        customer_category_loaded: false,
        selected_customer_category: {},
        selected_customer_code: {},
        customer_codes: [],
        new_customer_code: false,
        customer_code_dropdown: [],
        customer_code_loaded: false,
        customer_files: [],
        list_template:[],
        active_tab:'',
        active_tab:'',
        customer_changes: {},
        approval_dialog: false,
        is_mother: 0,
        mass_update_dialog: false,
    },
    getters: {
        GET_ALL_CUSTOMER: (state) => state.customer,
        GET_ALL_LIST_TEMPLATE:(state) => state.list_template,
        GET_ALL_CLASSIFICATION: (state) => state.classifications,
        GET_LIST_customer: (state) => state.customer,
        SELECTED_CUSTOMER: (state) => state.selected_customer,
        SELECTED_CLASSIFICATION: (state) => state.selected_classification,
        GET_AFFLIATE_CUSTOMER: (state) => state.affiliatecustomer,
        GET_SALESMAN_DROPDOWN: (state) => state.salesman_dropdown,
        GET_SALESMANCOOR_DROPDOWN: (state) => state.salesman_coor_dropdown,
        GET_CLASSIFICATIONS_DROPDOWN: (state) => state.classifications_dropdown,
        GET_BRAND_DISCOUNT: (state) => state.brand_discount,
        GET_BRAND_ARR: (state) => state.brand_arr,
        NEW_CUSTOMER: (state) => state.newcustomer,
        TOTAL_CUSTOMER: (state) => state.totalcustomer,
        GET_DISCOUNT_TYPE: (state) => state.discount_type,
        GET_CUSTOMER_ID: (state) => state.customer_id,
        NEW_CLASSIFICATION: (state) => state.new_classification,
        GET_CUSTOMER_ADDRESSES: (state) => state.customer_addresses,
        GET_SHOW_CUSTOMERS: (state) => state.show_customers,
        GET_CUSTOMERS: (state) => state.get_customers,
        GET_GENTRADE_CUSTOMERS: (state) => state.gentrade_customers,
        GET_CLASSIFICATION_LOADED: (state) => state.classification_loaded,
        GET_ALL_CUSTOMER_CATEGORY: (state) => state.customercategory,
        GET_CUSTOMER_CATEGORY_LOADED: (state) => state.customer_category_loaded,
        SELECTED_CUSTOMER_CATEGORY: (state) => state.selected_customer_category,
        NEW_CUSTOMER_CATEGORY: (state) => state.new_customer_category,

        SELECTED_CUSTOMER_CODE: (state) => state.selected_customer_code,
        GET_ALL_CUSTOMER_CODE: (state) => state.customer_codes,
        NEW_CUSTOMER_CODE: (state) => state.new_customer_code,
        GET_CUSTOMER_CODE_DROPDOWN: (state) => state.customer_code_dropdown,
        GET_CUSTOMER_CODE_LOADED: (state) => state.customer_code_loaded,
        GET_CUSTOMER_FILES: (state) => state.customer_files,
        GET_ACTIVE_TAB: (state) => state.active_tab,
        GET_CUSTOMER_CHANGES: (state) => state.customer_changes,
        GET_APPROVAL_DIALOG: (state) => state.approval_dialog,
        GET_IS_MOTHER: (state) => state.is_mother,
        GET_MASS_UPDATE_DIALOG: (state) => state.mass_update_dialog,
    },
    actions: {
        deletecustomer({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .deletecustomer(payload)
                    .then(({ data, status }) => {
                        if (status == 200) {
                            resolve(data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: error.response.data.message,
                            icon: "error",
                        });
                    });
            });
        },
        getallcustomer({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .customers(payload)
                    .then((response) => {
                        if (response.status == 200) {
                            let res = response.data.data;
                            commit("ALL_CUSTOMER_LOAD", res.data);
                            resolve(response);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            });
        },
        getAllClassification({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit("CLASSIFICATION_LOADED", true);
                repository
                    .classifications(payload)
                    .then((response) => {
                        commit("CLASSIFICATION_LOADED", false);
                        commit("CLASSIFICATIONS_LOAD", response.data);

                        resolve(response)
                    })
                    .catch((error) => {
                        console.log(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                        commit("CLASSIFICATION_LOADED", false);
                    });
            })
        },
        addclassification({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .addclassifications(payload)
                    .then((response) => {
                        if (response.status == 200) {
                            resolve(response)
                            commit("NEW_CLASSIFICATION", true);
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            })

        },
        updateclassification({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .updateclassifications(payload)
                    .then((response) => {
                        if (response.status == 200) {
                            resolve(response)
                            commit("NEW_CLASSIFICATION", true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            })
        },
        deleteclassification({ commit }, payload) {
            return new Promise((resolve, reject) => {
            repository
                .deleteclassifications(payload)
                .then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit("NEW_CLASSIFICATION", true);
                    }
                })
                .catch((error) => {
                    reject(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
            })
        },
        addcustomers({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addcustomers(payload).then((response)=>{
                        // commit("NEW_CUSTOMER", true);
						resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        updatecustomers({ commit }, payload) {

            return new Promise((resolve, reject) => {
                repository
                    .updatecustomers(payload)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        addshippingaddress({ commit }, payload) {
            repository
                .addshippingaddress(payload)
                .then((response) => {})
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        addbillingaddress({ commit }, payload) {
            repository
                .addbillingaddress(payload)
                .then((response) => {})
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        addCustomerCluster({ commit }, payload) {
            repository
                .addCustomerCluster(payload)
                .then((response) => {})
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        adddiscount({ commit }, payload) {
            const params = {
                cust_id: payload.cust_id,
                brand_dc: payload.brand_dc,
                dc_type: payload.dc_type,
                dc_arr: payload.dc_arr,
                dc_text_arr: payload.dc_text_arr,
                trash: payload.trash,
                brand_name:payload.brand_name,
                action:payload.action,
                approved:payload.approved,
            };
            repository
                .adddiscount(params)
                .then((response) => {})
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        discounttype({ commit }) {
            repository
                .discounttype()
                .then((response) => {
                    commit("DISCOUNT_TYPE", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        getAffliatecustomer({ commit }) {
            dropdown
                .affiliatecustomer()
                .then((response) => {
                    commit("AFFLIATE_CUSTOMER_LOAD", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        getBranddiscount({ commit }) {
            dropdown
                .branddiscount()
                .then((response) => {
                    commit("BRAND_DISCOUNT_LOAD", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        getSalesmanDropDown({ commit }) {
            dropdown
                .salesman()
                .then((response) => {
                    commit("SALESMAN_DROPDOWN_LOAD", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        getSalesmanCoorDropDown({ commit }) {
            dropdown
                .salesmancoor()
                .then((response) => {
                    commit("SALESMANCOOR_DROPDOWN_LOAD", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        getClassification({ commit }) {
            dropdown
                .getallcustomerclassificationselection()
                .then((response) => {
                    commit("CLASSIFICATIONS_DROPDOWN_LOAD", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        getCustomerAddress({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getcustomeraddress(payload.id, payload.params)
                    .then((response) => {
                        if (response.status == 200) {
                            resolve(response);
                            commit("CUSTOMER_ADDRESSES", response.data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        showCustomers({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .showcustomers(payload)
                    .then((response) => {
                        if (response.status == 200) {
                            resolve(response);
                            commit("SHOW_CUSTOMERS", response.data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getCustomers({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getcustomers(payload.params)
                    .then((response) => {
                        if (response.status == 200) {
                            commit("G_CUSTOMERS", response.data);
                            resolve(response);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getGenTradeCustomers({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getgentradecustomers(payload)
                    .then((response) => {
                        if (response.status == 200) {
                            resolve(response);
                            commit("GENTRADE_CUSTOMERS", response.data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getAllCustomerCategory({ commit }) {
            commit("CUSTOMER_CATEGORY_LOADED", true);
            repository
                .CustomerCategory()
                .then((response) => {
                    commit("CUSTOMER_CATEGORY_LOADED", false);
                    commit("CUSTOMER_CATEGORY_LOAD", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                    commit("CUSTOMER_CATEGORY_LOADED", false);
                });
        },
        getClassification({ commit }) {
            dropdown
                .getallcustomerclassificationselection()
                .then((response) => {
                    commit("CLASSIFICATIONS_DROPDOWN_LOAD", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        addCustomerCategory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .addCustomerCategory(payload)
                    .then((response) => {
                        // store.dispatch("SocketReload");
                        if (response.status == 200) {
                            resolve(response);
                            commit("NEW_CUSTOMER_CATEGORY", true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            });
        },
        updateCustomerCategory({ commit }, payload) {
            const params = {
                id: payload.id,
                code: payload.payload.code,
                name: payload.payload.name,
                description: payload.payload.description,
            };
            return new Promise((resolve, reject) => {
                repository
                    .updateCustomerCategory(params)
                    .then((response) => {
                        // store.dispatch("SocketReload");
                        if (response.status == 200) {
                            resolve(response)
                            commit("NEW_CUSTOMER_CATEGORY", true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            });

        },
        deleteCustomerCategory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .deleteCustomerCategory(payload)
                    .then((response) => {
                        // store.dispatch("SocketReload");
                        if (response.status == 200) {
                            resolve(response)
                            commit("NEW_CUSTOMER_CATEGORY", true);
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            });
        },
        exportCustomer({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.exportCustomer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustomerClassificationsList({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				repository.getcustomerclassificationslist(payload).then((response)=>{
                    if (response.status == 200) {
                        commit('CLASSIFICATIONS_LOAD', response.data)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getCustomerCardDetails({ commit }, payload) {
            const params = {
                customer_id: payload
            };
            return new Promise((resolve, reject) => {
                repository.getCustomerCardDetails(params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllCustomerCode({ commit }) {
            commit("CUSTOMER_CODE_LOADED", true);
            repository
                .customer_code()
                .then((response) => {
                    commit("CUSTOMER_CODE_LOAD", response.data);
                    commit("CUSTOMER_CODE_LOADED", false);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                    commit("CUSTOMER_CODE_LOADED", false);
                });
        },
        getCustomerCode({ commit }) {
            dropdown
                .getallcustomercodeselection()
                .then((response) => {
                    commit("CUSTOMER_CODES_DROPDOWN_LOAD", response.data);
                })
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        addcustomercode({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .addcustomercodes(payload)
                    .then((response) => {
                        if (response.status == 200) {
                            resolve(response)
                            commit("NEW_CUSTOMER_CODE", true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            })
        },
        updatecustomercode({ commit }, payload) {
            const params = {
                id: payload.id,
                code: payload.payload.code,
                name: payload.payload.name,
                color: payload.payload.color,
                description: payload.payload.description,
            };
            return new Promise((resolve, reject) => {
                repository
                    .updatecustomercodes(params)
                    .then((response) => {
                        if (response.status == 200) {
                            resolve(response)
                            commit("NEW_CUSTOMER_CODE", true);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            })
        },
        deletecustomercode({ commit }, payload) {
            repository
            .deletecustomercodes(payload)
            .then((response) => {
                store.dispatch("SocketReload");
                commit("NEW_CUSTOMER_CODE", true);
            })
            .catch((error) => {
                console.log(error);
                swal.fire({
                    title: "ERROR",
                    icon: "error",
                });
            });
        },
        getCustomerCodesList({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				repository.getcustomercodeslist(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getWarehousesRequest({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				dropdown.getWarehousesRequest(payload).then((response)=>{
                    if(response.status == 200){
                        commit('CUSTOMER_FILES', response.data);
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getCustomerFiles({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				repository.getCustomerFiles(payload).then((response)=>{
					if(response.status == 200){
                        commit('CUSTOMER_FILES', response.data);
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        deleteCustomerFile({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				repository.deleteCustomerFile(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        uploadCustomerFile({ commit }, payload) {
            repository
                .uploadCustomerFile(payload)
                .then((response) => {})
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        showCustomerCollectibles({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				repository.showCustomerCollectibles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
                        }
                })
            })
        },
        getListTemplate({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.getListTemplate(payload).then((response)=>{
					if(response.status == 200){
                        commit('LIST_TEMAPLTE',response.data)
                        resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        addCustomerTempRecord({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.addcustomertemprecord(payload).then((response) => {
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        addCustomerTempDiscount({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.addcustomertempdiscount(payload).then((response) => {
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        addCustomerTempShippingAddress({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.addcustomertempshippingaddress(payload).then((response) => {
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        addCustomerTempBillingAddress({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.addcustomertempbillingaddress(payload).then((response) => {
                        resolve(response.data);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            });
        },
        uploadCustomerTempFile({ commit }, payload) {
            repository.uploadcustomertempfile(payload).then((response) => {})
                .catch((error) => {
                    console.log(error);
                    swal.fire({
                        title: "ERROR",
                        icon: "error",
                    });
                });
        },
        getAllTempCustomer({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .customertemp(payload)
                    .then((response) => {
                        if (response.status == 200) {
                            let res = response.data.data;
                            commit("ALL_CUSTOMER_LOAD", res.data);
                            resolve(response);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            });
        },
        approveCustomerTemp({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .approvecustomertemp(payload).then((response) => {
                        if (response.status == 200) {
                            resolve(response.data);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        swal.fire({
                            title: "ERROR",
                            icon: "error",
                        });
                    });
            });
        },
        getCustomerTempFiles({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				repository.getCustomerTempFiles(payload).then((response)=>{
					if(response.status == 200){
                        commit('CUSTOMER_FILES', response.data);
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getCustomerChanges({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				repository.getCustomerChanges(payload).then((response)=>{
					if(response.status == 200){
                        commit('CUSTOMER_CHANGES', response.data);
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        submitMassUpdate({ commit }, payload) {
            return new Promise((resolve,reject)=>{
				repository.submitMassUpdate(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
    },
    mutations: {
        ALL_CUSTOMER_LOAD: (state, data) => {
            state.customer = data;
        },
        CLASSIFICATIONS_LOAD: (state, data) => {
            state.classifications = data;
        },
        CUSTOMER_ID: (state, data) => {
            state.customer_id = data;
        },
        NEW_CUSTOMER: (state, data) => {
            state.newcustomer = data;
        },
        customer_LOAD: (state, data) => {
            state.customer = data;
        },
        AFFLIATE_CUSTOMER_LOAD: (state, data) => {
            state.affiliatecustomer = data;
        },
        SALESMAN_DROPDOWN_LOAD: (state, data) => {
            state.salesman_dropdown = data;
        },
        SALESMANCOOR_DROPDOWN_LOAD: (state, data) => {
            state.salesman_coor_dropdown = data;
        },
        CLASSIFICATIONS_DROPDOWN_LOAD: (state, data) => {
            state.classifications_dropdown = data;
        },
        BRAND_DISCOUNT_LOAD: (state, data) => {
            state.brand_discount = data;
        },
        EDIT_CUSTOMER: (state, data) => {
            state.selected_customer = data;
        },
        EDIT_CLASSIFICATION: (state, data) => {
            state.selected_classification = data;
        },
        BRAND_ARRAY: (state, data) => {
            state.brand_arr = data;
        },
        TOTAL_CUSTOMER: (state, data) => {
            state.totalcustomer = data;
        },
        REMOVE_CUSTOMER: (state, id) =>
            (state.allcustomer = state.allcustomer.filter(
                (customer) => customer.id !== id
            )),
        DISCOUNT_TYPE: (state, data) => {
            state.discount_type = data;
        },
        NEW_CLASSIFICATION: (state, data) => {
            state.new_classification = data;
        },
        CUSTOMER_ADDRESSES: (state, data) => {
            state.customer_addresses = data;
        },
        SHOW_CUSTOMERS: (state, data) => {
            state.show_customers = data;
        },
        G_CUSTOMERS: (state, data) => {
            state.get_customers = data;
        },
        GENTRADE_CUSTOMERS: (state, data) => {
            state.gentrade_customers = data;
        },
        CLASSIFICATION_LOADED: (state, data) => {
            state.classification_loaded = data;
        },
        CUSTOMER_CATEGORY_LOAD: (state, data) => {
            state.customercategory = data;
        },
        CUSTOMER_CATEGORY_LOADED: (state, data) => {
            state.customer_category_loaded = data;
        },
        NEW_CUSTOMER_CATEGORY: (state, data) => {
            state.new_customer_category = data;
        },
        EDIT_CUSTOMER_CATEGORY: (state, data) => {
            state.selected_customer_category = data;
        },

        CUSTOMER_CODE_LOAD: (state, data) => {
            state.customer_codes = data;
        },
        NEW_CUSTOMER_CODE: (state, data) => {
            state.new_customer_code = data;
        },
        CUSTOMER_CODE_LOADED: (state, data) => {
            state.customer_code_loaded = data;
        },
        EDIT_CUSTOMER_CODE: (state, data) => {
            state.selected_customer_code = data;
        },
        CUSTOMER_CODES_DROPDOWN_LOAD: (state, data) => {
            state.customer_code_dropdown = data;
        },
        CUSTOMER_FILES: (state, data) => {
            state.customer_files = data;
        },
        LIST_TEMAPLTE:(state,data)=>{
            state.list_template = data
        },
        ACTIVE_TAB: (state, data) => {
            state.active_tab = data;
        },
        CUSTOMER_CHANGES: (state, data) => {
            state.customer_changes = data;
        },
        APPROVAL_DIALOG: (state, data) => {
            state.approval_dialog = data;
        },
        IS_MOTHER: (state, data) => {
            state.is_mother = data;
        },
        MASS_UPDATE_DIALOG: (state, data) => {
            state.mass_update_dialog = data;
        },
    },
};
