const routes = [{
    path: 'admin/reports/asset-management',
    name: 'assetmanagementreport',
    component: () => import('@/views/main/modules/Reports/AdminReports/AssetManagementReportComponent'),
    meta: {
        requiresAuth: true,
        title: 'Asset Management Report',
    }
},
]

export default routes;
