import repository from '@/api/modules/Admin/contractorendorsementform'

        export default {
        state: {
            cef_dispatch: {},
            status: '',
            new_cef_table: false,
            loaded: false,
            cef_items_list: {
                labor_items: [],
                material_items: []
            },
            edit_cef: {},
            cef_print: false,
        },
        getters: {
            GET_CEF_DISPATCH: (state) => state.cef_dispatch,
            GET_CEF_DISPATCH_STATUS: (state) => state.status,
            GET_NEW_CEF_TABLE: (state) => state.new_cef_table,
            GET_CEF_ITEMS_LOADED: (state) => state.loaded,
            GET_CEF_ITEMS_LIST: (state) => state.cef_items_list,
            GET_EDIT_CEF: (state) => state.edit_cef,
            GET_CEF_PRINT: (state) => state.cef_print,
        },
        actions: {
            CreateCEF({ commit, state }, payload) {
                return new Promise((resolve, reject) => {
                        repository.CreateCEF(payload).then((response) => {
                                resolve(response);
                                commit('NEW_CEF_TABLE', true)
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            getAllCEF({ commit },payload) {
                return new Promise((resolve, reject) => {
                        repository.getAllCEF(payload).then((response) => {
                                resolve(response.data);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            getCEFItems({ commit }, payload) {
                return new Promise((resolve, reject) => {
                        commit('CEF_ITEMS_LOADED', true);
                        repository.getCEFItems(payload).then((response) => {
                                commit('CEF_ITEMS_LIST', response.data);
                                commit('CEF_ITEMS_LOADED', false);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            UpdateCEF({ commit, state }, payload) {
                return new Promise((resolve, reject) => {
                        repository.UpdateCEF(payload).then((response) => {
                                resolve(response);
                                commit('NEW_CEF_TABLE', true)
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            DeleteCEF({ commit, state }, payload) {
                return new Promise((resolve, reject) => {
                        repository.DeleteCEF(payload).then((response) => {
                                commit('NEW_CEF_TABLE', true)
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
            cefPrintData({ commit }, payload) {
                return new Promise((resolve, reject) => {
                        repository.cefPrintData(payload).then((response) => {
                                resolve(response);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
            },
        },
        mutations: {
            CEF_DISPATCH: (state, data) => {
                state.cef_dispatch = data;
            },
            NEW_CEF_TABLE: (state, data) => {
                state.new_cef_table = data
            },
            CEF_ITEMS_LIST: (state, data) => {
                state.cef_items_list = data
            },
            EDIT_CEF: (state, data) => {
                state.edit_cef = data
            },
            CEF_DISPATCH_STATUS: (state, data) => state.status = data,
            CEF_ITEMS_LOADED: (state, data) => state.loaded = data,
            CEF_PRINT: (state, data) => state.cef_print = data,
        },
}
