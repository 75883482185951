

import api from '../../../api'
export default {

   getcyclecount(params){
      return api.get(`inventory/cycle-count`,{params})
   },
   showcyclecountdetails(id,params){
      return api.get(`inventory/cycle-count/${id}/details`,{params})
   },
   postcyclecount(params){
      return api.post(`inventory/cycle-count/${params}`)
   },
   approvecyclecount(id){
      return api.post(`inventory/cycle-count/approve/${id}`)
   },
   savedetailcyclecount(id,params){
      return api.post(`inventory/cycle-count/${id}/save-detail`,params)
   },
   submitforcheckingcyclecount(id){
      return api.post(`inventory/cycle-count/${id}/submit-for-checking`)
   },
   returnforrecountcyclecount(id){
      return api.post(`inventory/cycle-count/${id}/return-for-recount`)
   },
   completecyclecount(id){
      return api.post(`inventory/cycle-count/${id}/complete`)
   },
   destroycyclecount(id){
      return api.post(`inventory/cycle-count/destroy/${id}`)
   },
   importcyclecounttemplate(id,params){
      return api.post(`inventory/cycle-count/${id}/import-counter-template`,params)
   },
   createcyclecount(params){
      return api.post(`inventory/cycle-count/create`,params)
   },
   schedulecyclecount(params){
      return api.post(`inventory/cycle-count/schedule`,params)
   },
   getschedulecyclecount(params){
      return api.get(`inventory/cycle-count/schedule`,{params})
   },
   destroycyclecountschedule(id){
      return api.delete(`inventory/cycle-count/schedule/destroy/${id}`)
   },
   exportcyclecount(id,params){
      return api.get(`inventory/cycle-count/${id}/export`,{params})
   },
   itemsnotadequate(params){
      return api.get(`inventory/cycle-count/has-items-of-not-adequate`,{params})
   }
   

}
