import api from '../../../api'
export default {

    getdebitmemohistory(params){
        return api.get(`accounting/${params.url}`,{params})
    },
    postdebitmemohistory(params){
        return api.post(`accounting/${params.url}`,params)
    },
    putdebitmemohistory(params){
        return api.put(`accounting/${params.url}`,params)
    },
}