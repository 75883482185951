import store from '../../../store';
import api from '../../api'

export default {
    CreateAdminfasItem(params){
       return api.post('/admin/adminfasitems',params)
    },
    Updateadminfasitems(params){
        return api.put(`/admin/adminfasitems/${params.id}`,params)
     },
     Deleteadminfasitems(params){
        return api.delete(`/admin/adminfasitems/${params}`)
     },
     getAlladminfasitems(params){
        return api.get('/admin/getAlladminfasitems',{params})
    },
    saveFromEXCEL(params){
      return api.post('/admin/saveFromEXCEL',params)
    },
    findFasByCode(params){
      return api.post('/admin/findFasByCode',params)
    }
}

