import repository from '@/api/modules/Services/index'


export default {
    state:{
        DIALOGS:{
            ITEMS:false,
            CHECK_LIST_TEMPLATE:{
                VALUE:false,
                mutations:'CHECK_LIST_TEMPLATE_DIALOG'
            },
            UNIT_WITH_CHECK_LISTS:false,
            VIEW:{
                VALUE:false,
                mutations:'CHECK_LIST_TEMPLATE_DIALOG_VIEW'
            },
        },
        SELECTED:{},
        ACTION:'',
        CHECKLISTTABLE:[],
        REFRESH: false,
        TAB: 0,
        checklist_dispatch:{},
        checklist_dialog_title: '',
        checklist_dialog_view: {},
    },
    getters:{
        TESTER_CHECK_LISTS:(state)=>state,
        GET_CHECK_LIST_TABLE:(state)=>state.CHECKLISTTABLE,
        GET_REFRESH:(state)=>state.REFRESH, 
        GET_CHECK_LIST:(state)=>state.ACTION, 
        GET_TABS:(state)=>state.TAB, 
        GET_CHECKLIST_DISPATCH:(state)=>state.checklist_dispatch,
        GET_CHECKLIST_DIALOG_TITLE:(state)=>state.checklist_dialog_title,
        GET_CHECKLIST_DIALOG_VIEW:(state)=>state.checklist_dialog_view,

    },
    mutations:{
        CHECK_LIST_TEMPLATE_DIALOG:(state,data)=> state.DIALOGS.CHECK_LIST_TEMPLATE.VALUE  = data,
        GET_CHECK_LIST_SELECTED_ITEM:(state,data)=> state.SELECTED  = data,
        GET_CHECK_LIST_ACTION:(state,data)=> state.ACTION  = data,
        CHECK_LIST_TEMPLATE_DIALOG_VIEW:(state,data)=> state.DIALOGS.VIEW.VALUE = data,
        CHECK_LIST_TABLE:(state,data)=>{(state.CHECKLISTTABLE = data)},
        REFRESH:(state,data)=>{state.REFRESH = data},
        TAB:(state,data)=>{state.TAB = data},
        CHECKLIST_DISPATCH:(state,data)=>{state.checklist_dispatch = data},
        CHECKLIST_DIALOG_TITLE:(state,data)=>{state.checklist_dialog_title = data},
        CHECKLIST_DIALOG_VIEW:(state,data)=>{state.checklist_dialog_view = data},

    },
    actions:{
        
        itemsGet({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.itemsGet(payload).then((response)=>{
                    commit('CHECK_LIST_TABLE',response.data)
                    // commit('REFRESH', true)
                    resolve(response)
                }).catch(e=>{
                    reject(e)
                })
            })
        },

        itemsPost({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.itemsPost(payload).then((response)=>{
                    commit('CHECK_LIST_TABLE',response.data)
                    commit('TAB',response.data)
                    commit('REFRESH', true)
                    resolve(response)
                }).catch(e=>{
                    reject(e)
                })
            })
        },

        AddChecklist({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addchecklist(payload).then((response)=>{
					commit('REFRESH',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
    }
}

