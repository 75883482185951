const routes = [{
    path: '/service/reports/',
    name: 'servicereports',
    component: () => import('@/views/main/modules/Reports/ServiceReportComponent.vue'),
    meta: {
        requiresAuth: true,
        title: 'Sales Reports',
        type: 'sales'
    },
}]

export default routes;
