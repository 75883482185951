import repository from '@/api/modules/Dashboard/Hr'

export default {
    state: {
        employee_total: [],
        employee_for_approval: [],
        employee_count_per_dept:[],
        employee_count_by_emp_status:[],
        employee_count_by_city:[],
        employee_count_by_contract:[],
        employee_count_by_age:[],
        employee_count_by_category:[],
        employee_count_by_gender:[],
        employee_hired_vs_left:[],
        employee_birthday_celebrants:[],
        geojson: {}
    },
    getters: {
        GET_EMPLOYEE_TOTAL: state => state.employee_total,
        GET_EMPLOYEE_FOR_APPROVAL: state => state.employee_for_approval,
        GET_EMPLOYEE_COUNT_PER_DEPT: state => state.employee_count_per_dept,
        GET_EMPLOYEE_COUNT_BY_EMP_STATUS: state => state.employee_count_by_emp_status,
        GET_EMPLOYEE_COUNT_BY_CITY: state => state.employee_count_by_city,
        GET_EMPLOYEE_COUNT_BY_CONTRACT: state => state.employee_count_by_contract,
        GET_EMPLOYEE_COUNT_BY_AGE: state => state.employee_count_by_age,
        GET_EMPLOYEE_COUNT_BY_CATEGORY: state => state.employee_count_by_category,
        GET_EMPLOYEE_COUNT_BY_GENDER: state => state.employee_count_by_gender,
        GET_EMPLOYEE_HIRED_VS_LEFT: state => state.employee_hired_vs_left,
        GET_EMPLOYEE_BIRTHDAY_CELEBRANTS: state => state.employee_birthday_celebrants,
        // GET_GEOJSON_CITY: state => state.geojson,
    },
    actions: {
        getEmployeeCount({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeecount(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_TOTAL', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeForApprovalCount({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeeforapprovalcount(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_FOR_APPROVAL', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeCountPerDept({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeecountperdept(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_COUNT_PER_DEPT', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeCountByEmpStatus({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeecountbyempstatus(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_COUNT_BY_EMP_STATUS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeCountByCity({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeecountbycity(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_COUNT_BY_CITY', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeCountByContract({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeecountbycontract(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_COUNT_BY_CONTRACT', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeCountByAge({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeecountbyage(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_COUNT_BY_AGE', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeCountByCategory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeecountbycategory(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_COUNT_BY_CATEGORY', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeCountByGender({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeecountbygender(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_COUNT_BY_GENDER', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeHiredVsLeft({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeehiredvsleft(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('EMPLOYEE_HIRED_VS_LEFT', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getEmployeeBirthdayCelebrants({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getemployeebirthdaycelebrants(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('GET_EMPLOYEE_BIRTHDAY_CELEBRANTS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getGeojson({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getGeojson(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getRegionCityGeojson({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getRegionCityGeojson(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        EMPLOYEE_TOTAL: (state, data) => {
            state.employee_total = data
        },
        EMPLOYEE_FOR_APPROVAL: (state, data) => {
            state.employee_for_approval = data
        },
        EMPLOYEE_COUNT_PER_DEPT: (state, data) => {
            state.employee_count_per_dept = data
        },
        EMPLOYEE_COUNT_BY_EMP_STATUS: (state, data) => {
            state.employee_count_by_emp_status = data
        },
        EMPLOYEE_COUNT_BY_CITY: (state, data) => {
            state.employee_count_by_city = data
        },
        EMPLOYEE_COUNT_BY_CONTRACT: (state, data) => {
            state.employee_count_by_contract = data
        },
        EMPLOYEE_COUNT_BY_AGE: (state, data) => {
            state.employee_count_by_age = data
        },
        EMPLOYEE_COUNT_BY_CATEGORY: (state, data) => {
            state.employee_count_by_category = data
        },
        EMPLOYEE_COUNT_BY_GENDER: (state, data) => {
            state.employee_count_by_gender = data
        },
        EMPLOYEE_HIRED_VS_LEFT: (state, data) => {
            state.employee_hired_vs_left = data
        },
        GET_EMPLOYEE_BIRTHDAY_CELEBRANTS: (state, data) => {
            state.employee_birthday_celebrants = data
        },
        GEOJSON: (state, data) => {
            state.geojson = data
        },
    }
}
