import repository from '@/api/modules/Accounting/Fundamentals/fundamentals.js'
import store from '@/store';
export default {
	state: {
		fundamentals: [],
		edit_fundamentals:{},
		new_fundamentals: false,
	},
	getters: {
		GET_FUNDAMENTALS:(state)=>state.fundamentals,
		GET_EDIT_FUNDAMENTALS:(state)=>state.edit_fundamentals,
        GET_NEW_FUNDAMENTALS_TABLE:(state)=>state.new_fundamentals,
	},
	actions:{
        getAllFundamentals({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllFundamentals(payload).then((response)=>{
					if(response.status == 200){
						commit('FUNDAMENTALS', response.data);
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getFundamentalsItem({commit},payload) {
			return new Promise((resolve,reject) => {
				repository.getFundamentalsItem(payload).then((response) => {
						if(response.status == 200){
							commit('EDIT_FUNDAMENTALS', response.data);
							resolve(response.data)
						}
				}).catch((error) => {
						console.log(error);
						reject(error);
				})
			})
		},
		CreateFundamentals({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.CreateFundamentals(payload).then((response)=>{
					if(response.status == 200){
                        commit('NEW_FUNDAMENTALS_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
                    console.log(error);
					reject(error)
				});
			})
		},
		UpdateFundamentals({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateFundamentals(payload).then((response)=>{
					if(response.status == 200){
                        commit('NEW_FUNDAMENTALS_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteFundamentals({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteFundamentals(payload).then((response)=>{
					if(response.status == 200){
						commit('NEW_FUNDAMENTALS_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
	},
	mutations:{
		FUNDAMENTALS:(state, data) => {
			state.fundamentals = data
		},
		EDIT_FUNDAMENTALS:(state, data) => {
			state.edit_fundamentals = data
		},
		NEW_FUNDAMENTALS_TABLE:(state,data)=>{
			state.new_fundamentals = data;
		},
	},
}