<template>
  <v-app id="app">
    <v-main>
      <router-view />
      <v-menu
      v-if="updateExists && USERACCOUNT_LOAD.president == 1"
      open-on-hover
      left
      offset-x
     >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          style="position: fixed; bottom: 10px; right: 15px;"
          color="primary"
          v-bind="attrs"
            v-on="on"
          >
          <v-progress-circular
          v-if="!isUpdate"
          indeterminate
          color="white"
        ></v-progress-circular>
        <v-icon v-else>mdi-update</v-icon> 
        </v-btn>
      </template>
        <v-card color="primary">
          <v-card-text class="text-white">
            <v-icon color="white">mdi-update</v-icon> {{contentText}}
            <v-btn v-if="isUpdate" text @click="refreshApp">
                Update
              </v-btn>
              <v-progress-linear
              v-else
              color="amber lighten-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-menu>
      <v-snackbar v-if="USERACCOUNT_LOAD.president == 0" top right v-model="updateExists" :timeout="-1" color="primary">
            <v-icon>mdi-update</v-icon> {{contentText}}
            <v-btn v-if="isUpdate" text @click="refreshApp">
              Update 
            </v-btn>
            <v-progress-linear
            v-else
            color="amber lighten-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-snackbar>
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import swUpdate from '@/mixins/swUpdate'
export default {
  mixins:[swUpdate],
  mounted() {
    this.refresher;
  },
  methods: {},
  computed: {
    ...mapGetters([
      "USERACCOUNT_LOAD",
      "LINK_ACTIVE",
    ]),
    refresher() {
      if(this.USERACCOUNT_LOAD.length != 0){
        this.$store.dispatch("credentials");
        // this.$store.dispatch("Load_to_per_user");
      }
    },
  },
};
</script>

