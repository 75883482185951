import repository from '@/api/credentials';

const routes = [
    {
        path : '/hr/hrtimekeeping/:id',
        name: 'hrtimekeeping',
        component : () => import('@/views/main/modules/HR/Timekeeping/TimekeepingGroupComponent.vue'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                } else {
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Timekeeping',
        }
    }

];

export default routes;
