import repository from '@/api/modules/HR/Overtime/Overtime'
import store from '@/store';
export default {
	state: {
		hr_Overtime:[],
        hr_overtime_new_data_trigger: false,
		hr_overtime_viewfile_id: [],
		hr_overtime_static_viewfile: [],
		department_access: [],
        current_index: null,
        overtime_hr_pov: []
	},
	getters: {
		GET_OVERTIME_POV:(state)=>state.overtime_hr_pov,
		GET_HR_OVERTIME_NEW_DATA:(state)=>state.hr_overtime_new_data_trigger,
		GET_HR_OVERTIME_VIEWFILE_ID:(state)=>state.hr_overtime_viewfile_id,
		GET_HR_OVERTIME_STATIC_VIEW_FILE:(state)=>state.hr_overtime_static_viewfile,
		GET_DEPARTMENT_ACCESS:(state)=>state.department_access,
		GET_CURRENT_INDEX:(state)=>state.current_index,
	},
	actions:{
		addEmployeeOvertime({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.addEmployeeOvertime(payload).then((response)=>{
					commit('HR_OVERTIME',response)
					commit('HR_OVERTIME_NEW_DATA', true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},

		getOvertimeFiles({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.getOvertimeFiles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},

		getOvertimeRecord({commit}, payload){
			return new Promise((resolve, reject)=>{
                commit('HR_OVERTIME', [])
				repository.getOvertimeRecord(payload).then((response)=>{
					commit('HR_OVERTIME', response)
					if(response.status == 200){
						resolve(response.data.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		showOvertimeRecord({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.showOvertimeRecord(payload).then((response)=>{
					commit('HR_OTHER_TABS_DIALOG_VIEW', response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		updateOvertimeRecord({commit}, payload){
            return new Promise((resolve, reject)=>{
				repository.updateOvertimeRecord(payload).then((response)=>{
					commit('HR_OVERTIME_NEW_DATA', true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		deleteOvertimeRecord({commit}, payload){
            return new Promise((resolve, reject)=>{
				repository.deleteOvertimeRecord(payload).then((response)=>{
					commit('HR_OVERTIME_NEW_DATA', true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		deleteOvertimeFiles({commit}, payload){
            return new Promise((resolve, reject)=>{
				repository.deleteOvertimeFiles(payload).then((response)=>{
					commit('HR_OVERTIME_NEW_DATA', true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		changeOvertimeStatus({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.changeOvertimeStatus(payload).then((response)=>{
					commit('HR_OVERTIME_NEW_DATA', true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		changeOvertimeStatus({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.changeOvertimeStatus(payload).then((response)=>{
					commit('HR_OVERTIME_NEW_DATA', true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		approveEmployeeOvertime({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.approveEmployeeOvertime(payload).then((response)=>{
					commit('HR_OVERTIME_NEW_DATA', true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		batchApproveAndReject({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.batchApproveAndReject(payload).then((response)=>{
					commit('HR_OVERTIME_NEW_DATA', true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		checkDepartmentAccess({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.checkDepartmentAccess(payload).then((response)=>{
					commit('DEPARTMENT_ACCESS', response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        //  For Confirm Tab
        getOvertimeRecordHR({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.getOvertimeRecordHR(payload).then((response)=>{
					commit('OVERTIME_POV', response.data.data)
					if(response.status == 200){
						resolve(response.data.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        showOvertimeRecordHR({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.showOvertimeRecordHR(payload).then((response)=>{
					commit('HR_OTHER_TABS_DIALOG_VIEW', response.data)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        updateOvertimeRecordHR({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.updateOvertimeRecordHR(payload).then((response)=>{
					commit('HR_OVERTIME_NEW_DATA', true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        checkEmployeeBioDate({commit}, payload){
			return new Promise((resolve, reject)=>{
				repository.checkEmployeeBioDate(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
		},
	},
	mutations:{
		HR_OVERTIME:(state, data)=>{state.hr_Overtime = data},
		HR_OVERTIME_NEW_DATA:(state, data)=>{state.hr_overtime_new_data_trigger = data},
		HR_OVERTIME_VIEWFILE_ID:(state, data)=>{state.hr_overtime_viewfile_id = data},
		HR_OVERTIME_STATIC_VIEW_FILE:(state, data)=>{state.hr_overtime_static_viewfile = data},
		DEPARTMENT_ACCESS:(state, data)=>{state.department_access = data},
		CURRENT_INDEX:(state, data)=>{state.current_index = data},
		OVERTIME_POV:(state, data)=>{state.overtime_hr_pov = data},
	},
}
