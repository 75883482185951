import repository from '@/api/modules/HR/HRUsers/HRUsers'
import store from '@/store';

export default {
	state: {
        hr_users : [],
		new_hr_users : false,
        hr_user_departments: [],
        hr_users_location_items: [],
	},
	getters: {
		GET_HR_USERS:(state) => state.hr_users,
		GET_NEW_HR_USERS: state => state.new_hr_users,
		GET_HR_USER_DEPARTMENTS: state => state.hr_user_departments,
		GET_HR_USER_LOCATION_ITEMS: state => state.hr_users_location_items,
	},
	actions:{
		addHRUsers({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addHrUser(payload).then((response)=>{
					commit('NEW_HR_USERS',true)
					if(response.data){
						resolve(response.data)
					}
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
        getHRUsersTable({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getHrUser(payload).then((response)=>{
                    commit('HR_USERS', response);
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        viewUsers({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.viewUsers(payload).then((response)=>{
                    // commit('HR_USERS', response);
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getSelectedUser({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getSelectedUser(payload).then((response)=>{
                    // commit('HR_USERS', response);
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        updateHrUser({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.updateHrUser(payload).then((response)=>{
                    // commit('HR_USERS', response);
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        deleteHrUser({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.deleteHrUser(payload).then((response)=>{
                    // commit('HR_USERS', response);
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
	},

	mutations:{
		HR_USERS:(state, data) => {state.hr_users = data},
		NEW_HR_USERS: (state, data) => {state.new_hr_users = data},
        HR_USER_DEPARTMENTS: (state, data) => {state.hr_user_departments = data},
        HR_USER_LOCATION_ITEMS: (state, data) => {state.hr_users_location_items = data},
	},
}
