import api from '../../../api'

export default {
    getAllAccountingGroups(params){
        return api.get('/accounting/getAllAccountingGroups',{params})
    },
    getAccountingGroupItems(params) {
        return api.get('/accounting/getAccountingGroupItems',{params})
    },
    CreateAccountGroups(params){
        return api.post('/accounting/groups',params)
    },
    UpdateAccountGroups(params){
        return api.put(`/accounting/groups/${params.id}`,params)
    },
    DeleteAccountGroups(params){
        return api.delete(`/accounting/groups/${params}`)
    },
}

