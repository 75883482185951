<template>
    <div>
        <div class="page-wrapper ma-1">
            <div class="row p-2">
                <div class="col-8 d-flex no-block align-items-center">
                    <h4 class="page-title">For Claiming Items In China</h4>
                </div>
            </div>
            <div class="container">
                <v-card class="px-4">
                    <v-layout row class="mx-0 pt-4 px-4">
                        <v-spacer />
                        <v-flex shrink>
                            <v-btn @click="exportExcel(excelHeadersAndData, exportName); exportWithImage()">EXPORT</v-btn>
                        </v-flex>
                    </v-layout>

                    <v-card-title>
                        <v-layout>
                            <v-spacer />
                            <v-flex lg4>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                    clearable
                                />
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                        >
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <div style="display:none">
            <span v-for="(img,index) in imagePaths" :key="img.name">
                <img :id="`img${index}`" :src="`${origin}${img.path}`" alt="">
            </span>
        </div>
    </div>
</template>

<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';
import { mapGetters } from 'vuex';
import JSZip from 'jszip';
export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            headers: [
                { text: 'RF#', value: 'rf_number' },
                { text: 'Qty', value: 'quantity' },
                { text: 'Model', value: 'model' },
                { text: 'FOC', value: 'foc' },
                { text: 'PL#', value: 'packing_list_num' },
                { text: 'Warranty Type', value: 'warranty_type' },
                { text: 'Complaint / Symptom', value: 'defect_description' },
                { text: 'PL Date', value: 'created_at' },
            ],
            items: [],
            search: '',
            excelHeadersAndData: {
                Sheet1: {
                    headers:[
                        {header:'RF#', key:'rf_number', width:15},
                        {header:'Qty', key:'quantity', width:22},
                        {header:'Model', key:'model', width:22},
                        {header:'FOC', key:'foc', width:22},
                        {header:'PL#', key:'packing_list_num', width:16},
                        {header:'Complaint / Symptom', key:'defect_description', width:10},
                        {header:'PL Date', key:'created_at', width:16}
                    ],
                    data:[]
                }
            },
            exportLoading: false,
            exportName: `china-defective-report-items-${this.$dayjs().format('YYYY-MM-DD')}`,
            imagePaths: [],
            origin: window.location.origin,
        }
    },
    components: {
        //
    },
    async mounted() {
        await this.getForChinaClaimingItems();
    },
    computed:{
        ...mapGetters([
            'GET_FOR_CHINA_CLAIMING_ITEMS',
        ])
    },
    methods: {
        getForChinaClaimingItems() {
            this.$store.dispatch('getForChinaClaimingItems').then(response=>{

                this.imagePaths = this.GET_FOR_CHINA_CLAIMING_ITEMS.rf_files;
                this.items = this.GET_FOR_CHINA_CLAIMING_ITEMS.pl_list;
                this.items.forEach(e => e.created_at = this.formatDate(e.created_at))
                this.excelHeadersAndData.Sheet1.data = this.items;
            });
        },
         startDownload() {
            this.exportLoading = true;
        },
        finishDownload() {
            this.exportLoading = false;
        },
        exportWithImage() {
            let zip = new JSZip();
            let dateToday = this.$dayjs().format('YYYY-MM-DD');

            this.imagePaths.forEach((x,i)=>{
                let path = this.origin+x.path;
                let imgId = `img${i}`;

                let img = document.getElementById(imgId);

                let canvas = document.createElement("canvas")
                let ctx = canvas.getContext("2d");
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);

                let dataURL = canvas.toDataURL("image/jpg");

                let base64Img = dataURL.replace(/^data:image\/(png|jpg);base64,/, "")

                let name = x.name;

                if(name.includes('/')) {
                    name = name.replaceAll('/', '.');
                }

                zip.file(name, base64Img, { base64: true });
            });

            zip.generateAsync({type:"base64"})
                .then(function(content) {
                // see FileSaver.js
                // this.$filesaver.saveAs(content, `china-defective-report-images-${dateToday}.zip`);
                // location.href="data:application/zip;base64," + content;

                let link = document.createElement('a')
                link.download = `china-defective-report-images-${dateToday}`;
                link.href="data:application/zip;base64," + content;
                link.click();
            });
        },
    }
}
</script>

<style>

</style>
