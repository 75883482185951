import store from '../../../store';
import api from '../../api'

export default {
    adminDeliveryReceiptPost(params){
        return api.post(`/admin/${params.url}`,params)
    },
    adminDeliveryReceiptGet(params){
        return api.get(`/admin/${params.url}`,{params})
    },
}

