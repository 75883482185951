import repository from '@/api/modules/Services/index'

export default {
    state: {
        get_auto_foc_sp: [],
        TAB: 0,
        foc_dispatch:{},
        dialog_title:'',
        REFRESH: false,

    },

    getters: {
		  GET_AUTO_FOC_SP:(state)=>state.get_auto_foc_sp,
		  GET_FOC_TAB:(state)=>state.TAB,
      GET_FOC_DISPATCH:(state)=>state.foc_dispatch,
      GET_FOC_DIALOG_TITLE:(state) => state.dialog_title,
      GET_REFRESH_FOC:(state)=>state.REFRESH, 

    },

    mutations: {
		  AUTO_FOC_SP:(state,data)=>{state.get_auto_foc_sp = data},
		  FOC_TAB:(state,data)=>{state.TAB = data},
      FOC_DISPATCH:(state,data)=>{state.foc_dispatch = data},
      FOC_DIALOG_TITLE:(state,data)=>{state.dialog_title = data},
      REFRESH_FOC:(state,data)=>{state.REFRESH = data},

    },

    actions: {
        getAutoFocSp({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.getAutoFocSp(payload).then((response)=>{
              commit('AUTO_FOC_SP',response.data)
                if(response.status == 200){
                  resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },

        updateAutoFoc({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.updateAutoFoc(payload).then((response)=>{
              commit('REFRESH_FOC', true)
                if(response.status == 200){
                  resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },

        deleteAutoFoc({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.deleteAutoFoc(payload).then((response)=>{
              commit('REFRESH_FOC', true)
                if(response.status == 200){
                  resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },
    },
}