import Containers from '@/api/modules/Operations/Containers';
import repository from '@/api/modules/Operations/Containers'
import it_repository from '@/api/modules/it'

export default {
  state: {
    // stock_adjustment:[],
    get_all_containers: [],
    view_containers: [],
    warehouse_lists: [],
    get_warehouse_item_stocks: [],
    get_items_by_model: [],
    add_new_containers: [],
    edit_containers: [],
    receive_containers: [],
    approve_containers: [],
    reject_containers: [],
    delete_containers: [],
    export_containers: [],
    container_expiration_dialog: false,
    container_expiration_item: {},
    new_data: false,
    new_id: '',
    close_dialog: false,
  },
  getters: {
    // GET_ALL_STOCK:(state)               => state.stock_adjustment,
    GET_ALL_CONTAINERS: state => state.get_all_containers,
    VIEW_CONTAINERS: state => state.view_containers,
    // GET_ALL_WAREHOUSES: state => state.warehouse_lists,
    GET_WAREHOUSE_ITEM_STOCKS: state => state.get_warehouse_item_stocks,
    GET_ITEMS_BY_MODELS: state => state.get_items_by_model,
    ADD_NEW_CONTAINERS: state => state.add_new_containers,
    EDIT_CONTAINERS: state => state.edit_containers,
    RECEIVE_CONTAINERS: state => state.receive_containers,
    APPROVE_CONTAINERS: state => state.approve_containers,
    REJECT_CONTAINERS: state => state.reject_containers,
    DELETE_CONTAINERS: state => state.delete_containers,
    EXPORT_CONTAINERS: state => state.export_containers,
    GET_CONTAINER_EXPIRATION_DIALOG: state => state.container_expiration_dialog,
    GET_CONTAINER_EXPIRATION_ITEM: state => state.container_expiration_item,
    GET_NEW_DATA: state => state.new_data,
    GET_NEW_ID: state => state.new_id,
    GET_CLOSE_DIALOG: state => state.close_dialog,
  },
  actions: {
    getAllContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .getallcontainers(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('ALL_CONTAINERS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    viewContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .viewcontainers(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('V_CONTAINERS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    // getAllWarehouses({ commit }) {
    //   it_repository.getallwarehouses().then(response => {
    //     commit('WAREHOUSE_LISTS', response.data);
    //   })
    // },
    getWarehouseItemStocks({ commit }, payload) {
      return new Promise((resolve, reject) => {
        it_repository
          .getwarehouseitemstocks(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('WAREHOUSE_ITEM_STOCKS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    // getItemsByModel({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     it_repository
    //       .getitemsbymodel(payload)
    //       .then(response => {
    //         if (response.status == 200) {
    //           resolve(response);
    //           commit('ITEMS_BY_MODEL', response.data);
    //         }
    //       })
    //       .catch(error => {
    //         reject(error);
    //       })
    //   });
    // },
    addNewContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .addnewcontainers(payload)
          .then(response => {
              resolve(response);
              commit('NEW_CONTAINERS', response.data);
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    editContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .editcontainers(payload)
          .then(({ data }) => {
            resolve(data);
            commit('E_CONTAINERS', true)
          })
          .catch(e => {
            reject(e);
          })
      });
    },
    receiveContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .receivecontainers(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('REC_CONTAINERS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    approveContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .approvecontainers(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('APP_CONTAINERS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    rejectContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .rejectcontainers(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('REJ_CONTAINERS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    deleteContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .deletecontainers(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('DEL_CONTAINERS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    exportContainers({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .exportcontainers(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
              commit('EXP_CONTAINERS', response.data);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    sendContainerEmail({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .sendContainerEmail(payload)
          .then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    uploadContainerFiles({ commit }, payload) {
      return new Promise((resolve, reject) => {
        repository
          .uploadContainerFiles(payload.data,payload.config)
          .then(response => {
              resolve(response);
          })
          .catch(error => {
            reject(error);
          })
      });
    },
    getContainerFiles({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.getcontainerfiles(payload).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
            reject(error)
            });
        })
    },
    containerGet({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.containerGet(payload).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
            reject(error)
            });
        })
    },
    containerPost({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.containerPost(payload).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
            reject(error)
            });
        })
    }
  },
  mutations: {
    // STOCK_LOAD    : (state,data)            => {(state.stock_adjustment = data)},
    ALL_CONTAINERS: (state, data) => {
      state.get_all_containers = data;
    },
    V_CONTAINERS: (state, data) => {
      state.view_containers = data;
    },
    WAREHOUSE_LISTS: (state, data) => {
      state.warehouse_lists = data;
    },
    WAREHOUSE_ITEM_STOCKS: (state, data) => {
      state.get_warehouse_item_stocks = data;
    },
    ITEMS_BY_MODEL: (state, data) => {
      state.get_items_by_model = data;
    },
    NEW_CONTAINERS: (state, data) => {
      state.add_new_containers = data;
    },
    E_CONTAINERS: (state, data) => {
      state.edit_containers = data;
    },
    REC_CONTAINERS: (state, data) => {
      state.receive_containers = data;
    },
    APP_CONTAINERS: (state, data) => {
      state.approve_containers = data;
    },
    REJ_CONTAINERS: (state, data) => {
      state.reject_containers = data;
    },
    DEL_CONTAINERS: (state, data) => {
      state.delete_containers = data;
    },
    EXP_CONTAINERS: (state, data) => {
      state.export_containers = data;
    },
    CONTAINER_EXPIRATION_DIALOG: (state, data) => {
      state.container_expiration_dialog = data;
    },
    CONTAINER_EXPIRATION_ITEM: (state, data) => {
      state.container_expiration_item = data;
    },
    newData: (state, data) => {
      state.new_data = data;
    },
    NEW_ID: (state, data) => {
      state.new_id = data;
    },
    CLOSE_DIALOG: (state, data) => {
      state.close_dialog = data;
    },
  },
}
