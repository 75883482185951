import store from '../../../store';
import api from '../../api'

export default {
    adminInboundOutboundPost(params){
        return api.post(`/admin/${params.url}`,params)
    },
    adminInboundOutboundGet(params){
        return api.get(`/admin/${params.url}`,{params})
    },
    adminInboundOutboundShowItems(params){
        return api.get(`/admin/${params.url}/${params.id}`)
    }
}

