import repository from '@/api/modules/HR/Leaves/leaves'
import store from '@/store';
export default {
	state: {
		hr_leaves_request:[],
		hr_leave_type: [],
        hr_leave_new_data_trigger: false,
        hr_leave_new_leave_type_trigger: false,
        hr_leaves_data_view: {},
        hr_employee_leave_with_pay: [],
        hr_leave_request_approvable: false,
        hr_leave_request_dates_by_leave_type: [],
        hr_leave_request_dates_only: [],
        hr_leave_loading: {
            leave_type: false,
            employee_list: false,
        },
        hr_verification: {
            dialog: false,
            email: '',
            leave_request: {
                id: '',
                code: '',
                employee_id: '',
            }
        }
	},
	getters: {
		GET_HR_LEAVES_REQUEST:(state)=>state.hr_leaves_request,
		GET_HR_LEAVES_TYPE:(state)=>state.hr_leave_type,
		GET_HR_LEAVES_NEW_DATA:(state)=>state.hr_leave_new_data_trigger,
		GET_HR_LEAVES_NEW_LEAVE_TYPE_DATA:(state)=>state.hr_leave_new_leave_type_trigger,
        GET_HR_LEAVES_DATA_VIEW:(state)=>state.hr_leaves_data_view,
        GET_HR_EMPLOYEE_LEAVE_WITH_PAY:(state)=>state.hr_employee_leave_with_pay,
        GET_HR_LEAVE_REQUEST_APPROVABLE:(state)=>state.hr_leave_request_approvable,
        GET_HR_LEAVE_REQUEST_DATES_BY_LEAVE_TYPE:(state)=>state.hr_leave_request_dates_by_leave_type,
        GET_HR_LEAVE_REQUEST_DATES_ONLY:(state)=>state.hr_leave_request_dates_only,
        GET_HR_LOADING:(state)=>state.hr_leave_loading,
        GET_HR_VERIFICATION:(state)=>state.hr_verification,
	},
	actions:{
		// Leave Request Actions
		AddHrLeavesRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addLeaveRequest(payload).then((response)=>{
					commit('HR_LEAVES_NEW_DATA',true)
						resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		UpdateHrLeavesRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateLeaveRequest(payload).then((response)=>{
					commit('HR_LEAVES_NEW_DATA',true)
						resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		DeleteHrLeavesRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deleteLeaveRequest(payload).then((response)=>{
					commit('HR_LEAVES_NEW_DATA',true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        GetHrLeavesRequest({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getLeaveRequest(payload).then((response)=>{
                    commit('HR_LEAVES_REQUEST', response.data)
                    commit('HR_USER_DEPARTMENTS', response.data.hr_user_departments)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
		ShowHrLeavesRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showLeaveRequest(payload).then((response)=>{
					// commit('HR_LEAVES_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getLeaveRequestFiles({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getLeaveRequestFiles(payload).then((response)=>{
					// commit('HR_LEAVES_NEW_DATA',true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        changeLeaveRequestStatus({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.changeLeaveRequestStatus(payload).then((response)=>{
					commit('HR_LEAVES_NEW_DATA', true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		// Leave Type Actions
		AddHrLeavesTypes({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addLeaveTypes(payload).then((response)=>{
					commit('HR_LEAVES_NEW_LEAVE_TYPE_DATA', true)
						resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        UpdateHrLeavesTypes({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateLeaveTypes(payload).then((response)=>{
					commit('HR_LEAVES_NEW_LEAVE_TYPE_DATA', true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		DeleteHrLeavesTypes({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deleteLeaveTypes(payload).then((response)=>{
					commit('HR_LEAVES_NEW_LEAVE_TYPE_DATA', true)
						resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		GetHrLeavesTypes({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getLeaveTypes(payload).then((response)=>{
					commit('HR_LEAVES_TYPE',response.data)
						resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		ShowHrLeavesTypes({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showLeaveTypes(payload).then((response)=>{
					commit('HR_LEAVES_NEW_LEAVE_TYPE_DATA', true)
						resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },

		// Leave Balances Actions
		addLeaveBalance({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addLeaveBalance(payload).then((response)=>{
					commit('HR_LEAVES_NEW_DATA', true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        updateLeaveBalance({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateLeaveBalance(payload).then((response)=>{
					commit('HR_LEAVES_NEW_DATA', true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		deleteLeaveBalance({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deleteLeaveBalance(payload).then((response)=>{
					commit('HR_LEAVES_NEW_DATA', true)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getLeaveBalance({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getLeaveBalance(payload).then((response)=>{
					commit('HR_LEAVES_TYPE',response.data)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		showLeaveBalance({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showLeaveBalance(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		checkLeaveWithPay({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.checkLeaveWithPay(payload).then((response)=>{
                    resolve(response.data)
                    commit('HR_EMPLOYEE_LEAVE_WITH_PAY', response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		checkLeaveRequestWithPay({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.checkLeaveRequestWithPay(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		saveLeaveRequestResetValue({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.saveLeaveRequestResetValue(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getLeaveResetValue({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getLeaveResetValue(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		checkLeaveIfApprovable({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.checkLeaveIfApprovable(payload).then((response)=>{
                    resolve(response.data)
                    commit('HR_LEAVE_REQUEST_APPROVABLE', response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getLeaveRequestDatesByLeaveType({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getLeaveRequestDatesByLeaveType(payload).then((response)=>{
                    resolve(response.data)
                    commit('HR_LEAVE_REQUEST_DATES_BY_LEAVE_TYPE', response.data)

                    let temp = []
                    let arr = []
                    temp = response.data.filter((x) => x.dates.length > 0);
                    temp.forEach((e) =>{
                        if(payload.action == 'Update'){
                            if(e.value != payload.leave_type_id){
                                arr.push(...e.dates)
                            }
                        }else{
                            arr.push(...e.dates)
                        }
                    })
                    commit('HR_LEAVE_REQUEST_DATES_ONLY', arr)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        checkLeaveRequestVerCode({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.checkLeaveRequestVerCode(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        generateNewVerCode({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.generateNewVerCode(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        getEmployeeDataDetails({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getEmployeeDataDetails(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
	},
	mutations:{
		HR_LEAVES_REQUEST:(state,data)=>{state.hr_leaves_request = data},
		HR_LEAVES_TYPE:(state,data)=>{state.hr_leave_type = data},
		HR_LEAVES_NEW_DATA:(state,data)=>{state.hr_leave_new_data_trigger = data},
		HR_LEAVES_NEW_LEAVE_TYPE_DATA:(state,data)=>{state.hr_leave_new_leave_type_trigger = data},
		HR_LEAVES_DATA_VIEW:(state,data)=>{state.hr_leaves_data_view = data},
		HR_EMPLOYEE_LEAVE_WITH_PAY:(state,data)=>{state.hr_employee_leave_with_pay = data},
		HR_LEAVE_REQUEST_APPROVABLE:(state,data)=>{state.hr_leave_request_approvable = data},
		HR_LEAVE_REQUEST_DATES_BY_LEAVE_TYPE:(state,data)=>{state.hr_leave_request_dates_by_leave_type = data},
		HR_LEAVE_REQUEST_DATES_ONLY:(state,data)=>{state.hr_leave_request_dates_only = data},
		HR_VERIFICATION_DIALOG:(state,data)=>{state.hr_verification.dialog = data},
		HR_VERIFICATION_EMAIL:(state,data)=>{state.hr_verification.email = data},
		HR_VERIFICATION_LEAVE_REQUEST_ID:(state,data)=>{state.hr_verification.leave_request.id = data},
		HR_VERIFICATION_EMPLOYEE_ID:(state,data)=>{state.hr_verification.leave_request.employee_id = data},
		HR_LOADING:(state, data)=>{
            state.hr_leave_loading[data.key] = data.value
        },
	},
}
