
import repository from '@/api/credentials';

const routes = [{
    path: '/operations/inventorystocktransfer/:id',
    name: 'warehousestocktransfernew',
    component: () => import('@/views/main/modules/Operations/StockTransferNew/InventoryTransferComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })

    },
    meta: {
        requiresAuth: true,
        title: 'Stock Transfers',
    }
}]

export default routes;
