import api from '../../../api'
export default {

    getcustomerpaymentdetails(params){
        return api.post('accounting/getCustomerPaymentDetails',params)
    },
    deletecustomerpaymentdetail(id){
        return api.post(`accounting/deleteCustomerPaymentDetail/${id}`)
    },
    getpaymenttypes(){
        return api.get(`accounting/getPaymentTypes`)
    },
    getbanks(){
        return api.get(`accounting/getBanks`)
    },
    getcashdenominations(){
        return api.get(`accounting/getCashDenominations`)
    },
    createcustomerpaymentdetail(params,config){
        return api.post(`accounting/createCustomerPaymentDetail`,params,config)
    },
    getcustomerpaymentdetail(id){
        return api.get(`accounting/getCustomerPaymentDetail/${id}`)
    },
    updatecustomerpaymentdetail(params,config){
        return api.post(`accounting/updateCustomerPaymentDetail`,params,config)
    },
    getpaymenttype(id){
        return api.get(`accounting/getPaymentType/${id}`)
    },
    createpaymenttype(params){
        return api.post(`accounting/createPaymentType`,params)
    },
    updatepaymenttype(params){
        return api.post(`accounting/updatePaymentType`,params)
    },
    deletepaymenttype(params){
        return api.post('accounting/deletePaymentType',params)
    },
    soagetallapproved(params){
        return api.post(`soa/statementOfAccounts/getAllApproved`,params)
    },
    getallpaymenttypes(){
        return api.get(`accounting/getAllPaymentTypes`)
    },
    getcustpaymentdetbycustomer(id){
        return api.get(`accounting/getCustPaymentDetByCustomer/${id}`)
    },
    updatecustomersoapayments(params){
        return api.post('accounting/updateCustomerSoaPayments',params)
    },
    getviewcustomerpaymentdetail(id){
        return api.get(`accounting/getViewCustomerPaymentDetail/${id}`)
    },
    updatecustpayment(params){
        return api.post('accounting/updateCustPayment',params)
    },
    getsoacustomerpayments(id){
        return api.get(`accounting/getSoaCustomerPayments/${id}`)
    },
    customerpaymentallocations(params){
        return api.post('accounting/customerPaymentAllocations',params)
    },
    updatechequedate(params){
        return api.post('accounting/updateChequeDate',params)
    },
    storeChequeMovement(params){
        return api.post('accounting/storeChequeMovement',params)
    },
    storePaymentAllocation(params){
        return api.post('accounting/storePaymentAllocation',params)
    },
    exportPaymentDetails(params){
        return api.get(`accounting/exportPaymentDetails`,{params})
    },
    updatedepositdate(params){
        return api.post('accounting/updateDepositDate',params)
    },
    confirmAndAllocatePayment(params){
        return api.post('accounting/confirmAndAllocatePayment',params)
    },
    getToAllocateSoa(params){
        return api.get(`soa/getToAllocateSoa`,{params})
    },
}
