import repository from '@/api/modules/MasterData/accountspayablesettings'
export default {
  state: {
    new_cheque_settings:false,
    edit_cheque_setting:{}
  },
  getters: {
    GET_NEW_CHEQUE_SETTINGS:(state)=>state.new_cheque_settings,
    GET_EDIT_CHEQUE_SETTING:(state)=>state.edit_cheque_setting
  },
  actions: {
      getAllChequeSettings({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.getAllChequeSettings(payload).then((response)=>{
                resolve(response.data);
            }).catch((error)=>{
                reject(error);
            })
        })
    },
    saveChequeSettings({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.saveChequeSettings(payload).then((response)=>{
                resolve(response.data);
                commit('NEW_CHEQUE_SETTINGS',true);
            }).catch((error)=>{
                reject(error);
            })
        })
    },
    updateChequeSetting({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.updateChequeSetting(payload).then((response)=>{
                resolve(response.data);
                commit('NEW_CHEQUE_SETTINGS',true);
            }).catch((error)=>{
                reject(error);
            })
        })
    },
    deleteChequeSetting({commit},payload){
        return new Promise((resolve,reject)=>{
            repository.deleteChequeSetting(payload).then((response)=>{
                resolve(response.data);
                commit('NEW_CHEQUE_SETTINGS',true);
            }).catch((error)=>{
                reject(error);
            })
        })
    }
  },
  mutations: {
    NEW_CHEQUE_SETTINGS:(state,data)=>{
      state.new_cheque_settings = data
    },
    EDIT_CHEQUE_SETTING:(state,data)=>{
      state.edit_cheque_setting = data
    }
  },
};
