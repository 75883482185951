import repository from '@/api/modules/Services/index'

export default {
    state: {
        get_defective: [],
        TAB: 0,
        defective_dispatch: {},
        dialog_title:'',
        REFRESH: false,
        defective_dialog_view: {},
        ACTION:'',

    },

    getters: {
        GET_DEFECTIVE:(state)=>state.get_defective,
		GET_DEFECTIVE_TAB:(state)=>state.TAB,
        GET_DEFECTIVE_DISPATCH:(state)=>state.defective_dispatch,
        GET_DEFECTIVE_DIALOG_TITLE:(state) => state.dialog_title,
        GET_REFRESH_DEFECTIVE:(state)=>state.REFRESH, 
        GET_DEFECTIVE_DIALOG_VIEW:(state)=>state.defective_dialog_view,
        
    },

    mutations: {
        DEFECTIVE:(state,data)=>{state.get_defective = data},
		DEFECTIVE_TAB:(state,data)=>{state.TAB = data},
        DEFECTIVE_DISPATCH:(state,data)=>{state.defective_dispatch = data},
        DEFECTIVE_DIALOG_TITLE:(state,data)=>{state.dialog_title = data},
        REFRESH_DEFECTIVE:(state,data)=>{state.REFRESH = data},
        DEFECTIVE_DIALOG_VIEW:(state,data)=>{state.defective_dialog_view = data},

    },

    actions: {
        getDefective({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.getDefective(payload).then((response)=>{
                if(response.status == 200){
                    commit('DEFECTIVE',response.data)
                    resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },

        approveDTA({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.approveDTA(payload).then((response)=>{
                if(response.status == 200){
                    resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },

        reAssessmentDTA({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.reAssessmentDTA(payload).then((response)=>{
                if(response.status == 200){
                    resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },

        pullOutDTA({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.pullOutDTA(payload).then((response)=>{
                if(response.status == 200){
                    resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },
     
        confirmDCA({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.confirmDCA(payload).then((response)=>{
                if(response.status == 200){
                    resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },

        confirmRepair({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.confirmRepair(payload).then((response)=>{
                if(response.status == 200){
                    resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },

        
    },
}