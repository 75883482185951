import Swal from "sweetalert2";
export default {
    state: {
 
    },
    getters: {
    
    },
    mutations: {
      
    },
    actions: {
        SwalAlertQuestion({commit},payload){
            return Swal.fire({
                showConfirmButton: true,
                showCloseButton: false,
                confirmButtonColor: '#397373',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonColor: 'grey',
                ...payload
            })
        },
        SwalAlertWarning({commit},payload){
            return Swal.fire({
                icon:'warning',
                ...payload
            })
        },
        SwalAlertSuccess({commit},payload){
            return Swal.fire({
                icon:'success',
                ...payload
            })
        },
    },
  };
  