import repository from "@/api/modules/Accounting/PaymentTransmittal";

export default {
    state: {
        payment_transmittal_dispatch: {},
        payment_transmittal_view: {},
        refresh: false,
    },

    getters: {
        GET_PAYMENT_TRANSMITTAL_DISPATCH:(state)=>state.payment_transmittal_dispatch,
        GET_PAYMENT_TRANSMITTAL_VIEW:(state)=>state.payment_transmittal_view,
        GET_PAYMENT_TRANSMITTAL_REFRESH:(state)=>state.refresh,
    },

    mutations: {
        PAYMENT_TRANSMITTAL_DISPATCH:(state,data)=>{state.payment_transmittal_dispatch = data},
        PAYMENT_TRANSMITTAL_VIEW:(state,data)=>{state.payment_transmittal_view = data},
        PAYMENT_TRANSMITTAL_REFRESH:(state,data)=>{state.refresh = data},
    },

    actions: {
        async paymentTransmittalGet({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.paymentTransmittalGet(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async paymentTransmittalPost({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.paymentTransmittalPost(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
    },

}