import api from "../../api";

export default {
    adminAssetConditionGet(params) {
        return api.get(`/admin/asset-condition/${params.url ?? ""}`, { params });
    },
    adminAssetConditionPost(params) {
        return api.post(`/admin/asset-condition/${params.url ?? ""}`, params);
    },
    adminAssetConditionPut(params) {
        return api.put(`/admin/asset-condition/${params.url ?? ""}`, params);
    },
    adminAssetConditionDelete(params) {
        return api.delete(`/admin/asset-condition/${params.url ?? ""}`, params);
    },
};
