import api from '../../api'
export default {

    getWebScraping(params){
        return api.get(`web-scraping/${params.url}`,{params});
    },
    postWebScraping(params){
        return api.post(`web-scraping/${params.url}`,params);
    },
    postConfigWebScraping(params){
        return api.post(`web-scraping/${params.url}`,params.params,params.config);
    },
    // getWebScrapingImage(params){
    //     return api.post(`web-scraping/${params.url}`,params.params,params.config);
    // }
}
