<template>
    <v-container fluid class="pa-3">
        <v-row class="ma-1">
            <v-col cols="2">
                <h3 class="page-title">{{ title }}</h3>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-content-end">
                <v-btn @click="toggleCardSelection()">Add Cards</v-btn>
            </v-col>
        </v-row>
        <v-row class="ma-1">
            <v-tabs v-model="selected_tab" color="cyan" slider-color="cyan" background-color="#424242" dark show-arrows>
                <v-tab v-for="tab in GET_ALL_DASHBOARD_MODULES" :key="tab.title" ripple>{{ tab.title }}</v-tab>
                <v-tabs-items v-model="selected_tab">
                    <v-tab-item v-for="tab in GET_ALL_DASHBOARD_MODULES" :key="tab.title">
                        <DepartmentDashboard v-if="selected_tab == tab.id - 1" :grid="grid" :module_id="tab.id" :module_title="tab.title"></DepartmentDashboard>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-row>
        <HomeDashboardCardSelection
            v-if="trigger_dialog"
            :trigger_dialog="trigger_dialog"
            :tabs="GET_ALL_DASHBOARD_MODULES"
            :tab_filter="selected_tab"
            @toggleCardSelection="toggleCardSelection"
        ></HomeDashboardCardSelection>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    components: {
        HomeDashboardCardSelection: () => import('./HomeDashboardCardSelection.vue'),
        DepartmentDashboard: () => import('./DepartmentDashboard.vue'),
    },
    data() {
        return {
            title: 'Dashboard',
            grid: {
                colNum: 12,
                rowHeight: 45,
                responsive: true,
                draggable: true,
                resizable: false,
                verticalCompact: true,
                useCssTransforms: true,
            },
            tabs: [],
            selected_tab: null,
            trigger_dialog: false,
        }
    },
    methods: {
        toKebabCase(str) {
            return str &&
                str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                    .map(x => x.toLowerCase())
                    .join('-');
        },
        toggleCardSelection() {
            this.trigger_dialog = !this.trigger_dialog
        },
    },
    mounted() {
        this.$store.dispatch('getAllDashboardModules')
    },
    computed: {
        ...mapGetters([
            'GET_ALL_DASHBOARD_MODULES'
        ]),
        windowHeight() {
            return window.innerHeight
        },
    }
}
</script>
