import repository from "@/api/modules/Sales/Dashboard/index";

export default {
    state: {
        totals: [],
        by_region:[],
        by_type:[],
        by_category:[],
        by_channel:[],
        by_dms:[],
    },
    getters: {
        GET_TOTALS_DB: (state) => state.totals,
        GET_SALES_BY_REGION_DB: (state) => state.by_region,
        GET_SALES_BY_TYPE: (state) => state.by_type,
        GET_SALES_BY_CATEGORY: (state) => state.by_category,
        GET_SALES_BY_CHANNEL: (state) => state.by_channel,
        GET_SALES_BY_DMS: (state) => state.by_dms,
    },
    actions: {
        getSalesDb({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.salesdbget(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getTotalsDb({commit},payload){
            repository.salesdbget(payload).then((response)=>{
                commit('TOTAL_DB',response.data)
            })
        },
        getSalesByRegion({commit},payload){
            repository.salesdbget(payload).then((response)=>{
                commit('SALES_BY_REGION',response.data)
            })
        },
        getSalesByType({commit},payload){
            repository.salesdbget(payload).then((response)=>{
                commit('SALES_BY_TYPE',response.data)
            })
        },
        getSalesByCategory({commit},payload){
            repository.salesdbget(payload).then((response)=>{
                commit('SALES_BY_CATEGORY',response.data)
            })
        },
        getSalesByChannel({commit},payload){
            repository.salesdbget(payload).then((response)=>{
                commit('SALES_BY_CHANNEL',response.data)
            })
        },
        getDms({commit},payload){
            repository.salesdbget(payload).then((response)=>{
                commit('SALES_BY_DMS',response.data)
            })
        },
    },
    mutations: {
        TOTAL_DB: (state, data) => { (state.totals = data) },
        SALES_BY_REGION: (state, data) => { (state.by_region = data) },
        SALES_BY_TYPE: (state, data) => { (state.by_type = data) },
        SALES_BY_CATEGORY: (state, data) => { (state.by_category = data) },
        SALES_BY_CHANNEL: (state, data) => { (state.by_channel = data) },
        SALES_BY_DMS: (state, data) => { (state.by_dms = data) },
    }

}
