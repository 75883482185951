import api from '../../api'

export default {
    gettotalassets(params){
        return api.get('dashboard/admin/total-assets', {params});
    },
    getassetcountbydept(params){
        return api.get('dashboard/admin/asset-count-by-dept', {params});
    },
    getassetcountbystatus(params){
        return api.get('dashboard/admin/asset-count-by-status', {params});
    },
    gettotalaccountability(params){
        return api.get('dashboard/admin/total-accountability', {params});
    },
    getassetcountbyclassification(params){
        return api.get('dashboard/admin/asset-count-by-classification', {params});
    },
    gettotalpendingpurchaserequest(params){
        return api.get('dashboard/admin/total-pending-purchase-request', {params});
    },
    getassetcountbysupplier(params){
        return api.get('dashboard/admin/asset-count-by-supplier', {params});
    },
    gettotalpendingpurchaseorder(params){
        return api.get('dashboard/admin/total-pending-purchase-order', {params});
    },
    getitemscountbyvendor(params){
        return api.get('dashboard/admin/items-count-by-vendor', {params});
    },
    getassetcountbycategory(params){
        return api.get('dashboard/admin/asset-count-by-category', {params});
    },
}
