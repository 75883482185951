import repository from '@/api/modules/Admin/shippingVia'
import store from '@/store';
export default {
	state: {
		REFRESH: false,
		PRINT: false,
		EXPORT: false,
		DATA: [],
       
	},
	getters: {
        SHIPPING_VIA:(state)=>state,
	},
	actions:{
        shippingViaGet({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.shippingViaGet(payload).then((response)=>{
					commit('SHIPPING_VIA_REFRESH', false)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        shippingViaPost({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.shippingViaPost(payload).then((response)=>{
					commit('SHIPPING_VIA_REFRESH', true)
                    resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
	},

	mutations:{
        SHIPPING_VIA_REFRESH:(state,data) =>{
			state.REFRESH = data
		},
	},
}
