import repository from '@/api/modules/HR/employeeData/employeeData'
import store from '@/store';
export default {
	state: {
        employee_data : [],
		employee_data_view:[],
		new_employee_data : false,
		employee_data_table_count:'',
		org_chart_data: {},
		files:[],
		hr_settings:[],
		idPicture:null,
		isEmployeeList: 0,
		id_picture_preview_dialog: false,
		id_picture_details :{},
		id_picture_upload_dialog: false,
		id_picture_action : '',
		id_templates:[],
        batchUploadData: {},
        file_viewer_settings:{
            dispatch:'',
            module:'',
            upload:''
        },
        exportHeaders:[],
        exportFilters:{},
        employeeRequirements:[],
        uploadedRequirements: {
            tor: [],
            diploma: [],
            tin: [],
            marriage_certificate: [],
            birth_certificate: [],
            nbi_clearance: [],
            barangay_clearance: [],
            sss: [],
            philhealth: [],
            pagibig: [],
            valid_id: [],
            medical_result: [],
        },
        validationButtonColor: 'black',
        isNewEmployee: 0,
	},
	getters: {
		GET_EMPLOYEE_DATA_VIEW:(state) => state.employee_data_view,
		GET_NEW_EMPLOYEE_DATA: state => state.new_employee_data,
		GET_EMPLOYEE_DATA_TABLE_COUNT:(state) => state.employee_data_table_count,
		GET_ORG_CHART_DATA:(state) => state.org_chart_data,
		GET_FILES:(state) => state.files,
		GET_SETTINGS:(state) => state.hr_settings,
		GET_ID_PICTURE:(state) => state.idPicture,
		GET_IS_EMPLOYEE_LIST:(state) => state.isEmployeeList,
		GET_ID_PICTURE_DIALOG:(state) => state.id_picture_dialog,
		GET_ID_PICTURE_DETAILS:(state) => state.id_picture_details,
		GET_ID_PICTURE_UPLOAD_DIALOG:(state) => state.id_picture_upload_dialog,
		GET_ID_PICTURE_ACTION:(state) => state.id_picture_action,
        GET_ID_PICTURE_DIALOG:(state) => state.id_picture_preview_dialog,
		GET_ID_TEMPLATES:(state) => state.id_templates,
		GET_FILE_VIEWER_SETTINGS:(state) => state.file_viewer_settings,
		GET_EXPORT_HEADERS:(state) => state.exportHeaders,
		GET_EXPORT_FILTERS:(state) => state.exportFilters,
		GET_EMPLOYEE_REQUIREMENTS:(state) => state.employeeRequirements,
		GET_UPLOADED_REQUIREMENTS:(state) => state.uploadedRequirements,
		GET_VALIDATION_COLOR:(state) => state.validationButtonColor,
		GET_IS_NEW_EMPLOYEE:(state) => state.isNewEmployee,
	},
	actions:{
		addEmployeeData({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addEmployeeData(payload).then((response)=>{
					commit('NEW_EMPLOYEE_DATA',true)
					if(response.data){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getEmployeeData({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getEmployeeData(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		updateEmployeeData({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateEmployeeData(payload).then((response)=>{
					commit('NEW_EMPLOYEE_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		deleteEmployeeData({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deleteEmployeeData(payload).then((response)=>{
					commit('NEW_EMPLOYEE_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		showEmployeeData({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showEmployeeData(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getEmployeeDataTableCount({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getEmployeeDataTableCount(payload).then((response)=>{
					commit('EMPLOYEE_DATA_TABLE_COUNT',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getOrgChartData({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getOrgChartData(payload).then((response)=>{
					commit('ORG_CHART_DATA',response.data.ds)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		ApproveEmployeeData({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.approveEmployeeData(payload).then((response)=>{
                    commit('NEW_EMPLOYEE_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getFiles({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getfiles(payload).then((response)=>{
					commit('FILES_DATA',response)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		uploadFiles({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.uploadfiles(payload.params,payload.config).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		hrSettings({commit},payload){
            return new Promise((resolve,reject)=>{
				commit('HR_SETTINGS',response)
				repository.hrsettings(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getBasicRecordsOfOldEmployee({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getBasicRecords(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getIDTemplates({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getIDTemplates(payload).then((response)=>{
                    commit('ID_TEMPLATES',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		saveIDTemplates({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.saveIDTemplates(payload).then((response)=>{
					resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		updateEmployeeSalaryType({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updateEmployeeSalaryType(payload).then((response)=>{
					resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		saveFromExcelHR({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.saveFromExcelHR(payload).then((response)=>{
                    commit('HR_NEW_DATA_TRIGGER',true)
					resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        getEmployeeRequirementFiles({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getEmployeeRequirementFiles(payload).then((response)=>{
                        commit('EMPLOYEE_REQUIREMENTS', response.data)
						resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        uploadEmployeeNewRequirements({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.uploadEmployeeNewRequirements(payload.params,payload.config).then((response)=>{
					commit('HR_FILE_VIEWER_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        uploadEmployeeNewRequirementsMultiple({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.uploadEmployeeNewRequirementsMultiple(payload.params,payload.config).then((response)=>{
					commit('HR_FILE_VIEWER_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },

        // Employee Data Temp
        saveEmployeeDataTemp({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.saveEmployeeDataTemp(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        getEmployeeDataTemp({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getEmployeeDataTemp(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        showEmployeeDataChanges({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showEmployeeDataChanges(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        showEmployeeDataTemp({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showEmployeeDataTemp(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        deleteEmployeeDataTemp({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deleteEmployeeDataTemp(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        approveEmployeeTemp({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.approveEmployeeTemp(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
	},

	mutations:{
		EMPLOYEE_DATA_VIEW:(state,data) => {state.employee_data_view = data},
		NEW_EMPLOYEE_DATA: (state, data) => {
			(state.new_employee_data = data);
		  },
		EMPLOYEE_DATA_TABLE_COUNT:(state,data) => {state.employee_data_table_count = data},
		ORG_CHART_DATA:(state,data) => {state.org_chart_data = data},
		FILES_DATA:(state,data) => {state.files = data},
		HR_SETTINGS:(state,data) => {state.hr_settings = data},
		ID_PICTURE:(state,data) => {state.idPicture = data},
		IS_EMPLOYEE_LIST:(state,data) => {state.isEmployeeList = data},
		ID_PICTURE_DIALOG:(state,data) => {state.id_picture_dialog = data},
		ID_PICTURE_DETAILS:(state,data) => {state.id_picture_details = data},
		ID_PICTURE_UPLOAD_DIALOG:(state,data) => {state.id_picture_upload_dialog = data},
		ID_PICTURE_ACTION:(state,data) => {state.id_picture_action = data},
		ID_TEMPLATES:(state,data) => {state.id_templates = data},
		FILE_VIEWER_DISPATCH:(state,data) => {state.file_viewer_settings.dispatch = data},
		FILE_VIEWER_MODULE:(state,data) => {state.file_viewer_settings.module = data},
		FILE_VIEWER_UPLOAD:(state,data) => {state.file_viewer_settings.upload = data},
        ID_PICTURE_DIALOG:(state,data) => {state.id_picture_preview_dialog = data},
        EXPORT_HEADERS:(state,data) => {state.exportHeaders = data},
        EXPORT_FILTERS:(state,data) => {state.exportFilters = data},
        EMPLOYEE_REQUIREMENTS:(state,data) => {state.employeeRequirements = data},
        UPLOADED_REQUIREMENTS:(state,data) => {state.uploadedRequirements = data},
        VALIDATION_COLOR:(state,data) => {state.validationButtonColor = data},
        IS_NEW_EMPLOYEE:(state,data) => {state.isNewEmployee = data},
	},
}
