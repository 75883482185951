import api from '../../../api'
export default{

    calendarget(params){
      return api.get(`/inventory/`+params.url,{params})
    },
    calendarpost(params){
      return api.post(`/inventory/`+params.data.url,params)
    },
    calendarput(params){
      return api.put(`/inventory/`+params.url,params)
    },
    calendardelete(params){
      return api.delete(`/inventory/`+params.url,params)
    },

}
