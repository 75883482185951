import repository from '@/api/credentials'

const routes = [
    {
        path: '/hr/employeememo/:id',
        name: 'employeememo',
        component: () => import('@/views/main/modules/HR/EmployeeMemo/EmployeeMemoGroupComponent'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                }else{
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'Employee Memo'
        }
    },
];

export default routes;
