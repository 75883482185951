
import repository from "@/api/credentials";

const routes = [{
    path: 'reports/inventory/inventory-counts/:id',
    name: 'inventory-counts',
    component: () => import('@/views/main/modules/Reports/InventoryReports/InventoryCountComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Inventory Count Report',
    }
},
{
    path: 'reports/inventory/item-movements/:id',
    name: 'item-movements',
    component: () => import('@/views/main/modules/Reports/InventoryReports/ItemMovementComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Item Movement Report',
    }
},
{
    path: 'reports/inventory/daily-movement-reports/:id',
    name: 'daily-movement-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/DailyMovementReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Daily Movement Report',
    }
},
{
    path: 'reports/inventory/pending-stock-card-by-customers/:id',
    name: 'pending-stockcard-by-customers',
    component: () => import('@/views/main/modules/Reports/InventoryReports/PendingSCByCustomersComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending SC By Customer Report',
    }
},
{
    path: 'reports/inventory/pending-stock-card-by-items/:id',
    name: 'pending-stockcard-by-items',
    component: () => import('@/views/main/modules/Reports/InventoryReports/PendingSCByItemsComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending SC By Item Report',
    }
},
{
    path: 'reports/inventory/pending-stock-card-by-pl/:id',
    name: 'pending-stockcard-by-pl',
    component: () => import('@/views/main/modules/Reports/InventoryReports/PendingSCByPLComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Pending SC By PL Report',
    }
},
{
    path: 'reports/inventory/cycle-count-status-percentage/:id',
    name: 'cycle-count-status-percentage',
    component: () => import('@/views/main/modules/Reports/InventoryReports/CycleCountStatusPercentageComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Cycle Count Status Percentage Report',
    }
},
{
    path: 'reports/inventory/cycle-count-consolidated-variance/:id',
    name: 'cycle-count-consolidated-variance',
    component: () => import('@/views/main/modules/Reports/InventoryReports/CycleCountConsolidatedItemVarianceComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Cycle Count Consolidated Item Variance Report',
    }
},
{
    path: 'reports/inventory/stock-request-reports/:id',
    name: 'stock-request-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/StockRequestReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Stock Request Report',
    }
},
{
    path: 'reports/inventory/stock-transfer-reports/:id',
    name: 'stock-transfer-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/StockTransferReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Stock Transfer Report',
    }
},
{
    path: 'reports/inventory/daily-warehouse-inventory-reports/:id',
    name: 'daily-warehouse-inventory-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/DailyWarehouseInventoryRerportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Daily Warehouse Inventory Report',
    }
},
{
    path: 'reports/inventory/trucks-per-day-reports/:id',
    name: 'trucks-per-day-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/TrucksPerDayReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Trucks Per Day Report',
    }
},
{
    path: 'reports/inventory/shipment-per-item-reports/:id',
    name: 'shipment-per-item-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/ShipmentPerItemReport'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Shipment Per Item Report',
    }
},
{
    path: 'reports/inventory/sales-quotaions-reports/:id',
    name: 'sales-quotations-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/SalesQuotationReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Sales Quotations Report',
    }
},
{
    path: 'reports/inventory/container-item-reports/:id',
    name: 'container-item-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/ContainerReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Container Report',
    }
},
{
    path: 'reports/inventory/order-items-with-stock-reports/:id',
    name: 'order-items-with-stock-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/OrderItemWithStocksReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Order Item With Stocks Report',
    }
},
{
    path: 'reports/inventory/items-with-new-models-report/:id',
    name: 'items-with-new-models-report',
    component: () => import('@/views/main/modules/Reports/InventoryReports/ItemsWithNewModelsComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Items With New Models Report',
    },
},
{
    path: 'reports/inventory/inactive-items-reports/:id',
    name: 'inactive-items-reports',
    component: () => import('@/views/main/modules/Reports/InventoryReports/InactiveItemsActualVsVirtualReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Inactive Items: Actual vs Virtual Stocks',
    },
},
{
    path: 'reports/inventory/items-which-container-last-arrived/:id',
    name: 'items-which-container-last-arrived',
    component: () => import('@/views/main/modules/Reports/InventoryReports/ItemsWhichContainerLastArrivedComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Items Which Container Last Arrived',
    },
},
{
    path: 'reports/inventory/items-based-on-stock-range/:id',
    name: 'items-based-on-stock-range',
    component: () => import('@/views/main/modules/Reports/InventoryReports/ItemWithStocksReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Items Based on Stock Range',
    },
},
{
    path: 'reports/inventory/warehouse-stocks-vs-item-movement/:id',
    name: 'warehouse-stocks-vs-item-movement',
    component: () => import('@/views/main/modules/Reports/InventoryReports/WarehouseStocksVsItemMovement'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Warehouse Stocks vs Item Movement',
    },
},
{
    path: 'reports/inventory/warehouse-hakot/:id',
    name: 'warehouse-hakot',
    component: () => import('@/views/main/modules/Reports/InventoryReports/WarehouseHakotReportComponent'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Hakot',
    },
},
]

export default routes;
