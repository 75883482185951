<template>
  <v-dialog v-model="DIALOGS" persistent :max-width="width" :scrollable="scrollable" :retain-focus="retainfocus">
    <component :is="cp"></component>
  </v-dialog>
</template>
<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'

export default{

    props:['cp','scrollable','width','selectedAction','retainfocus'],
    data(){
        return{}
    },
    computed:{
      ...mapGetters([
        'DIALOGS'
      ])
    },
    methods: {
        closeDialog() {
            this.$store.commit("DIALOG", false);
            this.cp = "";
        },
    },
};
</script>
