
import repository from '@/api/credentials'

const routes = [{
    path: '/service/sales-quotation/:id',
    name: 'servicesalesquotation',
    props: true,
    component: () => import('@/views/main/modules/Services/SalesQuotationComponentMain'),
    beforeEnter:(to,from,next)=>{
      repository.checkAccessUser(to.params.id).then((response)=>{
          if(response.data == 'fail'){
              next('/no-access')
              }else{
              next()
          }
      }).catch(()=>{

      })
    },
    meta: {
      requiresAuth: true,
      service:"1",
    },
  }];

export default routes;
