import repository from "@/api/modules/Accounting/Payments";

export default {
    state: {
        customer_payment_details: [],
        payment_types: [],
        banks: [],
        cash_denominations: [],
        customer_payment_detail: [],
        payment_type: [],
        soa_get_all_approved:[],
        all_payment_types:[],
        cust_payment_det_by_customer:[],
        view_customer_payment_detail:[],
        soa_customer_payments:[],

    },
    getters: {
        GET_CUSTOMER_PAYMENT_DETAILS: (state) => state.customer_payment_details,
        GET_PAYMENT_TYPES: (state) => state.payment_types,
        GET_BANKS: (state) => state.banks,
        GET_CASH_DENOMINATIONS: (state) => state.cash_denominations,
        GET_CUSTOMER_PAYMENT_DETAIL: (state) => state.customer_payment_detail,
        GET_PAYMENT_TYPE: (state) => state.payment_type,
        GET_SOA_GET_ALL_APPROVED: (state) => state.soa_get_all_approved,
        GET_ALL_PAYMENT_TYPES: (state) => state.all_payment_types,
        GET_CUST_PAYMENT_DET_BY_CUSTOMER: (state) => state.cust_payment_det_by_customer,
        GET_VIEW_CUSTOMER_PAYMENT_DETAIL: (state) => state.view_customer_payment_detail,
        GET_SOA_CUSTOMER_PAYMENTS: (state) => state.soa_customer_payments,
    },
    actions: {
        getCustomerPaymentDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getcustomerpaymentdetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUSTOMER_PAYMENT_DETAILS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteCustomerPaymentDetail({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deletecustomerpaymentdetail(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPaymentTypes({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getpaymenttypes(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PAYMENT_TYPES',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getBanks({commit},payload){
            return new Promise((resolve, reject) => {
                repository.getbanks(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('BANKS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCashDenominations({commit},payload){
            return new Promise((resolve, reject) => {
                repository.getcashdenominations(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CASH_DENOMINATIONS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createCustomerPaymentDetail({commit},payload){
            return new Promise((resolve, reject) => {
                repository.createcustomerpaymentdetail(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustomerPaymentDetail({commit},payload){
            return new Promise((resolve, reject) => {
                repository.getcustomerpaymentdetail(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUSTOMER_PAYMENT_DETAIL',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateCustomerPaymentDetail({commit},payload){
            return new Promise((resolve, reject) => {
                repository.updatecustomerpaymentdetail(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getPaymentType({commit},payload){
            return new Promise((resolve, reject) => {
                repository.getpaymenttype(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('PAYMENT_TYPE',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        createPaymentType({commit},payload){
            return new Promise((resolve, reject) => {
                repository.createpaymenttype(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updatePaymentType({commit},payload){
            return new Promise((resolve, reject) => {
                repository.updatepaymenttype(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deletePaymentType({commit},payload){
            return new Promise((resolve, reject) => {
                repository.deletepaymenttype(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        soaGetAllApproved({commit},payload){
            return new Promise((resolve, reject) => {
                repository.soagetallapproved(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SOA_GET_ALL_APPROVED',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllPaymentTypes({commit}){
            return new Promise((resolve, reject) => {
                repository.getallpaymenttypes().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ALL_PAYMENT_TYPES',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCustPaymentDetByCustomer({commit},payload){
            return new Promise((resolve, reject) => {
                repository.getcustpaymentdetbycustomer(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('CUST_PAYMENT_DET_BY_CUSTOMER',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateCustomerSoaPayments({commit}){
            return new Promise((resolve, reject) => {
                repository.updatecustomersoapayments().then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getViewCustomerPaymentDetail({commit},payload){
            return new Promise((resolve, reject) => {
                repository.getviewcustomerpaymentdetail(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('VIEW_CUSTOMER_PAYMENT_DETAIL',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateCustPayment({commit},payload){
            return new Promise((resolve, reject) => {
                repository.updatecustpayment(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSoaCustomerPayments({commit},payload){
            return new Promise((resolve, reject) => {
                repository.getsoacustomerpayments(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SOA_CUSTOMER_PAYMENTS',response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        customerPaymentAllocations({commit},payload){
            return new Promise((resolve, reject) => {
                repository.customerpaymentallocations(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateChequeDate({commit},payload){
            return new Promise((resolve, reject) => {
                repository.updatechequedate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storeChequeMovement({commit},payload){
            return new Promise((resolve, reject) => {
                repository.storeChequeMovement(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        storePaymentAllocation({commit},payload){
            return new Promise((resolve, reject) => {
                repository.storePaymentAllocation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        exportPaymentDetails({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.exportPaymentDetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        updateDepositDate({commit},payload){
            return new Promise((resolve, reject) => {
                repository.updatedepositdate(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        confirmAndAllocatePayment({commit},payload){
            return new Promise((resolve, reject) => {
                repository.confirmAndAllocatePayment(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getToAllocateSoa({commit}, payload){
            return new Promise((resolve, reject)=>{
                repository.getToAllocateSoa(payload).then((response)=>{
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        }
    },
    mutations: {
        CUSTOMER_PAYMENT_DETAILS: (state, data) => { (state.customer_payment_details = data) },
        PAYMENT_TYPES: (state, data) => { (state.payment_types = data) },
        BANKS: (state, data) => { (state.banks = data) },
        CASH_DENOMINATIONS: (state, data) => { (state.cash_denominations = data) },
        CUSTOMER_PAYMENT_DETAIL: (state, data) => { (state.customer_payment_detail = data) },
        PAYMENT_TYPE: (state, data) => { (state.payment_type = data) },
        SOA_GET_ALL_APPROVED: (state, data) => { (state.soa_get_all_approved = data) },
        ALL_PAYMENT_TYPES: (state, data) => { (state.all_payment_types = data) },
        CUST_PAYMENT_DET_BY_CUSTOMER: (state, data) => { (state.cust_payment_det_by_customer = data) },
        VIEW_CUSTOMER_PAYMENT_DETAIL: (state, data) => { (state.view_customer_payment_detail = data) },
        SOA_CUSTOMER_PAYMENTS: (state, data) => { (state.soa_customer_payments = data) },
    }

}
