import store from '../../../store';
import api from '../../api'

export default {
    AdminCreateAccountability(params){
       return api.post('/admin/adminaccountability',params)
    },
    AdminUpdateAccountability(params){
        return api.put(`/admin/adminaccountability/${params.asset_id}`,params)
     },
     AdminDeleteAccountability(params){
        return api.delete(`/admin/adminaccountability/${params}`)
     },
     getAllAccountability(params){
        return api.get('/admin/getAllAccountability',{params})
    },
     exportAccountability(params){
        return api.get('/admin/exportAccountability',{params})
    },
   getAdminAccountabilityFiles(params){
      return api.get(`admin/get-admin-accountability-files/${params.id}`);
   },
   deleteAdminAccountabilityFiles(params){
      return api.get(`admin/delete-admin-accountability-files/${params.id}`)
   },
   accountabilityGet(params){
      return api.get(`/admin/${params.url}`,{params})
   },
   getWarehouseItemSelectionsConsumables(params){
      return api.get(`/admin/warehouse-selection-consumables`,{params})
   },
   getAccountabilityWithoutAsset(params){
      return api.get(`/admin/${params.url}`,{params})
   },
   postAccountabilityWithoutAsset(params){
      return api.post(`/admin/${params.url}`, params)
   },
   addAdminReleaseForm(params){
      return api.post(`/admin/${params.url}`, params)
   },
   getItemConsumableFromPO(params){
      return api.get(`/admin/item-consumable-from-po`,{params})
   },
   adminAccountabilityPost(params){
      return api.post(`/admin/accountability/${params.url}`,params)
   },
   adminAccountabilityGet(params){
      return api.get(`/admin/accountability/${params.url}`,{params})
   },
}

