import repository from '@/api/modules/Admin/adminAccountability'
import store from '@/store';
export default {
	state: {
		export_item_consumables: false,
        edit_accountability_details:{},
        new_accountability_details:false,
		admin_data_table_headers:[],
		admin_item_collection:[],
		admin_warehouse_item_selection_consumables:[],
		item_consumable_from_po:[],
	},
	getters: {
        GET_EXPORT_ITEM_CONSUMABLES:(state)=>state.export_item_consumables,
        GET_EDIT_ADMIN_ACCOUNTABILITY_DETAILS:(state)=>state.edit_accountability_details,
        GET_NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE:(state)=>state.new_accountability_details,
		GET_ADMIN_DATA_TABLE_HEADERS:(state)=>state.admin_data_table_headers,
		GET_ADMIN_ITEMS_COLLECTIONS:(state)=>state.admin_item_collection,
		GET_ADMIN_WAREHOUSE_ITEMS_SELECTION_CONSUMABLES:(state)=>state.admin_warehouse_item_selection_consumables,
		GET_ITEM_CONSUMABLE_FROM_PO:(state)=>state.item_consumable_from_po,
	},
	actions:{
        AdminCreateAccountability({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.AdminCreateAccountability(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		AdminUpdateAccountability({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.AdminUpdateAccountability(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		AdminDeleteAccountability({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.AdminDeleteAccountability(payload).then((response)=>{
					commit('NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
        getAllAccountability({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllAccountability(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        exportAccountability({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.exportAccountability(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getAdminAccountabilityFiles({ commit }, payload) {
			return new Promise((resolve,reject)=>{
				repository.getAdminAccountabilityFiles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		deleteAdminAccountabilityFiles({ commit }, payload) {
			return new Promise((resolve,reject)=>{
				repository.deleteAdminAccountabilityFiles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
        accountabilityGet({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.accountabilityGet(payload).then((response)=>{
					resolve(response.data);
				}).catch((error)=>{
					reject(error);
				});
			})
        },
        getWarehouseItemSelectionsConsumables({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getWarehouseItemSelectionsConsumables(payload).then((response)=>{
					resolve(response.data.data);
					commit('ADMIN_WAREHOUSE_ITEMS_SELECTION_CONSUMABLES', response.data)
				}).catch((error)=>{
					reject(error);
				});
			})
        },
        getAccountabilityWithoutAsset({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAccountabilityWithoutAsset(payload).then((response)=>{
					resolve(response.data.data);
				}).catch((error)=>{
					reject(error);
				});
			})
        },
        getAccountabilityWithoutAssetExport({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAccountabilityWithoutAsset(payload).then((response)=>{
					resolve(response.data);
				}).catch((error)=>{
					reject(error);
				});
			})
        },
        postAccountabilityWithoutAsset({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.postAccountabilityWithoutAsset(payload).then((response)=>{
					resolve(response.data);
				}).catch((error)=>{
					reject(error);
				});
			})
        },
        addAdminReleaseForm({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addAdminReleaseForm(payload).then((response)=>{
					resolve(response.data);
				}).catch((error)=>{
					reject(error);
				});
			})
        },
		getItemConsumableFromPO({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getItemConsumableFromPO(payload).then((response)=>{
					resolve(response.data.data);
					commit('ITEM_CONSUMABLE_FROM_PO', response.data.data)
				}).catch((error)=>{
					reject(error);
				});
			})
        },
		adminAccountabilityPost({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.adminAccountabilityPost(payload).then((response)=>{
					resolve(response.data);
				}).catch((error)=>{
					reject(error);
				});
			})
        },
		adminAccountabilityGet({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.adminAccountabilityGet(payload).then((response)=>{
					resolve(response.data[0]);
				}).catch((error)=>{
					reject(error);
				});
			})
        },
	},

	mutations:{
        EDIT_ADMIN_ACCOUNTABILITY_DETAILS:(state,data)=>{
            state.edit_accountability_details = data;
        },
        NEW_ADMIN_OTHER_ACCOUNTABILITY_TABLE:(state,data)=>{
            (state.new_accountability_details = data);
        },
		ADMIN_DATA_TABLE_HEADERS:(state,data)=>{
			state.admin_data_table_headers = data;
		},
		ADMIN_ITEMS_COLLECTIONS:(state,data)=>{
			state.admin_item_collection = data;
		},
		ADMIN_WAREHOUSE_ITEMS_SELECTION_CONSUMABLES:(state,data)=>{
			state.admin_warehouse_item_selection_consumables = data;
		},
		ITEM_CONSUMABLE_FROM_PO:(state,data)=>{
			state.item_consumable_from_po = data;
		},
		EXPORT_ITEM_CONSUMABLES:(state,data)=>{
			state.export_item_consumables = data;
		}
	},
}
