import store from '../../../../store';
import api from '../../../api'

export default {
    addhrSettings(params){
        return api.post('/hr/hrsettings',params);
    },
    gethrSettings(params){
        return api.get('/hr/hrsettings',{params});
    },
    updatehrSettings(params){
        return api.put(`/hr/hrsettings/${params.id}`,params.payload);
    },
    deletehrSettings(params){
        return api.delete(`/hr/hrsettings/${params}`);
    },
    savehrSettings(params){
        return api.post(`/hr/saveSettings`,params);
    },
    getSettingsByModule(params){
        return api.post(`/hr/getSettingsByModule`,params);
    },
}
