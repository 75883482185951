import repository from "@/api/modules/Logistics/Delivery/index";

export default {
    state: {
        get_so_for_schedule: [],
        component: false,
        refresh: false,
        logistic_view: {},
        edit_component: false,
    },
    getters: {
        GET_SO_FOR_SCHEDULING:(state)=>state.get_so_for_schedule,
        GET_COMPONENT:(state)=>state.component,
        GET_EDIT_COMPONENT:(state)=>state.edit_component,
        GET_REFRESH_LOGISTIC:(state)=>state.refresh,
        GET_LOGISTIC_VIEW:(state)=>state.logistic_view,
    },
    actions: {
        postDelivery({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deliverypost(payload).then((response) => {
                    if (response.status == 200) {
                        commit('REFRESH_LOGISTIC',true)
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        putDelivery({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deliveryput(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        deleteDelivery({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deliverydelete(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getDelivery({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.deliveryget(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },

        getSoForScheduling({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.getSoForScheduling(payload).then((response)=>{
                if(response.status == 200){
                    commit('SO_FOR_SCHEDULING',response.data)
                    resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },
        getAllSoForScheduling({commit},payload){
            return new Promise((resolve,reject)=>{
            repository.getAllSoForScheduling(payload).then((response)=>{
                if(response.status == 200){
                    commit('SO_FOR_SCHEDULING',response.data)
                    resolve(response.data)
                }
              }).catch((error)=>{
                reject(error)
              });
          })
        },
    },
    mutations: {
        // PENDING_SO_LIST_REPORT: (state, data) => { (state.export_pending_so_list_report = data) }
        SO_FOR_SCHEDULING:(state,data)=>{state.get_so_for_schedule = data},
        COMPONENT:(state,data)=>{state.component = data},
        EDIT_COMPONENT:(state,data)=>{state.edit_component = data},
        REFRESH_LOGISTIC:(state,data)=>{(state.refresh = data)},
        LOGISTIC_VIEW:(state,data)=>{state.logistic_view = data},
    }

}
