import repository from '@/api/credentials'

const routes = [
    {
        path: '/hr/hruser/:id',
        name: 'hruser',
        component: () => import('@/views/main/modules/HR/HrUser/HrUserComponent'),
        beforeEnter: (to, from, next) => {
            repository.checkAccessUser(to.params.id).then((response) => {
                if (response.data == 'fail') {
                    next('/no-access')
                }else{
                    next()
                }
            }).catch(() => {

            })
        },
        meta: {
            requiresAuth: true,
            title: 'HR User'
        }
    },
];

export default routes;
