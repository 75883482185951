import repository from '@/api/modules/Admin/recurringBill'
export default {
	state: {
        ACTION:'',
        TAB: ''
	},
	getters: {
        RECURRING_BILL: (state) => state
	},
	actions:{
        recurringBillGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.recurringBillGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        recurringBillPost({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.recurringBillPost(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getRecordFiles({ commit }, payload) {
            return new Promise((resolve,reject)=>{
                repository.getRecordFiles(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        },
        deleteRecordFiles({ commit }, payload) {
            return new Promise((resolve,reject)=>{
                repository.deleteRecordFiles(payload).then((response)=>{
                    if(response.status == 200){
                        resolve(response)
                    }
                }).catch((error)=>{
                    reject(error)
                });
            })
        }
	},
	mutations:{
        RECURRING_BILL_ACTION: (state, data) => { (state.ACTION = data) },
        RECURRING_BILL_TAB: (state, data) => { (state.TAB = data) },
	}
}
