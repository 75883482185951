import api from '../../../api'

export default {
    index(params){
        return api.get('/accounting/accounting-departments',{params})
    },
    show(params) {
        return api.get('/accounting/accounting-departments',{params})
    },
    store(params){
        return api.post('/accounting/accounting-departments',params)
    },
    update(params){
        return api.put(`/accounting/accounting-departments/${params.id}`,params.payload)
    },
    delete(params){
        return api.delete(`/accounting/accounting-departments/${params}`)
    },
}

