import api from '../../../api'
export default {

    getpromoshortlists(params) {
        return api.get(`promos/getPromoShortlists/${params}`);
    },
    getcustomerpromoinfo(params){
        return api.get(`promos/getCustomerPromoInfo/${params.customer_id}`,{params});
    },
    itemsnotinpromo(params){
        return api.get(`promos/getItemsNotInPromo`, {params});
    },
    customersnotinpromo(params){
        return api.get(`promos/getCustomersNotInPromo`,{params});
    },

    promosget(params){
        return api.get(`promos/`+params.url,{params})
    },
    promospost(params){
        return api.post(`promos/`+params.url,params)
    },
    promosput(params){
        return api.put(`promos/`+params.url,params)
    },
    promosdelete(params){
        return api.delete(`promos/`+params.url,params)
    },
}
