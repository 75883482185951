import repository from "@/api/modules/Sales/Dms/index";
export default {
    state: {
        TABS:0,
        DIALOG:{
            ATTENDANCE:false,
            CUSTOMER:false
        },
        ACTION:'',
        SELECTED:{},
        SO:{
            SELECTED:{}
        },
        salesman_dropdown: [],
        dms_location:{
            salesman_id: null,
            date_from: null,
            date_to: null,
            legends:[],
            filter_day:[],
            selected_days:[]
        },
        preview_dialog:{
            dialog: false,
            items: {},
            imageSource: ''
        },
        geojson:[],
        day_selections:[]
    },
    getters:{
        DAILY_MANAGEMENT_SYSTEM: (state) => state,
        GET_DMS_SALESMAN_DROPDOWN: (state) => state.salesman_dropdown,
        GET_PREVIEW_DIALOG: (state) => state.preview_dialog,
        GET_GEOJSON: (state) => state.geojson,

    },
    actions: {
        dailyManagementSystemGet({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.dailyManagementSystemGet(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        dailyManagementSystemPost({commit},payload) {
            return new Promise((resolve, reject) => {
             repository.dailyManagementSystemPost(payload).then((response) => {
                    if (response.status == 204 || response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getCurrentCity({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getCurrentCity(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSalesmanDTR({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getsalesmandtr(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSalesmanDetails({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getsalesmandetails(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getAllsalesmanCount({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getallsalesmancount(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getallSalesmanDropdown({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getallsalesmandropdown(payload).then((response) => {
                    commit('DMS_SALESMAN_DROPDOWN', response.data)
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSalesmanLocation({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getSalesmanLocation(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSalesmanLocationMap({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getSalesmanLocationMap(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getSalesmanTotal({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getsalesmantotal(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
        getGeojson({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getGeojson(payload).then((response)=>{
					commit('GEOJSON',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
        // getGeojson({ commit },payload) {
        //     new Promise((resolve,reject)=>{
        //     repository.getGeojson(payload).then(response => {
        //         commit('GEOJSON',response.data)
        //         resolve(response.data)
        //         });
        //     }).catch(error=>{
        //         reject(error);
        //     })
        // },
    },
    mutations: {
        DMS_DIALOG:(state,data) => state.DIALOG.ATTENDANCE = data,
        DMS_CUSTOMER:(state,data) => state.DIALOG.CUSTOMER = data,
        DMS_ACTION:(state,data) => state.ACTION = data,
        DMS_SELECTED:(state,data) => state.SELECTED = data,
        DMS_SELECTED_SO:(state,data) => {
            return state.SO.SELECTED = data
        },
        DMS_SALESMAN_DROPDOWN: (state, data) => {
            return state.salesman_dropdown = data
        },
        DMS_LOCATION:(state,data) => {
            return state.dms_location = data
        },
        DAY_SELECTION:(state,data) => {
            return state.day_selections = data
        },
        LEGENDS: (state, data) => {state.dms_location.legends = data},
        FILTER_DAY: (state, data) => {state.dms_location.filter_day = data},
        PREVIEW_DIALOG: (state, data) => {
            return state.preview_dialog = data
        },
        GEOJSON: (state, data) => {state.geojson.filter_day = data},
    }
}
