import api from '../../api';

export default {
    storeflowget(params){
        return api.get(`/stores/${params.url}`,{params})
    },
    storeflowpost(params){
        return api.post(`/stores/${params.url}`,params)
    },
    getallstoreflows(params){
        return api.get(`/stores/store-flows`, {params})
    }
}