import repository from '@/api/modules/Accounting/AccountsPayable'
import store from '@/store';
export default {
	state: {
		voucher_types:[],
		payment_voucher_print: false,
		pv_print_data:{},
		precision: 0,
		accounts_payable_status:null
	},
	getters: {
		GET_ALL_PAYMENT_VOUCHER_TYPES: (state) => state.voucher_types,
		GET_PV_PRINT: (state) => state.payment_voucher_print,
		GET_PV_PRINT_DATA: (state) => state.pv_print_data,
		GET_PRECISION: (state) => state.precision,
		GET_ACCOUNTS_PAYABLE_STATUS:(state)=>state.accounts_payable_status
	},
	actions:{
		getAcctsPayable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctPayableGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
						commit(payload.mutation_code, response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		postAcctsPayable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctPayablePost(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		putAcctsPayable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctPayablePut(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteAcctsPayable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctPayableDelete(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		createVendorPaymentDetail({commit},payload){
            return new Promise((resolve, reject) => {
                repository.createvendorpaymentdetail(payload.params,payload.config).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
		getVendorPaymentList({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.getVendorPaymentList(payload).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
		updatePaymentVoucher({commit},payload) {
            return new Promise((resolve, reject) => {
                repository.updatePaymentVoucher(payload.id,payload.params).then((response) => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
		getPVPrintData({commit}, payload) {
			return new Promise ((resolve, reject) => {
				repository.getPVPrintData(payload).then((response) => {
					resolve(response)
				}).catch((error) => {
					console.log(error);
					reject(error);
				})
			})
		},
		getLastCVDetails({commit}, payload) {
			return new Promise ((resolve, reject) => {
				repository.getLastCVDetails(payload).then((response) => {
					resolve(response)
				}).catch((error) => {
					console.log(error);
					reject(error);
				})
			})
		},
		getPaymentAllocations({commit}, payload) {
			return new Promise((resolve, reject) => {
				repository.getPaymentAllocations(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		getCheckVoucherByPV({commit},payload){
			return new Promise((resolve, reject) => {
				repository.getCheckVoucherByPV(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		chequeVoucherPrint({commit},payload){
			return new Promise((resolve, reject) => {
				repository.chequeVoucherPrint(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		getEnterBillItems({commit},payload){
			return new Promise((resolve, reject) => {
				repository.getEnterBillItems(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		getEnterBillTransactItems({commit},payload){
			return new Promise((resolve, reject) => {
				repository.getEnterBillTransactItems(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		getTransactTypeModelName({commit},payload){
			return new Promise((resolve, reject) => {
				repository.getTransactTypeModelName(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		cardBankGet({commit},payload){
			return new Promise((resolve, reject) => {
				repository.cardBankGet(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		cardBankPost({commit},payload){
			return new Promise((resolve, reject) => {
				repository.cardBankPost(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		cardBankGetUser({commit},payload){
			return new Promise((resolve, reject) => {
				repository.cardBankGet(payload).then((response) => {
					if (response.status == 200) {
						resolve(response)
					}
				}).catch((error) => {
					reject(error)
				});
			})
		},
		getViewFiles({ commit }, payload) {
			return new Promise((resolve,reject)=>{
				repository.getViewFiles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		getPaymentBillFiles({ commit }, payload) {
			return new Promise((resolve,reject)=>{
				repository.getPaymentBillFiles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		postPaymentBillUpload({ commit }, payload) {
			return new Promise((resolve,reject)=>{
				repository.postPaymentBillUpload(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		deletePaymentBillFiles({ commit }, payload) {
			return new Promise((resolve,reject)=>{
				repository.deletePaymentBillFiles(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
		getVendorCardPayments({ commit }, payload) {
			return new Promise((resolve,reject)=>{
				repository.getVendorCardPayments(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
		},
	},
	mutations:{
		VOUCHER_TYPES: (state, data) => { 
			(state.voucher_types = data) 
		},
		PV_PRINT: (state, data) => {
			state.payment_voucher_print = data
		},
		PV_PRINT_DATA: (state, data) => { 
			state.pv_print_data = data 
		},
		PRECISION: (state, data) => {
			state.precision = data
		},
		ACCOUNTS_PAYABLE_STATUS:(state,data)=>{
			state.accounts_payable_status = data
		}
	},
}
