
import repository from '@/api/credentials';

const routes = [{
    path: '/changepassword/:id',
    name: 'change-password',
    component: () => import('@/views/main/Utils/ChangePasswordComponent.vue'),
    // beforeEnter: (to, from, next) => {
    //     repository.checkAccessUser(to.params.id).then((response) => {
    //         if (response.data == 'fail') {
    //             next('/no-access')
    //         } else {
    //             next()
    //         }
    //     }).catch(() => {

    //     })
    // },
    meta: {
        requiresAuth: true,
        title: 'Change Password',
    }
}]

export default routes;
