const state = {
    repair: {
        itemsDialog: false,
    }
};
const getters = {};
const actions = {};
const mutations = {
    showScEstDialog(state) {
        state.repair.itemsDialog = true;
    },
    closeScEstDialog(state) {
        state.repair.itemsDialog = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
