import repository from '@/api/modules/Dashboard/Sales'

export default {
    state: {
        company_target: [],
        sales_by_item_type: [],
        sales_by_item_category: [],
        salesman_dialog: false,
        salesman_id: null,
        salesman_details: [],
    },
    getters: {
        GET_COMPANY_TARGET: state => state.company_target,
        GET_SALES_BY_ITEM_TYPE: state => state.sales_by_item_type,
        GET_SALES_BY_ITEM_CATEGORY: state => state.sales_by_item_category,
        GET_SALESMAN_DIALOG_ACTION: state => state.salesman_dialog,
        GET_SALESMAN_ID: state => state.salesman_id,
        GET_SALESMAN_DETAILS: state => state.salesman_details
    },
    actions: {
        getCompanyTarget({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getcompanytarget(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('COMPANY_TARGET', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getSalesByItemType({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getsalesbyitemtype(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SALES_BY_ITEM_TYPE', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getSalesByItemCategory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getsalesbyitemcategory(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('SALES_BY_ITEM_CATEGORY', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

        getSalesDashboard({ commit }, payload){
            return new Promise((resolve, reject) => {
                repository.getSalesDashboard(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getAverageTimeIn({ commit }, payload){
            return new Promise((resolve, reject) => {
                repository.getAverageTimeIn(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        COMPANY_TARGET: (state, data) => {
            state.company_target = data
        },
        SALES_BY_ITEM_TYPE: (state, data) => {
            state.sales_by_item_type = data
        },
        SALES_BY_ITEM_CATEGORY: (state, data) => {
            state.sales_by_item_category = data
        },
        SALESMAN_DIALOG: (state, data) => {
            state.salesman_dialog = data
        },
        SALESMAN_ID:(state, data) => {
            state.salesman_id = data
        },
        SALESMAN_DETAILS:(state, data) => {
            state.salesman_details = data
        }
    }
}
