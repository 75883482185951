<template>
    <div>
        <div class="page-wrapper ma-1">
            <div class="row p-2">
                <div class="col-8 d-flex no-block align-items-center">
                    <h4 class="page-title">Report</h4>
                </div>
            </div>
            <div class="container">
                <v-card class="px-4">
                    <div>
                        <v-layout row class="px-6 pt-6">
                            <v-spacer />
                            <v-flex lg2>
                                <div style="text-align:end">
                                    <v-btn @click="exportExcel(excelHeadersAndData, exportName)">EXPORT</v-btn>
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <v-card-title>
                            <v-layout>
                                <v-spacer />
                                <v-flex lg4>
                                    <v-text-field
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        label="Search"
                                        single-line
                                        hide-details
                                        clearable
                                    />
                                </v-flex>
                            </v-layout>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="sparePartHeaders"
                            :items="excelHeadersAndData.Sheet1.data"
                            :search="search"
                        >
                            <template v-slot:item.selling_price="{ item }">
                                {{ item.selling_price | currency }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent.vue';

export default {
    mixins:[SharedFunctionsComponent],
    data() {
        return {
            sparePartHeaders: [
                 { text: 'Model', value: 'model' },
                { text: 'Name', value: 'name' },
                { text: 'UOM', value: 'item_uom' },
                { text: 'Selling Price', value: 'selling_price' },
                { text: 'FOC', value: 'foc' },
                { text: 'Part Num', value: 'part_num' },
            ],
            excelHeadersAndData: {
                Sheet1: {
                    headers:[
                        {header:'Model', key:'model', width:15},
                        {header:'Name', key:'name', width:22},
                        {header:'Description', key:'description', width:22},
                        {header:'UOM', key:'item_uom', width:22},
                        {header:'Selling Prices', key:'selling_price', width:16},
                        {header:'FOC', key:'foc', width:10},
                        {header:'Part Num', key:'part_num', width:16}
                    ],
                    data:[]
                }
            },
            search: '',
            exportLoading: false,
            exportName: `Spare-Parts-${this.$dayjs().format('YYYY-MM-DD')}`,
        }
    },
    components: {
        //
    },
    mounted: function() {
        this.getReportSpareParts();
    },
    watch: {
    },
    computed: {
        ...mapGetters([
            'GET_REPORT_SPARE_PARTS',
            'GET_SERVICE_ALL_CUSTOMERS',
        ])
    },
    methods: {
        async getReportSpareParts(){

            await this.$store.dispatch('getReportSpareParts').then(response=>{
                this.excelHeadersAndData.Sheet1.data = this.GET_REPORT_SPARE_PARTS;
            });
        },
        startDownload() {
            this.exportLoading = true;
        },
        finishDownload() {
            this.exportLoading = false;
        },
    }
}
</script>

<style lang="scss">
</style>
