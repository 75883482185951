import store from '../../../store';
import api from '../../api'

export default {

    AddAdminClassification(params){
        return api.post('/admin/adminclassification',params);
    },
    GetAllAdminClassification(params){
        return api.get('/admin/adminclassification',{params});
    },
    UpdateAdminClassification(params){
        return api.put(`/admin/adminclassification/${params.id}`,params.payload);
    },
    DeleteAdminClassification(params){
        return api.delete(`/admin/adminclassification/${params}`);
    },
    AddAdminLocation(params){
        return api.post('/admin/adminlocation',params);
    },
    GetAllAdminLocation(params){
        return api.get('/admin/adminlocation',{params});
    },
    UpdateAdminLocation(params){
        return api.put(`/admin/adminlocation/${params.id}`,params.payload);
    },
    DeleteAdminLocation(params){
        return api.delete(`/admin/adminlocation/${params}`);
    },
    AddAdminBrand(params){
        return api.post('/admin/adminbrand',params);
    },
    GetAllAdminBrand(params){
        return api.get('/admin/adminbrand',{params});
    },
    UpdateAdminBrand(params){
        return api.put(`/admin/adminbrand/${params.id}`,params.payload);
    },
    DeleteAdminBrand(params){
        return api.delete(`/admin/adminbrand/${params}`);
    },
    AddAdminCategories(params){
        return api.post('/admin/admincategories',params);
    },
    GetAllAdminCategories(params){
        return api.get('/admin/admincategories',{params});
    },
    UpdateAdminCategories(params){
        return api.put(`/admin/admincategories/${params.id}`,params.payload);
    },
    DeleteAdminCategories(params){
        return api.delete(`/admin/admincategories/${params}`);
    },
    adminDropdownGet(params){
        return api.get('/dropdown/'+params.url,{params});
    },
    getSelectedLowValueAsset(params){
        return api.get('/admin/'+params.url,{params});
    },
    adminDeliveryAddressesGet(params) {
        return api.get('/admin/'+params.url,{params});
    },
    adminDeliveryAddressesPost(params) {
        return api.post('/admin/'+params.url,{params});
    },
    adminDeliveryAddressesPut(params) {
        return api.put('/admin/'+params.url,{params});
    },
    adminDeliveryAddressesDelete(params) {
        return api.delete('/admin/'+params.url,{params});
    }
}

