import repository from '@/api/modules/Stores_/index'

export default {
    state: {
        TAB: 0,
        store_flows: [],
        reload: false,
        loading: false,
        is_dialog_state: false,
        filter_dialog: false,
    },

    getters: {
        GET_STORE_FLOW_TAB:(state) => state.TAB,
        GET_STORE_FLOWS:(state) => state.store_flows,
        GET_RELOAD:(state) => state.reload,
        GET_LOADING:(state) => state.loading,
        GET_DIALOG_STATE: (state) => state.is_dialog_state,
        GET_FILTER_DIALOG: (state) => state.filter_dialog,
    },

    mutations: {
        STORE_FLOW_TAB:(state,data) => {state.TAB = data},
        STORE_FLOWS:(state,data) => {state.store_flows = data},
        RELOAD:(state,data) => {state.reload = data},
        LOADING:(state,data) => {state.loading = data},
        DIALOG_STATE: (state,data) => {state.is_dialog_state = data},
        FILTER_DIALOG: (state,data) => {state.filter_dialog = data},
    },
    actions: {
        async storeFlowGet({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.storeflowget(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async storeFlowPost({commit},payload) {
            return await new Promise((resolve,reject)=>{
                repository.storeflowpost(payload).then(response=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        async getAllStoreFlows({commit}, payload) {
            return await new Promise((resolve, reject) => {
                repository.getallstoreflows(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('STORE_FLOWS', response.data)
                    }
                }).catch((error) => {
                    reject(error)
                });
            })
        },
    }
}