import api from '../../api'

export default {
    internationalPaymentPost(params) {
        return api.post('/admin/'+params.url, params)
    },
    internationalPaymentGet(params) {
        return api.get('/admin/'+params.url, { params })
    },
}
