import api from '../../../api'
export default{
    sampleformget(params){
      return api.get(`/sample-form/`+params.url,{params})
    },
    sampleformpost(params){
      return api.post(`/sample-form/`+params.url,params)
    },
    sampleformput(params){
      return api.put(`/sample-form/`+params.url,params)
    },
    sampleformdelete(params){
      return api.delete(`/sample-form/`+params.url,params)
    },
    getsampleform(id, params){
      return api.get(`/sample-form/viewSampleForm/${id}`,{params});
    },
    viewreturnform(id){
      return api.get(`/sample-form/viewReturnFormDetails/${id}`);
    },
    uploadfilesSampleFormAPI(params,config){
        return api.post(`/sample-form/${params.url}`,params.data,config)
    },
    getUploadFilesAPI(params,config){
        return api.get(`/sample-form/${params.url}`,{params})
    },

}
