export default {
    state: {
        camera:{
            camera_capture:false,
            open_camera:false,
        }
    },
    getters: {
        GET_CAMERA_CAPTURE:(state)=>state.camera.camera_capture,
        GET_OPEN_CAMERA:(state)=>state.camera.open_camera,
    },
    mutations: {
        CAMERA_CAPTURE:(state,data)=>{
            state.camera.camera_capture = data
        },
        OPEN_CAMERA:(state,data)=>{
            state.camera.open_camera = data
        }
    },
    actions: {
        cameraInit({commit},params){
            if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
                navigator.mediaDevices.getUserMedia(params.constraints).then(stream=>{
                    params.videoPlayer.srcObject = stream;
                    params.videoPlayer.play();

                    commit('OPEN_CAMERA',true);
                })
            }
        },
        cameraStop({commit},params){
            if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
                navigator.mediaDevices.getUserMedia(params.constraints).then(stream=>{
                    const tracks = stream.getTracks();
                    // console.log(tracks[0]);
                      tracks[0].stop;
                    commit('OPEN_CAMERA',false);
                })
            }
        }
    },
  };
  