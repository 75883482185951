import store from '../../../../store';
import api from '../../../api'

export default {
    // Leave Request api
    addLeaveRequest(params){
        return api.post('/hr/hrleaverequests',params);
    },
    getLeaveRequest(params){
        return api.get('/hr/hrleaverequests',{params});
    },
    updateLeaveRequest(params){
        return api.put(`/hr/hrleaverequests/${params.id}`,params);
    },
    changeLeaveRequestStatus(params){
        return api.post(`/hr/changeLeaveRequestStatus`,params);
    },
    deleteLeaveRequest(params){
        return api.delete(`/hr/hrleaverequests/${params}`);
    },
    showLeaveRequest(params){
        return api.get(`/hr/hrleaverequests/${params}`);
    },
    getLeaveRequestFiles(params){
        return api.post(`/hr/getLeaveRequestFiles`, params);
    },

    // Leave Type api
    addLeaveTypes(params){
        return api.post('/hr/hrleavetypes',params);
    },
    getLeaveTypes(params){
        return api.get('/hr/hrleavetypes',{params});
    },
    updateLeaveTypes(params){
        return api.put(`/hr/hrleavetypes/${params.id}`,params.payload);
    },
    deleteLeaveTypes(params){
        return api.delete(`/hr/hrleavetypes/${params}`);
    },
    showLeaveTypes(params){
        return api.get(`/hr/hrleavetypes/${params}`);
    },

    // Leavebalance api
    addLeaveBalance(params){
        return api.post('/hr/hrleavebalances',params);
    },
    getLeaveBalance(params){
        return api.get('/hr/hrleavebalances',{params});
    },
    updateLeaveBalance(params){
        return api.put(`/hr/hrleavebalances/${params.id}`,params);
    },
    deleteLeaveBalance(params){
        return api.delete(`/hr/hrleavebalances/${params}`);
    },
    showLeaveBalance(params){
        return api.get(`/hr/hrleavebalances/${params.id}`);
    },
    checkLeaveWithPay(params){
        return api.post('/hr/checkLeaveWithPay',params);
    },
    checkLeaveRequestWithPay(params){
        return api.post('/hr/checkLeaveRequestWithPay', params);
    },
    saveLeaveRequestResetValue(params){
        return api.post('/hr/saveLeaveRequestResetValue', params);
    },
    getLeaveResetValue(params){
        return api.get('/hr/getLeaveResetValue', params);
    },
    checkLeaveIfApprovable(params){
        return api.post('/hr/checkLeaveIfApprovable', params);
    },
    getLeaveRequestDatesByLeaveType(params){
        return api.post('/hr/getLeaveRequestDatesByLeaveType', params);
    },

    // Leave Request Verification
    checkLeaveRequestVerCode(params){
        return api.post('/hr/checkLeaveRequestVerCode', params);
    },
    generateNewVerCode(params){
        return api.post('/hr/generateNewVerCode', params);
    },
    getEmployeeDataDetails(params){
        return api.post('/hr/getEmployeeDataDetails', params);
    },
}
