import api from '../../../api'

export default {
    getAllFundamentals(params){
        return api.get('/accounting/getAllFundamentals',{params})
    },
    getFundamentalsItem(params) {
        return api.get('/accounting/getFundamentalsItems',{params})
    },
    CreateFundamentals(params){
        return api.post('/accounting/fundamentals',params)
    },
    UpdateFundamentals(params){
        return api.put(`/accounting/fundamentals/${params.id}`,params)
    },
    DeleteFundamentals(params){
        return api.delete(`/accounting/fundamentals/${params}`)
    },
}

