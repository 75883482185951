import store from '../../../../store';
import api from '../../../api'

export default {
    //  HR Users
    addHrUser(params){
        return api.post('/hr/hrusers',params);
    },
    getHrUser(params){
        return api.get('/hr/getAllData', {params});
    },
    updateHrUser(params){
        return api.post(`/hr/updateData`,params);
    },
    deleteHrUser(params){
        return api.post(`/hr/deleteData`,params);
    },
    viewUsers(params){
        return api.get(`/hr/viewUsers`, {params});
    },
    getSelectedUser(params){
        return api.get(`/hr/getSelectedUser`, {params});
    },
}
