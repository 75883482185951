<template>
    <!-- <v-container fluid >
        <div id="div_rf_logo" >
            <div style="font-size: 12px; line-height: 1.9">
                <p>
                    <span style="font-size: 18px; font-weight: bold;">
                        INGCOPH Traders Inc.
                    </span>
                    <br>2 D Arellano St. Bagong Barrio, Caloocan City
                    <br>Cell No.:
                    <br>Tel. No.:
                    <br>Service Mobile / Viber No.: +63 917 625 2286
                    <br>Spare Parts Mobile / Viber No.:
                </p>
            </div>
        </div>
        <div id="div_rf_num" style="font-size: 12px; line-height: 1.9">
            <div style="border-top: 1px dashed #CFCFCF; padding: 2px; padding-bottom: -4px; text-align: center; border-bottom: none;">&nbsp;</div>
            <p>
                <span style="font-size: 18px; font-weight: bold;">Repair Form</span>
                <br>
                <b>RF#: {{ details.rf_number }}</b>
                <br>
                <b>Pullout#: {{ details.sc_receiving_report.pullout_number }}, RD#: {{ details.sc_receiving_report.rr_number }}</b>
                <br>
                <b>Issued: {{ details.sc_receiving_report.created_at }}</b>
            </p>
        </div>

        <div>
            <table id="rf_details">
                <tbody>
                    <tr>
                        <td colspan="3">Item Model: {{ details.sc_repair_form_item.model }}</td>
                        <td colspan="2">Serial No: {{ details.sc_repair_form_item.seriel_number }}</td>
                        <td colspan="3">Category: {{ details.sc_repair_form_item.category }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Item Name: {{ details.sc_repair_form_item.product_name }}</td>
                    </tr>
                    <tr>
                        <td colspan="3">Warranty: {{ details.sc_repair_form_item.warranty_type }}</td>
                        <td colspan="2">Purchase Date: {{ details.sc_repair_form_item.purchase_date }}</td>
                        <td colspan="3">Document: {{ details.sc_repair_form_item.warranty }}</td>
                    </tr>
                    <tr>
                        <td colspan="5">Company: {{ details.sc_receiving_report.customer.company_name }}</td>
                        <td colspan="3">Contact No: {{ details.sc_receiving_report.customer.mobile_1 }}</td>
                    </tr>
                    <tr>
                        <td colspan="5">Customer: {{ details.sc_receiving_report.customer.customer_name }}</td>
                        <td colspan="3">Contact No: {{  details.sc_receiving_report.customer.mobile_1 }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Address: {{ details.sc_receiving_report.customer.all_shipping_addresses[0].text }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Complaint/Symptom: {{ details.sc_repair_form_item.defect_description }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Remarks: {{ details.sc_repair_form_item.defect_remarks }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Physical Condition: {{ details.sc_repair_form_item.unit_condition }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Description: {{ details.sc_repair_form_item.other_unit_condition }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Included Items: {{ details.sc_repair_form_item.accessories }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Details: {{ details.sc_repair_form_item.other_accessory }}</td>
                    </tr>
                    <tr>
                        <td colspan="5">Assigned Technician: {{ details.sc_repair_form_item.technician }}</td>
                        <td colspan="3">Begin Repair Date: {{  }}</td>
                    </tr>
                    <tr>
                        <td colspan="8">Symptoms as Tested: {{  }}</td>
                    </tr>
                </tbody>
            </table>

            <div id="rf_estimate_title" style="font-size:12px">
                Estimate Information
            </div>
            <table id="rf_estimation">
                <tbody>
                   <tr>
                       <td colspan="5">Recommended part/s for Replacement:</td>
                       <td colspan="3">Diagnosis Date:</td>
                   </tr>
                   <tr>
                       <td colspan="8"></td>
                   </tr>
                   <tr>
                       <td colspan="8"></td>
                   </tr>
                   <tr>
                        <td colspan="1">Quotation No: {{  }}</td>
                        <td colspan="1">Quotation Date: {{  }}</td>
                        <td colspan="1">Total Amount: {{  }}</td>
                        <td colspan="1">Approve Date: {{  }}</td>
                        <td colspan="1">Coordinator: {{  }}</td>
                        <td colspan="3">Remarks: {{  }}</td>
                    </tr>
                    <tr>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="3"></td>
                    </tr>
                </tbody>
            </table>

            <div id="rf_unit_movements_title" style="font-size:12px">
                Unit Status
            </div>
            <table id="rf_unit_movements">
                <tbody>
                   <tr>
                        <td colspan="1">Status: {{  }}</td>
                        <td colspan="1">WFR/WFC: {{  }}</td>
                        <td colspan="1">FCA: {{  }}</td>
                        <td colspan="1">PFR: {{  }}</td>
                        <td colspan="1">RET: {{  }}</td>
                        <td colspan="1">WFP: {{  }}</td>
                        <td colspan="1">PIS: {{  }}</td>
                        <td colspan="1">RFD: {{  }}</td>
                    </tr>
                    <tr>
                        <td colspan="1">Date</td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                    </tr>
                    <tr>
                        <td colspan="1">Employee</td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                    </tr>
                </tbody>
            </table>

            <div id="rf_repair_info_title" style="font-size:12px">
                Repair Information
            </div>
            <table id="rf_repair_info">
                <tbody>
                   <tr>
                        <td colspan="5">Job Done: {{  }}</td>
                        <td colspan="1">Section: {{  }}</td>
                        <td colspan="1">Defect: {{  }}</td>
                        <td colspan="1">Repair: {{  }}</td>
                    </tr>
                    <tr>
                        <td colspan="5"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                        <td colspan="1"></td>
                    </tr>
                    <tr>
                        <td colspan="5">QA Technician: </td>
                        <td colspan="3">Repair Completion Date: </td>
                    </tr>
                </tbody>
            </table>

            <div id="rf_releasing_info_title" style="font-size:12px">
                Releasing Information
            </div>
            <table id="rf_releasing_info">
                <tbody>
                    <tr>
                        <td colspan="3">Delivery Date: </td>
                        <td colspan="2">Released By: </td>
                        <td colspan="3">Delivery Mode: </td>
                    </tr>
                    <tr>
                        <td colspan="3">Collection Date: </td>
                        <td colspan="2">Confirmed By: </td>
                        <td colspan="3"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-container> -->

    <!-- PRINT OUT TABLE -->
    <v-container fluid style="display:none">
            <div>
                <table id="rf_form">
                    <tbody>
                        <tr>
                            <td colspan="5">Company: {{ scRecevingReport.customer?.company_name }} </td>
                            <td colspan="3">Contact No: {{ this.scRecevingReport.customer?.company_name == 'END USER' ? scRecevingReport.sc_customer?.contact_number : scRecevingReport.customer?.mobile_1 }} </td>
                        </tr>
                        <tr>
                            <td colspan="3">Item Model: {{ scRepairFormItem.model }}</td>
                            <td colspan="2">Serial No: {{ scRepairFormItem.serial }} </td>
                            <td colspan="3">Category: {{ scRepairFormItem.category }}</td>
                        </tr>
                        <tr>
                            <td colspan="8">Item Name: {{ scRepairFormItem.product_name }}</td>
                        </tr>
                        <tr v-if="this.scRecevingReport.customer?.company_name == 'END USER'">
                            <td colspan="8">Customer Name : {{ (scRecevingReport.sc_customer?.first_name ?? '')  +' '+ (scRecevingReport.sc_customer?.middle_name ?? '')  +' '+ (scRecevingReport.sc_customer?.last_name ?? '') }}</td>
                        </tr>
                        <tr>
                            <td colspan="4">Warranty: </td>
                            <td colspan="4">Purchase Date: {{ scRepairFormItem.purchase_date }}</td>
                        </tr>
                        <tr>
                            <td colspan="8">Complaint/Symptom: {{ scRepairFormItem.defect_description }}</td>
                        </tr>
                        <tr>
                            <td colspan="8">Remarks: {{ scRepairFormItem.defect_remarks }}</td>
                        </tr>
                        <tr>
                            <td colspan="8">Description: {{ scRepairFormItem.other_unit_condition }}</td>
                        </tr>
                        <tr>
                            <td colspan="8">Included Items: {{ scRepairFormItem.accessories }}</td>
                        </tr>
                        <tr>
                            <td colspan="8">Inside the box: {{ scRepairFormItem.other_accessory }}</td>
                        </tr>
                        <tr>
                            <td colspan="5">Assigned Technician: {{ scTechnician.name }}</td>
                            <td colspan="3">Begin Repair Date: </td>
                        </tr>
                        <tr>
                            <td colspan="8">Symptoms as Tested: </td>
                        </tr>
                    </tbody>
                </table>

                <div style="font-size:12px">
                    Estimate Information
                </div>
                <table id="rf_estimation_form">
                    <tbody>
                        <tr>
                            <td colspan="5">Recommended part/s for Replacement:</td>
                            <td colspan="3">Diagnosis Date:</td>
                        </tr>
                        <tr>
                            <td colspan="8"></td>
                        </tr>
                        <tr>
                            <td colspan="8"></td>
                        </tr>
                    </tbody>
                </table>

                <div style="font-size:12px">
                    QA Assessment Information
                </div>
                <table id="qa_assessment_info">
                    <tbody>
                        <tr>
                            <td colspan="4">Technician Name: {{ scTechnician.name }}</td>
                            <td colspan="4">Date: </td>
                        </tr>
                        <tr>
                            <td colspan="7">Remarks: </td>
                            <td colspan="1">Reject Count: {{ details.item_rejected_counter }}</td>
                        </tr>
                        <!-- <tr>
                            <td colspan="8">Check list: </td>
                        </tr> -->
                    </tbody>
                </table>

                <div style="font-size:12px">
                    Log Information
                </div>
                <table id="log_info">
                    <tbody>
                        <tr>
                            <td colspan="8">Date: </td>
                        </tr>
                        <tr>
                            <td colspan="8">Remarks: </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';

import SharedFunctionsComponentVue from '../../Shared/SharedFunctionsComponent.vue';
export default {
    props:['rfid'],
    mixins:[SharedFunctionsComponentVue],
    data:()=>{
        return{
            details:{},
            scRecevingReport:{},
            scRepairFormItem:{},
            scTechnician:{},
        }
    },
    mounted(){
        // this.$store.dispatch('getLists')
        // this.submitData()
    },
    computed:{
        ...mapGetters([
            'GET_RF_DETAILS',
            'GET_CHECK_LISTS_SELECTION',
            'GET_FROM_MODULE',
        ]),
        repairFormId(){
            return this.$store.state.Services.print_rf_id
        }
    },
    watch: {
        details() {
            if(!!this.details){
                if(!!this.details.sc_receiving_report){
                    this.scRecevingReport = this.details.sc_receiving_report;
                }

                if(!!this.details.sc_repair_form_item){
                    this.scRepairFormItem = this.details.sc_repair_form_item;
                }

                if(!!this.details.sc_technician){
                    this.scTechnician = this.details.sc_technician;
                }
            }
        },
        'repairFormId': {
            handler(val){
                if(val != 0 ){
                    this.$store.dispatch('getLists')
                    this.submitData(val)
                }
            }
        }
    },
    methods:{
        async submitData(rfid) {

            await this.$store.dispatch('serviceGet',{
                url:'getRfDetails/'+ rfid
            }).then(response =>{
                this.details = response.data
            })

            this.$forceUpdate();

            let doc = new this.$jspdf("p", "mm", "letter");
            let rrDate = this.$dayjs(this.details.sc_receiving_report.created_at).format('YYYY-MM-DD, hh:mm:ss a');

            doc.setFont(undefined, 'bold').setFontSize(13).text(175, 13, "Repair Form")
            doc.setFont(undefined, 'bold').setFontSize(13).text(175,14,"\nRF#: "+this.details.rf_number)
            doc.setFont(undefined, 'bold').setFontSize(13).text(93,7.8,"\nPullout#: "+this.details.sc_receiving_report.pullout_number + "\nIssued#: "+ rrDate)
            doc.setFont(undefined, "bold").setFontSize(13).text(6, 13, "INGCOPH Traders Inc.\n" + "RD#: "+this.details.sc_receiving_report.rr_number);
            // doc.setFont(undefined, 'bold').setFontSize(9).text(162,13,"\nRF#:"+this.details.rf_number+"\n\nPullout#: "+this.details.sc_receiving_report.pullout_number+", RD#: "+this.details.sc_receiving_report.rr_number+"\n\nIssued: "+this.details.sc_receiving_report.created_at)

            let autoTableResult = doc.autoTable({
                html: '#rf_form',
                theme: 'grid',
                startY: 24,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });
            let startPosition = autoTableResult.autoTable.previous.settings.startY
            let formEndPosition = doc.lastAutoTable.finalY;

            // doc.rect(32,46.2,4,3,'S').setFont(undefined).setFontSize(8).text(37,48.5,'IN');
            // doc.rect(53,46.2,4,3,'S').setFont(undefined).setFontSize(8).text(58,48.5,'OUT');
            // doc.rect(78,46.2,4,3,'S').setFont(undefined).setFontSize(8).text(83,48.5,'DS');

            let type = this.details.sc_repair_form_item.warranty_type
            let checkbox = ''
            if (type == 'Out-Warranty') {
                checkbox = 'OUT'
            } else if (type == 'In-Warranty') {
                checkbox = 'IN'
            } else {
                checkbox = 'DS'
            }

            // this.scRecevingReport.customer.company_name == 'END USER'

            let warranty_type = ['IN', 'OUT', 'DS'];
            let warranty_x = 30;
            let warranty_y = this.scRecevingReport.customer?.company_name == 'END USER' ? startPosition + 29 : startPosition + 22;
            warranty_type.forEach((arr, i) => {
                doc.rect(warranty_x, warranty_y, 4, 3, checkbox == arr ? "F" : "S")

                doc.setFont(undefined, 'normal').setFontSize(9).text(arr, warranty_x + 6, warranty_y + 3);

                warranty_x += 25;
            })

            doc.setFont(undefined, 'normal').setFontSize(9).text(7,formEndPosition + 4,"QA Assessment:")
            doc.autoTable({
                html: '#qa_assessment_info',
                theme: 'grid',
                startY: formEndPosition + 5,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                },
                // didParseCell(data) {
                //     if (data.row.index == 2) {
                //     data.cell.styles.minCellHeight = 30;
                //     }
                // }
            });
            // doc.setFont(undefined).setFontSize(9).text(120,110,'__ __ / __ __ / __ __ __ __');

            let assessmentEndPosition = doc.lastAutoTable.finalY;

            doc.setFont(undefined).setFontSize(9).text(120,formEndPosition + 10.5,'___ ___ / ___ ___ / ___ ___ ___ ___');

            doc.setFont(undefined, 'normal').setFontSize(9).text(7,assessmentEndPosition + 6,"Check list:")

            let check_list = this.GET_CHECK_LISTS_SELECTION.slice();
            let x = 9;
            let y = assessmentEndPosition + 7;

            const checkListLength = check_list.length;
            const checkListHeight = Math.floor((checkListLength / 6) + 1) * 5;

            doc.rect(7,assessmentEndPosition + 7,203,checkListHeight + 2,'S')

            check_list.forEach((arr, i) => {
            doc.rect(x, y + 2, 4, 3);

            // Display arr.name next to the rectangle
            doc.text(arr.name, x + 6, y + 5);

            // Increment the X-coordinate for the next item
            x += 35; // You can adjust this value to control horizontal spacing

            // Start a new line after every 5 items
            if ((i + 1) % 6 === 0) {
                x = 9; // Reset X-coordinate to the initial position
                y += 5; // Move to the next line, you can adjust this value for vertical spacing
            }

            // Set the font size for the text
            doc.setFont(undefined).setFontSize(8);
            });

            const checkListEndPosition = assessmentEndPosition + checkListHeight + 2

            doc.setFont(undefined, 'normal').setFontSize(9).text(7,checkListEndPosition + 13,"Log Information:")
            doc.autoTable({
                html: '#log_info',
                theme: 'grid',
                startY: checkListEndPosition + 14,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });

            // doc.setFont(undefined).setFontSize(9).text(19,160,'__ __ / __ __ / __ __ __ __');
            let logInfoEndPosition = doc.lastAutoTable.finalY;

            doc.setFont(undefined, 'normal').setFontSize(9).text(7,logInfoEndPosition + 6,"Estimate Information:")
            doc.autoTable({
                html: '#rf_estimation_form',
                theme: 'grid',
                startY: logInfoEndPosition + 7,
                margin: {top: 37, left: 7, right: 6},
                columnStyles: {
                    0: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    1: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    2: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    3: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    4: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    5: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    6: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8},
                    7: {cellWidth: 25, halign:'left', textColor: '#000000', fontSize: 8}
                }
            });

            this.GET_FROM_MODULE == 'CM' ? window.open(doc.output('bloburl'), '_blank') : doc.save(this.details.rf_number+'.pdf');
            this.$store.commit('PRINT_RF_ID', 0);
            this.$store.commit('FROM_MODULE', '');
            // doc.autoPrint();
            // window.open(doc.output('bloburl'), '_blank');


            // doc.save(this.details.rf_number+'.pdf')
            // this.$emit('printed')
        },
    }
}
</script>

<style>

</style>
