import api from '../../api';
export default {

    async partsTransferGet(params){
         return await api.get('services/'+params.url,{params});
    },
    partsTransferPost(params){
        return api.post('services/parts-transfer'+params.url, params);
    },
}
