import api from '../../../api'
export default {

    getpopurchaseorder(params){
        return api.get('procurement/purchase-order',{params});
    },
    getpoitemselection(params){
        return api.get(`procurement/purchase-order/${params}/item-selection`)
    },
    getpodetailsitem(id,params){
        return api.get(`procurement/purchase-order/${id}/details/items`,{params})
    },
    getpodetails(id,params){
        return api.get(`procurement/purchase-order/${id}/details`,{params})
    },
    storepo(params){
        return api.post(`/procurement/purchase-order`,params)
    },
    getpoexport(id,params){
        return api.get(`procurement/purchase-order/${id}/export`,{params})
    },
    getincomingstocks(params){
        return api.post(`/items/incomingStocks`,params)
    },
    getaverageconsumption(params){
        return api.post(`/items/averageConsumption`,params)
    },
    getpendingorders(params){
        return api.post(`/items/pendingOrders`,params)
    },
    getitemstocks(params){
        return api.post(`/items/itemStocks`,params)
    },
    importordertemplate(id,params){
        return api.post(`procurement/purchase-order/${id}/import-order-template`,params)
    },
    poupdate(id,params){
        return api.post(`procurement/purchase-order/${id}/update`,params)
    },
    submitforchecking(id){
        return api.get(`procurement/purchase-order/${id}/submit-for-checking`)
    },
    destroypo(id){
        return api.delete(`procurement/purchase-order/${id}/destroy`)
    },
    exportrawdata(params){
        return api.post(`/procurement/purchase-order/details/export/rawdata`,params)
    },
    exportitemdetails(params){
        return api.post(`/procurement/purchase-order/details/export/item-details`,params)
    },
    purchaseOrderGet(params){
        return api.post(`/procurement/purchase-order/${params.url}`,params)
    },
    purchaseOrderPost(params){
        return api.post(`/procurement/purchase-order/${params.url}`,params)
    },
    itemsGet(params){
        return api.get(`/items/${params.url}`,{params})
    },
    itemsPost(params){
        return api.post(`/items/${params.url}`,params)
    }

}
