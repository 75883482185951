<template>
    <v-container>
        <ComponentHeader :button="true" :title="'Technician Accuracy Report'" :type="'Service'"></ComponentHeader>
        <v-card class="bg-bluish-gray m-1 p-1">
            <v-card-text>
                <v-row>
                    <v-col lg="10">
                        <v-row>
                            <v-col cols="4" class="mt-2 ml-3">
                                <v-autocomplete
                                :items="GET_CUSTOMERS_SELECTION"
                                v-model="filters.customer_id"
                                auto-select-first
                                multiple
                                small-chips
                                clearable
                                outlined
                                dense
                                deletable-chips
                                background-color="white"
                                label="Customer Name">
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="3" class="mt-2">
                                <v-autocomplete
                                :items="GET_ITEMS"
                                v-model="filters.model"
                                auto-select-first
                                multiple
                                small-chips
                                clearable
                                outlined
                                dense
                                deletable-chips
                                background-color="white"
                                label="Item Unit/Model">
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="2" class="mt-2">
                                <v-text-field
                                v-model="filters.rf"
                                multiple
                                chips
                                outlined
                                dense
                                deletable-chips
                                background-color="white"
                                label="RF#">
                                </v-text-field>
                            </v-col>

                            <v-col cols="2" class="mt-2">
                                <v-text-field
                                v-model="filters.sq"
                                multiple
                                chips
                                outlined
                                dense
                                deletable-chips
                                background-color="white"
                                label="SQ#">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="2">
                        <v-btn small @click="resetFields()" class="mr-2">Clear </v-btn>
                         <v-menu
                        transition="slide-y-transition"
                        bottom
                        :close-on-content-click="false"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-2"
                                >
                                Export </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-card
                                        class="mx-auto"
                                        max-width="500"
                                        max-height="200"
                                    >
                                        <v-card-text>
                                            <v-chip-group
                                                v-model="filters.selectedExport"
                                                column
                                                multiple
                                            >
                                                <v-chip
                                                    filter
                                                    outlined
                                                >
                                                Raw Data
                                                </v-chip>
                                                <v-chip
                                                    filter
                                                    outlined
                                                >
                                                General Data
                                                </v-chip>
                                            </v-chip-group>
                                        </v-card-text>
                                        <v-card-text>
                                            <v-btn small icon text color="green" @click="list(1)" class="pull-right float-right mx-2 mr-6 pr-8"><v-icon>mdi-cloud-download</v-icon></v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <!-- <v-btn small @click="list(1)" class="mr-2">Export </v-btn> -->
                        <v-btn small @click="list()">Filter </v-btn>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col lg="12">
                        <v-card class="my-3">
                            <v-card-text>
                                <v-container fluid>
                                    <v-data-table
                                    :headers="dataTable.headers"
                                    :items="dataTable.items"
                                    :options.sync="dataTable.options"
                                    :server-items-length="dataTable.totalItems"
                                    :expanded-sync="dataTable.expanded"
                                    :items-per-page="50"
                                    :footer-props="{
                                        itemsPerPageOptions: dataTable.itemsPerPageOptions,
                                    }"
                                    disable-sort item-key="rf_id"
                                    @item-expanded="viewSQ($event.item.rf_id)"
                                    dense
                                    show-expand
                                    single-expand
                                    >
                                        <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length">
                                                    <v-data-table
                                                        :headers="dropdownTable.inheaders"
                                                        :items="sales_quotations"
                                                        item-key="sq_id"
                                                        dense
                                                        dark
                                                        >
                                                        <template v-slot:[`item.quote_num`]="{ item }">
                                                            <span class="text-nowrap">
                                                                {{ item.quote_num }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:[`item.estimation_id`]="{ item }">
                                                            <span class="text-nowrap">
                                                                {{ item.estimation_id }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:[`item.total_amount`]="{ item }">
                                                            <span class="text-nowrap">
                                                                &#x20b1;{{ item.total_amount | currency }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:[`item.discount_amount`]="{ item }">
                                                            <span class="text-nowrap">
                                                                &#x20b1;{{ item.discount_amount | currency }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:[`item.created_at`]="{ item }">
                                                            <span class="text-nowrap">
                                                                {{ item.created_at | formatDateTime }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:[`item.actions`]="{ item }">
                                                            <v-btn icon text @click="viewDialog(item.sq_id)" color="orange" small>
                                                                <v-icon small>mdi-eye</v-icon>
                                                            </v-btn>
                                                        </template>
                                                    </v-data-table>
                                                </td>
                                        </template>
                                    </v-data-table>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- View Dialog for Sales Quotation items -->
        <v-dialog width="50%" v-model="sqDialog">
            <v-card>
                <v-card-title >
                    <span>SQ#: {{this.sq_details.sq_id}}</span>
                     <v-row class="m-0">
                        <v-col cols="pull-right-10 p-2">
                            <v-btn
                            text
                            icon
                            small
                            color="gray"
                            class="float-right"
                            @click="closeDialog()"
                            >
                            <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-col>
                     </v-row>
                </v-card-title>
                <!-- <v-divider/> -->
                <v-row>
                    <v-col  cols="4">
                        <div  class="ml-6 pl-4">
                            Quotation#: {{this.sq_details.quote_num}}
                        </div>
                        <div  class="ml-6 pl-4">
                            Salesman: {{ this.sq_details.name }}
                        </div>
                        <div class="ml-6 pl-4">
                            Issuer: {{ this.sq_details.issuer_name }}
                        </div>
                        <div  class="ml-6 pl-4" pull-right-10>
                            Date Issued:
                            {{ this.sq_details.created_at | formatDateTime }}
                        </div>
                    </v-col>
                    <v-col cols="8">
                        <div   class="ml-6 pl-4" >
                            Customer: {{ this.sq_details.customer_name }}
                        </div>
                        <div  class="ml-6 pl-4">
                            Remarks: {{ this.sq_details.remarks }}
                        </div >
                        <div class="ml-6 pl-4">
                            Notes: {{ this.sq_details.notes }}
                        </div>
                    </v-col>
                </v-row>
                <v-divider/>
                <v-data-table
                :headers="lastTable.headers"
                :items="sq_items"
                item-key="sq_id"
                dense
                disable-sort
                >
                 <template v-slot:[`item.srp`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.srp | currency }}
                    </span>
                </template>
                 <template v-slot:[`item.disc_price`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.disc_price | currency }}
                    </span>
                </template>
                 <template v-slot:[`item.total_amount`]="{ item }">
                    <span class="text-nowrap">
                        &#x20b1;{{ item.total_amount | currency }}
                    </span>
                </template>
                 <template v-slot:[`item.created_at`]="{ item }">
                    <span class="text-nowrap">
                        {{ item.created_at | formatDateTime }}
                    </span>
                </template>
                <template v-slot:[`item.foc`]="{ item }">
                    <span class="text-nowrap">
                    {{ item.foc === 1 ? 'YES' : 'NO' }}
                    </span>
                </template>
                </v-data-table>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dataTable.loading" width="100%" persistent>
            <v-progress-circular
                size="100"
                :width="10"
                color="primary"
                indeterminate
            ></v-progress-circular>
        </v-dialog>
    </v-container>
</template>

<script>
import ComponentHeader from "@/views/main/layouts/Reports/ReportsHeaderComponent.vue";
import SharedFunctionsComponent from "@/views/main/Shared/SharedFunctionsComponent.vue";
import DialogComponent from '@/views/main/layouts/dialogs/Dialog.vue';
import { mapGetters } from "vuex";
import Swal from 'sweetalert2'
export default {
    mixins: [SharedFunctionsComponent],
    components: {
        ComponentHeader,
        DialogComponent
    },
    data: () => ({
        filters: {
            customer_id: [],
            model: [],
            rf: '',
            sq: '',
            selectedExport:[] ?? null,
        },
        sqDialog: false,
        dataTable: {
            items: [],
            headers: [
                { text: 'RF#',          value: 'rf_num' },
                { text: 'RD#',          value: 'rd_num' },
                { text: 'Item Unit',    value: 'model' },
                { text: 'Count',        value: 'count' },
            ],
            totals: [],
            options: {},
            itemsPerPageOptions: [10, 50, 100, 150],
            itemsPerpage: 150,
            totalItems: 0,
            loading: false,
            expanded: [],
        },
        dropdownTable: {
            inheaders: [
                { text: 'SQ#',              value: 'quote_num' },
                { text: 'ES#',              value: 'estimation_num' },
                { text: 'Total Amount',     value: 'total_amount' },
                { text: 'Discount Amount',  value: 'discount_amount' },
                { text: 'Date Created',     value: 'created_at' },
                { text: 'Action',           value: 'actions' },
            ],

        },
        lastTable: {
            headers: [
                { text: 'Item',             value: 'item_id' },
                { text: 'Quantity',         value: 'quantity' },
                { text: 'SRP',              value: 'srp' },
                { text: 'Discount Amount',  value: 'disc_price' },
                { text: 'Total Amount',     value: 'total_amount' },
                { text: 'FOC',              value: 'foc' },
                { text: 'Date Issued',      value: 'created_at' },
            ]
        },
        sales_quotations: [],
        sq_details: [],
        sq_items: [],
    }),

    mounted() {
        this.$store.dispatch('getAllcustomersSelection')
        this.$store.dispatch('getItems')
        this.$store.dispatch('salesQuotationsGetAll')

    },

    computed: {
        ...mapGetters([
            'GET_CUSTOMERS_SELECTION',
            'GET_ITEMS',
            'DIALOG',
            'GET_SALES_QOUTATION_LIST'
        ]),
    },
    methods: {
        async list(exportToExcel = 0) {
            this.dataTable.items = []
            this.dataTable.loading = true

                if(exportToExcel == 1){
                    Swal.fire('Exporting File Please Wait')
                    Swal.showLoading()

                    this.dataTable.loading = false
                    if(this.filters.selectedExport === null || this.filters.selectedExport.length === 0 || this.filters.selectedExport.length === 2){
                        Swal.fire("-Select 1 export type", 'Raw or General', "warning")
                    }

                    if(this.filters.selectedExport.length === 1 ){
                        await this.$store.dispatch('serviceGet',{
                        url:'export-Technician-Accuracy',
                        ...this.filters,
                        page: this.dataTable.options.page,
                        itemsPerPage: this.dataTable.options.itemsPerPage,

                        }).then(response=>{
                            this.exported_data = response.data
                            }).catch(err=>{
                                console.log(err)
                                Swal.fire('Failed! Here!','','error')
                                })

                            let exportHeadersAndData = {};
                            exportHeadersAndData['Technician-Accuracy-Report'] = this.filters.selectedExport.includes(1) ? this.exportGeneralData() : this.exportRawData()
                            this.exportExcel(exportHeadersAndData, `Technical-Accuracy-Report${this.$dayjs().format('YYYY-MM-DD')}`)
                    }
                }

        await this.$store.dispatch('serviceGet', {
                url: 'service-report-tech-accuracy',
                ...this.filters,
                page:         this.dataTable.options.page,
                itemsPerPage: this.dataTable.options.itemsPerPage,

            }).then(response => {
                this.dataTable.items      = response.data.data.data
                this.dataTable.totalItems = response.data.data.total

            }).catch(err => {
                console.log(err)
                Swal.fire('Error!', '-', 'error')
                this.dataTable.loading = false
                Swal.close();
            })
                this.dataTable.loading = false
                Swal.close();
        },

        exportRawData() {
            let worksheet = {}
            if (this.filters.selectedExport.includes(0)) {
                worksheet.headers = [
                    {header:'RF#',                          key:'rf_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'RD#',                          key:'rd_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Item Unit',       width: 20,   key:'model', style: { alignment: { vertical: 'middle', horizontal: 'left' } }},
                    {header:'SQ#',                          key:'quote_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'ES#',                          key:'estimation_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Customer Name',   width: 28,   key:'customer_name', style: { alignment: { vertical: 'middle', horizontal: 'left' } }},
                    {header:'Total Amount',    width: 20,   key:'total_amount', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Discount Amount', width: 20,   key:'discount_amount', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Date Created',    width: 20,   key:'created_at', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Item',            width: 25,   key:'item_model', style: { alignment: { vertical: 'middle', horizontal: 'left' } }},
                    {header:'Quantity',                     key:'quantity', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'SRP',             width: 25,   key:'srp', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'FOC',                          key:'foc', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                ];

                this.exported_data.forEach(e => {
                    e.rd_num            = parseInt(e.rd_num);
                    e.rd_num            = parseInt(e.rd_num);
                    e.quote_num         = parseInt(e.quote_num);
                    e.total_amount      = parseFloat(e.total_amount).toFixed(2);
                    e.discount_amount   = parseFloat(e.discount_amount).toFixed(2);
                    e.srp               = parseFloat(e.srp).toFixed(2);
                    e.created_at        = this.$dayjs(e.created_at).format('MM-DD-YYYY');
                    e.foc               = e.foc === '1' ? 'YES' : 'NO' ;
                });
                worksheet.data = this.exported_data;
            }
            return worksheet;
        },

        exportGeneralData() {
            let worksheet = {}
            if (this.filters.selectedExport.includes(1)) {
                worksheet.headers = [
                    {header:'RF#',                          key:'rf_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'RD#',                          key:'rd_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Item Unit',        width: 20,  key:'model', style: { alignment: { vertical: 'middle', horizontal: 'left' } }},
                    {header:'SQ#',                          key:'quote_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'ES#',                          key:'estimation_num', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Customer Name',    width: 28,  key:'customer_name', style: { alignment: { vertical: 'middle', horizontal: 'left' } }},
                    {header:'Total Amount',     width: 20,  key:'total_amount', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Discount Amount',  width: 20,  key:'discount_amount', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},
                    {header:'Date Created',     width: 20,  key:'created_at', style: { alignment: { vertical: 'middle', horizontal: 'center' } }},

                    ]

                this.exported_data.forEach(e => {
                    e.rd_num            = parseInt(e.rd_num);
                    e.quote_num         = parseInt(e.quote_num);
                    e.total_amount      = parseFloat(e.total_amount).toFixed(2);
                    e.discount_amount   = parseFloat(e.discount_amount).toFixed(2);
                    e.created_at        = this.$dayjs(e.created_at).format('MM-DD-YYYY');
                });
                worksheet.data = this.exported_data;
            }
            return worksheet;
        },

        async viewSQ(rf_id) {
            this.sales_quotations   = []
            this.dataTable.loading  = true;

                await this.$store.dispatch('serviceGet', {
                    url: 'viewSQ-Technician-Accuracy',
                    items: rf_id,
                }).then(response => {

                if (Array.isArray(response.data.data)) {
                    this.sales_quotations = response.data.data;
                }

                    this.dataTable.loading = false;
                }).catch(err => {
                console.log(err)
                Swal.fire('Error!', '-', 'error')
                this.dataTable.loading = false
            })
        },

        resetFields() {
            Object.assign(this.$data, this.$options.data.call(this));
        },

       async viewDialog(sq_id) {
            this.sqDialog   = true;
            this.sq_details = []
            this.sq_items   = []


            const matchedSalesQuotation = this.sales_quotations.find(sq => sq.sq_id === sq_id);
            if (matchedSalesQuotation) {
                this.sq_details = matchedSalesQuotation;
            } else {
                console.error('Sales quotation not found for sq_id:', sq_id);
            }

             await this.$store.dispatch('serviceGet', {
                url: 'viewSQ-Items-Technician-Accuracy',
                sq_id: sq_id,
            }).then(response => {
                this.sq_items = response.data.data

                 }).catch(err => {
                console.log(err)
                Swal.fire('Error!', '-', 'error')
                this.dataTable.loading = false
            })
        },

        closeDialog() {
            this.sqDialog = false
        }
    },
    watch: {
        'GET_CUSTOMERS_SELECTION': {
            handler(val) {
                this.customers_list    = val
                this.dataTable.loading = false
            }
        },
        'dataTable.options': {
            handler(val) {
                this.list()
            }
        }
    }

}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
</style>
