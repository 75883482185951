import repository from '@/api/modules/MasterData';

export default {
    state: {
        get_report_module: [],
        get_report_module_view: {},
        get_report_category_view: {},
        refresh_report: false,
        refresh_category_report: false,
    },

    getters: {
        GET_REPORT_MODULE:(state)=>state.get_report_module,
        GET_REPORT_MODULE_VIEW:(state)=>state.get_report_module_view,
        GET_REPORT_CATEGORY_VIEW:(state)=>state.get_report_category_view,
        GET_REFRESH_REPORT:(state)=>state.refresh_report,
        GET_REFRESH_CATEGORY_REPORT:(state)=>state.refresh_category_report,
    },

    mutations: {
		REPORT_MODULE:(state,data)=>{state.get_report_module = data},
		REPORT_MODULE_VIEW:(state,data)=>{state.get_report_module_view = data},
		REPORT_CATEGORY_VIEW:(state,data)=>{state.get_report_category_view = data},
		REFRESH_REPORT:(state,data)=>{state.refresh_report = data},
		REFRESH_CATEGORY_REPORT:(state,data)=>{state.refresh_category_report = data},
    },

    actions: {
        reportsGet({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.reportsGet(payload).then((response)=>{
                    commit('REPORT_MODULE',response.data)
                    // commit('REFRESH', true)
                    resolve(response)
                }).catch(e=>{
                    reject(e)
                })
            })
        },

        reportsModulePost({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.reportsModulePost(payload).then((response)=>{
                    commit('REPORT_MODULE',response.data)
                    resolve(response)
                }).catch(e=>{
                    reject(e)
                })
            })
        },
    }
}