import api from '../../../api'

export default {
    journalManagerPost(params){
        return api.post(`/accounting/journalvouchermanager/`+params.url,params)
    },
    journalManagerGet(params){
        return api.get(`/accounting/journalvouchermanager/`+params.url,{params})
    },
    journalManagerPut(params){
        return api.put(`/accounting/journalvouchermanager/`+params.url,params)
    },
    journalManagerDelete(params){
        return api.delete(`/accounting/journalvouchermanager/journalvouchermanager/`+params.url)
    },
    getJVMNum(params){
        return api.get('/accounting/journalvouchermanager/getJVMNum',{ params });
    },
    getJVMFiles(params){
        return api.get(`accounting/journalvouchermanager/get-journal_voucher-manager-files/${params.id}`,{params});
    },
    deleteJVMFiles(params){
        return api.get(`accounting/journalvouchermanager/delete-journal_voucher-manager-files/${params.id}`)
    }
}

