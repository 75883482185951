import store from '../../../../store';
import api from '../../../api'

export default {
    // Overtime api
    getOvertimeRecord(params){
        return api.get('/hr/hr-overtime-mother-component', {params});
    },
    showOvertimeRecord(params){
        return api.post(`/hr/showOvertimeRecord`, params);
    },
    // showOvertimeRecord(params){
    //     return api.get(`/hr/hr-overtime-mother-component/${params.id}`, {params});
    // },
    deleteOvertimeRecord(params){
        return api.delete(`/hr/hr-overtime-mother-component/${params}`);
    },
    updateOvertimeRecord(params){
        return api.post(`/hr/hr-overtime-items-update/${params.id}`, params);
    },
    addEmployeeOvertime(params){
        return api.post('/hr/hr-overtime-items', params);
    },
    changeOvertimeStatus(params){
        return api.post(`/hr/hr-overtime-items-change-status/${params.id}`, params);
    },
    approveEmployeeOvertime(params){
        return api.post(`/hr/hr-overtime-change-status/${params.id}`, params);
    },
    batchApproveAndReject(params){
        return api.post(`/hr/hr-overtime-items-multiple-change-status`, params);
    },
    getOvertimeFiles(params){
        return api.get(`/hr/hr-overtime-file`, {params});
    },
    deleteOvertimeFiles(params){
        return api.delete(`/hr/hr-overtime-file-delete/${params}`);
    },
    checkDepartmentAccess(params){
        return api.get(`/hr/check-department-access`, {params});
    },

    getOvertimeRecordHR(params){
        return api.get('/hr/getOvertimeRecordHR', {params});
    },
    showOvertimeRecordHR(params){
        return api.post(`/hr/showOvertimeRecordHR`, params);
    },
    updateOvertimeRecordHR(params){
        return api.post(`/hr/updateOvertimeRecordHR`, params);
    },
    checkEmployeeBioDate(params){
        return api.post(`/hr/checkEmployeeBioDate`, params);
    },
}
