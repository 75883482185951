import repository from '@/api/modules/Accounting/AccountingGroup/accountinggroup'
import store from '@/store';
export default {
	state: {
		accounting_groups: [],
		edit_accounting_groups: {},
		new_accounting_groups: false,
	},
	getters: {
		GET_ACCOUNTING_GROUPS:(state)=>state.accounting_groups,
		GET_EDIT_ACCOUNTING_GROUPS:(state)=>state.edit_accounting_groups,
        GET_NEW_ACCOUNTING_GROUPS_TABLE:(state)=>state.new_accounting_groups,
	},
	actions:{
        getAllAccountingGroups({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllAccountingGroups(payload).then((response)=>{
					if(response.status == 200){
						commit('ACCOUNTING_GROUPS', response.data);
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		getAccountingGroupItems({commit},payload) {
			return new Promise((resolve,reject) => {
				repository.getAccountingGroupItems(payload).then((response) => {
						if(response.status == 200){
							commit('EDIT_ACCOUNTING_GROUPS', response.data);
							resolve(response.data)
						}
				}).catch((error) => {
						console.log(error);
						reject(error);
				})
			})
		},
		CreateAccountGroups({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.CreateAccountGroups(payload).then((response)=>{
					if(response.status == 200){
                        commit('NEW_ACCOUNTING_GROUPS_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
                    console.log(error);
					reject(error)
				});
			})
		},
		UpdateAccountGroups({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdateAccountGroups(payload).then((response)=>{
					if(response.status == 200){
                        commit('NEW_ACCOUNTING_GROUPS_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeleteAccountGroups({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeleteAccountGroups(payload).then((response)=>{
					if(response.status == 200){
						commit('NEW_FUNDAMENTALS_TABLE', response.data)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
	},
	mutations:{
		ACCOUNTING_GROUPS:(state, data) => {
			state.accounting_groups = data
		},
		EDIT_ACCOUNTING_GROUPS:(state, data) => {
			state.edit_accounting_groups = data
		},
		NEW_ACCOUNTING_GROUPS_TABLE:(state,data)=>{
			state.new_accounting_groups = data;
		},
	},
}
