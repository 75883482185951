<template>
    <div>
        <v-tooltip bottom color="#f69f1a">
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                :loading="isLoading"
                color="#f69f1a"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                small
                fab
                text
                @click="showFilesViewerDialog()"
              >
                <v-icon>mdi-file-document-multiple-outline</v-icon>
            </v-btn>

            </template>
            <span>{{ toolTipMessage ?? 'View Files' }}</span>
        </v-tooltip>
        <v-dialog v-model="filesViewerDialog" persistent max-width="50%">
            <v-card>
                <v-row class="m-0">
                    <v-col>
                        <v-card-title>
                            <span class="headline">Files Viewer</span>
                        </v-card-title>
                    </v-col>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeFilesViewerDialog()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="my-0" />

                <v-layout class="mx-0 px-4 d-flex align-center">
                    <v-flex shrink>
                        <v-checkbox v-model="batchDownload">
                            <template v-slot:label>
                                <div class="font-weight-medium">Batch Download</div>
                            </template>
                        </v-checkbox>
                    </v-flex>
                </v-layout>

                <v-data-table
                    class="pa-4"
                    :headers="filesHeader"
                    :items="files"
                    :loading="loading"
                    :no-data-text="noDataText"
                    :show-select="batchDownload"
                    v-model="selectedImages"
                >
                <template v-slot:item.action="{ item }">
                    <td class="px-0">
                        <span class="text-nonwrap">
                            <v-btn text icon color="orange">
                                <v-icon class="btn-action" small @click="showFile(item); newImagePath()">mdi-eye</v-icon>
                            </v-btn>
                            <v-btn v-if="!batchDownload" text icon color="blue">
                                <v-icon class="btn-action" small @click="downloadFile(item)">mdi-download</v-icon>
                            </v-btn>
                            <v-btn v-if="withDelete" text icon color="red">
                                <v-icon class="btn-action" small @click="deleteFile(item)">mdi-delete</v-icon>
                            </v-btn>
                        </span>
                    </td>
                </template>
                <template v-slot:item.created_at="{ item }">
                    <td class="px-0">
                        {{ date(item) }}
                    </td>
                </template>
                </v-data-table>

                <div v-if="batchDownload" class="pb-4">
                    <v-divider class="mt-0" />

                    <div style="text-align:center">
                        <v-btn @click="downloadSelectedImages()">Download</v-btn>
                    </div>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="viewFile" persistent max-width="80%">
            <v-card>
                <v-row class="m-0">
                    <v-card-title>
                        <span class="headline">{{ itemName }}</span>
                    </v-card-title>
                    <v-col cols="pull-right-10 p-2">
                        <v-btn text icon color="gray" class="float-right" @click="closeViewFile()">
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-0" />
                <div>
                    <!-- <iframe :src="sourceFrame" frameborder="0" class="responsive-iframe"></iframe> -->
                    <pinch-scroll-zoom
                        ref="zoomer"
                        :width="300"
                        :height="400"
                        :scale="scale"
                        @scaling="scalingHandler"
                        style="width:100%;height:600px;overflow:auto;"
                    >
                        <img :src="sourceFrame" />
                    </pinch-scroll-zoom>
                </div>
            </v-card>
        </v-dialog>

        <div style="display:none">
            <span v-for="(img,index) in selectedImages" :key="img.name">
                <img :id="`img${index}`" :src="sourceFrame" alt="" style="cursor:move">
            </span>
        </div>
    </div>
</template>

<script>
// import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue-pinch-scroll-zoom";
import { map } from 'rxjs';
import { mapGetters } from 'vuex';
import swal from 'sweetalert2';
import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue-pinch-scroll-zoom";
export default {
    data() {
        return {
            filesHeader: [
                { text: 'Name', value: 'name' },
                { text: 'Uploaded By', value: 'uploaded_by' },
                { text: 'Date', value: 'created_at' },
                { text: 'Action', value: 'action' },
            ],
            files: [],
            repairFormId: '',
            loading: true,
            noDataText: '',
            viewFile: false,
            itemPath: '',
            itemName: '',
            withDelete: false,
            itemWithSpId: '',
            scale: 2,
            batchDownload: false,
            selectedImages: [],
            rf_number: '',
            origin: process.env.VUE_APP_API_HOST,
            downloadOnFlight: false,
            sourceFrame: '',
            inbound_id: '',
            salesQuotationId: '',
            disposal_id: '',
            sc_return_receipt_id: '',
        }
    },
    components: {
        PinchScrollZoom
    },
    props: ['rfNumber', 'rfId','delete', 'itemId', 'buttonName','stockAdjustmentId', 'buttonColor', 'buttonLoading', 'inboundId', 'toolTipMessage', 'sqId', 'disposalId', 'scReturnReceiptId'],
    watch: {
        repairFormId() {
            if(!!this.repairFormId) {
                this.getREpairFormFiles();
            }
        },
        itemWithSpId() {
            if(!!this.itemWithSpId) {
                this.getItemFiles();
            }
        },
        inbound_id() {
            if (!!this.inbound_id) {
                this.getInboundFiles();
            }
        },
        sqId() {
            if(!!this.sqId) {
                this.getSqFiles();
            }
        },
        disposal_id() {
            if (!!this.disposal_id) {
                this.getDisposalFiles();
            }
        },
        sc_return_receipt_id() {
            if (!!this.sc_return_receipt_id) {
                this.getReturnReceiptFiles();
            }
        },
        files() {
            if(this.files.length > 0) {
                this.loading = false;
            } else {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false,
                    this.noDataText = 'No Uploaded Files';
                }, 10000);
            }
        },
        rfNumber() {
            if(!!this.rfNumber) {
                this.rf_number = this.rfNumber;
            }
        },
        batchDownload() {
            if(this.batchDownload) {
                // this.showSelect = true;
            }
        },
    },
    computed: {
        ...mapGetters([
            'GET_REPAIR_FORM_FILES',
            'GET_ITEM_WITH_SP_FILES',
            'GET_INBOUND_UPLOAD_FILES',
            'GET_SQ_FILES',
        ]),
        filesViewerDialog() {
            return this.$store.state.service.filesViewerDialog;
        },
        renderButtonColor() {
            return this.buttonColor ?? 'blue';
        },
        isLoading() {
            return this.buttonLoading ?? false;
        }
    },

    methods: {
        clearFields() {
            this.loading = true;
            this.files = [];
            this.repairFormId = '';
            this.itemWithSpId = '';
            this.noDataText = '';
            this.withDelete = false;
            this.rf_number = '';
            this.batchDownload = false;
            this.downloadOnFlight = false;
            this.selectedImages = [];
            this.inbound_id = '';
            this.salesQuotationId = '';
            this.disposal_id = '';
            this.sc_return_receipt_id = '';
        },
        showFilesViewerDialog() {
            this.$store.commit('showFilesViewerDialog');

            if(!!this.inboundId) {
                this.inbound_id = this.inboundId
            }

            if(!!this.rfId) {
                this.repairFormId = this.rfId;
            } else if(!!this.itemId) {
                this.itemWithSpId = this.itemId;
            }
            if(!!this.sqId) {
                this.salesQuotationId = this.sqId;
            }

            if (!!this.disposalId) {
                this.disposal_id = this.disposalId;
            }

            if (!!this.scReturnReceiptId) {
                this.sc_return_receipt_id = this.scReturnReceiptId
            }

            if(this.delete) {
                this.withDelete = true;
            } else {
                this.withDelete = false;
            }
        },
        closeFilesViewerDialog() {
            this.$store.commit('closeFilesViewerDialog');
            this.clearFields();
        },
        getREpairFormFiles() {
            this.$store.dispatch('getRepairFormFiles',this.repairFormId).then(response=>{
                this.files = this.GET_REPAIR_FORM_FILES;
            });
        },
        getItemFiles() {
            this.$store.dispatch('getItemWithSpFiles',this.itemWithSpId).then(response=>{
                this.files = this.GET_ITEM_WITH_SP_FILES;
            });
        },
        date(item) {
            if(!!item) {
                return this.$dayjs(item.created_at).format('YYYY-MM-DD, hh:mm:ss a');
            }

            return '';
        },
        showFile(file) {
            this.viewFile = true;
            this.itemPath = file.path;
            this.itemName = file.name
            this.$store.commit('closeFilesViewerDialog');
        },
        downloadFile(file) {
            swal.fire({
                text: 'Are you sure you want to download?',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then(async(result) => {
                if(result.isConfirmed) {
                    let uri
                    if ( this.inbound_id) {
                        uri = `${process.env.VUE_APP_API_HOST}/img/operations/inbound?image_name=${file.name}`
                    } else if ( this.disposal_id) {
                        uri = `${process.env.VUE_APP_API_HOST}/img/service/sc_disposal?image_name=${file.name}`
                    } else if (this.sc_return_receipt_id) {
                        uri = `${process.env.VUE_APP_API_HOST}/img/service/sc_return_receipt?image_name=${file.name}`
                    } else {
                        uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${file.name}`
                    }
                    fetch(uri)
                    .then(resp => resp.blob())
                    .then(blobobject => {
                        const blob = window.URL.createObjectURL(blobobject);
                        const anchor = document.createElement('a');
                        anchor.style.display = 'none';
                        anchor.href = blob;
                        anchor.download = file.name;
                        document.body.appendChild(anchor);
                        anchor.click();
                        window.URL.revokeObjectURL(blob);
                    })
                    .catch(() => console.log('An error in downloadin gthe file sorry'));
                        }
                    });
        },
        deleteFile(file) {
            swal.fire({
                text: 'Are you sure you want to delete?',
                icon: 'question',
                dangerMode: true,
                buttons: true
            }).then((confirm) => {
                if(confirm) {
                    let payload = {
                        fileId: file.id
                    }
                    if (!!this.inbound_id) {
                        this.$store.dispatch('inboundDeleteFile',payload).then(response => {
                            this.getInboundFiles();
                            let res = response.data.message;
                            swal.fire(res, " ", res.toLowerCase());
                        }).catch(e => {
                            swal.fire(res, " ", res.toLowerCase());
                        })

                    } else if(!!this.sqId){
                        this.$store.dispatch('sqDeleteFile',payload).then(response => {
                            this.getSqFiles()
                            let res = response.data.message;
                            swal.fire(res, " ", res.toLowerCase());
                        }).catch(e => {
                            swal.fire(res, " ", res.toLowerCase());
                        })
                    } else if (!!this.disposal_id) {
                        this.$store.dispatch('disposalDestroy', {fileId: file.id, url: 'disposal-file/{disposal_file}'}).then(response => {
                            let res = response.data.message;
                            swal.fire(res, " ", res.toLowerCase());
                            this.getDisposalFiles()
                        }).catch(e => {
                            swal.fire(res, " ", res.toLowerCase());
                        })
                    } else if (!!this.sc_return_receipt_id) {
                        this.$store.dispatch('serviceDelete', {fileId: file.id, url: 'sc-return-receipt-files/{sc_return_receipt_file}'}).then(response => {
                            let res = response.data.message;
                            swal.fire(res, " ", res.toLowerCase());
                            this.getReturnReceiptFiles()
                            if (this.files.length == 0) {
                                this.$emit('deletedAll');
                            }
                        }).catch(e => {
                            swal.fire(res, " ", res.toLowerCase());
                        })
                    }else {
                        this.$store.dispatch('serviceDeleteFile',payload).then(response=>{
                            if(!!this.repairFormId) {
                                this.getREpairFormFiles();
                            } else if(!!this.itemWithSpId) {
                                this.getItemFiles();
                            }
                            swal.fire("", response.data.msg, "success");
                        }).catch(e => {
                            swal.fire("", e.data.error, "error");
                        })
                    }
                }
            });
        },
        closeViewFile() {
            this.reset();
            this.itemPath = "";
            this.viewFile = false;
            this.$store.commit('showFilesViewerDialog');
        },
        scalingHandler(e) {
            // console.log(e);
        },
        reset() {
            this.$refs.zoomer.setData({
                scale: 1,
                originX: 0,
                originY: 0,
                translateX: 0,
                translateY: 0
            });
        },
        downloadSelectedImages() {
            this.downloadOnFlight = true;

            let zip = new this.$jszip();
            let dateToday = this.$dayjs().format('YYYY-MM-DD');
            let name = !!this.rfNumber ? this.rfNumber : 'Images'
            let vm = this;

            if(this.selectedImages == 0) {
                swal.fire('', 'Please select images', 'warning').then(confirm=>{
                    this.downloadOnFlight = false;
                });
            } else {
                this.selectedImages.forEach((x,i)=>{
                    let path = this.origin+x.path;
                    let imgId = `img${i}`;

                    let img = document.getElementById(imgId);

                    let canvas = document.createElement("canvas")
                    let ctx = canvas.getContext("2d");
                    canvas.height = img.height;
                    canvas.width = img.width;
                    ctx.drawImage(img, 0, 0);

                    let dataURL = canvas.toDataURL("image/jpg");

                    let base64Img = dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
                    zip.file(x.name, base64Img, { base64: true });
                });

                zip.generateAsync({type:"base64"})
                    .then(async function(content) {
                    // see FileSaver.js
                    // this.$filesaver.saveAs(content, `china-defective-report-images-${dateToday}.zip`);
                    // location.href="data:application/zip;base64," + content;

                    let link = document.createElement('a')
                    link.download = `${name}-${dateToday}`;
                    link.href="data:application/zip;base64," + content;
                    link.click();

                    vm.downloadOnFlight = false;
                });
            }
        },
        async newImagePath(path){
            let uri = '';
            if(this.inbound_id) {
                uri = `${process.env.VUE_APP_API_HOST}/img/operations/inbound?image_name=${this.itemName}`
            } else if (this.disposal_id) {
                uri = `${process.env.VUE_APP_API_HOST}/img/service/sc_disposal?image_name=${this.itemName}`
            } else if (this.sc_return_receipt_id) {
                uri = `${process.env.VUE_APP_API_HOST}/img/service/sc_return_receipt?image_name=${this.itemName}`
            }else {
                uri = `${process.env.VUE_APP_API_HOST}/img/service?image_name=${this.itemName}`
            }
            fetch(uri)
            .then(resp => resp.blob())
            .then(blobobject => {
                const blob = window.URL.createObjectURL(blobobject);
                this.sourceFrame = blob;
            })
        },
        getInboundFiles() {
            this.$store.dispatch('getInboundFiles',this.inbound_id).then(response=>{
                this.files = this.GET_INBOUND_UPLOAD_FILES;
            });
        },
        getSqFiles() {
            this.$store.dispatch('getSqFiles', this.sqId).then(response=>{
                this.files = this.GET_SQ_FILES;
            });
        },
        getDisposalFiles() {
            this.$store.dispatch('disposalGet', {disposal_id: this.disposal_id, url:'disposal-file'}).then(response => {
                this.files = response.data
            })
        },
        getReturnReceiptFiles() {
            this.$store.dispatch('serviceGet', {sc_return_receipt_id: this.sc_return_receipt_id, url:'sc-return-receipt-files'}).then(response => {
                this.files = response.data
            })
        }
    }
}
</script>

<style scoped>
    .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
</style>
