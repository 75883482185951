import store from '../../../store';
import api from '../../api'

export default {
    CreatePurchaseRequest(params){
      return api.post('/admin/purchaserequest',params)
   },
   UpdatePurchaseRequest(params){
       return api.put(`/admin/purchaserequest/${params.asset_id}`,params)
    },
    DeletePurchaseRequest(params){
       return api.delete(`/admin/purchaserequest/${params}`)
    },
    getAllPurchaseRequest(params){
       return api.get('/admin/getAllPurchaserequest',{params})
   },
   findPR(params){
      return api.get('/admin/findPR',{params});
    },
    findItemEmployee(params){
      return api.get(`/admin/getprfitemsemployee/${params}`);
    }
}

