import api from '../../api'
export default {
    saveFiles(params){
        return api.post(`file/upload-file`,params);
    },
    retrieveFiles(params){
        return api.get(`file/upload-file`,{params});
    },
    destroyFiles(params){
        return api.delete(`file/upload-file`, {params});
    },
    downloadFile(params){
        return api.get(`file/download-file`,{params,responseType:'blob'});
    }
}
