import repository from '@/api/modules/MasterData/vatType';

export default {
    state: {
        reload_data_table: false,
        vat_type_view_id: undefined,
        vat_types: [],
	},
	getters: {
        GET_RELOAD_DATA_TABLE: state => state.reload_data_table,
        GET_VAT_TYPE_VIEW_ID: state => state.vat_type_view_id,
        GET_VAT_TYPES: state => state.vat_types,
	},
	actions:{
        createVatType: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                repository.vatTypePost(payload).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        viewVatType: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                repository.vatTypeGet(payload).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        updateVatType: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                repository.vatTypePut(payload).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        deleteVatType: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                repository.vatTypeDelete(payload).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getVatTypes: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                commit('VAT_TYPES', []);
                repository.vatTypeGet(payload).then(response => {
                    commit('VAT_TYPES', response.data.data.data);
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
	},
	mutations:{
        RELOAD_DATA_TABLE: (state, data) => { state.reload_data_table = data },
        VAT_TYPE_VIEW_ID: (state, data) => { state.vat_type_view_id = data },
        VAT_TYPES: (state, data) => { state.vat_types = data },
	},
}
