import repository from '@/api/modules/Admin/adminStockAdjustment'
import store from '@/store';
export default {
	state: {
		REFRESH: false,
		PRINT: false,
		EXPORT: false,
		DATA: [],
       
	},
	getters: {
        ADMIN_STOCK_ADJUSTMENT:(state)=>state,
	},
	actions:{
        adminStockAdjustmentGet({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.adminStockAdjustmentGet(payload).then((response)=>{
					commit('ADMIN_STOCK_ADJUSTMENT_REFRESH', false)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        adminStockAdjustmentPost({ commit }, payload){
			return new Promise((resolve,reject)=>{
				repository.adminStockAdjustmentPost(payload).then((response)=>{
					commit('ADMIN_STOCK_ADJUSTMENT_REFRESH', true)
                    resolve(response.data)
				}).catch((error)=>{
				    reject(error)
				});
			})
        },
	},

	mutations:{
        ADMIN_STOCK_ADJUSTMENT_REFRESH:(state,data) =>{
			state.REFRESH = data
		},
		ADMIN_STOCK_ADJUSTMENT_DATA:(state,data) =>{
			state.DATA = data
		},

	},
}
