import repository from '@/api/modules/Admin/purchaseRequest'
import store from '@/store';
export default {
	state: {
        edit_purchase_request_details:{},
        new_purchase_request_details:false,
		print_purchase_request:false,
		print_purchase_request_detail:{},
		purchase_request_dispatch:{},
		purchase_request_status:{},
		purchase_request_refresh:1,
		purchase_request_number:'',
		purchase_request_cart:[],
		purchase_request_sub_dialog:false,
		admin_items_list_section:false,
		admin_other_item_dialog:false,
		admin_other_item:[],
		admin_edit_other_item:{},
		admin_change_prf_item:{},
		admin_edit_other_item_dialog:false,
		admin_edit_other_current_items:{},
		admin_add_new_items:false,
		admin_export: false
	},
	getters: {
        GET_EDIT_PURCHASE_REQUEST_TABLE:(state)=>state.edit_purchase_request_details,
        GET_NEW_PURCHASE_REQUEST_TABLE:(state)=>state.new_purchase_request_details,
		GET_PRINT_PURCHASE_REQUEST:(state)=>state.print_purchase_request,
		GET_PRINT_PURCHASE_REQUEST_DETAIL:(state)=>state.print_purchase_request_detail,
		GET_PURCHASE_REQUEST_DISPATCH:(state)=>state.purchase_request_dispatch,
		GET_PURCHASE_REQUEST_DISPATCH_STATUS:(state)=>state.purchase_request_status,
		GET_PURCHASE_REQUEST_DISPATCH_REFRESH:(state)=>state.purchase_request_refresh,
		GET_PURCHASE_REQUEST_NUMBER:(state)=>state.purchase_request_number,
		GET_ADMIN_PR_CART:(state)=>state.purchase_request_cart,
		GET_ADMIN_PR_SUB_DIALOG:(state)=>state.purchase_request_sub_dialog,
		GET_ADMIN_ITEMS_LIST_SECTION:(state)=>state.admin_items_list_section,
		GET_ADMIN_OTHER_ITEM_DIALOG:(state)=>state.admin_other_item_dialog,
		GET_ADMIN_OTHER_ITEMS:(state)=>state.admin_other_item,
		GET_ADMIN_EDIT_OTHER_ITEMS:(state)=>state.admin_edit_other_item,
		GET_ADMIN_CHANGE_PRF_ITEM:(state)=>state.admin_change_prf_item,
		GET_ADMIN_EDIT_OTHER_ITEM_DIALOG:(state)=>state.admin_edit_other_item_dialog,
		GET_ADMIN_EDIT_OTHER_CURRENT_ITEMS:(state)=>state.admin_edit_other_current_items,
		GET_ADMIN_ADD_NEW_ITEMS:(state)=>state.admin_add_new_items,
		GET_ADMIN_EXPORT:(state)=>state.admin_export
	},
	actions:{
        CreatePurchaseRequest({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.CreatePurchaseRequest(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		UpdatePurchaseRequest({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.UpdatePurchaseRequest(payload).then((response)=>{
					commit('NEW_PURCHASE_REQUEST_TABLE',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		DeletePurchaseRequest({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.DeletePurchaseRequest(payload).then((response)=>{
					commit('NEW_PURCHASE_REQUEST_TABLE',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
        getAllPurchaseRequest({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllPurchaseRequest(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		findPR({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.findPR(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
		findItemEmployee({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.findItemEmployee(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
        },
	},
	
	mutations:{
        EDIT_PURCHASE_REQUEST_TABLE:(state,data)=>{
            state.edit_purchase_request_details = data;
        },
		PURCHASE_REQUEST_DISPATCH:(state,data)=>{
			state.purchase_request_dispatch = data
		},
        NEW_PURCHASE_REQUEST_TABLE:(state,data)=>{
            (state.new_purchase_request_details = data);
        },
		PRINT_PURCHASE_REQUEST_DETAIL:(state,data)=>{
			state.print_purchase_request_detail = data
		},
		PRINT_PURCHASE_REQUEST:(state,data)=>{
			state.print_purchase_request = data;
		},
		PURCHASE_REQUEST_DISPATCH_STATUS:(state,data)=>{
			state.purchase_request_status = data;
		},
		PURCHASE_REQUEST_DISPATCH_REFRESH:(state,data) =>{
			(state.purchase_request_refresh = data);
		},
		PURCHASE_REQUEST_NUMBER:(state,data)=>{
			state.purchase_request_number = data;
		},
		ADMIN_PR_CART:(state,data)=>{
			state.purchase_request_cart = data;
		},
		ADMIN_PR_SUB_DIALOG:(state,data)=>{
			state.purchase_request_sub_dialog = data;
		},
		ADMIN_ITEMS_LIST_SECTION:(state,data)=>{
			state.admin_items_list_section = data
		},
		ADMIN_OTHER_ITEM_DIALOG:(state,data)=>{
			state.admin_other_item_dialog = data
		},
		ADMIN_OTHER_ITEMS:(state,data)=>{
			if(Object.keys(data).length > 0){
				state.admin_other_item.push(data)
			}else{
				state.admin_other_item = []
			}
			
		},
		ADMIN_EDIT_OTHER_ITEMS:(state,data)=>{
			state.admin_edit_other_item = data
		},
		ADMIN_CHANGE_PRF_ITEM:(state,data)=>{
			state.admin_change_prf_item = data
		},
		ADMIN_EDIT_OTHER_ITEM_DIALOG:(state,data)=>{
			state.admin_edit_other_item_dialog = data
		},
		ADMIN_EDIT_OTHER_CURRENT_ITEMS:(state,data)=>{
			state.admin_edit_other_current_items = data
		}
		,
		ADMIN_ADD_NEW_ITEMS:(state,data)=>{
			state.admin_add_new_items = data
		},
		ADMIN_EXPORT:(state,data)=>{
			state.admin_export = data
		}
	},
}
