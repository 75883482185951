import api from '../../../api'
export default{

    deliveryget(params){
      return api.get(`/logistics/`+params.url,{params})
    },
    deliverypost(params){
      return api.post(`/logistics/`+params.data.url,params)
    },
    deliveryput(params){
      return api.put(`/logistics/`+params.url,params)
    },
    deliverydelete(params){
      return api.delete(`/logistics/`+params.url,params)
    },
    getSoForScheduling(params){
      return api.get('/logistics/getSoForScheduling',{params});
    },
    getAllSoForScheduling(params){
      return api.get('/logistics/getAllSoForScheduling',{params});
    },
}
