import repository from '@/api/modules/Dashboard/Admin'

export default {
    state: {
        total_assets: 0,
        asset_count_by_dept:[],
        asset_count_by_status:[],
        total_accountability: 0,
        asset_count_by_classification:[],
        total_pending_purchase_request: 0,
        asset_count_by_supplier:[],
        total_pending_purchase_order: 0,
        items_count_by_vendor:[],
        asset_count_by_category:[]
    },
    getters: {
        GET_TOTAL_ASSETS: state => state.total_assets,
        GET_ASSET_COUNT_BY_DEPT: state => state.asset_count_by_dept,
        GET_ASSET_COUNT_BY_STATUS: state => state.asset_count_by_status,
        GET_TOTAL_ACCOUNTABILITY: state => state.total_accountability,
        GET_ASSET_COUNT_BY_CLASSIFICATION: state => state.asset_count_by_classification,
        GET_TOTAL_PENDING_PURCHASE_REQUEST: state => state.total_pending_purchase_request,
        GET_ASSET_COUNT_BY_SUPPLIER: state => state.asset_count_by_supplier,
        GET_ASSET_COUNT_BY_CATEGORY: state => state.asset_count_by_category,
        GET_TOTAL_PENDING_PURCHASE_ORDER: state => state.total_pending_purchase_order,
        GET_ITEMS_COUNT_BY_VENDOR: state => state.items_count_by_vendor,
    },
    actions: {
        getTotalAssets({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.gettotalassets(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TOTAL_ASSETS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getAssetCountByDept({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getassetcountbydept(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ASSET_COUNT_BY_DEPT', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getAssetCountByStatus({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getassetcountbystatus(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ASSET_COUNT_BY_STATUS', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getTotalAccountability({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.gettotalaccountability(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TOTAL_ACCOUNTABILITY', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getAssetCountByClassification({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getassetcountbyclassification(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ASSET_COUNT_BY_CLASSIFICATION', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getTotalPendingPurchaseRequest({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.gettotalpendingpurchaserequest(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TOTAL_PENDING_PURCHASE_REQUEST', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getAssetCountBySupplier({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getassetcountbysupplier(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ASSET_COUNT_BY_SUPPLIER', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getTotalPendingPurchaseOrder({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.gettotalpendingpurchaseorder(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('TOTAL_PENDING_PURCHASE_ORDER', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getItemsCountByVendor({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getitemscountbyvendor(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ITEMS_COUNT_BY_VENDOR', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getAssetCountByCategory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                repository.getassetcountbycategory(payload).then(response => {
                    if (response.status == 200) {
                        resolve(response)
                        commit('ASSET_COUNT_BY_CATEGORY', response.data)
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {
        TOTAL_ASSETS: (state, data) => {
            state.total_assets = data
        },
        ASSET_COUNT_BY_DEPT: (state,data) => {
            state.asset_count_by_dept = data
        },
        ASSET_COUNT_BY_STATUS: (state,data) => {
            state.asset_count_by_status = data
        },
        TOTAL_ACCOUNTABILITY: (state,data) => {
            state.total_accountability = data
        },
        ASSET_COUNT_BY_CLASSIFICATION: (state,data) => {
            state.asset_count_by_classification = data
        },
        TOTAL_PENDING_PURCHASE_REQUEST: (state,data) => {
            state.total_pending_purchase_request = data
        },
        ASSET_COUNT_BY_SUPPLIER: (state,data) => {
            state.asset_count_by_supplier = data
        },
        TOTAL_PENDING_PURCHASE_ORDER: (state,data) => {
            state.total_pending_purchase_order = data
        },
        ITEMS_COUNT_BY_VENDOR: (state,data) => {
            state.items_count_by_vendor = data
        },
        ASSET_COUNT_BY_CATEGORY: (state,data) => {
            state.asset_count_by_category = data
        },
    }
}
