import repository from '@/api/modules/HR/Plantilla/plantilla'
import store from '@/store';
export default {
	state: {
		hr_plantilla:[],
        hr_plantilla_new_data_trigger: false,
	},
	getters: {
		GET_HR_PLANTILLA:(state)=>state.hr_plantilla,
		GET_HR_PLANTILLA_NEW_DATA:(state)=>state.hr_plantilla_new_data_trigger,
	},
	actions:{
		// Plantilla Actions
		AddHrPlantilla({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.addPlantilla(payload).then((response)=>{
					commit('HR_PLANTILLA_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		UpdateHrPlantilla({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.updatePlantilla(payload).then((response)=>{
					commit('HR_PLANTILLA_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		DeleteHrPlantilla({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.deletePlantilla(payload).then((response)=>{
					commit('HR_PLANTILLA_NEW_DATA',true)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		GetHrPlantilla({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getPlantilla(payload).then((response)=>{
					commit('HR_PLANTILLA',response.data)
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		ShowHrPlantilla({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.showPlantilla(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getPositionPlantilla({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getPositionPlantilla(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getAllExistingPlantillaDept({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getAllExistingPlantillaDept(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
		getCurrentManpower({commit},payload){
            return new Promise((resolve,reject)=>{
				repository.getCurrentManpower(payload).then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
					reject(error)
				});
			})
        },
	},
	mutations:{
		HR_PLANTILLA:(state,data)=>{state.hr_plantilla = data},
		HR_PLANTILLA_NEW_DATA:(state,data)=>{state.hr_plantilla_new_data_trigger = data},
	},
}
