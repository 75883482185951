import repository from '@/api/modules/HR/employeeData/EmployeeDataOtherTabs'
import store from '@/store';
export default {
	state: {
		dialog_title:'',
        hr_dispatch:{},
        hr_getters:null,
		hr_new_data: false,
		hr_other_tabs_dialog_view: [],
		hr_tab_is_active:'',
		hr_job_titles_table : [],
		hr_employee_religion_table : [],
		hr_employee_marital_status_table : [],
		hr_employee_citizenship_table : [],
		hr_employee_contract_type_table: [],
		hr_job_level_table:[],
		hr_employee_skills_table:[],
		hr_employment_history_table:[],
		hr_employee_movement_table:[],
		hr_movement_employee_selection:[],
		hr_movement_change_view: false,
		hr_current_active_movement:{},
        hr_store_branches:[],
        holidays: [],
        new_holiday_trigger: false,
        year_range: [],
	},
	getters: {
		GET_HR_DIALOG_TITLE:(state) => state.dialog_title,
        GET_HR_DISPATCH:(state)=>state.hr_dispatch,
        GET_HR_GETTERS:(state)=>state.hr_getters,
		GET_HR_NEW_DATA_TRIGGER:(state)=>state.hr_new_data,
		GET_HR_OTHER_TABS_DIALOG_VIEW:(state) => state.hr_other_tabs_dialog_view,
		GET_HR_TAB_IS_ACTIVE:(state) => state.hr_tab_is_active,
		GET_HR_JOB_TITLES_TABLE:(state) => state.hr_job_titles_table,
		GET_HR_EMPLOYEE_RELIGION_TABLE:(state) => state.hr_employee_religion_table,
		GET_HR_EMPLOYEE_MARITAL_STATUS_TABLE:(state) => state.hr_employee_marital_status_table,
		GET_HR_EMPLOYEE_CITIZENSHIP_TABLE:(state) => state.hr_employee_citizenship_table,
		GET_HR_EMPLOYEE_CONTRACT_TYPE_TABLE:(state) => state.hr_employee_contract_type_table,
		GET_JOB_LEVEL_TABLE:(state) => state.hr_job_level_table,
		GET_EMPLOYEE_SKILLS_TABLE:(state) => state.hr_employee_skills_table,
		GET_EMPLOYMENT_HISTORY_TABLE:(state) => state.hr_employment_history_table,
		GET_EMPLOYEE_MOVEMENT_TABLE:(state) => state.hr_employee_movement_table,
		GET_EMPLOYEE_MOVEMENT_TABLE_SELECTION:(state) => state.hr_movement_employee_selection,
		GET_HR_MOVEMENT_CHANGE_VIEW:(state) => state.hr_movement_change_view,
		GET_HR_CURRENT_ACTIVE_MOVEMENT:(state) => state.hr_current_active_movement,
		GET_HR_STORE_BRANCHES:(state) => state.hr_store_branches,
		GET_HOLIDAYS:(state) => state.holidays,
		GET_NEW_HOLIDAY_TRIGGER:(state) => state.new_holiday_trigger,
		GET_YEAR_RANGE:(state) => state.year_range,
	},
	actions:{
		// Status Actions
		getEmployeeDataStatus({commit}){
			return new Promise((resolve,reject)=>{
				repository.getemployeedatastatus().then((response)=>{
					if(response.status == 200){
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeDataStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addemployeedatastatus(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeDataStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateemployeedatastatus(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeDataStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteemployeedatastatus(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		// Job Title Actions
		getEmployeeJobTitles({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getemployeejobtitle(payload).then((response)=>{
                    commit('HR_JOB_TITLES_TABLE',response.data)
                    resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeJobTitles({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addemployeejobtitle(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeJobTitles({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateemployeejobtitle(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeJobTitles({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteemployeejobtitle(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		ShowEmployeeJobTitles({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.showemployeejobtitle(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		// Employee Religion Actions
		getEmployeeReligion({commit}){
			return new Promise((resolve,reject)=>{
				repository.getemployeereligion().then((response)=>{
					if(response.status == 200){
						commit('HR_EMPLOYEE_RELIGION_TABLE',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeReligion({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addemployeereligion(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeReligion({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateemployeereligion(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeReligion({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteemployeereligion(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		// Employee Marital S. Actions
		getEmployeeMaritalStatus({commit}){
			return new Promise((resolve,reject)=>{
				repository.getemployeemaritalstatus().then((response)=>{
					if(response.status == 200){
						commit('HR_EMPLOYEE_MARITAL_STATUS_TABLE',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeMaritalStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addemployeemaritalstatus(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeMaritalStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateemployeemaritalstatus(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeMaritalStatus({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteemployeemaritalstatus(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		// Citizenship Actions
		getEmployeeCitizenship({commit}){
			return new Promise((resolve,reject)=>{
				repository.getemployeecitizenship().then((response)=>{
					if(response.status == 200){
						commit('HR_EMPLOYEE_CITIZENSHIP_TABLE',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeCitizenship({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addemployeecitizenship(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeCitizenship({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateemployeecitizenship(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeCitizenship({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteemployeecitizenship(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		// Contract Type Actions
		getEmployeeContractType({commit}){
			return new Promise((resolve,reject)=>{
				repository.getemployeecontractType().then((response)=>{
					if(response.status == 200){
						commit('HR_EMPLOYEE_CONTRACT_TYPE_TABLE',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeContractType({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addemployeecontractType(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeContractType({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateemployeecontractType(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeContractType({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteemployeecontractType(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		// Job Level Actions
		getJobLevel({commit}){
			return new Promise((resolve,reject)=>{
				repository.getjoblevel().then((response)=>{
					if(response.status == 200){
						commit('HR_JOB_LEVEL_TABLE',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addJobLevel({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addjoblevel(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateJobLevel({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updatejoblevel(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteJobLevel({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deletejoblevel(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		// Employee Skills Actions
		getEmployeeSkills({commit}){
			return new Promise((resolve,reject)=>{
				repository.getemployeeskills().then((response)=>{
					if(response.status == 200){
						commit('HR_EMPLOYEE_SKILLS_TABLE',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeSkills({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addemployeeskills(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeSkills({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateemployeeskills(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeSkills({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteemployeeskills(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},

		// Employment History Actions
		getEmploymentHistory({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getEmploymentHistory(payload).then((response)=>{
					if(response.status == 200){
						commit('HR_EMPLOYMENT_HISTORY_TABLE',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},

		// Employee Movement Actions
		getEmployeeMovement({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getEmployeeMovement(payload).then((response)=>{
					if(response.status == 200){
						commit('HR_EMPLOYEE_MOVEMENT_TABLE',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeMovement({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addEmployeeMovement(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeMovement({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateEmployeeMovement(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		approveEmployeeMovement({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.approveEmployeeMovement(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getMovementTableSelection({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getMovementTableSelection(payload).then((response)=>{
					commit('HR_EMPLOYEE_MOVEMENT_TABLE_SELECTION',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeMovement({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteEmployeeMovement(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getCurrentActiveMovement({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getCurrentActiveMovement(payload).then((response)=>{
					if(response.status == 200){
						commit('HR_CURRENT_ACTIVE_MOVEMENTS',response.data[0])
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
        // Employee Store Branch Actions
		getEmployeeStoreBranch({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getstorebranch(payload).then((response)=>{
					if(response.status == 200){
						commit('HR_STORE_BRANCHES',response.data)
						resolve(response.data)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addEmployeeStoreBranch({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addstorebranch(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateEmployeeStoreBranch({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updatestorebranch(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteEmployeeStoreBranch({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deletestorebranch(payload).then((response)=>{
					commit('HR_NEW_DATA_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
        // Holiday Actions
		getHolidaysTable({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getholidays(payload).then((response)=>{
                    commit('HOLIDAYS',response.data.data.data)
                    resolve(response.data.data)
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		addHolidays({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.addholidays(payload).then((response)=>{
					commit('NEW_HOLIDAY_TRIGGER',true)
						resolve(response)
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		updateHolidays({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.updateholidays(payload).then((response)=>{
					commit('NEW_HOLIDAY_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		deleteHolidays({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.deleteholidays(payload).then((response)=>{
					commit('NEW_HOLIDAY_TRIGGER',true)
					if(response.status == 200){

						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		showHolidays({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.showholidays(payload).then((response)=>{
                    resolve(response.data)
				}).catch((error)=>{
				reject(error)
				});
			})
		},
	},

	mutations:{
		HR_DIALOG_TITLE:(state,data)=>{state.dialog_title = data},
        HR_DISPATCH:(state,data)=>{state.hr_dispatch = data},
        HR_GETTERS:(state,data)=>{state.hr_getters = data},
		HR_NEW_DATA_TRIGGER:(state,data) => {state.hr_new_data = data},
		HR_OTHER_TABS_DIALOG_VIEW:(state,data) => {state.hr_other_tabs_dialog_view = data},
		HR_TAB_IS_ACTIVE:(state,data) =>{state.hr_tab_is_active =data},
		HR_JOB_TITLES_TABLE:(state,data) => {state.hr_job_titles_table = data},
		HR_EMPLOYEE_RELIGION_TABLE:(state,data) => {state.hr_employee_religion_table = data},
		HR_EMPLOYEE_MARITAL_STATUS_TABLE:(state,data) => {state.hr_employee_marital_status_table = data},
		HR_EMPLOYEE_CITIZENSHIP_TABLE:(state,data) => {state.hr_employee_citizenship_table = data},
		HR_EMPLOYEE_CONTRACT_TYPE_TABLE:(state,data) => {state.hr_employee_contract_type_table = data},
		HR_JOB_LEVEL_TABLE:(state,data) => {state.hr_job_level_table = data},
		HR_EMPLOYEE_SKILLS_TABLE:(state,data) => {state.hr_employee_skills_table = data},
		HR_EMPLOYMENT_HISTORY_TABLE:(state,data) => {state.hr_employment_history_table = data},
		HR_EMPLOYEE_MOVEMENT_TABLE:(state,data) => {state.hr_employee_movement_table = data},
		HR_MOVEMENT_CHANGE_VIEW:(state,data) => {state.hr_movement_change_view = data},
		HR_CURRENT_ACTIVE_MOVEMENTS:(state,data) => {state.hr_current_active_movement = data},
		HR_STORE_BRANCHES:(state,data) => {state.hr_store_branches = data},
		HOLIDAYS:(state,data) => {state.holidays = data},
		NEW_HOLIDAY_TRIGGER:(state,data) => {state.new_holiday_trigger = data},

		// For Selection of movement if employee will be rehired
		HR_EMPLOYEE_MOVEMENT_TABLE_SELECTION:(state,data) => {state.hr_movement_employee_selection = data},
		YEAR_RANGE:(state,data) => {state.year_range = data},
	},
}
