import store from '../../../store';
import api from '../../api'

export default {
    async purchaseOrderItemsTemplateGet(params){
        return api.get(`admin/${params.url}`,{params})
    },
    purchaseOrderItemsTemplatePost(params){
        return api.post(`admin/${params.url}`,params)
    },
    getPurchaseOrderItemsTemplateSelection(params) {
        return api.get(`dropdown/${params.url}`,{params})
    },
}

