
import repository from "@/api/credentials";

const routes = [{
    path: "/system-settings/navigation-drawer/:id",
    name: "navigationdrawer",
    component: () => import('@/views/main/SystemSettings/NavigationDrawer.vue'),
    beforeEnter:(to,from,next)=>{
        repository.checkAccessUser(to.params.id).then((response)=>{
            if(response.data == 'fail'){
                next('/no-access')
                }else{
                next()
            }
        }).catch(()=>{

        })
    },
    meta:{
        requiresAuth:true,
        title:'Navigation Settings'
    }
},
{
    path: "/system-settings/general-settings/:id",
    name: "generalsettings",
    component: () => import('@/views/main/SystemSettings/GeneralSettingsComponent.vue'),
    beforeEnter:(to,from,next)=>{
        repository.checkAccessUser(to.params.id).then((response)=>{
            if(response.data == 'fail'){
                next('/no-access')
                }else{
                next()
            }
        }).catch(()=>{

        })
    },
    meta:{
        requiresAuth:true,
        title:'General Settings'
    }
},
{
    path: "/system-settings/email-settings/:id",
    name: "emailsettings",
    component: () => import('@/views/main/SystemSettings/Email/EmailSettingsMainGroupComponent.vue'),
    beforeEnter:(to,from,next)=>{
        repository.checkAccessUser(to.params.id).then((response)=>{
            if(response.data == 'fail'){
                next('/no-access')
                }else{
                next()
            }
        }).catch(()=>{

        })
    },
    meta:{
        requiresAuth:true,
        title:'Email Settings'
    }
    }]

export default routes;
