<template>
    <v-container fluid class="pa-3">
            <div class="row p-2">
                <div class="col-8 d-flex no-block align-items-center">
                    <h4 class="page-title">Efficient Technicians</h4>
                </div>
            </div>
        <v-container class="bg-bluish-gray default-container ma-0">
            <v-row no-gutters class="pb-1">
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.item_type_ids"
                        :items="dropdowns.item_types.list"
                        :loading="loading.item_types"
                        :disabled="loading.item_types"
                        class="d-inline-block w-100 small"
                        label="Discount Type"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="3">
                    <v-autocomplete
                        v-model="filters.item_category_ids"
                        :items="dropdowns.item_categories.list"
                        :loading="loading.item_categories"
                        :disabled="loading.item_categories"
                        class="d-inline-block w-100 small"
                        label="Category"
                        background-color="white"
                        clearable
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.date_option"
                        :items="dropdowns.date_option.list"
                        class="d-inline-block w-100 small"
                        label="Select Date Range"
                        background-color="white"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                        @input="showDatePicker"
                    ></v-autocomplete>
                </v-col>
                <v-col sm="12" md="2">
                    <v-autocomplete
                        v-model="filters.export_type"
                        :items="dropdowns.export_type.list"
                        class="d-inline-block w-100 small"
                        label="Export Type"
                        background-color="white"
                        outlined
                        dense
                        auto-select-first
                        hide-details
                    ></v-autocomplete>
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="12" md="3" class="text-right">
                    <v-btn class="mr-1" @click="resetFields()">Clear</v-btn>
                    <v-btn @click="getNewItems(1)">Export</v-btn>
                    <v-btn class="ml-1" @click="clearUrlParams(); getNewItems()">Filter</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col sm="12" md="2" v-if="toggles.custom_date">
                    <v-text-field
                        v-model="filters.allowable"
                        class="mt-1"
                        label="Allowable until"
                        background-color="white"
                        dense
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col sm="12" md="2" v-if="toggles.custom_date">
                    <v-menu
                        v-model="toggles.date_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="date_to"
                                v-on="on"
                                label="Date"
                                append-icon="mdi-calendar-clock"
                                readonly
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="date_to" @input="toggles.date_to = false"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row dense no-gutters>
                <v-col cols="12">
                    <v-card>
                        <v-container fluid>
                            <v-data-table
                                :headers="datatable.headers"
                                :items="datatable.items.list"
                                :options.sync="datatable.options"
                                :server-items-length="datatable.items.count"
                                :loading="toggles.loaded"
                                class="w-100"
                                dense
                                disable-sort
                                @update:page="getNewItems()"
                                @update:items-per-page="getNewItems()"
                            >
                                <template v-slot:item.image_path="{ item }">
                                    <v-img class="img-thumbnail" width="50" height="50" :src="checkImagePath(item.image_path)" eager contain></v-img>
                                </template>
                                <template v-slot:item.selling_price="{ item }">
                                    <span class="text-nowrap">
                                        {{ item.selling_price | currency }}
                                    </span>
                                </template>
                                <template v-slot:item.received_date="{ item }">
                                    <span class="text-nowrap">
                                        {{ new Date(item.received_date).toLocaleDateString() }}
                                    </span>
                                </template>
                                <template v-slot:item.total_stocks="{ item }">
                                    <span class="text-nowrap">
                                        {{ thousandSeprator(item.total_stocks) }}
                                    </span>
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn text icon color="orange">
                                        <v-icon class="btn-action" small @click="viewItem(item.id)">mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-container>
                    </v-card>
                    <ItemsViewComponent
                        :viewItemId="datatable.items.selected"
                        @closeView="closeView"
                    ></ItemsViewComponent>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import SharedFunctionsComponent from '@/views/main/Shared/SharedFunctionsComponent'
import ComponentHeader from '@/views/main/layouts/Reports/ReportsHeaderComponent.vue'
import ItemsViewComponent from "@/views/main/layouts/dialogs/Sales/Items/ItemsViewComponent.vue"
import { mapGetters } from 'vuex'

export default {
    mixins: [
        SharedFunctionsComponent
    ],
    components: {
        ComponentHeader,
        ItemsViewComponent,
    },
    data: () => ({
        urlParams: {
            date_option: null,
            date_from: null,
            date_to: null,
            type_ids: [],
            category_ids: [],
        },
        defaults: {
            date_option: 3,
            date_from: this.$dayjs().startOf('month').format('YYYY-MM-DD'),
            date_to: this.$dayjs().endOf('month').format('YYYY-MM-DD'),
            item_type_ids: [],
            item_category_ids: [],
            allowable: 0,
            export_type: 1,
            for_export: 0,
        },
        toggles: {
            loaded: false,
            date_to: false,
            custom_date: false,
        },
        loading: {
            item_types: true,
            item_categories: true,
        },
        filters: {},
        dropdowns: {
            date_option: {
                list: [
                    { value: 1, text: 'Past Week' },
                    { value: 2, text: 'Past Month' },
                    { value: 3, text: 'Past 2 Months' },
                    { value: 4, text: 'Past Quarter'},
                    // { value: 5, text: 'Custom' },
                ],
                allowable: 0,
            },
            export_type: {
                list: [
                    { value: 1, text: 'Pricelist' },
                    { value: 2, text: 'Raw Data' },
                ],
            },
            item_types: {
                list: [],
            },
            item_categories: {
                list: [],
            },
        },
        datatable: {
            items: {
                list: [],
                count: 0,
                selected: 0,
            },
            headers: [
                { text: 'IMAGE', align: 'start', value: 'image_path', },
                { text: 'MODEL', align: 'start', value: 'model', },
                { text: 'NAME', align: 'start', value: 'name', },
                { text: 'TYPE', align: 'start', value: 'type' },
                { text: 'UOM', align: 'start', value: 'uom' },
                { text: 'CATEGORY', align: 'start', value: 'category', },
                { text: 'SRP', align: 'end', value: 'selling_price', },
                { text: 'MOQ', align: 'end', value: 'moq', },
                { text: 'MB', align: 'end', value: 'master_box_qty', },
                { text: 'SB', align: 'end', value: 'small_box_qty', },
                { text: 'CONTAINER', align: 'end', value: 'containers', },
                { text: 'CONTAINER RECEIVED', align: 'end', value: 'received_date', },
                { text: 'STOCKS', align: 'end', value: 'total_stocks', },
                { text: 'ACTION', align: 'center', value: 'action' },
            ],
            options: {},
        },
    }),
    async mounted(){
        this.resetFields()
        await this.storeUrlParams()
        await this.loadDropdowns()
        await this.setDefaultValues()
    },
    computed: {
        ...mapGetters([
            'GET_ITEM_TYPES',
            'GET_ITEM_CATEGORIES',
        ])
    },
    methods: {
        viewItem(item_id) {
            this.datatable.items.selected = item_id
        },
        closeView() {
            this.datatable.items.selected = 0
        },
        async storeUrlParams() {
            const params = new URLSearchParams(window.location.search)

            Object.assign(this.urlParams, {
                date_option: params.get('ddSelection') == null ? this.defaults.date_option : params.get('ddSelection'),
                date_from: params.get('date_from') == null ? this.defaults.date_from : params.get('date_from'),
                date_to: params.get('date_to') == null ? this.defaults.date_to : params.get('date_to'),
                type_ids: params.get('type_id') == null ? this.defaults.item_type_ids : JSON.parse(params.get('type_id')),
                category_ids: params.get('category_id') == null ? this.defaults.item_category_ids : JSON.parse(params.get('category_id')),
            })

            Object.assign(this.filters, {
                date_option: this.urlParams.date_option,
                item_type_ids: this.urlParams.type_ids,
                item_category_ids: this.urlParams.category_ids,
            })
        },
        async loadDropdowns() {
            await this.$store.dispatch('getItemTypes')
            await this.$store.dispatch('getItemCategories')
        },
        async setDefaultValues() {
            Object.assign(this.filters, {
                date_from: this.defaults.date_from,
                date_to: this.defaults.date_to,
                item_type_ids:
                    this.dropdowns.item_types.list.filter(f => {
                        return [1, 2].includes(f.value)
                    }).map(m => parseInt(m.value))
            })
        },
        showDatePicker() {
            this.filters.date_option == 5 ? this.toggles.custom_date = true : this.toggles.custom_date = false
        },
        clearUrlParams(){
            // window.history.pushState({}, document.title, "/showNewItems")
            Object.assign(this.urlParams, {
                type_ids: [],
                category_ids: [],
            })
        },
        async getNewItems(for_export = 0) {
            this.toggles.loaded = true
            this.filters.for_export = for_export
            this.datatable.items.list = []
            this.filters.date_to = this.$dayjs().format('YYYY-MM-DD')

            switch (this.filters.date_option) {
                case 1:
                    this.filters.date_from = this.$dayjs().subtract(7,'d').format('YYYY-MM-DD')
                    break
                case 2:
                    this.filters.date_from = this.$dayjs().subtract(1,'month').format('YYYY-MM-DD')
                    break
                case 3:
                    this.filters.date_from = this.$dayjs().subtract(2,'month').format('YYYY-MM-DD')
                    break
                case 4:
                    this.filters.date_from = this.$dayjs().subtract(3,'month').format('YYYY-MM-DD')
                    break
                case 5:
                    if (this.urlParams.date_from != '') {
                        this.filters.date_option = 5
                        this.filters.date_from = this.urlParams.date_from
                        this.filters.date_to = this.urlParams.date_to
                        this.toggles.custom_date = true
                    }
                    break
            }

            let payload = {
                pagination: this.datatable.options,
                filters: this.filters,
                url: 'getNewItems',
            }

            await this.$store.dispatch('reports', payload).then(async response => {
                if (for_export == 0) {
                    let data = response.data.data
                    this.datatable.items.list = data.data
                    this.datatable.items.count = data.total
                } else {
                    let data = response.data
                    const workbook = new this.$exceljs.Workbook()
                    const rawDataSheetName = `New Items ${this.$dayjs().format('YYYY-MM-DD')}`
                    let worksheet = workbook.addWorksheet(rawDataSheetName)

                    const columns = [
                        { header: 'IMAGE', key: '', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: false },
                        { header: 'MODEL', key: 'model', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                        { header: 'NAME', key: 'name', width: 30, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                        { header: 'DESCRIPTION', key: 'description', width: 57, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                        { header: 'UOM', key: 'uom', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: true, raw_data: true },
                        { header: 'TYPE', key: 'type', style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                        { header: 'CATEGORY', key: 'category', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                        { header: 'SRP', key: 'selling_price', width: 18, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0.00' }, pricelist: true, raw_data: true },
                        { header: 'ITEM CREATION DATE', key: 'created_at', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: 'mm/dd/yyyy' }, pricelist: false, raw_data: true },
                        { header: 'CONTAINER#', key: 'containers', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true } }, pricelist: false, raw_data: true },
                        { header: 'CONTAINER RECEIVE DATE', key: 'received_date', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: 'mm/dd/yyyy' }, pricelist: false, raw_data: true },
                        { header: 'TOTAL STOCKS', key: 'total_stocks', width: 13, style: { alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }, numFmt: '#,##0' }, pricelist: false, raw_data: true },
                    ]

                    for (const category of data) {
                        if (this.filters.export_type == 1) {
                            worksheet = workbook.addWorksheet(category.name)
                        } else {
                            workbook.getWorksheet(rawDataSheetName)
                        }

                        worksheet.columns = columns.filter(f => {
                            return this.filters.export_type == 1 ? f.pricelist == true : f.raw_data == true
                        })

                        let pos = 1

                        for await (const item of category.items) {
                            item.model = this.filters.export_type == 1 ? `(${item.model})\n${item.name}` : item.model
                            item.selling_price = parseFloat(item.selling_price)
                            item.total_stocks = parseInt(item.total_stocks)
                            item.created_at = this.formatDate(item.created_at)
                            item.received_date = this.formatDate(item.received_date)
                            worksheet.addRow(item)

                            if (this.filters.export_type == 1) {
                                let finalImage = workbook.addImage({
                                    base64: item.base64img,
                                    extension: 'jpg',
                                })

                                worksheet.addImage(finalImage, {
                                    tl: { col: 0.99, row: pos + 0.9 },
                                    ext: { width: 150, height: 150 },
                                    editAs: 'undefined'
                                })

                                worksheet.getRow(pos).height = 250
                            }

                            pos++
                        }

                        worksheet.getRow(1).font = { bold: true };
                        worksheet.getRow(1).height = 30;
                        let borderStyles = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" }
                        };

                        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
                            row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                                cell.border = borderStyles;
                            });
                        });
                    }

                    if (this.filters.export_type == 1) {
                        workbook.removeWorksheet(rawDataSheetName)
                        workbook.eachSheet(ws => {
                            if (ws.rowCount == 1) {
                                workbook.removeWorksheet(ws.name)
                            }
                        })
                    }

                    workbook.xlsx.writeBuffer().then((data) => {
                        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=utf-8' })
                        const filename = `New Items Report ${this.filters.export_type == 1 ? 'Pricelist' : 'Raw Data'} as of ${this.filters.date_to}.xlsx`
                        this.$filesaver.saveAs(blob, filename)
                    })
                }

                this.toggles.loaded = false
            })
        },
        resetFields(){
            this.filters = JSON.parse(JSON.stringify(this.defaults))
        },
    },
    watch: {
        'GET_ITEM_TYPES': {
            handler(val) {
                this.dropdowns.item_types.list = val
                this.loading.item_types = false
            }
        },
        'GET_ITEM_CATEGORIES': {
            handler(val) {
                this.dropdowns.item_categories.list = val
                this.loading.item_categories = false
            }
        },
    }
}
</script>
<style>
    .card-body{
        padding: 3px !important
    }
</style>
