import repository from '@/api/modules/TargetSales'
import store from '@/store';
export default {
	state: {
		
	},
	getters: {
		
	},
	actions:{
		getSalesTarget({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getCustomerSalesTarget(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},

		getNewItems({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getNewItems(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},

		getCustomerClassificationSalesTarget({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getCustomerClassificationSalesTarget(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getConceptStoreSalesTarget({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.getConceptStoreSalesTarget(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		list({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.list(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
        
		
	},
	mutations:{
	
	},
}
