import repository from '@/api/modules/Admin/admininventory'

export default {
    state: {
        adm_inventory_dispatch: {},
        code: '',
    },
    getters: {
        GET_ADM_INVENTORY_DISPATCH: (state) => state.adm_inventory_dispatch,
        GET_ADM_INVENTORY_CODE: (state) => state.code,
    },
    actions: {
        getAllAdminInventory({ commit },payload) {
                return new Promise((resolve, reject) => {
                        repository.getAllAdminInventory(payload).then((response) => {
                                resolve(response.data);
                        }).catch((error) => {
                                console.log(error);
                                reject(error);
                        })
                })
        },
    },
    mutations: {
        ADM_INVENTORY_DISPATCH: (state, data) => {
                state.adm_inventory_dispatch = data;
        },
        ADM_INVENTORY_CODE: (state, data) => state.code = data
    }
}