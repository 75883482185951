import repository from '@/api/modules/Admin/adminTransmittal';

export default {
    state: {
        reload_admin_transmittal_table: false,
        selected_admin_transmittal: undefined,
        transmittal_purchase_order_dropdown: [],
        transmittal_job_order_dropdown: [],
        transmittal_enter_bill_dropdown: [],
        transmittal_transaction_types: [
            {
                title: 'Purchase Order',
                model_name: 'AdminTransmittalPurchaseOrder',
                item: 'purchase_order_items',
                transaction_id: 'admin_purchase_order_id',
                dropdown_url: 'transmittal-purchase-order-dropdown',
                dropdown_loading: false,
            },
            {
                title: 'Job Order',
                model_name: 'AdminTransmittalJobOrder',
                item: 'job_order_items',
                transaction_id: 'admin_job_order_id',
                dropdown_url: 'transmittal-job-order-dropdown',
                dropdown_loading: false,
            },
            {
                title: 'Enter Bill',
                model_name: 'AdminTransmittalEnterBill',
                item: 'enter_bill_items',
                transaction_id: 'admin_enter_bill_id',
                dropdown_url: 'transmittal-enter-bill-dropdown',
                dropdown_loading: false,
            },
        ],
	},
	getters: {
        GET_RELOAD_ADMIN_TRANSMITTAL_TABLE: state => state.reload_admin_transmittal_table,
        GET_SELECTED_ADMIN_TRANSMITTAL: state => state.selected_admin_transmittal,
        GET_TRANSMITTAL_TRANSACTION_TYPES: state => state.transmittal_transaction_types,
        GET_TRANSMITTAL_PURCHASE_ORDER_DROPDOWN: state => state.transmittal_purchase_order_dropdown,
        GET_TRANSMITTAL_JOB_ORDER_DROPDOWN: state => state.transmittal_job_order_dropdown,
        GET_TRANSMITTAL_ENTER_BILL_DROPDOWN: state => state.transmittal_enter_bill_dropdown,
	},
	actions:{
        adminTransmittalGet: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                repository.adminTransmittalGet(payload).then(response => {
                    if (payload?.url == 'transmittal-purchase-order-dropdown') 
                        commit('TRANSMITTAL_PURCHASE_ORDER_DROPDOWN', response.data);
                    else if (payload?.url == 'transmittal-job-order-dropdown')
                        commit('TRANSMITTAL_JOB_ORDER_DROPDOWN', response.data);
                    else if (payload?.url == 'transmittal-enter-bill-dropdown')
                        commit('TRANSMITTAL_ENTER_BILL_DROPDOWN', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        adminTransmittalPost: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                repository.adminTransmittalPost(payload).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        adminTransmittalPut: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                repository.adminTransmittalPut(payload).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        adminTransmittalDelete: ({ commit }, payload) => {
            return new Promise((resolve, reject) => {
                repository.adminTransmittalDelete(payload).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
	},
	mutations:{
        RELOAD_ADMIN_TRANSMITTAL_TABLE: (state, data) => { state.reload_admin_transmittal_table = data },
        SELECTED_ADMIN_TRANSMITTAL: (state, data) => { state.selected_admin_transmittal = data },
        TRANSMITTAL_PURCHASE_ORDER_DROPDOWN: (state, data) => { state.transmittal_purchase_order_dropdown = data },
        TRANSMITTAL_JOB_ORDER_DROPDOWN: (state, data) => { state.transmittal_job_order_dropdown = data },
        TRANSMITTAL_ENTER_BILL_DROPDOWN: (state, data) => { state.transmittal_enter_bill_dropdown = data },
	},
}
