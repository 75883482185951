import api from '../api'
export default {

    getAllData(params){
        return api.get(params.url,{params});
    },
    post(params){
        return api.post(params.url,params);
    },
    put(params){
        return api.put(`${params.url}/${params.id}`,params);
    },
    delete(params){
        return api.delete(`${params.url}/${params.id}`);
    },
    show(params){
        return api.get(`${params.url}/${params.id}`);
    },

    
}
