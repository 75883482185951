import store from '../../../store';
import api from '../../api'

export default {
    createFasPO(params){
       return api.post('/admin/faspurchaseorder',params)
    },
    updateFasPO(params){
      return api.put(`/admin/faspurchaseorder/${params.id}`,params)
   },
    deleteFasPO(params){
      return api.delete(`/admin/faspurchaseorder/${params}`)
   },
    getAllFasPurchaseOrder(params){
        return api.get('/admin/getAllFasPurchaseOrder',{params})
     },
}

