import repository from '@/api/modules/Maps/index'
import store from '@/store';
export default {
	state: {
		all_customer_maps:[],
		new_marker:false,
		selected_customer_maps:[],
		selected_marker:[],
		refresh_map:false,
		legends:[],
		marker_refresh:false,
		new_customer_maps: false,
		tab: [],
	},
	getters: {
		GET_ALL_CUSTOMER_MAPS: state => state.all_customer_maps,
		GET_NEW_MARKER: (state) => state.new_marker,
		SELECTED_CUSTOMER_MAPS: (state) => state.selected_customer_maps,
		SELECTED_MARKER: (state) => state.selected_marker,
		MAP_REFRESH: (state) => state.refresh_map,
		MARKER_REFRESH: (state) => state.marker_refresh,
		GET_NEW_CUSTOMER_MAPS: (state) => state.new_customer_maps,
		GET_TAB: (state) => state.tab,

	},
	actions:{
		postCustomerMaps({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.mapspost(payload).then((response)=>{
					commit(payload.tab == 'marker' ? 'NEW_CUSTOMER_MAPS' : 'NEW_MARKER', true);
					if(response.status == 200){	
						store.dispatch('SocketReload');
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		postCustomerMarkers({commit},payload){
			return new Promise((resolve,reject)=>{
				payload.forEach((value, key) => (payload[key] = value));
				repository.markerpost(payload).then((response)=>{
					commit(payload.tab == 'legends' ? 'NEW_MARKER' : 'NEW_CUSTOMER_MAPS', true);
					if(response.status == 200){	
						store.dispatch('SocketReload');
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		getCustomerMaps({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.mapsget(payload).then((response)=>{
					if(response.status == 200){
						commit('ALL_CUSTOMER_MAPS',response.data)
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
	},
	mutations:{
		ALL_CUSTOMER_MAPS: (state, data) => {state.all_customer_maps = data},
		NEW_MARKER: (state, data) => {state.new_marker = data},
		EDIT_CUSTOMER_MAPS: (state, data) => {state.selected_customer_maps = data},
		EDIT_MARKER: (state, data) => {state.selected_marker = data},
		REFRESH_MAP: (state, data) => {state.refresh_map = data},
		LEGENDS: (state, data) => {state.legends = data},
		REFRESH_MARKER: (state, data) => {state.marker_refresh = data},
		NEW_CUSTOMER_MAPS: (state, data) => {state.new_customer_maps = data},
		TAB: (state, data) => {state.tab = data},
	},
}
