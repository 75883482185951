import repository from '@/api/modules/Accounting/Banks'
import store from '@/store';
export default {
	state: {
		bank_types:[
			{ value: 'AR', text: 'AR' },
			{ value: 'AP', text: 'AP' }
		],
		origin:[
			{ value: 1, text: 'Local' },
			{ value: 0, text: 'International' }
		]
	},
	getters: {
		GET_BANK_TYPES_SELECTION: (state) => state.bank_types,
		GET_BANK_ORIGIN_SELECTION: (state) => state.origin,
	},
	actions:{
		banksGet({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctBanksGet(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
		banksPost({commit},payload){
			return new Promise((resolve,reject)=>{
				repository.acctBanksPost(payload).then((response)=>{
					if(response.status == 200){
						resolve(response)
					}
				}).catch((error)=>{
				reject(error)
				});
			})
		},
	},
	mutations:{
		
	},
}
