<template>
   <v-app>
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout row wrap align-center>
        <v-flex>
          <v-card
            class="mx-auto"
            max-width="380"
            elevation="20"
            bg
            fill-height
            grid-list-md
            text-xs-center
          >
          <v-toolbar
              elevation="2"
              class="ma-0 pa-0"
              prominent
              src="@/assets/ingcoph_bg/ingcoph_login.jpg"
            >
            </v-toolbar>
            <v-card-title>
                <span>Find your account</span>
            </v-card-title>
            <v-divider class="m-0"></v-divider>
            <v-card-text>
                <v-form
                    v-model="valid"
                    @submit="searchSubmit"
                    ref="form"
                    lazy-validation
                >
                    <span>Please enter your username to search for your account.</span>
                    <v-text-field
                        v-model="form.username"
                        :rules="required.usernamerules"
                        required
                        class="mt-3"
                        placeholder="Username"
                        outlined
                        dense
                    >
                    </v-text-field>
                    <span class="text-danger">{{ error }}</span>
                    <v-divider class="m-0 mt-10"></v-divider>
                    <v-card-actions class="py-5">
                        <v-spacer />
                        <div class="btn-toolbar">
                            <v-btn 
                                @click="cancel()"
                                class="mr-2"
                                elevation="2"
                                text
                            >
                                CANCEL
                            </v-btn>
                            <v-btn 
                                :loading="loading"
                                type="submit"
                                elevation="2" 
                            >
                                SEARCH
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
    data(){
        return {
            loading: false,
            valid: true,
            error: "",
            form: {
                username: null,
                user_id:null
            },
            required: {
                usernamerules: [(v) => !!v || "Username is required"],
            }
        };
    },
    methods: {
        searchSubmit(e){
            e.preventDefault();
            this.$refs.form.validate();
            this.loading = true;
            if (this.form.username == null) {
                this.loading = false;
            }
            else{
                this.$store.dispatch("forgotPassword", this.form).then((success) => {
                    if(success){
                        this.$router.push({ path: '/confirmresetpassword' }).catch((err) => {
                            this.loading = false;
                        });
                    }
                }).catch((error) => {
                    this.error = error.response.data.message;
                    this.loading = false;
                });
            }
        },
        cancel(){
            this.$router.push({ path: '/' }).catch((err) => {
                this.loading = false;
            });
        }
    },
}
</script>

<style scoped>

</style>