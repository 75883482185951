import repository from '@/api/credentials';

const routes = [{
    path: '/accounting/pay-bills/:id',
    name: 'acctpaybills',
    component: () => import('@/views/main/modules/Accounting/PayBills/PayBillsGroupComponent.vue'),
    beforeEnter: (to, from, next) => {
        repository.checkAccessUser(to.params.id).then((response) => {
            if (response.data == 'fail') {
                next('/no-access')
            } else {
                next()
            }
        }).catch(() => {

        })
    },
    meta: {
        requiresAuth: true,
        title: 'Payments',
    }
}]

export default routes;
