import api from '../../api'
export default {
   addnavigationdrawer(params){
      return api.post('system-settings/add-navigation-drawer',params);
   },
   updatenavigationdrawer(params){
    return api.post('system-settings/update-navigation-drawer',params);
   },
   updatenavigationdraweraction(params){
    return api.post('system-settings/update-navigation-drawer-action',params);
   },
   getnavigationdrawer(){
    return api.get('system-settings/get-navigation-drawer');
  },
  deletenavigation(params){
    return api.put(`system-settings/delete-navigation-drawer/${params}`);
  },
  geticons(){
    return api.get('dropdown/get-icons');
  },
  deleteActions(params){
    return api.delete(`system-settings/delete-action/${params}`);
  },
  addnavlinkaction(params){
    return api.post('system-settings/add-navigatio-link-action',params)
  },
  savenavlinkaction(params){
    return api.post('system-settings/save-all-navigatio-link-action',params)
  },
  saveNavigationDrawer(params){
    return api.post('system-settings/save-navigation-drawer',params)
  },
  AddStatus(params){
    return api.post('/system-settings/statuses',params);
  },
  GetAllStatus(params){
      return api.get('/system-settings/statuses',{params});
  },
  UpdateStatus(params){
    return api.put(`/system-settings/statuses/${params.id}`,params.payload);
  },
  DeleteStatus(params){
    return api.delete(`/system-settings/statuses/${params}`);
  },
  accessReports(params){
      return api.get('/system-settings/accessReports',{params});
  },
}
